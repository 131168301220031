.tabs {

  overflow: auto;
  margin-top: 70px;

 

  .nav-tabs {
    width: 100%;
    overflow-x: auto;
    flex-wrap: inherit;
    border-bottom: none !important;

    li {
      .nav-link {
        height: 100%;
        font-size: 16px;
        font-weight: 700;
        color: $content-light;
        text-align: center;
        padding: 8px 45px;
        margin-left: 15px;
        margin-right: 15px;
        transition: background-color 0.3s;
        background-color: transparent;
        cursor: pointer;
       

        &:hover,
        &:focus,
        &:active {
        }

        &.nav-link-sm {
          padding: 8px 30px;
        }
        
        &.nav-link-xs {
          padding: 8px 10px;
        }
      }

      .nav-link.active,
      .nav-link.active:focus,
      .nav-link.active:hover {
        color: #F35321 ;
      }

      &.disabled .nav-link {
        color: #999999;
      }

    }
  }

  &.tabs--bordered-bottom {
    .nav-tabs {
      .nav-link {
        border-color: transparent;
        padding-bottom: 14px;
      }

      .nav-link.active {
        &,
        &:focus,
        &:hover {
          border-bottom: 4px solid #F35321;
        }
      }
    }
  }

  &.tabs--justify {
    .nav-tabs {
      display: flex;
      flex-wrap: nowrap;

      li {
        width: 100%;

        a {
          text-align: center;
        }
      }
    }
  }

  &.tabs--center {
    .nav-tabs {
      justify-content: center;

      @media (max-width: 600px) {
        justify-content: start;
      }
    }
  }

  &.tabs--left {
    .nav-tabs {
      padding-left: 30px;
    }
  }
}

.tabs2 {

  overflow: auto;
  margin-top: 70px;
  margin-bottom: 120px;

  .nav-tabs {
    width: 100%;
    overflow-x: scroll;
    flex-wrap: inherit;
    border-bottom: 0;
    align-items: center;

    li {
      .nav-link {
        height: 100%;
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        text-align: center;
        padding: 8px 10px;
        margin-left: 15px;
        margin-right: 15px;
        transition: background-color 0.3s;
        background-color: transparent;
        border: 0;
        cursor: pointer;
       

        &.nav-link-sm {
          padding: 8px 30px;
        }
        
        &.nav-link-xs {
          padding: 8px 10px;
        }
      }

      .nav-link.active,
      .nav-link.active:focus,
      .nav-link.active:hover {
        font-size: 40px;
      }

      &.disabled .nav-link {
        color: #999999;
      }

    }

  }
}


.nav-segment-secondary {
  position: relative;
  background-color: #f8f9fc;
  padding: 0.25rem 0.25rem;
  border-radius: 0.3125rem;

  .nav-link {
    color: #8492a6;
    padding: 0.4375rem 0.65625rem;
    border-radius: 0.3125rem;
    &.active {
      color: #1e2022;
      background-color: #fff;
      box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
    }
  }
}

.nav-segment-primary {
  position: relative;
  background-color: darken($light, 1%);
  border-radius: 0.3125rem;

  .nav-link {
    color: #8492a6;
    border-radius: 0.3125rem;

    &.active {
      background: #2f55d4;
      h6 {
        color: #fff !important;
      }
    }
  }
}


.tabs3 {
 
  overflow: hidden;
  margin-top: 70px;
  @media only screen and (max-width: 991px) {
    .margin-xs-5 {
      margin-bottom: 1.5rem;
    }
  }
  .nav-tabs {
    width: 100%;
    overflow-x: scroll;
    flex-wrap: inherit;
    border-bottom: 0;
    align-items: center;
    background: #F35321;
    border-radius: 10px;

    li {
      .nav-link {
        height: 100%;
        font-size: 16px;
        font-weight: 500;
        color: #ffff;
        text-align: center;
        padding: 8px 10px;
        margin-left: 15px;
        margin-right: 15px;
        transition: background-color 0.3s;
        background-color: transparent;
        border: 0;
        cursor: pointer;
       

        &.nav-link-sm {
          padding: 8px 30px;
        }
        
        &.nav-link-xs {
          padding: 8px 10px;
        }
      }

      .nav-link.active,
      .nav-link.active:focus,
      .nav-link.active:hover {
        font-size: 40px;
      }

      &.disabled .nav-link {
        color: #999999;
      }

    }

  }
}