

.material-table__wrap {
  border-radius: 17px;
  padding: 40px 32px 40px 32px;

  @media (max-height: 768px) {
    padding: 30px 20px 30px 20px;
  }

  .MuiPaper-root {
    font-family: 'Poppins', sans-serif;
    background: transparent !important;
    box-shadow: none !important;
    color: $content-light !important;
  }

  .MuiTableHead-root {
    th {
      font-family: 'Poppins', sans-serif;
      font-weight: 700;
      font-size: 16px;
      color: $content-light;
      border: none !important;
    }
  }

  .MuiTableRow-root {
    min-height: 72px;
    color: $content-light;
    vertical-align: middle;


    &:nth-child(odd) {
      background-color: $white;
    }

    td {
      font-weight: 400;
      font-size: 16px;
      border: none;
    }
  }

  .MuiTableRow-footer {
    border: none !important;
    background-color: transparent !important;
    padding-top: 1rem;
    display: flex;
    justify-content: end;
    .MuiToolbar-root {
      div {
        background-color: #dee2e6 !important;
        border-radius: 8px !important;

        .Mui-disabled.MuiButton-contained{
          background-color: #000 !important;
          border-radius: 10;
          color: #fff !important;
        }

        .Mui-disabled{
          background-color: #e9ecef !important;
          svg{
            fill: #adb5bd !important
          }
        }
      .MuiIconButton-root{
        padding: 10px !important;
        height: 30px;
      }
      span:first-child{
        .MuiIconButton-root {
          border-radius: 8px 0 0 8px !important;
        }
      }
      span:last-child{
        .MuiIconButton-root {
          border-radius: 0 8px 8px 0 !important;
        }
      }
      span > button{
        border-radius: 0 !important;
      }
      .MuiButtonBase-root{
        border: 1px solid #d4d9df !important;
        &:hover{
          background-color: #e1e1e1 !important;
        }
      }
  
      .MuiSelect-select{
        height: 17px;
        display: flex;
        align-items: center;
      }

        span {
          font-size: 13px;
          // color: #4e5156;
        }

        svg {
          width: 0.8rem !important; 
          height: 0.8rem !important;
          fill: #4e5156 !important;
        }
        // .Mui-disabled{
        //   background-color: #c !important;
        //   color: #adb5bd !important;
        // }
      }
    }

    .MuiTablePagination-selectRoot {
      background-clip: padding-box !important;
      background-color: #dee2e6 !important;
      border: 1px solid #dee2e6 !important;
      border-radius: 1rem !important;
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
      .MuiSvgIcon-root {
        width: 1.5rem !important;
        height: 1.5rem !important;
        fill: #4e5156 !important;
      }
    }

    .pagination {
      .active-page {
        background-color: #6C5DD3 !important;
        color: $white !important;
      }

      .page-link {
        border: 1px solid $muted !important;
        background-color: #E9ECEF;
        border: none !important;

        &:hover {
          background-color: #6C5DD3 !important;
        }
      }
    }
  }
}

.Component-horizontalScrollContainer-5 {
  border-radius: 20px !important;
}

.user-table-card {
  .MuiTableBody-root {
    --bs-gutter-x: 1.5rem !important;
    --bs-gutter-y: 0;
    display: flex !important;
    flex-wrap: wrap !important;
    padding-bottom: 10px;

    >* {
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-left: calc(var(--bs-gutter-x) * 0.5);
    }
  }
}


.notification-table {
  .material-table__wrap {
    background: transparent;
    padding: 0;

    .MuiTableRow-footer {
      padding-top: 0;
    }
  }
}

.trending-table {

  .material-table__wrap {
    background-color: white;

    .MuiTableHead-root {
      .MuiTableCell-head {
        font-size: 15px !important;
      }

      tr {
        th {
          background-color: #f2f5f9 !important;
          border: none !important;
        }

        th:first-child {
          border-radius: 20px 0 0 20px;
        }

        th:last-child {
          border-radius: 0 20px 20px 0;
          padding-right: 0px !important;
          margin-right: 0px !important;
        }
      }

    }
    .MuiTableBody-root{
      tr{
        &:hover{
          background-color: #e8edf4 !important;
        } 
      }
      .MuiTableRow-root {
        //background-color: "#f5f5f5";
        
        td:first-child{
          border-radius:20px 0 0 20px;
        }
        
      td:last-child{
          border-radius:0 20px 20px 0;

        }
    
    
        &:nth-child(even) {
          background-color: #f2f5f9;
        }
      }

 }
    .MuiTableRow-footer {
      padding-top: 1.2rem !important;
    }
  }
}

.all_recent_activities {
  .card-header {
    border-bottom: 0;
    background-color: transparent !important;
    margin-top: 1rem;
  }
  .recent_activities_table {

    .material-table__wrap {
      background-color: white;

      .MuiTableHead-root {
        tr {

          th:first-child {
            border-radius: 20px 0 0 20px;
            width: 100px;
          }

          th:last-child {
            border-radius: 0 20px 20px 0;
            width: 60%;
          }
        }

      }

      .MuiTableRow-root {
        td {
          font-weight: 400;
          font-size: 16px;
          border: none;
          overflow: hidden;
          padding-top: 0 !important;
        }
      }

    }
  }

  .timeline_table {
    padding-right: 20px;


    ul,
    li {
      list-style: none;
      padding: 0;
    }

    li {
      padding-bottom: 0rem;
      position: relative;

      &:last-child {
        border: 0px;
        padding-bottom: 0;
      }
    }

    .timeline-dotted {
      width: 20px;
      height: 20px;
      border-radius: 50px !important;
      position: relative;

      .timeline-dotted-line {
        background-color: #585C66 !important;
        margin-top: 21px !important;
        position: absolute !important;
        height: 32px !important;
        width: 3px !important;
        display: flex !important;
        justify-content: center !important;
        margin-left: 8px !important;
       }
    }
  }
}

.social-media-cards {
  // margin-right: 2rem !important;

  .material-table__wrap {
    padding-bottom: 0;
  }

  .MuiTableBody-root {
    --bs-gutter-x: 1.5rem !important;
    --bs-gutter-y: 0;
    display: flex !important;
    flex-wrap: wrap !important;
    padding-bottom: 10px;

    >* {
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-left: calc(var(--bs-gutter-x) * 0.5);
    }
  }
}

.btn-icon-table {
  width: 34px;
  height: 34px;
  font-size: 18px;
  border-radius: 4px;
  background-color: #ffffff;
  color: #A1ABA7 !important;
  transition: all 0.4s;
  overflow: hidden;
  z-index: 0;
  border: 0;
  justify-content: center;


  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    color: #002F6D !important;
  }
}

.hashtag_table {
  .material-table__wrap {
    background-color: white;

    .MuiTableHead-root {


      tr {

        th {
          border-top: 0 !important;
          min-width: 156px !important;
          max-width: 156px !important;
          padding-left: 30px !important;
          margin-right: 0px !important;
          padding-right: 0px !important;

          // .MuiTableSortLabel-icon{
          //   font-size: 20px !important;
          //   color: red !important;
          //   font-weight: 700 !important;
          //   width: 20px !important;
          // }
        }

        th:first-child {
          border-radius: 20px 0 0 20px;
          min-width: 350px !important;
        }

        th:last-child {
          border-radius: 0 20px 20px 0;
        }
      }
    }

    .MuiTableBody-root {
      font-weight: 500;
      font-size: 16px;

      .MuiTableRow-root {
        border-radius: 20px !important;
        &:hover{
          background-color: #e8edf4 !important;
        }
      }

      tr {
        // border: 1px solid gray !important;
        border-radius: 20px !important;
        color: black !important;

        td {
          // border: 2px solid gray !important;
          text-align: center !important;
        }

        td:first-child {
          border-radius: 20px 0 0 20px !important;
          margin-right: 0px !important;
          padding-right: 0px !important;
        }

        td:last-child {
          border-radius: 0 20px 20px 0;
          // border: 2px solid gray !important;
        }
      }

    }

  }
}
.MuiTableRow-footer {
  select {
    background-color: #17191e;
    border: 1px solid #d4d9df;
    color: #fff;
  }
  .MuiSelect-root{
    background-color: #dee2e6;
    border: 1px solid #d4d9df;
    color: #000;
  }

  .pagination-btn {
    background-color: #dee2e6;
    border: 1px solid #d4d9df;
    color: #000;
    &:hover {
      background-color: #e1e1e1 !important;
      border: 1px solid #d4d9df;
    }
  }
  .active{
    background-color: #000 !important;
    color: #fff !important;
    opacity: inherit;
    border: 1px solid #000;
    &:hover {
      background-color: #000 !important;
     
    }
  }
  .pagination-icon {
    background-color: #dee2e6;
    border: 1px solid #d4d9df;
    color: #000;
    &:hover {
      background-color: #e1e1e1;
      border: 1px solid #d4d9df;
    }
  }

  :disabled {
    background-color: #e9ecef;
    color: #adb5bd;
  }

}
.MuiInput-underline::before, .MuiInput-underline::after{
  border-bottom: none !important;
}