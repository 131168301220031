.ai-icons {
    margin-left: 1rem;
    font-size: 30px;
}
.ai-list-bg{
    // background-color: black !important;
    border-radius: 30px;
    // padding-bottom: 3rem;

.ai-list-card {
    height: 210px;
    border-radius: 20px;
    border: 0;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 20px 0px;
    z-index: 0;
    cursor: pointer;
    background-color: #17191e !important;


    &:hover {
        box-shadow: 1px 8px 20px #5a617a;
        -webkit-transition: box-shadow .6s ease-in;
    }

    .ai-header {
        border-radius: 20px 20px 0px 0px;
        background-color: transparent !important;
        border-bottom: 0;
        margin-top: .8rem;
    }

    .ai-body {
        h6 {
            font-weight: 400;
            padding-left: 15px;
            padding-right: 10px;
        }
    }
    .ai-starred-btn{
        padding: 8px !important;
        background-color: transparent !important;
        border: none !important;
        display: flex;
        align-items: center;
        justify-content: center;
        i{
            padding: 6px;
            &:hover{
                background-color: #ffee9050 !important;
                border-radius: 10px;
                
                
            }
            
        }

    }
}
}
.ai-card {
    border-radius: 20px;
    background-color: transparent !important;

    .card-header {
        border: none !important;
        border-radius: 20px;
        color: white;
        padding-bottom: 0;
        margin-bottom: 0;

        i {
            font-size: 35px !important;
        }
    }

    .form__form-group-input-wrap{
        textarea{
            background-clip: padding-box !important;
            // background-color: #383838 !important;
            // border: 1px solid #383838 !important;
            background-color: #17191e !important;
            border: 1px solid #17191e !important;
            border-radius: 1rem !important;
            box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
            color: #FFCF53;
            padding: 16px !important;
            
        }
        textarea::placeholder{
            font-size: 14px;
            font-weight: 400;
            padding-left: 3px;
            padding-top: 4px;
            font-size: 14px;
            font-weight: 500px;
            color: #a1a5b4 ;
        }

    }

    .submit-btn {
        background-color: transparent;
        border: 1px solid #798EFD;
        border-radius: 10px;
        color: #798EFD;
        font-size: 12px;
        font-weight: 400;

        &:hover {
            background-color: rgba(121, 142, 253, 0.2);
            border: 1px solid #798EFD;
            border-radius: 10px;
            color: #798EFD;
        }
    }

    .ai-tab {
        .tabs-bg {
            background-color: #242630 !important;
            padding: .5rem;
            border-radius: 20px;
    
        }
    
        .nav-link {
            margin-left: 5px;
            margin-right: 5px;
            border-radius: 15px;
            color: white;
            background-color: transparent;
            border: 0;
            padding: .6rem;
            padding-left: 1rem;
            padding-right: 1rem;
            width: 130px;
            &:hover {
                background-color: #414248 !important;
                border-color: #35373e !important;
            }
        }
    
        .nav-link {
            &.active {
                border-bottom: none !important;
                color: white !important;
                background-color: black !important;
    
                &:hover {
                    background-color: #414248 !important;
                    border-color: #35373e !important;
                }
            }
        }
    }

    .output-history {
        background-clip: padding-box !important;
        background-color: #17191e !important;
        border: 1px solid #17191e !important;
        border-radius: 20px;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
        padding-top: 1rem;
        // box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px, rgba(0, 0, 0, 0.12) 0px 1px 1px;
    }

    .nav-language {
        margin-top: 0 !important;
        background-color: transparent !important;
              
        .accordion-arrow{
            display: flex !important;
            justify-content: start !important;
            align-items: center !important;
        color: #fff !important;
      
          .social-accordion-title{
            color: #fff !important;
            font-weight: 600 !important;
            font-size: 14px !important;
            padding-left: 1rem !important;
            padding-right: .5rem !important;
            
          }
   
          i {
            font-size: 12px !important;
            padding-top: 0.2rem;
          }
        }
      }
}
.output-list{
    list-style: none;
    padding-left: .5rem;
    padding-right: .5rem;

        max-height: 25rem !important;
        overflow-y: scroll !important;


    .li-item{
        border: 2px solid #a1a5bc;
        border-style: dotted;
        border-radius: 20px;
        margin-bottom: 1rem;
        padding: 1rem;
        color: white;
    }
    .output-delete-btn{
        padding: 8px !important;
        background-color: transparent !important;
        border: none !important;
        display: flex;
        align-items: center;
        justify-content: center;
  
        &:hover{
            background-color: #fdd8cd !important;
            
        }
        i{
            color:#f35523;
        }
    }
    .ai-icons-output{
        font-size: 20px;
        margin-right: .5rem;

    }
    .output-fav-btn{
        padding: 8px !important;
        background-color: transparent !important;
        border: none !important;
        display: flex;
        align-items: center;
        justify-content: center;
        i{
            color: #8e959b;
        }
        &:hover{
            background-color: #fff7cc !important;
            i{
                color: #ffd800 !important;
            }
            
        }

    }
}