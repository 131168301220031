.sidebar {
    position: fixed;
    top: 11px;
    left: 11.5px;
    // z-index: 99;
    height: 97.5vh;
    // width: 230px;
    width: 200px;
    padding-top: 15px;
    transition: transform 0.3s, width 0.3s;
    transform: translateX(calc(-100% - 20px));
    background-color: rgba(0, 0, 0, 1);
    backdrop-filter: blur(6px);
    border-radius: 20px;
    z-index: 1001;

    
  
    a {
      display: block;
    }
  
    &.sidebar--show {
      transform: translateX(0);
  
      .sidebar__back {
        display: block;
      }
    }
  }
  
  .sidebar__back {
    height: 100%;
    width: 100vw;
    position: absolute;
    display: none;
    background: transparent;
    border: none;
  }
  
  .sidebar__link-active {
      background-color: #19191f !important;
    border-radius: 8px !important;
    .sidebar__link {
  
      &:before {
        opacity: 1;
      }
    }
  }
  
  .sidebar__scroll {
    width: 200px;
  
    & > div {
      height: calc(100vh - 60px);
    }
  
    .scrollbar-track {
  
      &.scrollbar-track-y {
        width: 2px;
        margin-right: 3px;
      }
  
      &.scrollbar-track-x {
        display: none !important;
      }
    }
  
    .scrollbar-thumb {
      opacity: 0.3;
      width: 5px;
    }
  }
  
  .sidebar__content {
    padding-top: 0px;
    // height: 44rem;
    height: 88.5vh;
    overflow: auto;
    & > div:last-child {
      width: 4px !important;
  
      div {
        transition: height 0.3s;
        opacity: 0.52;
      }
    }
   
  }
  
  .sidebar__block {
    padding: 0;
  
   
    &:last-child {
      border: none;
    }
  }
  
  .sidebar__link {
    height: 47px;
    width: 100%;
    transition: all 0.3s;
    position: relative;
    cursor: pointer;
    display: flex;
    padding: 11px 20px;
    overflow: hidden;
    background: transparent;
    border: none;
    color: white !important;
    overflow-x: none;
    // left: 5.5px;
    align-items: center;    
    
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(252, 252, 252, 0.1);
        border-radius: 8px;
      opacity: 0;
      transition: all 0.3s;
    }
  
  
    p {
      position: absolute;
      left: 36px;
      width: 160px;
      transition: left 0.3s;
      top: 50%;
      transform: translateY(-50%);
    }
  
    &:hover {
      text-decoration: none;
      background-color: rgba(252, 252, 252, 0.1);
        color: rgb(255, 255, 255);
        border-radius: 8px;
        
      p{
        color: #ffffff !important;
      }
      .sidebar__category-icon{
        color: #ffffff !important;
      }
      .sidebar__link-icon{
        color: #ffffff !important;
      }
      &:before {
        opacity: 1;
      }
    }
  
  }

  
  .sidebar__link-title {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    position: relative;
    display: flex;
    padding-left: 10px;

    font-family: 'Poppins', sans-serif;
   
  }
  
  .sidebar__link-icon {
    margin-right: 10px;
    font-size: 15px;
    line-height: 15px;
    color: #ffffff;
    width: 15px !important;
    display: flex;
    justify-content: center;
  }
  
  .sidebar__submenu {
    transition: height 0.5s 0s, padding 0.5s 0s, opacity 0.4s 0.1s;
    padding: 10px 0;
    background-color: #0000 !important;
  
    & .sidebar__submenu {
      margin-bottom: 0;
      padding-bottom: 0;
      padding-top: 0;
  
      .sidebar__link {
        padding-left: 53px !important;
        display: flex !important;
        align-items: center !important;
  
        p {
          left: 53px !important;
        }
      }
    }
  
  
    .sidebar__link {
      padding-left: 53px !important;
      display: flex !important;
      align-items: center !important;

      p {
        left: 65px !important;
      }
    }
  
    .sidebar__link {
      display: flex;
      align-items: center;
      
    }
  }
  
  .sidebar__category-icon {
    position: absolute;
    right: 15px;
    font-size: 14px;
    line-height: 14px;
    margin-top: 4px;
    opacity: 1;
    transition: opacity 0.5s 0.2s, transform 0.3s;
    color: #fff;
  
  }
  
  .sidebar__link-badge {
    width: 26px;
    height: 14px;
    background-color: $danger;
    font-size: 8px;
    font-weight: 400;
    padding: 2px;
    margin-left: 5px;
    line-height: 9px;
    position: relative;
    text-transform: uppercase;
    border-radius: 7px;
  
    span {
      position: absolute;
      left: 0;
      top: 3px;
      width: 26px;
      text-align: center;
    }
  }
  
  .sidebar__wrapper--desktop {
    display: none;
  }
  
  .sidebar__category-wrap {
  
    &.sidebar__category-wrap--open {
  
      .sidebar__category-icon {
        transform: rotate(90deg);
        
      }
    }
  }
  
  .sidebar__category-new {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    top: -3px;
    display: block;
    margin-left: 5px;
    background: $danger;
  }
  
  @media screen and (min-width: 900px) {
  
    .sidebar {
      transform: translateX(0);
  
      &.sidebar--no-desktop {
        transform: translateX(calc(-100% - 20px));
  
        &.sidebar--show {
          transform: translateX(0);
        }
  
        .sidebar__wrapper--mobile {
          display: block;
        }
      }
  
      &.sidebar--collapse {
        width: 55px;
        overflow: visible;
        z-index: 2;

  
        .sidebar__scroll, .sidebar__content {
          width: 55px !important;
          overflow: visible !important;
          transition: width 0.3s;
        }
  
        .sidebar__submenu {
          padding: 0 0 15px 0;
          transition: 0s;
        }
  
        .sidebar__category-wrap {
  
          &:hover {
  
            .sidebar__category {
              width: 240px;
  
              
  
              &:before {
                opacity: 1;
              }
            }
  
            .sidebar__submenu-wrap {
              width: 185px;
            }
          }
        }
  
        .sidebar__submenu-wrap {
          position: absolute;
          left: 55px;
          width: 0;
          transition: 0.3s;
          overflow: hidden;
  
          .sidebar__link {
            width: 185px;
            padding-left: 15px;
  
            p {
              position: relative;
              left: 0;
              animation: none;
            }
          }
  
          .sidebar__submenu-wrap {
            position: relative;
            left: 0;
  
            .sidebar__link {
              padding-left: 30px;
            }
          }
        }
  
        .sidebar__link {
          overflow: hidden;
          width: 55px;
          background-color: transparent;
  
          p {
            position: absolute;
            left: 70px;
            width: 160px;
          }
  
          &:hover {
            width: 240px;
  
         
          }
        }
  
        .sidebar__category-icon {
          opacity: 0;
          transition: opacity 0s;
        }
  
        .scrollbar-track.scrollbar-track-y {
          margin-right: 188px;
        }
  
      }
    }
  
    .sidebar__wrapper--desktop {
      display: block;
    }
  
    .sidebar__wrapper--mobile {
      display: none;
    }
  
  }
  
  @media screen and (min-width: 992px) {
  
    .sidebar.sidebar--no-desktop {
      transform: translateX(0);
      display: none;
    }
  }
  .card-header{
    span{
      color: #34445e;
    }
  }
    .sidebar__button {
    width: 50px;
    height: 50px;
    display: flex;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: 0.3s;
   
  
  
    &:hover {
      outline: none;
      border-radius: 8px;
      background-color: rgba(252, 252, 252, 0.1);
      
    }
    &:focus {
      background-color: transparent;
      border-radius: 8px;
    }
    &:active{
      border: 0.5px solid $white;
    }
  }


  .sidebar-opacity-bg {
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(6px);
    z-index: 99999;

  }
  @media screen and (max-width: 900px) {
    .sidebar__button--desktop{
      display: none!important;
    }
  }
  @media screen and (min-width: 900px) {
    .sidebar__button--mobile{
      display: none!important;
    }
  }
  .sidebar-logout{
    width: 100%;
    background: transparent;
    border: none;
    padding: 0;
        &:hover{
      outline: none;
      background-color: rgba(252, 252, 252, 0.2);
      border-radius: 8px;
    }

  }