.auth-section {
    width: 100%;
    height: auto;
    min-height: 900px;
    background-color: #1f2127;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
      
    @media (max-height: 750px) { 
      height: auto;
      padding: 180px 0px;
     
    }
    .tabs {
      background-color: #dedede;
      border-radius: 20px;
      height: 65px;
      display: flex;
      align-items: center;
      .nav-tabs {
        display: flex;
        justify-content: center;
        padding: 0 !important;
      }
  
      .nav-link {
        background-color: transparent !important;
        border-radius: 10px !important;
     
      }
  
      .active {
        background-color: #323232 !important;
        border: none !important;
        color: #fff !important;
        display: flex;
        align-items: center;
        justify-content: center;
  
      }
    }
  
  
    button {
      padding: 12px 30px;
      margin-top: 2rem;
      background-color: #0f8279;
      border-color: #0f8279;
    }
  }
  
  .signup-section {
    background-color: #1f2127 !important;
    .signup-card {
      width: 615px;
      // height: auto;
      border-radius: 20px;
      margin: 20px 0px;
  
      .card-body {
        padding: 55px 50px 35px 50px;
      }
    }
    .bg-l10-dark, .bg-l10-dark-hover:hover {
      background-color: #e9e9ea!important;
    }
    .btn-dark {
    border-radius: 0.8rem!important;
    color: #fff;
    background-color:#35373e;
    }
  
    .btn-light-dark {    
      border-radius: 0.8rem!important;
       color: #35373e;
       background-color:#e9e9ea ;
       border: #e9e9ea;
    }
    .btn-light-dark:hover {
    color:#fff;
    background-color:#35373e;
    border: #35373e;
    }
  
  }
  
  .login-section {
    background-color: #1f2127 !important;
    @media (min-height: 60) { 
      height: auto;
      padding: 180px 0px !important;
    }
    @media (max-height: 50rem) { 
      height: auto;
      padding: 90px 0px ;
    }
    
    .login-card {
      width: 1280px;
      height: auto;
      border-radius: 20px;
      margin: 0 10px;
  
      .card-body {
        padding: 65px 150px;
  
        @media (max-width: 768px) { 
          padding: 50px 100px;
        }
        @media (max-width: 576px) { 
          padding: 30px 60px;
        }
      }
  
      .card-image {
        width: 550px;
        height: auto;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        background-image: url("../../../assets/images/auth/signup-bg-without-text.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
  
        @media (max-width: 1300px) { 
          width: 400px;
        }
  
        @media (max-width: 992px) { 
          display: none
        }
      }
  
    }
  
  
    .bg-l10-dark, .bg-l10-dark-hover:hover {
      background-color: #e9e9ea!important;
    }
    .btn-dark {
    border-radius: 0.8rem!important;
    color: #fff;
    background-color:#35373e;
    }
  
    .btn-light-dark {    
      border-radius: 0.8rem!important;
       color: #35373e;
       background-color:#e9e9ea ;
       border: #e9e9ea;
    }
    .btn-light-dark:hover {
    color:#fff;
    background-color:#35373e;
    border: #35373e;
    }
  }
  
  .forgot-pass{
    max-width: 500px;
    min-width: 500px;
    .forgot-card{
      border-radius: 20px;
    }
    .send_reset{
      border-radius: 1.50rem;
    }
  }
  
  .auth-section-form {
  
    .form-control {
      padding: 15px 13px !important;
      border: 1px solid #dedede !important;
      border-radius: 50px !important;
      font-size: 14px;
  
      &::-webkit-input-placeholder {
        font-size: 14px;
        color: #9ea2b1;
      }
      &::-moz-placeholder {
        font-size: 14px;
        color: #9ea2b1;
      }
      &:-ms-input-placeholder {
        font-size: 14px;
        color: #9ea2b1;
      }
      &:-moz-placeholder {
        font-size: 14px;
        color: #9ea2b1;
      }
    }
    .form-control-error-label {
      font-size: 10px;
      line-height: 13px;
      color: $red;
      margin-bottom: -5px;
      display: block;
      margin-top: 5px;
    }
    .btn {
      border-radius: 20px;
    }
  }
  .reset-pass-btn{
    // background-color: rgba(77,105,250,.3);
    // border: 1px solid rgba(77,105,250,.1);
    // border-radius: 30px !important;
    // color: #4d69fa;
    // font-size: 12px;
    // font-weight: 600;
    padding: 12px 30px;
    background-color: #0f8279;
    border-color: #0f8279;
    border-radius: 30px !important;
  //   &:hover {
  //     background-color: rgba(77,105,250,.8);
  //     border: 1px solid rgba(77,105,250,.1);
  //     border-radius: 15px;
  //     color: #fff;
  // }
  }
  .change-pass-btn{
    background-color: rgba(77,105,250,.3) !important;
    border: 1px solid rgba(77,105,250,.1) !important;
    // border-radius: 10px !important;
    color: #4d69fa;
    font-size: 15px;
    font-weight: 600;
    &:hover {
      background-color: rgba(77,105,250,.8) !important;
      border: 1px solid rgba(77,105,250,.1) !important;
      // border-radius: 15px;
      color: #fff;
  }
  }
  .invalid-email-icon{
    color: #dc3444;
    font-size: 90px;
  }
  .email-card {
    width: 450px;
    // height: auto;
    border-radius: 20px;
    margin: 20px 0px;

    .card-body {
      padding: 50px 30px 35px 30px;
    }
  }