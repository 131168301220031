
// Color
$white:                     #ffffff;
$muted:                     #B7B7C1;
$primary:                   #0F8279; 
$primary-light:             #B0CADC;
$primary-lighter:           #E7E7E7;
$yellow:                    #FFBE3F;
$yellow-light:              #ffefcf;
$content:                   #32334b;
$purble:                    #6a40bc;
$orange:                    #F35321;
$orange-light:              #f78764;
$purble-light:              #D1CFEC;
// Gray
$gray-100:                  #f8f9fa;
$gray-200:                  #e9ecef;
$gray-300:                  #dee2e6;
$gray-400:                  #ced4da;
$gray-500:                  #adb5bd;
$gray-600:                  #6c757d; 
$gray-700:                  #495057;

// Overlay
$overlay:                   rgba($dark, 0.7);
$bg-overlay-white:          rgba($white, 0.5);

//Body Background
$shadow:                    0 5px 20px 10px rgba($dark,.15);
$shadow-xs:                 0 5px 13px rgba($dark, 0.05);
$shadow-md:                 0 5px 13px rgba($dark, .2);
$shadow-lg:                 0 10px 25px rgba($dark, 0.15);

$footer:                    lighten($black, 6%);

// Base font
$font-size-base:            12px;

$font-family-base:          'Poppins', sans-serif !important;

$font-family-secondary:     'Poppins', sans-serif !important;


//Color Picker/Switcher
$default:                   #2f55d4;
$green:                     #6dc77a;
$red:                       #ff5b69;
$red-light:                 #ffe6e8;
$purple:                    #7952B3;
$skyblue:                   #32c2ec;
$skobleoff:                 #0f7173;
$cyan:                      #00c9a7;
$slateblue:                 #766df4;
$success:                   #45BBA8;
$danger:                    #DC3545;
$content2:                  #06071B;
$content-light:             #646d82;
$content-lighter:           #D9D9D9;


$btn-close-bg-dark:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$black}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>");
$btn-close-width:            1em;



$colors: (
    "primary": $primary,
    "primary-light": $primary-light,
    "primary-lighter": $primary-lighter,
    "yellow": $yellow,
    "yellow-light": $yellow-light,
    "muted": $muted,
    "content": $content,
    "purble": $purble,
    "content2": $content2,
    "content-light": $content-light,
    "content-lighter": $content-lighter,
    "orange": $orange,
    "success": $success,
    "red": $red,
    "red-light": $red-light,
    "purble-light": $purble-light,
    "orange-light": $orange-light
);

$heading-font-sizes: (
    "h1": 42px,
    "h2": 36px,
    "h3": 30px,
    "h4": 24px,
    "h5": 20px,
    "h6": 16px,
);

$display-font-sizes: (
    "display-1": 80px,
    "display-2": 72px,
    "display-3": 64px,
    "display-4": 56px,
);









$base-font-size: 12px !default;
$body-direction: ltr !default; // Default ltr, change it to rtl for Right To Left support.
$content-padding: 2rem !default;

//  ------------------------------
//    Colors
//  ------------------------------
$nav-component-border-color: #ddd !default;

$swiper-bg: #f2f4f4 !default;

//  ------------------------------
//    Navbar
//  ------------------------------
$navbar-height: 4.45rem !default;
$horizontal-menu-height: 4.45rem !default;
$footer-height: 3.35rem !default;

//  ------------------------------
//    Main Menu
//  ------------------------------

//main menu dark
$menu-dark-color: #dcdcdc !default;
$menu-dark-bg-color: #10163a !default;

$menu-padding: 10px 15px 10px 15px !default;
$menu-second-level-padding: 10px 15px 10px 20px !default;
$menu-third-level-padding: 10px 15px 10px 53px !default;
$menu-forth-level-padding: 10px 15px 10px 53px !default;

// vertical menu
$menu-expanded-width: 260px !default;
$menu-collapsed-width: 80px !default;

//  ------------------------------
//    Sidebar
//  -------------------------------
$sidebar-width: 260px !default;
$chat-sidebar-width: 360px !default;



//  -------------------------------
//  Blank Page Bg Color
//  -------------------------------

$blank-bg-color: #eff2f7 !default;

//  -------------------------------
//  Data Tables Bg Color
//  -------------------------------
$datatable-bg-color: #f8f8f8 !default;

//  -------------------------------
//  Timeline
//  -------------------------------
$timeline-border-color: $border-color !default;

$timeline-point-size: 1.75rem !default;
$timeline-point-indicator-size: 12px !default;
$timeline-point-indicator-color: $primary !default;
$timeline-point-indicator-wrapper-size: 20px !default;

$timeline-item-min-height: 4rem !default;
$timeline-item-icon-font-size: 0.85rem !default;

$timeline-event-time-size: 0.85rem !default;
$timeline-event-time-color: $text-muted !default;

// ------------------------------------------------
// custom options
// ------------------------------------------------
$custom-options-bg-color: rgba($secondary, 0.06);


//Avatar
.avatar {
  overflow: hidden;

  &.avatar-xxs {
    width: 35px;
    height: 35px;
    min-width: 35px;
    min-height: 35px;
    max-width: 35px;
    max-height: 35px;
  }
  &.avatar-xs {
    width: 45px;
    height: 45px;
    min-width: 45px;
    min-height: 45px;
    max-width: 45px;
    max-height: 45px;
  }
  &.avatar-sm {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    max-width: 50px;
    max-height: 50px;
  }
  &.avatar-md {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    max-width: 60px;
    max-height: 60px;
  }
  &.avatar-lg {
    width: 75px;
    height: 75px;
    min-width: 75px;
    min-height: 75px;
    max-width: 75px;
    max-height: 75px;
  }
  &.avatar-xl {
    width: 85px;
    height: 85px;
    min-width: 85px;
    min-height: 85px;
    max-width: 85px;
    max-height: 85px;
  }
  &.avatar-xxl {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
    max-width: 100px;
    max-height: 100px;
  }
  &.avatar-xxml {
    width: 150px;
    height: 150px;
    min-width: 150px;
    min-height: 150px;
    max-width: 150px;
    max-height: 150px;
  }
  &.avatar-xxxl {
    width: 200px;
    height: 200px;
    min-width: 200px;
    min-height: 200px;
    max-width: 200px;
    max-height: 200px;
  }
  &.avatar-square {
    width: 120px;
    height: 120px;
    min-width: 120px;
    min-height: 120px;
    max-width: 120px;
    max-height: 120px;
  }
  &.avatar-square-xl {
    width: 300px;
    height: 240px;
    min-width: 300px;
    min-height: 240px;
    max-width: 300px;
    max-height: 240px;
  }
  &.avatar-square-lg {
    width: 260px;
    height: 120px;
    min-width: 260px;
    min-height: 240px;
    max-width: 246px;
    max-height: 240px;
  }
  &.avatar-square-product {
    width: 70px;
    min-width: 70px;
    max-width: 70px;
    max-height: 70px;
    min-height: 70px;
  }
  &.avatar-user-lg {
    min-width: 60px;
    min-height: 60px;
    max-width: 60px;
    max-height: 60px;
  }
  @media screen and (min-width: 642px) {
    &.topRight-img {
      width: 50px;
      height: 50px;
      min-width: 50px;
      min-height: 50px;
      max-width: 50px;
      max-height: 50px;
    }
  }
  @media screen and (max-width: 642px) {
    &.topRight-img {
      margin-left: 1rem;
      font-size: 18px !important;
      width: 35px;
      height: 35px;
      min-width: 35px;
      min-height: 35px;
      max-width: 35px;
      max-height: 35px;
    }
  }
  // @media (max-width: 414px){
  //   &.avatar-square-analytics {
  //     width: 250px !important;
  //     height: 250px !important;
  //   }
  // }
  // @media (min-width: 415px){
  //   &.avatar-square-analytics {
  //     width: 200px;
  //     min-width: 200px;
  //     max-width: 200px;
  //     height: 190px;
  //   }
  // }
  &.avatar-square-analytics {
    width: 150px;
    min-width: 150px;
    max-width: 150px;
    height: 190px;
  }
 
  &.rounded-square{
    border-radius: 8px;
  }
  &.rounded-square-small{
    border-radius: 10px;
  }
  &.avatar-padding {
    background-color: #D1CFEC;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.scheduler-account-padding {
    background-color: #000;
    color: #e4e6eb;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.store-avatar-padding {
    background: transparent;
    border: 2px solid #303538;
    border-radius: 50%;
    color: #303538;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.avatar-border {
    border: 1px solid rgba(196, 196, 196, 0.5);
  }
  &.avatar-shadow-sm {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  &.avatar-shadow {
    box-shadow: 0px 3.9px 7.8px rgba(0, 0, 0, 0.2);
  }
  &.avatar-shadow-lg {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  }
}
.avatar-group{
  .avatar-group-item{
    box-sizing: initial;
    cursor: pointer;
    position: relative;
    border: 2px solid #c5c5c5;
    border-radius: 50%;
    &:nth-last-child(3){
      z-index: 3;
    }
    &:nth-last-child(2){
      margin-left: -.8rem;
      z-index: 2;
    }
    &:last-child{
      margin-left: -.8rem;
      z-index: 1;
    }
  }
}

// Badges
.badge {
  padding: 5px 10px;
  font-weight: 600;
  align-items: center;
  border-radius: 5px;
  align-self: center;
  color: #2e2e2e;

  &.badge-sm {
    padding: 7px 10px;
  }
  &.badge-md {
    padding: 7px 14px;
  }
  &.badge-lg {
    padding: 10px 20px;
  }
  &.badge-xl {
    padding: 10px 35px;
  }
  &.badge-severity {
    max-width: 34px;
    height: 25.5px;
    padding: 5px;
  }
  &.badge-link {
    &:hover {
      color: $white !important;
    }
  }
}

@each $name, $value in $colors {
  .badge-#{$name} {
    background-color: #{$value} !important;
    color: $white;
  }

  .badge-outline-#{$name} {
    background-color: transparent !important;
    color: #{$value} !important;
    border: 2px solid #{$value} !important;
  }
}

.badge-pills {
  border-radius: 8px;
}
.badge-rounded{
  border-radius: 8px;
}
.badge-circle{
  border-radius: 50rem;
}

.badge-notification-pill {
  width: 20px;
  height: 20px;
  padding: 3px 5px;
  line-height: 12px;
}

//Pagination
.pagination {
  .page-item {
    &:first-child .page-link {
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
    }
    &:last-child .page-link {
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
    }
    .page-link {
      color: $dark;
      border: 1px solid $gray-300;
      padding: 8px 16px;
      font-size: 14px;
      &:focus {
        box-shadow: none;
      }
      &:hover {
        color: $white;
        background: rgba($primary, 0.9);
        border-color: rgba($primary, 0.9);
      }
    }
    &.active {
      .page-link {
        color: $white;
        background: $primary !important;
        border-color: $primary;
        cursor: not-allowed;
      }
    }
  }
}

//Buttons

@each $name, $value in $colors {
  .btn-#{$name} {
    background-color: #{$value} !important;
    border: 1px solid #{$value} !important;
    color: $white !important;
    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus {
      background-color: darken($value, 10%) !important;
      border-color: darken($value, 10%) !important;
      color: $white !important;
    }
  }

  .btn-soft-#{$name} {
    background-color: rgba($value, 0.1) !important;
    border: 1px solid rgba($value, 0.1) !important;
    color: #{$value} !important;
    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus {
      background-color: #{$value} !important;
      border-color: #{$value} !important;
      color: $white !important;
    }
  }

  .btn-outline-#{$name} {
    border: 1px solid #{$value};
    color: #{$value};
    background-color: transparent;
    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus,
    &:not(:disabled):not(.disabled):active {
      background-color: #{$value};
      border-color: #{$value};
      color: $white !important;
    }
  }
  .btn-focus-none {
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }



  .btn-submit {
    font-weight: 400 !important;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus,
    &:not(:disabled):not(.disabled):active {
      background: rgba(1, 223, 184, 0.1);
      border: 1px solid rgba(1, 223, 184, 0.1);
      color: $content !important;
    }
  }

}
.btn {
  padding: 9px 21px;
  outline: none;
  text-decoration: none;
  font-size: 18px;
  font-weight: 700;
  transition: all 0.3s;
  border-radius: 6px;

  &:focus {
    box-shadow: none !important;
  }
  &.btn-md {
    padding: 9px 30px;
  }
  &.btn-lg {
    padding: 14px 35px;
  }
  &.btn-pills {
    border-radius: 8px;
  }
  &.btn-light {
    color: $dark !important;
    border: 1px solid $gray-300 !important;
    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus {
      background-color: darken($light, 10%) !important;
      color: $dark !important;
    }
  }
  &.btn-soft-light {
    color: rgba($dark, 0.5) !important;
    border: 1px solid $gray-300 ;
    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus {
      color: $dark !important;
    }
  }
  &.btn-outline-light {
    border: 1px solid $gray-300 !important;
    color: $dark !important;
    background-color: transparent;
    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus {
      background-color: $light !important;
    }
  }
  &.btn-icon {
    height: 36px;
    width: 36px;
    line-height: 34px;
    padding: 0;
    .icons {
      height: 16px;
      width: 16px;
      font-size: 16px;
    }

    &.btn-xsm {
      height: 26px;
      width: 26px;
      line-height: 0px;

      .icons {
        height: 14px;
        width: 14px;
        font-size: 14px;
      }
    }
    &.btn-sm {
      height: 30px;
      width: 30px;
      line-height: 28px;
      font-size: 14px;
    }
    &.btn-lg {
      height: 48px;
      width: 48px;
      line-height: 46px;
      .icons {
        height: 20px;
        width: 20px;
        font-size: 20px;
      }
    }
  }
}

button:not(:disabled) {
  outline: none;
}


//Form

.form-rounded {
  .form-control {
    // padding: 11px 13px !important;
    // border: 1px solid #dedede !important;
    // background-color: #ffffff !important;
    // border-radius: 50px;
    // font-size: 14px;
    // font-weight: 400;
    font-size: 12px;
    height: 40px;
    &.form-control-lg {
      padding: 14px 13px !important;
    }

    &::-webkit-input-placeholder {
      font-size: 14px;
      color: #9ea2b1 !important;
    }
    &::-moz-placeholder {
      font-size: 14px;
      color: #9ea2b1 !important;
    }
    &:-ms-input-placeholder {
      font-size: 14px;
      color: #9ea2b1 !important;
    }
    &:-moz-placeholder {
      font-size: 14px;
      color: #9ea2b1 !important;
    }
  }
  .form-description {
    margin-bottom: 15px;
    font-size: 13px;
    font-weight: 400;
    color: $muted;
    padding-left: 12px !important;
  }
  .form-label {
    padding-left: 12px !important;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 12px;
  }

  textarea {
    border-radius: 20px !important;
  }

  .react-select,
  .react-select-multi-container,
  .react-select-skill-interest {
    .react-select__control {
      border-radius: 50px !important;
      height: 45px ;
      min-height: 45px ;
      max-height: 45px ;
      background-color: #ffffff !important;

      .react-select__value-container {
        padding: 4px 13px !important;

        .react-select__placeholder {
          font-size: 12px !important;
          color: #9ea2b1 !important;
        }

        .react-select__single-value {
          font-size: 14px !important;
          color: #212529 !important;
        }
      }
    }
    .react-select__option {
      font-size: 14px !important;
      color: #212529 !important;
    }
    .react-select__option--is-selected {
      color: #ffffff !important;
    }

    .react-select__menu-list {
      max-height: 235px !important;
    }
  }
  .max-height.react-select {
    .react-select__menu-list {
      max-height: 153px !important;
    }
  }

  .react-select__menu {
    ::-webkit-scrollbar {
      width: 0em;
      height: 0em;
    }
    overflow: auto;
    z-index: 1000 !important;
  }
  .react-select-3-listbox {
    padding-bottom: 10rem !important;
    margin-bottom: 10rem !important;
    position: absolute;
  }
}

.form-label {
  font-weight: 600;
  // font-size: 16px;
  color: $content;
  padding-left: 10px;
}

.form-control-error-label {
  font-size: 10px;
  line-height: 13px;
  color: $red;
  margin-bottom: -5px;
  display: block;
  margin-top: 5px;
}

.form-control {
  // padding: 13px 13px !important;
  // border: 1px solid #dedede !important;
  // border-radius: 8px !important;
  // font-size: 14px;
  font-size: 12px;
  height: 40px;
  // background-clip: padding-box !important;
  // background-color: #f8f9fa !important;
  // border: 1px solid #f8f9fa !important;
  // border-radius: 8px !important;
  // box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
  &::-webkit-input-placeholder {
    font-size: 14px;
    color: #9ea2b1;
  }
  &::-moz-placeholder {
    font-size: 14px;
    color: #9ea2b1;
  }
  &:-ms-input-placeholder {
    font-size: 14px;
    color: #9ea2b1;
  }
  &:-moz-placeholder {
    font-size: 14px;
    color: #9ea2b1;
  }

  &:focus {
    border-color: $primary;
    box-shadow: none;
  }
  &[readonly] {
    background-color: $white !important;
  }
  &:disabled {
    background-color: $gray-300;
  }
  &.form-control-error {
    border: 1px solid $red;
  }
}

.support-filter {
  padding: 10px 35px 10px 25px !important;

  select {
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;
    font-size: 26px;
    background: #fff !important;
    color: white !important;
  }

  .form-select {
    background-color: rgb(0, 0, 0) !important;
    border: none !important;
    border-radius: 30px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    height: 42px !important;
    // margin-top: 0.5rem;
    color: #ffffff !important;
    padding: 0px 32px 0px 10px !important;

    .css-1xc3v61-indicatorContainer {
      color: #4D68F5 !important;
      color: #CCCCCC !important;
    }
  }

  .form-control {
    &:first-child {
      padding-left: 55px !important;
    }
  }

}
.text-blue{
  color:#4d69f9;
}
.text-yellow{
  color:#fdce50;
}
.text-pink{
  color:#ff59b2;
}
