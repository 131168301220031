.amount-spent{
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #542e26;
    border-radius: 10px;
    margin-right: 1rem;
    i{
        font-size: 25px;
        color: #f35321;
    }
}
.orders-count{
    padding: 1rem 1.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2b335d;
    border-radius: 10px;
    margin-right: 1rem;
    i{
        font-size: 25px;
        color: #4d69fa;
    }
}
.customer-tab {
    // margin-bottom: 2rem !important;
    .nav-link {
        color: white !important;
        background-color: #323643 !important;
          border-radius: 8px;
          border: none !important;
          // padding: 10px 25px 10px 25px;
          margin-left: 2px;
          margin-right: 8px;
          width: 120px;
          margin-top: 10px;
      }
      .nav-link:hover{
        background-color: #414248 !important;
        border-color: #35373e !important;
        color: white !important;
      }
    
      .nav-link {
          &.active {
            background-color: #e9ecef !important;
          color: black !important;
            border-bottom: none !important;

            &:hover{
                background-color: #414248 !important;
                border-color: #35373e !important;
            }
          } 
      }
    
  }
  .customers-filter {
    .form-check-input{
      width: 1.3rem !important;
      height: 1.3rem !important;
    }
    .filter-value-middle {
      display: flex;
      justify-content: start;
    }
  
    .form-control {
      background-clip: padding-box !important;
      background-color: #2b2f38 !important;
      border: 1px solid #2b2f38 ;
      border-radius: 8px !important;
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
      color: #fff;
    }
  
    .form-control.right {
      border-radius: 8px !important;
  
    }
  
    .form__form-group {
      width: 100% !important;
    }
  }
  .filter-total-spent{
    font-size: 19px;
    color: #f35321;
  }
  .filter-orders{
    font-size: 19px;
    color: #4d69fa;
  }
  .filter-country{
    font-size: 19px;
    color: #fcce50;
  }
  .filter-country-type{
    font-size: 19px;
    color: #44bcab;
  }