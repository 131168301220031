.fc-toolbar-title{
    color: #fff ;
}
.calendar-day{
    color: #fff;
}
.calendar-day-name{
    color: #7f7f7f;
}
.calendar-day.active{
    background-color: #3176dc;
    // padding: 5px 10px;
    border-radius: 50rem;
    width: fit-content;
    color: #fff;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.border-line{
    border-bottom: 1.6px solid #93939342;
}
.upload-button{
    border: none;
    outline: none;
    padding: 10px 30px;
    border-radius: 8px;
    background: #323643;
    box-shadow: 0px 1px 6px 2px #00000030;
    color: #fff;
}
.fc-timeGridWeek-view .fc-day-today:not(.fc-col-header-cell) {
    background-color: #131317;
}
.fc-daygrid-day.fc-day-today {
    background-color: #131317;

}
.upload-area{
    background: #18191d;
    padding: 30px 40px;
    border-radius: 16px;
    border: 2px dashed #6c757d;
}
.calendar-textarea{
    border: 2px solid #6c757d;
    border-radius: 16px;
    margin-top: 20px;
    overflow: hidden;
    position: relative;
    textarea{
        background: transparent;
        border-bottom: 2px solid #6c757d !important;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        width: 100%;
        height: 100px;
        padding: 10px;
        outline: none;
        color: #fff;
    }
}
.fc-timegrid-slot-label-cushion{
    color: #fff;
}
.fc-theme-standard{
    th{
        border: 0 !important;
        border-bottom: 1px solid #93939342 !important;
        border-top: 1px solid #93939342 !important;
    }
    .fc-scrollgrid{
        border: 0 !important;
    }
}
.fc-timegrid-slot {
    border: 1px solid #93939342 ;
}
.fc .fc-timegrid-slot,
.fc .fc-timegrid-slot-lane {
  border-color: #3d3e43 !important; 
}
.fc .fc-timegrid-slot:not(.fc-timegrid-slot-lane) {
    border-right: 1px solid #3d3e43 ;
  }
  .fc-theme-standard td{
    border: 1px solid #3d3e43 ;
  }

.fc-dayGridMonth-button, .fc-timeGridWeek-button, .fc-timeGridDay-button{
    color: #7f7f7f ;
    border: 1.5px solid #101215 !important;
    background: transparent !important;
    &:hover{
        background-color: rgba(16,18,21, .2);
        color: #7f7f7f ;
    }
}
.fc-button-active{
    background-color: #101215 !important;
    border: 1.5px solid #313540 !important;
    color: #fff ;
    z-index: 0 !important;
}
.fc-next-button, .fc-prev-button, .fc-today-button{
    outline: none;
    padding: 6px 12px;
    border-radius: 8px;
    margin: 0px 7px;
    border: 1.8px solid #313540 !important;
    box-shadow: 0px 1px 5px 2px #272a31 !important;
    background: #101215 !important;
    color: #fff;
    font-size: 14px;
    &:hover{
        border: 1.8px solid #313540;
        box-shadow: 0px 1px 5px 2px #272a31;
        background: #101215;
        color: #fff;
    }
}
.fc-today-button:disabled {
    border: 1.8px solid #313540;
    box-shadow: 0px 1px 5px 2px #272a31;
    background: #101215;
    color: #fff;
}
.new-post{
    .form-control{
  background-clip: padding-box !important;
  background-color: #2b2f38 !important;
  border: 1px solid #2b2f38 !important;
  border-radius: 8px !important;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
  color: #fff;
  }
}
.calendar-card{
    border: 2px solid #6c757d;
    padding: 15px;
    margin-top: 15px;
    border-radius: 10px;
    height: 290px;
    box-shadow: 1px 1px 5px 2px #40464a;
    i{
        color: #6c757d;
    }
    .calendar-avatar{
        border: 1px solid #6c757d;
        border-radius: 50rem;
        width: 35px;
        height: 35px;
    }
}
.calendar-post-type{
    border: 2px solid #6c757d;
    border-radius: 8px;
    padding: 5px 10px;
}
.ant-picker{
    padding: 8px 15px;
    border-radius: 8px;
    background: #323643;
    color: #fff;
    border: none;
    box-shadow: 0px 1px 6px 2px #00000030;
    .anticon svg{
        width: 16px;
        height: 16px;
        filter: brightness(0) saturate(100%) invert(78%) sepia(99%) saturate(1%) hue-rotate(146deg) brightness(102%) contrast(103%);
    }
    &:focus{
        border: none !important;
    }
    .ant-picker-input >input{
        color: #fff;
    }
    .ant-picker-input > input::placeholder{
        color: #636b7c;
    }
    .ant-picker-suffix:hover {
        background-color: #323643; 
      }
      .ant-picker-clear{
        background-color: #323643;
      }
      .ant-picker-clear:hover {
        background-color: #323643; 
      }
}
.calendar-close-btn{
    background: #18191d;
    color: #fff !important;
    box-shadow: 2px 1px 6px 1px #0000000f;
    &:hover{
        color: #fff !important;
    }
}
.fc-timeGridDay-view{
    .fc-timegrid-slot.fc-timegrid-slot-lane{
        background-color: #131317 !important;
    }
}
