.user-profile-card {

    -moz-box-shadow: 0 15px 0px -5px #2E3A74 !important;
    -webkit-box-shadow: 0 15px 0px -5px #2E3A74 !important;
    box-shadow: 0 15px 0px -5px #2E3A74 !important;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;

    .user-personel-information {
        .mail-info {
            align-items: center;
            margin-top: 25px;

            i {
                color: #6079FA !important;
                font-size: 30px;
                padding-right: 15px;
            }

            span {
                font-weight: 600;
            }
        }

        .tag-info {
            // display: inline-block;
            align-items: center;
            margin-top: 25px;

            i {
                color: #6079FA !important;
                font-size: 30px;
                padding-right: 15px;
            }

            span {
                font-weight: 600;
            }

            .info-icon {
                color: #e9e9ea !important;
                font-size: 18px !important;
                margin-left: 10px !important;

            }
        }

        .social-poster {
            align-items: center;
            margin-top: 25px;

            .main-i {
                color: #6079FA !important;
                font-size: 30px;
                padding-right: 15px;
            }

            .pin-icon {
                color: #F09433 !important;
                font-size: 20px;
                transform: rotate(30deg);
            }

            span {
                font-weight: 600;
            }

            .info-icon {
                color: #e9e9ea !important;
                font-size: 18px !important;
                margin-left: 10px !important;

            }
        }
    }
}

.user-skill-card {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;

    .skill-title {
        display: flex;

        i {
            font-size: 30px;
            color: #FECF51;

        }
    }
}

.user-static-card {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    border: none !important;
    .card-header{
        margin-top: 1rem;
    }

    .static-title {
        display: flex;
        align-items: center;
        margin-left: .5rem;
        i {
            font-size: 30px;
            color: #FEA0BE;
        }
    }

    

    .user-static-information {
        display: flex;
        width: 100%;
        // height: 80px;

        span {
            color: white !important;
        }

        .completed-task {
            background-color: #574D33;
            border-radius: 10px;
            margin-right: 5px;

            i {
                color: #FECF51;
                font-size: 25px;
                font-weight: 600;
            }
        }

        .earning {
            background-color: #2B335D;
            border-radius: 10px;
            margin-right: 5px;

            i {
                color: #4D69FA;
                font-size: 25px;
                font-weight: 600;
            }
        }

        .view {
            background-color: #3e396d;
            border-radius: 10px;
            margin-right: 5px;

            i {
                color: #6C5DD3;
                font-size: 25px;
                font-weight: 600;
            }
        }


        .time {
            background-color: #368c84;
            border-radius: 10px;
            margin-right: 5px;

            i {
                color: #44BCA9;
                font-size: 25px;
                font-weight: 600;
            }
        }

        .post_shared {
            // background-color: #254f3a;
            background-color: #294849;
            border-radius: 10px;
            margin-right: 5px;

            i {
                color: #44bca9;
                font-size: 25px;
                font-weight: 600;
            }
        }

        .likes {
            background-color: #8f6274;
            border-radius: 10px;
            margin-right: 5px;

            i {
                color: #ffa2c0;
                font-size: 25px;
                font-weight: 600;
            }
        }
        .unfollows{
            background-color: #542e26;
            border-radius: 10px;
            i {
                color: #f35424;
                font-size: 25px;
                font-weight: 600;
            }
        }
        .red-stats{
            background-color: #542e26;
            border-radius: 10px;
            i {
                color: #f35321;
                font-size: 25px;
                font-weight: 600;
            }
          }

          .pink-stats{
            background-color: #914c68;
            border-radius: 10px;
            margin-right: 5px;

            i {
                color: #ffa2c0;
                font-size: 25px;
                font-weight: 600;
            }
          }
          .green-stats{
            background-color: #224647;
            border-radius: 10px;
            margin-right: 5px;

            i {
                color: #31887a;
                font-size: 25px;
                font-weight: 600;
            }
          }
          .blue-stats{
            background-color: #253066;
            border-radius: 10px;
            margin-right: 5px;

            i {
                color: #3d54ce;
                font-size: 25px;
                font-weight: 600;
            }
          }
          .orange-stats{
            background-color: #5c2a1f;
            border-radius: 10px;
            i {
                color: #c1431c;
                font-size: 25px;
                font-weight: 600;
            }
          }
          .purple-stats{
            background-color: #3a356a;
            border-radius: 10px;
            margin-right: 5px;

            i {
                color: #6254c3;
                font-size: 25px;
                font-weight: 600;
            }
          }
          .yellow-stats{
            background-color: #68582f;
            border-radius: 10px;
            margin-right: 5px;

            i {
                color: #d7ae41;
                font-size: 25px;
                font-weight: 600;
            }
          }

        //   .pink-stats{
        //     background-color: #6a232d;
        //     border-radius: 10px;
        //     img{
        //         color: #d3495e;
        //         filter: invert(48%) sepia(14%) saturate(7184%) hue-rotate(320deg) brightness(84%) contrast(95%);
        //         font-size: 25px;
        //         font-weight: 600;
        //     }
        //   }
        //   .green-stats{
        //     background-color: #112F3B;
        //     border-radius: 10px;
        //     i{
        //         color: #235a70;
        //         font-size: 25px;
        //         font-weight: 600;
        //     }
        //   }
        //   .blue-stats{
        //     background-color: #1a2335;
        //     border-radius: 10px;
        //     i{
        //         color: #234182;
        //         font-size: 25px;
        //         font-weight: 600;
        //     }
        //   }
        //   .orange-stats{
        //     background-color: #542108;
        //     border-radius: 10px;
        //     i{
        //         color: #903203;
        //         font-size: 25px;
        //         font-weight: 600;
        //     }
        //   }
        //   .purple-stats{
        //     background-color: #3f1c41;
        //     border-radius: 10px;
        //     i{
        //         color: #6d3170;
        //         font-size: 25px;
        //         font-weight: 600;
        //     }
        //   }
        //   .yellow-stats{
        //     background-color: #584913;
        //     border-radius: 10px;
        //     i{
        //         color: #987910;
        //         font-size: 25px;
        //         font-weight: 600;
        //     }
        //   }
    }
}

.user-profile-general {
    -moz-box-shadow: 0 15px 0px -5px #383565 !important;
    -webkit-box-shadow: 0 15px 0px -5px #383565 !important;
    box-shadow: 0 15px 0px -5px #383565 !important;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    min-height: 10rem;
}

// .activities-table-header{
//     background-color: #D1CFEC;
//     th{
//         border-bottom: 0;
//     }
//     th:first-child{
//         border-radius:20px 0 0 20px;
//       }

//     th:last-child{
//         border-radius:0 20px 20px 0;
//       }
// }


.profile {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    .profile-recent-activities {
        .timeline {
            // padding-left: 130px;
            // padding-right: 20px;


            ul,
            li {
                list-style: none;
                padding: 0;
            }

            li {
                padding-bottom: 0rem;
                position: relative;
                padding-left: 30px;
                margin-left: 10px;

                &:last-child {
                    border: 0px;
                    padding-bottom: 0;
                }
            }

            .timeline-dotted {
                width: 20px;
                height: 20px;
                border-radius: 50px !important;
                position: relative;
                right: 27px;
                top: 24px;

                .timeline-dotted-line {
                    background-color: #a7a7a7;
                    top: 22.5px;
                    left: 2.7px;
                    position: relative;
                    height: 32px;
                    width: 3px;
                    display: flex;
                    justify-content: center;
                    margin-left: 5px;

                }
            }

            .time {
                color: #2a2839;
                font-weight: 700;
                position: absolute;
                left: -100px;
                top: 17px;

            }

            h6 {
                color: #4f4f4f;
                line-height: 1;
                //  margin-top:0.4rem;
            }
        }
        @media (max-width: 770px) {
            .timeline{
                padding-left: 95px;
                padding-right: 0;
            }
            
        }
        @media (min-width: 770px) {
            .timeline{
                padding-left: 120px;
                padding-right: 20px;
            }
            
        }
    }

    .card-header {
        border: none !important;
        border-radius: 20px;
        padding-bottom: 0;
        margin-bottom: 0;
    }


    .recent-activity-card {

        min-height: 40rem !important;
        max-height: 40rem !important;

        .timeline {
            padding-left: 110px;
            padding-right: 20px;


            ul,
            li {
                list-style: none;
                padding: 0;
                height: 60px;

            }

            li {
                padding-bottom: 0rem;
                position: relative;
                padding-left: 20px;
                margin-left: 10px;

                &:last-child {
                    border: 0px;
                    padding-bottom: 0;
                }
            }

            .timeline-dotted {
                width: 20px;
                height: 20px;
                border-radius: 50px !important;
                position: relative;
                right: 27px;
                top: 24px;

                .timeline-dotted-line {
                    background-color: #585C66;
                    top: 22.5px;
                    left: 2.7px;
                    position: relative;
                    height: 32px;
                    width: 3px;
                    display: flex;
                    justify-content: center;
                    margin-left: 5px;

                }
            }

            .time {
                color: #2a2839;
                font-weight: 700;
                position: absolute;
                left: -100px;
                margin-top: 1rem;

            }

            h6 {
                color: #4f4f4f;
                line-height: 1.5;
            }
        }

    }

    .card {
        height: calc(100% - 1.5rem);
        max-height: 31rem !important;
        border-radius: 20px;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;


        .card-body-scrollable {
            @media (min-width: 576px) {
                height: 10rem;
                overflow: auto;
            }

            @media (min-width: 1200px) {
                padding: 1.5rem;

            }

        }

        .card-body {
            color: var(--bs-card-color);
            flex: 1 1 auto;
            max-height: 40rem !important;
            overflow-y: scroll;
            margin-bottom: 2rem;

        }

    }
}

.profile-happiness-dark-card {
    background-color: #323053 !important;

    &:hover {
        background-color: lighten(#323053, 10%) !important;
    }
}

.profile-earning-dark-card {
    background-color: #542E27 !important;

    &:hover {
        background-color: lighten(#542E27, 10%) !important;
    }
}

.profile-chart-dark-card {
    background-color: #294849 !important;

    &:hover {
        background-color: lighten(#294849, 10%) !important;
    }
}

.user-analiytics-social-media-tab {
    .tabs-bg {
        background-color: #242630 !important;
        padding: .5rem;
        border-radius: 20px;

    }

    .nav-link {
        margin-left: 5px;
        margin-right: 5px;
        border-radius: 15px;
        color: white;
        background-color: transparent;
        border: 0;
        padding: .6rem;
        padding-left: 1rem;
        padding-right: 1rem;
        width: 130px;

    }

    .nav-link {
        &.active {
            border-bottom: none !important;
            color: white !important;
            background-color: black !important;

            &:hover {
                background-color: #414248 !important;
                border-color: #35373e !important;
            }
        }
    }
}
.user-details-card {
    border-radius: 20px;

    .card-header {
        border: none !important;
        border-radius: 20px;
        background-color: white;
        padding-bottom: 0;
        margin-bottom: 0;

        i {
            font-size: 25px !important;
        }
    }

    // .form-control {
    //     background-clip: padding-box !important;
    //         background-color: #383838 !important;
    //         border: 1px solid #383838 !important;
    //         border-radius: 1rem !important;
    //         box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
    //         color: #DBDEE2;
    // }
    .form-control{
        margin-top: 1rem;
    }
    .btn {
        background-color: transparent;
        border: 1px solid #798EFD;
        border-radius: 10px;
        color: #798EFD;
        font-size: 12px;
        font-weight: 400;

        &:hover {
            background-color: rgba(121, 142, 253, 0.2);
            border: 1px solid #798EFD;
            border-radius: 10px;
            color: #798EFD;
        }
    }

}

.user-card-header{
    border: 0;
    padding: 5px 0px 0px 5px;
    .form-check-input{
        width: 1rem !important;
        height: 1rem !important;
    }
}
.user-filter-dropdown{
    background-color: #000;
    border: 0;
    border-radius: 8px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    button{
        background-color: #000;
        &:hover{
            background-color: #5c636a !important;
        }
    }
    .user-filter-menu{
        background-color: #08070f !important;
        color: #fdfdfd;
        // width: max-content;
        width: 250px;
        // transform: translate3d(-320px, 25px, 0px) !important;
      
        .selected-option {
          background-color: #323643 !important;
          color: $white !important;
          border-radius: 10px;
        }
    }

}

.roles-dropdown{
    button{
        background-clip: padding-box !important;
        background-color: #2b2f38 !important;
        border: 1px solid #2b2f38 !important;
        border-radius: 8px !important;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
        color: #fff;
        height: 51px;
        font: inherit;
        display: flex;
        justify-content: space-between;
    }
    .roles-menu{
        background-color: #08070f !important;
        color: #fdfdfd;
        width: max-content;

        .menu-div-item{
            cursor: pointer;
            
            &:hover{
                background-color: hsla(0, 0%, 100%, .15);
                color: $white;
                border-radius: 8px;
            }
        }
      
        .selected-option {
          background-color: #323643 !important;
          color: $white !important;
          border-radius: 10px;
        }
    }
}
.user-actions-dropdown{
    button{
    background: #000;
    border: 1px solid #000;
    padding: 6px 12px !important;
    font-size: 14px;
    font-weight: 500;
    }
    .user-actions-menu{
        background-color: #08070f !important;
        color: #fdfdfd;
        width: max-content;
      
        .selected-option {
          background-color: #323643 !important;
          color: $white !important;
          border-radius: 10px;
        }
       .menu-div-item{
            cursor: pointer;

            &:hover{
                background-color: hsla(0, 0%, 100%, .15);
                color: $white;
                border-radius: 8px;
            }
        }
    }

}
.roles-tags{
    min-height: 230px;
    max-height: 230px;
    overflow-y: scroll;
    background-clip: padding-box !important;
    background-color: #2b2f38 !important;
    border: 1px solid #2b2f38 !important;
    border-radius: 10px !important;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
    color: #fff !important;
    padding: 10px 0px 10px 5px;
  }

.users-roles{
    .modal-ml {
        max-width: 500px !important;
        transition: max-width 0.4s ease !important;
      }
      
      .modal-lg {
        max-width: 800px !important;
        transition: max-width 0.4s ease !important;
      }
      .translation-show{
        transition: step-start 0.9s ease !important;
    
      }
}


  .email-card{
    .loading div {
        width: 3px !important;
        height: 3px !important;
        margin: 5px !important;
      }
  }

  .social-tag-emoji-input{
    .react-emoji-picker--wrapper{
        margin-top: 50px;
        bottom: 10 !important;
        position: relative !important;
    }
  }
.store-tag-container{
    em-emoji-picker{
        top: -24rem;
        right: 0;
    }

}