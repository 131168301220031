
.order_table{
  .MuiTableBody-root{
    .MuiTableRow-root {
      &:hover{
        background-color: #e8edf4 !important;
      }
      td:first-child {
        border-radius: 20px 0 0 20px;
      }

      td:last-child {
        border-radius: 0 20px 20px 0;

      }

      &:nth-child(even) {
        background-color: #f2f5f9;
      }
    }
  }



    .location-icon{
        color: #df4250;
    }
    .order-link{
      color: #767e90;
      &:hover{
        color: #506bf6;
      }
      
    }
}
.payment_method_badge{
  color: #48bdaa;
  background-color: #dff2f0;
  min-width: 80px;
}
.fulfilment_status_badge{
  background-color: #fff4da;
  color: #fec93f;
}
.location-icon-orders{
  width: 40px;
  i{
    font-size: 18px;
    color:#df414f;
  }

}
.shipping-icon{
  width: 40px;
  i{
    font-size: 18px;
    color: #bcbdbf;
  }
}
.paid-icon{
  font-size: 20px;
  color: #44bca9;
}
.unfulfill-icon{
  font-size: 20px;
  color: #fecf51;
}
.edit-icon{
  font-size: 18px;
  color: #4d69fa;
}
.tags-btn {
  background-color: transparent;
  border: 1px solid #798EFD;
  border-radius: 10px;
  color: #798EFD;
  font-size: 12px;
  font-weight: 400;

  &:hover {
      background-color: rgba(121, 142, 253, 0.2);
      border: 1px solid #798EFD;
      border-radius: 10px;
      color: #798EFD;
  }
}
.tags-form{
  .form-control{
    background-clip: padding-box !important;
    background-color: #f8f9fa !important;
    border: 1px solid #f8f9fa !important;
    border-radius: 1rem !important;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important
  }
}
.tags-list{
  max-height: 4rem;
  overflow-y: scroll;
  ul {
    list-style: none; 
  }
  
  ul li::before {
    content: "\2022";
    color: #727b81; 
    font-weight: bold; 
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
  }
}