.radialbar-chart {
  background-color: #242630 !important;
    border: none !important;
    .apexcharts-canvas {
      .apexcharts-datalabels-group {
        text{
          color: white !important;
        }
        text:first-child {
          font-size: 22px !important;
        }
        text:last-child {
          font-size: 16px !important;
        }
      }
      .apexcharts-legend {
        left: 0 !important;
        overflow-y: scroll !important;
        .apexcharts-legend-series {
          margin-top: 0 !important;
          display: flex;
          align-items: center !important;
          .apexcharts-legend-text {
            font-size: 14px !important;
            font-weight: 500 !important;
            color: #fff !important;
            font-family:  "Inter", sans-serif !important;
            left: 12px;
            padding-right: 20px !important;
            margin-top: 1rem !important;
          }
          .apexcharts-legend-marker {
            width: 15px !important;
            height: 15px !important;
            border-radius: 20px !important;
            margin-top: 1rem !important;
           }
        }
      }
    }
    .apexcharts-yaxis-label{
      color: white !important;
      font-size: 14px !important;
      font-weight: 400px !important;
    }
  }
  
   @media only screen and (max-width: 1500px) {
     .radialbar-chart {
       .apexcharts-canvas {
        display: flex !important;
        justify-content: center !important;
         .apexcharts-legend {
          display: flex ;
          flex-direction: row !important;
          margin-left: 0px !important;
           overflow-y: hidden !important;
           .apexcharts-legend-series {
             .apexcharts-legend-text {
               font-size: 14px !important;
               font-weight: 500 !important;
               //color: #11142d !important;
             }
            
           }
         }
       }
     }
   }
   .apexcharts-xaxis-label{
    color: white !important;
    font-size: 14px !important;
     font-weight: 500 !important;
   }
.apexcharts-yaxis-label{
  color: white !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}
.apexcharts-yaxis, .apexcharts-xaxis, .apexcharts-datalabels-group {
  filter: invert(88%) sepia(90%) saturate(3%) hue-rotate(182deg) brightness(111%) contrast(97%);
}
.apexcharts-yaxis-title-text{
  display: none !important;
}
.apexcharts-title-text{
  filter: invert(88%) sepia(90%) saturate(3%) hue-rotate(182deg) brightness(111%) contrast(97%);
  font-family: 'Poppins', sans-serif !important;
}
.line-chart{
  .apexcharts-legend {
    left: 0 !important;
    overflow-y: scroll !important;
    .apexcharts-legend-series {
      margin-top: 0 !important;
      display: flex;
      align-items: center !important;
      .apexcharts-legend-text {
        font-size: 14px !important;
        font-weight: 500 !important;
        color: #fff !important;
        font-family:  "Inter", sans-serif !important;
        left: 12px;
        padding-right: 20px !important;
        margin-top: 1rem !important;
      }
      .apexcharts-legend-marker {
        width: 15px !important;
        height: 15px !important;
        border-radius: 20px !important;
        margin-top: 1rem !important;
       }
    }
  }
}
.apexcharts-datalabel {
  font-size: 12px !important; /* istediğiniz boyutu belirtin */
  padding: 0px !important;
}