.thumbnail {
    width: 110px;
    height: 175px;
    border-radius: 5px;
}

.card {
    border-radius: 20px !important;
}

.product-filter {
    .modal-body {
        max-height: calc(100vh - 280px);
        overflow-y: auto !important;
    }
}

.react-select__multi-value {
    // width: 112px;
    width: 74px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: space-between;
}
.react-select__indicator{
    padding: 2px !important;
}

.react-select__dropdown-indicator {
    padding: 2px !important;
}

.dropdown-padding {
    // padding: 4px 15px !important;
}

.dropdown-toggle::after {
    font-size: .8rem;
}

.dropdown-left {
    height: 34px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.dropdown-right {
    height: 34px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.crown-icon {
    color: #ffaa00;
    font-size: 30px;
}

.analytics-detail-stats {
    div {
        min-height: 80px !important;
    }

}

@media screen and (min-width: "500px") {
    .dropdown-icon {
        display: none !important;
    }
}

@media screen and (max-width: "499px") {
    .dropdown-label {
        display: none !important;
    }
}

@media screen and (max-width: 767px) {
    .scheduler-imgs-card {
        height: auto !important;
    }
}

.empty-state-bg {
    height: 440px;
    border-radius: 20px;
}

.account-dis-card {
    .empty-state-bg {
        height: 230px !important;
    }
}

.btn-border-blue {
    border: 1px solid #ff693d;
    background: transparent;
    color: #ff693d;

    &:hover {
        border: 1px solid #ff693d;
        background-color: #ff693d;
    }
}

.page-not-found {
    color: #17191e;
    padding: 5px 15px;
    background-color: #ff693d;
    border-radius: 5px;
    font-weight: 500;
    position: absolute;
    bottom: 88px;
    left: 33%;
    transform: rotate(15deg);
}

.server-unavailable-img {
    filter: invert(36%) sepia(95%) saturate(821%) hue-rotate(0deg) brightness(106%) contrast(103%);
}

.MuiTableRow-footer {
    div {
        display: flex;
        justify-content: end;
        align-items: center;
    }

    select {
        margin-left: 1.5rem;
        border-radius: 8px;
        min-width: 40px;
        height: 34px;
        padding: 5px 10px;
        cursor: pointer;
    }

    .MuiSelect-root {
        margin-left: 1.5rem;
        border-radius: 8px;
        height: 15px;
        cursor: pointer;
        padding-left: 5px;
        border-bottom: none;
        font-size: 12px;
    }

    .first {
        border-radius: 8px 0 0 8px !important;
    }

    .last {
        border-radius: 0 8px 8px 0 !important;
    }

    .pagination-btn {
        padding: 5px 10px !important;
        font-size: 12px;
        font-weight: 400;
        border-radius: 0;
        height: 30px;
        min-width: 30px;
    }

    .pagination-icon {
        padding: 2px 4px !important;
        border-radius: 0;
        height: 30px;
        width: 30px;
    }

}

.MuiMenu-list {
    display: flex;
    flex-direction: column;
    align-items: center;

    option {
        cursor: pointer;
    }
}

.top-product {
    .MuiInput-underline {
        display: none !important;
    }
}

.card-tabs {

    .nav-link {
        border: none !important;
        border-radius: 8px;
        padding: 5px 15px;
        margin-left: 2px;
        margin-right: 2px;
        background-color: transparent !important;
        font-size: 12px;
        font-weight: 500;
    }
}

.list-item-index {
    border: 1px solid #4d69f8;
    border-radius: 50px;
    padding: .5rem 1rem;
}

.analytics-scrollable-card {
    height: 14rem;

    .card-body {
        overflow-y: auto;
    }

    ::-webkit-scrollbar-track {
        // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        margin-bottom: 1rem;
        margin-top: 1rem;
        background: transparent !important;
    }


    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
    }
}
.analytics-detail-small-card{
    .empty-state-bg{
        height: 90px;
    }
    .empty-img{
        width: 80px !important;
    }
}
.daily-stats-table{
    .MuiTableRow-head
    .MuiTableCell-head:first-child{
        color: #42bca8 !important;
    }
}
.btn-group{
    :first-child{
        border-radius: 8px 0 0 8px;
    }
    :last-child{
        border-radius: 0 8px 8px 0;
    }
    button{
        font-size: 12px;
        padding: .5rem 1rem;
        margin-left: 0 !important
    }
}
.post-detail-modal{
    .modal-body{
        max-height: calc(100vh - 180px);
        overflow-y: auto !important;
    }
}
// /* Yatay ve dikey scroll bar için genel stiller */
// ::-webkit-scrollbar {
//     width: 5px;  /* Dikey scroll barın genişliği */
//     height: 5px; /* Yatay scroll barın yüksekliği */
// }

// /* Scroll barın yuvarlaklık değerleri */
// ::-webkit-scrollbar-thumb {
//     border-radius: 8px;
//     transition: background-color 0.3s; /* Renk değişim animasyon süresi */
// }

// /* Fare üzerindeyken scroll barın rengi */
// ::-webkit-scrollbar-thumb:hover {
// }

// /* Scroll barın arka plan rengi (track rengi) */
// ::-webkit-scrollbar-track {
//     background: transparent;
// }
.tabs-container{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.dropdown-input{
    background: transparent;
    border: none;
    &:focus{
        background: transparent;
    }
}
.fav-dropdown-input{
    background: transparent;
}
.dropdown-icon-display-none{
    &::after{
        display: none !important;
    }
}
.fav-dropdown{
    .dropdown-item{
        padding: 0.25rem .5rem !important;
    }
}
.text-gray{
    color: #4b515e;
}
.clickable-red {
    &:hover {
      color: #f35321 !important;
    }
  }
  .filters-badge{
    padding: 7px 10px;
    margin-bottom: 5px;
  }

  .analytics-filter{
    .form-check-input{
        width: .9rem !important;
        height: .9rem !important;
    }
  }
  .icons-badge{
    width: 65px;
  }
  .dark-gray-badge{
    border-radius: 10px;
    font-size: 10px;
  }
  .analytics-filter{
    .react-select__control{
        min-height: 35px !important;
    }
    .react-select__multi-value{
        padding: 1px 2px !important;
    }
    .react-select__value-container{
        padding: 4px 0px 4px 4px !important;
    }
    .form-control{
        padding: 3px 5px !important;
        height: 30px !important;
    }
    .label-btn{
        background: transparent !important;
        border: none !important;
        text-decoration: underline;
    }
    .react-select{
        height: 35px !important;
    }
  }
  .scroll-table{
    .material-table__wrap > div > div{
        max-height: 17rem;
        overflow-y: scroll;
    }
  }
  .card-header{
    background: transparent;
    border: none;
  }
  .input-loader{
    position: absolute;
    right: 0;
    margin-right: 1.3rem;
    top: 0;
    margin-top: .7rem;
  }
  .avatar-btn{
    font-size: 10px;
    font-weight: 600;
    background-color: rgba(163,164,167, .7);
    padding: 0 18px;
    width: 100%;
    left: 50%;
    bottom: -1rem;
    height: 30px;
    text-align: center;
    opacity: 2;
  }
  .change-personal-information-card{
    .react-select__control{
        min-height: 40px !important;
      }
      .react-select{
        height: 45px !important;
      }
  }
.instagram-logo{
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); /* Instagram Gradient */
    color: transparent; 
    display: inline-block; 
    -webkit-background-clip: text; 
    background-clip: text;
}
.toolbar-pagination{
    height: 48px !important;
}
.custom-table-filter{
    height: 48px !important;
}
.not-sure-img{
    // background: rgb(235, 235, 235);
    object-fit: cover;
    transition: transform 0.3s ease 0s;
    width: 100%;
    border-radius: 20px 20px 0 0 ;
    height: 250px;
}
.to-do-list-badge{
    width: 35px;
    height: 35px;
    border-radius: 50%;
}
.card-img{
    width: 100%;
    max-height: 160px;
}
.training-tips-card{
    box-shadow: 0 1.6rem 3rem rgba(0,0,0,.1);
}
.note-badges{
    border-radius: 50rem;
    padding: 5px 10px;
    font-size: 12px;
}
.bg-light-purple{
    border-radius: 8px;
}
.chart-without-legend{
    .apexcharts-legend{
        display: none !important;
    }
}
@media screen and (max-width: 1444px) {
    .analytics-statistic-section{
    display: block;
}
}

@media screen and (min-width: 1445px) {
    .analytics-statistic-section{
    display: flex;
}
}
@media screen and (max-width: 1102px) {
    .analytics-web-info{
        display: none !important;
    }
    
}
@media screen and (min-width: 1103px) {
    .analytics-tablet-info{
        display: none !important;
    }
    
}
@media screen and (min-width: 768px) {
    .filter-mobile{
      display: none !important;
    }
  }
  @media screen and (max-width: 767px) {
    .filter-web{
      display: none !important;
    }
  }

  @media screen and (max-width: 1272px) {
    .saved-filters-web{
        display: none !important;
    }
  }
  @media screen and (min-width: 1273px) {
    .saved-filters-mobile{
        display: none !important;
    }
  }
  .flag-img{
    width: 20px;
    height: 18px;
    border-radius: 5px;
  }
 .invalid-input{
    border: 1.5px solid #f35321 !important;
 }
 .timestamp{
    position: relative;
    .form-control{
    padding: 10px 15px 10px 10px !important;
    border-radius: 10px !important;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    width: 155px;
    }
    .date-range-toggle{
        right: 0 !important;
        left: auto !important;
        
    }
 }

 @media screen and (min-width: 1000px) {
    .analytics-date-container{
        min-width: 150px;
    }
 }
@media screen and (max-width: 1000px) {
    .analytics-date-container{
        min-width:auto !important;
    }
    
}
.line-height-1{
    line-height: 1.5rem;
}


.fc-dayGridMonth-button, .fc-timeGridWeek-button, .fc-timeGridDay-button{
    padding: 7px 16px;

    border-radius: 100px !important;
    margin-right: 0.5rem;
}
.contact-form{
    .form{
        .form-label{
            margin-bottom: 6px !important;
        }
    }
}
@media screen and (max-width: 767px) {
    .user-filter-dropdown{
        height: 33px !important;
        width: 33px !important;
        img{
            width: 12px !important;
            height: 12px !important;
        }
    }

}
.form__form-group-error{
    color: #dc3444 ;
  }
  .daterangepicker select {
    height: 200px; /* İstediğiniz yüksekliğe ayarlayın */
    overflow-y: auto; /* Eğer içerik yüksekliği aşarsa kaydırmaya izin ver */
  }
  .form-check-input{
    cursor: pointer;
}