.container {
    padding-right: 0px;
  
    @media screen and (min-width: 768px) {
      width: 100%;
      max-width: 1850px;
    }
  }
  
  .container__wrap {
    font-family: 'Poppins', sans-serif !important;
    padding-left: 0;
    padding-top: 10px;
    padding-bottom: 30px;
    min-height: 100vh;
    transition: padding-left 0.3s;
    
    background: #F2F2F2;

   
  
    @media screen and (min-width: 576px) {
      padding-left: 265px;
      // padding-right: 2rem;
      padding-right: 1rem;
    }
    @media screen and (max-width: 576px) {
      padding-inline: 1rem !important;
    }
    // @media screen and (max-width: 480px) {
    //   padding-left: 0 !important;
    //   padding-right: 0 !important;
    //   margin-right: 0 !important;
    // }
    // @media screen and (max-width: 415px) {
    //   padding-left: 0px !important;
    //   padding-right: 0 !important;
    // }
  }
  
  .layout {
  
    &.layout--collapse {
  
      & + .container__wrap {
        padding-left: 0;
        @media screen and (min-width: 576px) {
          //padding-left: 100px;
        }
      }
    }
  
    &.layout--top-navigation{
  
      & + .container__wrap{
  
        // @media screen and (min-width: 576px) {
        //   padding-left: 0;
        //   padding-right: 0;
        // }
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
  .row > *{
    margin-right: 0 !important;
  }