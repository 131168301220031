.woocommerce-url{
    color: #485fe0;
}
.btn-primary{
    background-color: #0f7173 !important;
    :hover{

        background-color: #094546 !important;
        border-color: #094546 !important;
    }
}
.store-edit{
    color: #4d69fa;
}