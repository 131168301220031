//
// general.scss
//

body {
  font-family: $font-family-base !important;
  font-size: $font-size-base !important;
  color: #303538;
  background-color: #F4F8FB !important;
  margin: 0;
}

.font-primary {
  font-family: "Inter", sans-serif;
}

.font-secondary {
  font-family: "Ubuntu", sans-serif;
}

.font-tertiary {
  font-family: "Roboto", sans-serif;
}

.font-badge {
  font-family: 'Lobster', 'cursive';
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $font-family-base;
  line-height: 1.5;
  font-weight: 700;
}

::selection {
  background: rgba($primary, 0.9);
  color: $white;
}

a {
  text-decoration: none !important;
}

p {
  line-height: 1.6;
}

.text-underline {
  text-decoration: underline !important;
}

@media screen and (min-width: 1000px) {
  .font-size-10 {
    font-size: 10px !important;
  }

  .font-size-11 {
    font-size: 11px !important;
  }

  .font-size-12 {
    font-size: 12px !important;
  }

  .font-size-13 {
    font-size: 13px !important;
  }

  .font-size-14 {
    font-size: 14px !important;
  }

  .font-size-15 {
    font-size: 15px !important;
  }

  .font-size-16 {
    font-size: 16px !important;
  }

  .font-size-18 {
    font-size: 18px !important;
  }

  .font-size-20 {
    font-size: 20px !important;
  }

  .font-size-22 {
    font-size: 22px !important;
  }

  .font-size-24 {
    font-size: 24px !important;
  }

  .font-size-26 {
    font-size: 26px !important;
  }

  .font-size-28 {
    font-size: 28px !important;
  }

  .font-size-30 {
    font-size: 30px !important;
  }

  .font-size-32 {
    font-size: 32px !important;
  }

  .font-size-34 {
    font-size: 34px !important;
  }

  .font-size-36 {
    font-size: 36px !important;
  }

  .font-size-38 {
    font-size: 38px !important;
  }

  .font-size-40 {
    font-size: 40px !important;
  }

}

@media screen and (max-width: 1000px) {
  .font-size-10 {
    font-size: 8px !important;
  }

  .font-size-11 {
    font-size: 9px !important;
  }

  .font-size-12 {
    font-size: 10px !important;
  }

  .font-size-13 {
    font-size: 11px !important;
  }

  .font-size-14 {
    font-size: 12px !important;
  }

  .font-size-15 {
    font-size: 13px !important;
  }

  .font-size-16 {
    font-size: 14px !important;
  }

  .font-size-18 {
    font-size: 16px !important;
  }

  .font-size-20 {
    font-size: 18px !important;
  }

  .font-size-22 {
    font-size: 20px !important;
  }

  .font-size-24 {
    font-size: 22px !important;
  }

  .font-size-26 {
    font-size: 24px !important;
  }

  .font-size-28 {
    font-size: 27px !important;
  }

  .font-size-30 {
    font-size: 28px !important;
  }

  .font-size-32 {
    font-size: 28px !important;
  }

  .font-size-34 {
    font-size: 28px !important;
  }

  .font-size-36 {
    font-size: 25px !important;
  }

  .font-size-38 {
    font-size: 28px !important;
  }

  .font-size-40 {
    font-size: 34px !important;
  }

}

.font-weight-100 {
  font-weight: 100 !important;
}

.font-weight-200 {
  font-weight: 200 !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}

.line-height-20 {
  line-height: 20px;
}

.line-height-22 {
  line-height: 22px;
}

.line-height-24 {
  line-height: 24px;
}

.line-height-26 {
  line-height: 26px;
}

.line-height-28 {
  line-height: 28px;
}

.line-height-30 {
  line-height: 30px;
}

.line-height-32 {
  line-height: 32px;
}

.line-height-34 {
  line-height: 34px;
}

.line-height-36 {
  line-height: 36px;
}

.line-height-38 {
  line-height: 38px;
}

.line-height-40 {
  line-height: 40px;
}

.general-scroll-horizontal {}

.w-65 {
  width: 65% !important;
}

.text-dark {
  color: #303538 !important;
}

.two-lines {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.border-radius-10 {
  border-radius: 10px;
}

.border-radius-20 {
  border-radius: 20px;
}

.border-radius-30 {
  border-radius: 30px;
}

.border-radius-50 {
  border-radius: 50px;
}

.border-none {
  border: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.dark-thema-card {
  background-color: #242731 !important;
}

.dark-thema-card-dark {
  background-color: #17191e !important;

}

.dark-thema-innertext {
  color: #e4e6eb !important;
}

.card-stretch-full {
  height: calc(100% - 1.5rem);

}
.dark-thema-text {
  color: #ffff !important;
}