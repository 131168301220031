.settings-tab.col-xl-4 {
    @media (min-width: 1200px) {
        flex: 0 0 auto;
        width: 27%;
    }

}

.settings-page.col-xl-8 {
    @media (min-width: 1200px) {
        flex: 0 0 auto;
        width: 73%;
    }

}

.settings-tab {
    // margin-top: 1rem;


    .card {
        border: none !important;
        border-radius: 8px;
        background-color: #FDFDFD;

        .card-body {
         

        }

        .card-header {
            background-color: #FCFCFC;
            border: none !important;
            margin-right: 1rem;
            margin-left: 1rem;

        }

        .settings-card-footer {
            padding: 20px 30px 0px 30px;

        }

        .user-delete-button {
            border-radius: 10px !important;
            background-color: #FEEEE9 !important;
            border: none !important;
            color: #F7825F;
            font-size: 14px;
            font-weight: 500;
            padding: 10px 0px 10px 0px;

            &:hover {
                background-color: #F7825F !important;
                border: none !important;
                color: #FEEEE9;
            }

        }
    }

    .tabs {


        overflow: auto;
        margin-top: 70px;

        .nav-tabs {
            width: 100%;
            overflow-x: auto;
            border-bottom: none !important;
            flex-wrap: wrap !important;
            display: block !important;

            li {
                .nav-link {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    font-size: 12px;
                    font-weight: 500;
                    color: $content-light;
                    text-align: start;
                    padding: 8px 45px;
                    margin-left: 15px;
                    margin-right: 15px;
                    margin-top: 14px;
                    transition: background-color 0.3s;
                    border-radius: 8px;
                    color: #5570F9;
                    cursor: pointer;
                    padding-top: 0.9rem !important;
                    padding-bottom: 0.9rem !important;

                    &:hover,
                    &:focus,
                    &:active {}

                    &.nav-link-sm {
                        padding: 8px 30px;
                    }

                    &.nav-link-xs {
                        padding: 8px 10px;
                    }
                }

                .nav-link.active,
                .nav-link.active:focus,
                .nav-link.active:hover {
                    color: #ffff !important;
                }

                &.disabled .nav-link {
                    color: #999999;
                }
            }
        }

        &.tabs--bordered-bottom {
            .nav-tabs {
                .nav-link {
                    border-color: transparent;
                    padding-bottom: 14px;
                }

                .nav-link.active {

                    &,
                    &:focus,
                    &:hover {
                        border-bottom: none !important;
                        background-color: #5570F9 !important;

                    }
                }
            }
        }
    }
}

.settings-last-child {
    color: #44BCA9 !important;
    background-color: #EDF8F7 !important;

}

.settings-last-childactive,
.settings-last-childactive:focus,
.settings-last-childactive:hover {
    color: #ffffff !important;
}

.settings-last-childactive {

    &,
    &:focus,
    &:hover {
        border-bottom: none !important;
        background-color: #44BCA9 !important;

    }

}


.settings-plans-card.card {
    background-color: #F0EFFB !important;
    min-height: 170px !important;
    border-radius: 20px;
    margin-left: 17px;
    margin-right: 17px;
    margin-top: 14px;
    box-shadow: 0 15px 2px -2px #B5ADDB;
    border-collapse: collapse;

    .card-body {
        overflow-y: hidden !important;
    }

}

.settings-members-card.card {
    background-color: #fffaee !important;
    height: 170px !important;
    border-radius: 20px;
    margin-left: 17px;
    margin-right: 17px;
    margin-top: 14px;
    margin-bottom: 14px;
    -moz-box-shadow: 0 15px 2px -5px rgba(233, 216, 171, 1) !important;
    -webkit-box-shadow: 0 15px 2px -5px rgba(233, 216, 171, 1) !important;
    box-shadow: 0 15px 2px -5px rgba(233, 216, 171, 1) !important;
    border-collapse: collapse;

    .card-body {

        overflow-y: hidden !important;
    }

}

.change-information-wrapper {

    border: none !important;
    border-radius: 8px;
    background-color: #FDFDFD;

    .card-header {
        border: none !important;
        border-radius: 8px;
        background-color: #FDFDFD;
    }

    .card-body {
        // ::-webkit-scrollbar {
        //     width: 6px;
        //     height: 6px !important;
        //    }
        //     ::-webkit-scrollbar-track {
        //     border-radius: 10px;
        //     height: 6px !important;
        //     width: 6px;
        //    }
        //      ::-webkit-scrollbar-thumb {
        //     background: #C1C1C1;
        //     border-radius: 10px;
        //     height: 6px !important;
        //     width: 6px;
        //    }
        //     ::-webkit-scrollbar-thumb:hover {
        //     background: #C1C1C1;
        //     border-radius: 10px;
        //     height: 6px !important;
        //     width: 6px;
        //    }
    }

   
}
.change-personal-information-card {
    border: none !important;
    border-radius: 8px;
    background-color: #ffff;

    .form-control {
        background-clip: padding-box !important;
        background-color: #f8f9fa !important;
        border: 1px solid #f8f9fa !important;
        border-radius: 1rem !important;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
        border-radius: 8px !important;
    }
    .form-control:not(:read-only):focus{
        border: 1px solid #5570f9 !important;
    }
    .change-password-footer {
        background-color: #FFF5DC !important;
        border-radius: 20px;

    }

    // .btn {
    //     background-color: transparent;
    //     border: 1px solid #798EFD;
    //     border-radius: 10px;
    //     color: #798EFD;
    //     font-size: 12px;
    //     font-weight: 400;

    //     &:hover {
    //         background-color: rgba(121, 142, 253, 0.2);
    //         border: 1px solid #798EFD;
    //         border-radius: 10px;
    //         color: #798EFD;
    //     }
    // }

    .input-info{
        color: #a6a8a8 !important;
    }
}
.settings-avatar-upload {
    position: relative;
    overflow: hidden;

    &:before {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        border-radius: 0;
        opacity: 0.6;
        transition: 0.5s ease;
        width: 100%;
        height: 40px;
        bottom: 0;
        left: 0;
        background-color: #5570F9;
        color: #cebfe9;
    }

    &:hover {
        &:before {
            font-size: 30px;
            height: 100%;
            background-color: #5570F9;
        }
    }

    .photo-upload {
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
    }
}

.plan-upgrade-container {
    .plan-upgrade-card.active {
        border-radius: 20px;
        border: none;
        border: 4px solid #116BF4;
    }

    .plan-upgrade-card {
        border-radius: 20px;
        border: none;

        .plan-upgrade-cardbody {
            margin-top: 30px;

            .upgrade-plan-title {
                display: flex;
                justify-content: center;

                .title-wrapper {
                    text-align: center;

                    .price {
                        font-size: 55px;
                        margin-top: 15px;
                    }

                    .feautes-list {
                        width: 100%;
                        margin-top: 20px;

                        ul {
                            padding-left: 0;
                            list-style-type: none;
                            font-size: 18px;
                            font-weight: 400;

                            li {
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }

        }

        .rocket-icon {
            font-size: 75px;
            color: #4C69FA;
            display: flex;
            justify-content: center;
        }

        .building-icon {
            font-size: 75px;
            color: #45BBA8;
            display: flex;
            justify-content: center;
        }

        .industry-icon {
            font-size: 75px;
            color: #ADA8D5;
            display: flex;
            justify-content: center;
        }

        .cookie-icon {
            font-size: 75px;
            color: #ADA8D5;
            display: flex;
            justify-content: center;
        }
    }
}

.plans-detail-container {
    .current-plan-wrapper {
        .card {
            border-radius: 20px;
            border: none;

            .card-header {
                background-color: #ffff;
                border-radius: 20px 20px 0px 0px;

            }

            .current-plan-detail {
                border-top: 3px dotted $muted;
                margin-top: 30px;
            }
        }

    }
}

input[type=file] {
    width: 350px;
    max-width: 100%;
    color: #444;
    padding: 0px;
    border: none;
    font-size: 12px;
    font-weight: 600;
    background-clip: padding-box !important;
    background-color: #f8f9fa !important;
    border: 1px solid #f8f9fa !important;
    border-radius: 20px !important;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
    display: block;
}

input[type=file]::file-selector-button {
    margin-right: 20px;
    border: none;
    background: #e9e9ea;
    padding: 10px 10px;
    border-radius: 20px;
    color: #444;
    cursor: pointer;
    transition: background .2s ease-in-out;
    border-radius: 20px 0px 0px 20px;
}

input[type=file]::file-selector-button:hover {
    background: rgb(217, 216, 216);

}

.personal-information-card {
    border: none !important;
    border-radius: 30px;
    background-color: #FDFDFD;
    height: 45rem !important;

    .card-header {
        border: none !important;
        border-radius: 30px;
        background-color: #FDFDFD;
    }

    .card-body {
        max-height: 37rem;
        overflow-y: scroll;
    }

    .form-control {
        background-clip: padding-box !important;
        background-color: #f8f9fa !important;
        border: 1px solid #f8f9fa !important;
        border-radius: 1rem !important;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important
    }

    .change-password-footer {
        background-color: #FFF5DC !important;
        border-radius: 20px;
    }

    .btn {
        background-color: transparent;
        border: 1px solid #798EFD;
        border-radius: 10px;
        color: #798EFD;
        font-size: 12px;
        font-weight: 400;

        &:hover {
            background-color: rgba(121, 142, 253, 0.2);
            border: 1px solid #798EFD;
            border-radius: 10px;
            color: #798EFD;
        }
    }

    h6 {
        color: #34445E;
    }
}

.wallet-card {
    border: none !important;
    border-radius: 30px;
    background-color: #FDFDFD;
    height: 45rem !important;

    .card-header {
        border: none !important;
        border-radius: 30px;
        background-color: #FDFDFD;
    }

    .btn-add {
        background-color: #EDF0FF;
        border: 1px solid #EDF0FF;
        border-radius: 15px;
        color: #4D69FA;
        font-size: 12px;
        font-weight: 600;

        &:hover {
            background-color: #4D69FA;
            border: 1px solid #4D69FA;
            border-radius: 15px;
            color: #fff;
        }
    }

    .btn-all {
        background-color: #E9E9EA !important;
        border-radius: 20px;
        padding: 1rem;

        .wallet-btn-active {
            background-color: #414248 !important;
            color: #E7EEF8 !important;
        }

        .btn-card {
            margin-top: 10px;
            background-color: transparent;
            border-radius: 15px;
            color: #1F2128;
            font-size: 0.9rem !important;
            border: 0;
            padding-top: .4rem;
            padding-bottom: .4rem;

            &:hover {
                background-color: #414248;
                color: #E7EEF8;
            }
        }
    }

    .wallet-btn-active {
        background-color: #414248 !important;
        color: #E7EEF8 !important;
    }


}

.modal-save-credit-card {
    color: white;
    background-color: #4d69fa !important;
    border-color: #4d69fa !important;
    border-radius: 15px !important;
    width: 110px;
    height: 43px;
    padding: 0px 10px 0px 10px !important;

    &:hover {
        background-color: #6880fb !important;
        background-color: #5f78fb !important;

    }
}

.paypal-btn {
    color: white;
    background-color: #4d69fa !important;
    border-color: #4d69fa !important;
    border-radius: 15px !important;

    &:hover {
        background-color: #6880fb !important;
        background-color: #5f78fb !important;

    }
}

.credit-card-modal-list {
    margin-top: 1rem;
    border-collapse: collapse;

    thead {
        border-radius: 20px !important;
        background-color: #dee2e6;

        tr {

            th {
                padding: 1rem;
                border: none;
            }

            th:first-child {
                border-radius: 15px 0px 0px 15px !important;
            }

            th:last-child {
                border-radius: 0px 15px 15px 0px !important;
            }
        }
    }

    tbody {
        tr {
            &:nth-child(even) {
                background-color: #dee2e6;
            }

            td {
                border: none;
                padding: .5rem;
                vertical-align: middle;
                font-size: 15px;
                font-weight: 600;
            }

            td:first-child {
                border-radius: 15px 0px 0px 15px !important;

            }

            td:last-child {
                border-radius: 0px 15px 15px 0px !important;

            }
        }
    }
}



.email-notifications-card {
    border: none !important;
    border-radius: 30px;
    background-color: #FDFDFD;
    height: auto !important;

    .card-header {
        border: none !important;
        border-radius: 30px;
        background-color: #FDFDFD;
    }

    .btn {
        background-color: transparent;
        border: 1px solid #798EFD;
        border-radius: 10px;
        color: #798EFD;
        font-size: 12px;
        font-weight: 400;

        &:hover {
            background-color: rgba(121, 142, 253, 0.2);
            border: 1px solid #798EFD;
            border-radius: 10px;
            color: #798EFD;
        }
    }

    .notifications-card {
        border: none !important;
        border-radius: 30px;
        background-color: #FDFDFD;
        height: auto !important;
        padding-bottom: 2rem;
        .card-header{
            border-radius: 20px !important;
        }
        // .card-body {
        //     max-height: 22rem !important;
        //     overflow-y: scroll !important;

        // }
        // .card-body {
        //     ::-webkit-scrollbar {
        //         width: 6px;
        //         height: 6px !important;
        //        }
        //         ::-webkit-scrollbar-track {
        //         border-radius: 10px;
        //         height: 6px !important;
        //         width: 6px;
        //        }
        //          ::-webkit-scrollbar-thumb {
        //         background: #C1C1C1;
        //         border-radius: 10px;
        //         height: 6px !important;
        //         width: 6px;
        //        }
        //         ::-webkit-scrollbar-thumb:hover {
        //         background: #C1C1C1;
        //         border-radius: 10px;
        //         height: 6px !important;
        //         width: 6px;
        //        }
        // }

        /* Notifications Switch */
        .notifications-switch {

            .switch {
                position: relative;
                display: inline-block;
                width: 45px;
                height: 20px;

            }

            .switch input {
                opacity: 0;
                width: 0;
                height: 0;
                background-color: #f8f9fa !important;
                border: 1px solid rgba(0, 0, 0, .25) !important;
            }

            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #ccc;
                -webkit-transition: .4s;
                transition: .4s;
            }

            .slider:before {
                position: absolute;
                content: "";
                height: 15px;
                width: 15px;
                left: 1px;
                bottom: 3px;
                background-color: white;
                -webkit-transition: .4s;
                transition: .4s;
            }

            input:checked+.slider {
                background-color: #6c5dd3;
                border-color: #6c5dd3;
            }

            input:focus+.slider {
                box-shadow: 0 0 1px #6c5dd3;
            }

            input:checked+.slider:before {
                -webkit-transform: translateX(26px);
                -ms-transform: translateX(26px);
                transform: translateX(26px);
            }

            .slider.round {
                border-radius: 34px;
            }

            .slider.round:before {
                border-radius: 50%;
            }
        }
    }

}

.modal-delete-credit-card {
    border-radius: 10px !important;
    background-color: #FEEEE9 !important;
    border: none !important;
    color: #F7825F;
    // padding: 10px 0px 10px 0px;

    &:hover {
        background-color: #F7825F !important;
        border: none !important;
        color: #FEEEE9 !important;
    }
}

.change-password-card {
    border: none !important;
    border-radius: 30px;
    background-color: #FDFDFD;

    .card-header {
        border: none !important;
        border-radius: 30px;
        background-color: #FDFDFD;
    }

    .form-control {
        background-clip: padding-box !important;
        background-color: #f8f9fa !important;
        border: 1px solid #f8f9fa !important;
        border-radius: 1rem !important;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important
    }

    .change-password-footer {
        background-color: #FFF5DC !important;
        border-radius: 20px;

    }

    .btn {
        background-color: transparent;
        border: 1px solid #798EFD;
        border-radius: 10px;
        color: #798EFD;
        font-size: 12px;
        font-weight: 400;

        &:hover {
            background-color: rgba(121, 142, 253, 0.2);
            border: 1px solid #798EFD;
            border-radius: 10px;
            color: #798EFD;
        }
    }
}
.react-emoji{
    background-clip: padding-box !important;
    background-color: #f8f9fa !important;
    border: 1px solid #f8f9fa !important;
    border-radius: 8px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
    font-size: 6px;
    font-weight: 400;
    color: #ced4da !important;
    padding: 0 !important;
    margin-top: 1rem;
    height: 45px;
    .react-input-emoji--container{
        background-color: #f8f9fa !important;
        border: none !important;

        .react-input-emoji--placeholder{
            font-size: 14px;
            font-weight: 400;
            color: #a0a4b3 !important;
            font-family: 'Poppins', sans-serif;
        }
    }
}
// @media (max-width: 770px){
//     .notifications-switch {
//         margin-right: 0 !important;
//     }
// }
// @media (min-width: 770px){
//     .notifications-switch {
//         margin-right: 2rem;
//     }
// }
@media screen and (max-width: 710px) {
    .inputfile{
        width: 250px !important;
    }
}
@media screen and (min-width: 710px) {
    .inputfile{
        width: 350px !important;
    }
}
.settings-textarea{
    textarea{
  background-clip: padding-box !important;
  background-color: #f8f9fa !important;
  border: 1px solid #f8f9fa !important;
  border-radius: 8px !important;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
        padding: 10px !important;
    }
    textarea::placeholder{
        font-size: 14px;
        font-weight: 400;
        padding-left: 3px;
        color: #a1a5b4 ;
    }
}