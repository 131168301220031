.analytics-table {
  .tabs {
    .nav-tabs {
      .nav-link {
        font-size: 14px !important;
        font-weight: 300 !important;

      }

      .active {
        border-bottom: 3px solid #000 !important;
        color: #000 !important;
      }

    }

  }

  .MuiTable-root {
    .MuiTableHead-root {
      tr {
        padding-bottom: 5rem !important;

        th {
          background-color: #F5F5F5 !important;
          font-weight: 700;
          font-size: 13px;
          color: black !important;
          border-bottom: 0 !important;
        }

        th:first-child {
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
        }

        th:last-child {
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;


        }
      }
    }

    .MuiTableBody-root {
      .MuiTableRow-root {
        &:nth-child(even) {
          background-color: #F5F5F5 !important;
        }

        td:first-child {
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
        }

        td:last-child {
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;


        }
      }
    }
  }

  .analytics-filter {
    background-color: rgba($color: #ffff, $alpha: 0.5);
    backdrop-filter: blur(35px);
    width: 325px;
    position: absolute;
    top: 6rem;
    z-index: 9999;
    right: 0;
    border-radius: 20px 20px 20px 20px;

    .form-label {
      font-size: 14px;
      font-weight: 500px;
    }

    .form-check {
      font-size: 13px;
      font-weight: 400;
    }

    .form-control {
      background-clip: padding-box !important;
      background-color: #f8f9fa !important;
      border: 1px solid #f8f9fa !important;
      border-radius: 8px;
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
      font-size: 6px;
      font-weight: 400;
      color: #ced4da !important;
    }

  }

  .card {
    border: none !important;
    border-radius: 20px !important;

    .card-header {
      background-color: transparent !important;
      border-radius: 20px;
      border: none !important;
      font-size: 20px;
      font-weight: 600;
      margin-top: 1rem;
      margin-left: 2rem;
      height: 100px;
    }

    .card-body {
      padding: 0;

      .material-table__wrap {
        padding-top: 0;
      }
    }

    .card-footer {
      border: none !important;
      border-radius: 20px !important;
      background-color: transparent !important;
      margin-top: 2rem;
    }
  }

}

.analytic-select {
  select {
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;
    font-size: 26px;
    background: #fff !important;
    color: white !important;
  }

  .form-select {
    background-color: rgb(0, 0, 0) !important;
    border: none !important;
    border-radius: 10px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    height: 42px !important;
    // margin-top: 0.5rem;
    color: #000 !important;
    padding: 0px 32px 0px 10px !important;
    option{
      color: #fff !important;
    }
    .css-1xc3v61-indicatorContainer {
      color: #4D68F5 !important;
      color: #CCCCCC !important;
    }
  }

  // .form-control {
  //   &:first-child {
  //     padding-left: 55px !important;
  //   }
  // }
}

.custom-form {
  width: 50px;
}

.analytics-card-wrapper {
  .analytics-image-section {
    position: relative;

    .star-image {
      position: absolute;
      background-color: rgba(136, 141, 152, 0.3);
      color: #ffff;
      border-radius: 10px;
      width: 34px;
      height: 34px;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 15px;
      margin-top: 10px;
      backdrop-filter: blur(6px);

    }
  }

  .analytics-info-wrapper {
    .date-dotted {
      width: 6px;
      height: 6px;
      border-radius: 20px;
      background-color: #F58431;

    }
  }
}

.add-comment-icon {
  background-color: #E4ECF8 !important;
  color: #2D87FF !important;
  cursor: pointer;

  &:hover {
    border: 1px solid #2D87FF !important;
    background-color: transparent !important;

  }
}

.add-project-icon {
  background-color: #E4ECF8 !important;
  color: #2D87FF !important;
  cursor: pointer;

  &:hover {
    border: 1px solid #2D87FF !important;
    background-color: transparent !important;
  }
}

.analytics-follow-icon {
  background-color: #E4ECF8 !important;
  color: #2D87FF !important;

  &:hover {
    border: 1px solid #2D87FF !important;
    background-color: #2D87FF !important;
    color: #fff !important;

  }
}

.analytics-count {
  position: absolute;
  background-color: red;
  width: 20px;
  height: 20px;
  line-height: 18px;
  font-size: 14px;
  font-weight: 500;
  color: $white;
  text-align: center;
  padding-top: 2px;
  margin-top: -12px;
  border-radius: 20px 20px 20px 20px;
}

.material-table__wrap {
  padding: 0 !important;
}

.analytic-card {
  background-color: white;
  color: rgb(0, 0, 0);
  padding-top: .9rem;
  padding-bottom: .9rem;

  .badge-views-shadow {
    border: 2px solid #f35321;
    box-shadow: 1px 1px 10px #f35321;

  }

  .badge-like-shadow {
    border: 2px solid #2d87ff;
    box-shadow: 1px 1px 10px #2d87ff;

  }

  .badge-comment-shadow {
    border: 2px solid #f3bf37;
    box-shadow: 1px 1px 10px #f3bf37;
  }

  .badge-share-shadow {
    border: 2px solid #46bcab;
    box-shadow: 1px 1px 10px #46bcab;
  }

  .badge-reach-shadow {
    border: 2px solid #ff59b2;
    box-shadow: 1px 1px 10px #ff59b2;
  }

  .badge-impression-shadow {
    border: 2px solid #6c5dd3;
    box-shadow: 1px 1px 10px #6c5dd3;
  }
}
@media screen and (max-width: 1346px) {
.analytic-card{
  padding-bottom: 1rem;

}  
}

.analytic-image-trending {
  position: absolute;
  width: 100px;
  height: 34px;
  left: 230px;

  background: #FF59B2;
  border-radius: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;


}
.analytics-statistic-section {
  width: 100%;
  .share-badge {
    color: #6d7683 !important;
    height: 30px;
    margin-right: .5rem;
    padding: 5px !important;

    .badge-number {
      color: #000;
    }

    i {
      color: #46BCAB;
    }
  }

  .like-badge {
    color: #6d7683 !important;
    height: 30px;
    margin-right: .5rem;
    padding: 5px !important;

    .badge-number {
      color: #000;
    }

    i {
      color: #2D87FF;
    }
  }

  .comment-badge {
    color: #6d7683 !important;
    height: 30px;
    margin-right: .5rem;
    padding: 5px !important;

    .badge-number {
      color: #000;
    }

    i {
      color: #F3BF37;
    }
  }

  .save-badge {
    color: #6d7683 !important;
    height: 30px;
    margin-right: .5rem;
    padding: 5px !important;

    .badge-number {
      color: #000;
    }

    i {
      color: #4d69fa;
    }
  }

  .view-badge {
    color: #6d7683;
    height: 30px;
    margin-right: .5rem;
    padding: 5px !important;

    .badge-number {
      color: #000 !important;
    }

    i {
      color: #F35421;
    }
  }

  .impression-badge {
    color: #6d7683;
    height: 30px;
    margin-right: .5rem;
    padding: 5px !important;

    .badge-number {
      color: #000 !important;
    }

    i {
      color: #6C5DD3;
    }
  }

  .reach-badge {
    color: #6d7683;
    height: 30px;
    margin-right: .5rem;
    padding: 5px !important;

    .badge-number {
      color: #000 !important;
    }
    i {
      color: #ff59b2;
    }
  }

  // .play-badge{
  //     background-color: #FFF5DC !important;
  //     color: #6d7683;
  //     width: 166px;
  //     height: 36px;
  //     margin-right: .5rem;
  //     .badge-number{
  //         color: #000 !important;
  //     }
  //     i{
  //         color: #F3BF37;
  //     }
  // }
}
// .analytics-statistic-section {
//   .share-badge {
//     background-color: #EBF8F6;
//     color: #6d7683 !important;
//     width: 140px;
//     height: 30px;
//     margin-right: .5rem;

//     .badge-number {
//       color: #000;
//     }

//     i {
//       color: #46BCAB;
//     }
//   }

//   .like-badge {
//     background-color: #EAF3FF;
//     color: #6d7683 !important;
//     width: 140px;
//     height: 30px;
//     margin-right: .5rem;

//     .badge-number {
//       color: #000;
//     }

//     i {
//       color: #2D87FF;
//     }
//   }

//   .comment-badge {
//     background-color: #FFF5DC;
//     color: #6d7683 !important;
//     width: 140px;
//     height: 30px;
//     margin-right: .5rem;

//     .badge-number {
//       color: #000;
//     }

//     i {
//       color: #F3BF37;
//     }
//   }

//   .save-badge {
//     background-color: #EAF3FF;
//     color: #6d7683 !important;
//     width: 140px;
//     height: 30px;
//     margin-right: .5rem;

//     .badge-number {
//       color: #000;
//     }

//     i {
//       color: #4d69fa;
//     }
//   }

//   .view-badge {
//     background-color: #FFEEE9 !important;
//     color: #6d7683;
//     width: 140px;
//     height: 30px;
//     margin-right: .5rem;

//     .badge-number {
//       color: #000 !important;
//     }

//     i {
//       color: #F35421;
//     }
//   }

//   .impression-badge {
//     background-color: #F0EFFB !important;
//     color: #6d7683;
//     width: 140px;
//     height: 30px;
//     margin-right: .5rem;

//     .badge-number {
//       color: #000 !important;
//     }

//     i {
//       color: #6C5DD3;
//     }
//   }

//   .reach-badge {
//     background-color: #FFEEF7 !important;
//     color: #6d7683;
//     width: 140px;
//     height: 30px;
//     margin-right: .5rem;

//     .badge-number {
//       color: #000 !important;
//     }
//   }
// }
// @media (max-width: 675px) {
//   .analytics-statistic-section {
//     .share-badge {
//       background-color: #EBF8F6;
//       color: #6d7683 !important;
//       width: 166px;
//       height: 30px;
//       margin-right: .5rem;
  
//       .badge-number {
//         color: #000;
//       }
  
//       i {
//         color: #46BCAB;
//       }
//     }
  
//     .like-badge {
//       background-color: #EAF3FF;
//       color: #6d7683 !important;
//       width: 166px;
//       height: 30px;
//       margin-right: .5rem;
  
//       .badge-number {
//         color: #000;
//       }
  
//       i {
//         color: #2D87FF;
//       }
//     }
  
//     .comment-badge {
//       background-color: #FFF5DC;
//       color: #6d7683 !important;
//       width: 166px;
//       height: 30px;
//       margin-right: .5rem;
  
//       .badge-number {
//         color: #000;
//       }
  
//       i {
//         color: #F3BF37;
//       }
//     }
  
//     .save-badge {
//       background-color: #EAF3FF;
//       color: #6d7683 !important;
//       width: 166px;
//       height: 30px;
//       margin-right: .5rem;
  
//       .badge-number {
//         color: #000;
//       }
  
//       i {
//         color: #4d69fa;
//       }
//     }
  
//     .view-badge {
//       background-color: #FFEEE9 !important;
//       color: #6d7683;
//       width: 166px;
//       height: 30px;
//       margin-right: .5rem;
  
//       .badge-number {
//         color: #000 !important;
//       }
  
//       i {
//         color: #F35421;
//       }
//     }
  
//     .impression-badge {
//       background-color: #F0EFFB !important;
//       color: #6d7683;
//       width: 166px;
//       height: 30px;
//       margin-right: .5rem;
  
//       .badge-number {
//         color: #000 !important;
//       }
  
//       i {
//         color: #6C5DD3;
//       }
//     }
  
//     .reach-badge {
//       background-color: #FFEEF7 !important;
//       color: #6d7683;
//       width: 166px;
//       height: 30px;
//       margin-right: .5rem;
  
//       .badge-number {
//         color: #000 !important;
//       }
//     }
//   }
// }
// @media (min-width: 1346px){

//   .analytics-statistic-section {
//     .share-badge {
//       background-color: #EBF8F6;
//       color: #6d7683 !important;
//       width: 125px;
//       height: 30px;
//       margin-right: .5rem;
//       padding: 5px !important;
  
//       .badge-number {
//         color: #000;
//       }
  
//       i {
//         color: #46BCAB;
//       }
//     }
  
//     .like-badge {
//       background-color: #EAF3FF;
//       color: #6d7683 !important;
//       width: 125px;
//       height: 30px;
//       margin-right: .5rem;
//       padding: 5px !important;
  
//       .badge-number {
//         color: #000;
//       }
  
//       i {
//         color: #2D87FF;
//       }
//     }
  
//     .comment-badge {
//       background-color: #FFF5DC;
//       color: #6d7683 !important;
//       width: 125px;
//       height: 30px;
//       margin-right: .5rem;
//       padding: 5px !important;
  
//       .badge-number {
//         color: #000;
//       }
  
//       i {
//         color: #F3BF37;
//       }
//     }
  
//     .save-badge {
//       background-color: #EAF3FF;
//       color: #6d7683 !important;
//       width: 125px;
//       height: 30px;
//       margin-right: .5rem;
//       padding: 5px !important;
  
//       .badge-number {
//         color: #000;
//       }
  
//       i {
//         color: #4d69fa;
//       }
//     }
  
//     .view-badge {
//       background-color: #FFEEE9 !important;
//       color: #6d7683;
//       width: 125px;
//       height: 30px;
//       margin-right: .5rem;
//       padding: 5px !important;
  
//       .badge-number {
//         color: #000 !important;
//       }
  
//       i {
//         color: #F35421;
//       }
//     }
  
//     .impression-badge {
//       background-color: #F0EFFB !important;
//       color: #6d7683;
//       width: 125px;
//       height: 30px;
//       margin-right: .5rem;
//       padding: 5px !important;
  
//       .badge-number {
//         color: #000 !important;
//       }
  
//       i {
//         color: #6C5DD3;
//       }
//     }
  
//     .reach-badge {
//       background-color: #FFEEF7 !important;
//       color: #6d7683;
//       width: 125px;
//       height: 30px;
//       margin-right: .5rem;
//       padding: 5px !important;
  
//       .badge-number {
//         color: #000 !important;
//       }
//     }
  
// }
// }


.see-caption {
  background-color: transparent !important;
  padding: 0;
  margin-bottom: 0;
  margin-top: 0;

  .accordion-arrow {
    display: flex !important;
    justify-content: start !important;
    align-items: center !important;

    .social-accordion-title {
      color: #303538 !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      padding: 0 !important;

    }

    i {
      color: #35373e;
      font-size: 12px !important;
      padding-top: 0.1rem;
      padding-left: 8px;
      width: 20px;
    }
  }
}
.not-shared-accounts {
  background-color: transparent !important;
  display: flex;
  justify-content: end;
  padding: 0px !important;
  margin-top: 1rem;

  .accordion-arrow {
    display: flex !important;
    justify-content: start !important;
    align-items: center !important;
    padding: 0px !important;


    .social-accordion-title {
      color: #303538 !important;
      font-weight: 500 !important;
      font-size: 12px !important;
      padding: 0 !important;

    }

    i {
      color: #35373e;
      font-size: 12px !important;
      padding-top: 0.1rem;
      padding-left: 8px;
      width: 20px;
    }
  }
}

// @media (min-width: 720px) {
// .not-shared-accounts-mobil {
//   display: none !important;
// }
// }
// @media (max-width: 720px) {
// .not-shared-accounts{
//   display: none !important;
// }
  
// }
.not-shared-accounts-mobil {
  background-color: transparent !important;
  padding: 0px !important;
  margin-top: .3rem;

  .accordion-arrow {
    display: flex !important;
    justify-content: start !important;
    align-items: center !important;
    padding: 0px !important;


    .social-accordion-title {
      color: #303538 !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      padding: 0 !important;

    }

    i {
      color: #35373e;
      font-size: 12px !important;
      padding-top: 0.1rem;
      padding-left: 8px;
      width: 20px;
    }
  }
}

.analytics-modal-body {
  // max-height: 35rem;
  // overflow-y: scroll;
  overflow-x: auto;
  // padding-inline: inherit;
  padding: 0 !important;
}

.posted-by-label {
  color: #6c757d !important;
}

.image-container {
  height: 190px;
  max-width: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 8px !important;

  .bg-image {
    filter: blur(10px);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .img-reels {
    max-width: 100%;
    height: auto;
    position: relative;
    border-radius: 0 !important;

  }
}


@media(min-width: 573px){
  .analytics-filter-scroll {
    max-height: 38rem !important;
    overflow-y: scroll !important;
  
    .analytics-filter {
      padding: 0px 10px 5px 30px;
  
    }
  }
}
@media(max-width: 573px){
  .analytics-filter-scroll {
    max-height: 30rem !important;
    overflow-y: scroll !important;
  
    .analytics-filter {
      padding: 0px 10px 5px 30px;
  
    }
  }
}

@media (min-width: 415px) {
  // .image-container {
  //   height: 190px;
  //   max-width: 200px;
  //   position: relative;
  //   display: flex;
  //   justify-content: center;
  //   overflow: hidden;
  //   border-radius: 8px !important;

  //   .bg-image {
  //     filter: blur(10px);
  //     position: absolute;
  //     left: 0;
  //     top: 0;
  //     width: 100%;
  //     height: 100%;
  //     object-fit: fill;
  //   }

  //   .img-reels {
  //     max-width: 100%;
  //     height: auto;
  //     position: relative;
  //     border-radius: 0 !important;

  //   }
  // }

  // @media (max-width: 414px) {
  //   .nav-tabs {
  //     display: none !important;
  //   }
  // }
  // @media (min-width: 415px) {
  //   .nav-tabs-bottom {
  //     display: none !important;
  //   }
  // }
  // @media (max-width: 414px) {
  //   .nav-tabs-bottom {
  //       width: 390px;
  //       height: 70px;
  //       // left: 0;
  //       // right: 0;
  //       // top: 774px;
  //       bottom: 0 !important;
  //       position: sticky;
  //       position: -webkit-sticky;
  //       z-index: 1000 !important;

  //       display: flex;
  //       justify-content: space-around;

  //       background: #05050B !important;
  //       box-shadow: 0px 4px 50px rgba(31, 49, 73, 0.08);
  //       border-radius: 20px 20px 0px 0px;
  //   }
  // }
}

// @media (max-width: 1346px) {
//   .image-container {
//     height: 200px;
//     width: 250px;
//     position: relative;
//     display: flex;
//     justify-content: center;
//     overflow: hidden;
//     border-radius: 8px !important;

//     .bg-image {
//       filter: blur(10px);
//       position: absolute;
//       left: 0;
//       top: 0;
//       width: 100%;
//       height: 100%;
//       object-fit: fill;
//     }

//     .img-reels {
//       max-width: 100%;
//       height: auto;
//       position: relative;
//       border-radius: 0 !important;

//     }
//   }

//   // @media (max-width: 414px) {
//   //   .nav-tabs {
//   //     display: none !important;
//   //   }
//   // }
//   // @media (min-width: 415px) {
//   //   .nav-tabs-bottom {
//   //     display: none !important;
//   //   }
//   // }
//   // @media (max-width: 414px) {
//   //   .nav-tabs-bottom {
//   //       width: 390px;
//   //       height: 70px;
//   //       // left: 0;
//   //       // right: 0;
//   //       // top: 774px;
//   //       bottom: 0 !important;
//   //       position: sticky;
//   //       position: -webkit-sticky;
//   //       z-index: 1000 !important;

//   //       display: flex;
//   //       justify-content: space-around;

//   //       background: #05050B !important;
//   //       box-shadow: 0px 4px 50px rgba(31, 49, 73, 0.08);
//   //       border-radius: 20px 20px 0px 0px;
//   //       .nav-link{
//   //           color: white !important;
//   //       }
//   //   }
//   // }
// }

@media (max-width: 414px) {
  .analytics-avatar {
    img {
      border-radius: 8px !important;

    }

    .btn {
      backdrop-filter: blur(6px) !important;
      background-color: rgba(0, 0, 0, 0.7);
      //padding: 6px 32.4px;
      //padding-inline: 34px;
      border: none !important;
      border-radius: 8px 0px 0px 8px !important;
      // font-size: 9px !important;
      padding: 0px !important;
    }

    .btn-second {
      border-radius: 0px 8px 8px 0px !important;

    }

    .analytics-image-button {
      opacity: 1;
      position: relative;
      bottom: 38px;
      width: 120px;
      padding: 0 !important;
      margin: 0 !important;
      z-index: 1000;
    }

    &:hover {
      .analytics-image-button {
        opacity: 1;
      }
    }
  }

}

// @media (min-width: 414px) {
  .analytics-avatar {
    max-width: 150px !important;
    max-height: 190px !important;

    img {
      border-radius: 8px !important;

    }

    .btn {
      backdrop-filter: blur(6px) !important;
      background-color: rgba(0, 0, 0, 0.7);
      //padding: 6px 32.4px;
      // padding-inline: 34px;
      border: none !important;
      border-radius: 8px 0px 0px 8px;
      // padding: 9px 14.2px !important;
      padding: 5px 0 !important;
    }

    .btn-second {
      border-radius: 0px 8px 8px 0px;

    }

    .analytics-image-button {
      // opacity: 0;
      position: relative;
      bottom: 38px;
      width: 150px;
      padding: 0 !important;
      margin: 0 !important;
      z-index: 1000;
      a, Link, .btn{
        width: 76px !important;
      }
    }

    // &:hover {
    //   .analytics-image-button {
    //     opacity: 1;
    //   }
    // }
  }
// }
@media (min-width: 900px) {
  .analytics-avatar {
  .analytics-image-button {
    opacity: 0;
  }
      &:hover {
      .analytics-image-button {
        opacity: 1;
      }
    }
  }
}

@media (max-width: 1346px) {
  .web_badge {
    display: none !important;

  }

  .analytics-right-side-web {
    display: none !important;
  }
}

@media (min-width: 1346px) {
  .mobile_badge {
    display: none !important;
  }
}
@media (min-width: 1346px) {
  .post-info-tablet {
    display: none !important;
  }
}
@media (max-width: 1014px){
  .web-info {
    display: none !important;
  }
}
@media (min-width: 1014px){
  .tablet-info {
    display: none !important;
  }
}

.material-table-toolbar {
  //height: 70px;
  height: auto;
  // margin-top: 10px;
}


// @media (min-width: 415px) {
//   .analytics-search {
//     width: 500px;
//   }
// }

// @media (max-width: 1253px) {
//   .analytics-search {
//     width: 150px;
//   }
// }
@media screen and (min-width: 1380px) {
  .analytics-search{
    width: 70%;
  }
}
@media screen and (min-width: 1105px) and (max-width: 1380px) {
  .analytics-search{
    width: 60%;
  }
}

@media (max-width: 893px){
  .analiytics-web-right-side{
    display: none !important;
  }
}
@media (min-width: 893px){
  .analiytics-tablet-right-side{
    display: none !important;
  }
}
@media (min-width: 1346px){
  .product_name_tablet{
    display: none !important;
  }
}

.btn-save-this-filter{

    background-color: #edf8f7;
    border: 1px solid #48bdaa;
    border-radius: 8px;
    color: #48bdaa;
    font-size: 12px;
    font-weight: 600;
    width: 140px;
    padding: 9px 20px !important;

    &:hover {
        background-color: #48bdaa;
        border: 1px solid #48bdaa;
        border-radius: 8px;
        color: #fff;
    }
}
.alert-importing{
  margin-top: 1rem;
  border-radius: 8px;
  background-color: #dbe1fe;
  color:#2e3f96;
  border-color:#cad2fe ;
  display: flex;
  flex-wrap: wrap;
}
.alert-imported{
  margin-top: 1rem;
  border-radius: 20px;
  background-color: #daf2ee;
  color:#2a7166;
  border-color:#c8ebe6;
  display: flex;
  flex-wrap: wrap;
}
.btn-reset-payload{
  // background-color: #dee2e6;
  // border: 1px solid #dee2e6;
  // border-radius: 8px;
  // color: #000;
  // font-size: 12px;
  // font-weight: 600;
  // padding: 8px 15px !important;

  // &:hover {
  //     background-color: #b7c0c9;
  //     border: 1px solid #b7c0c9;
  //     border-radius: 10px;
  //     color: #000;
  // }

  color: #c90009;
  background-color: transparent;
  border: 1px solid #c90009;
    font-size: 12px;
  font-weight: 600;
  padding: 9px 20px !important;
  &:hover{
    border: 1px solid #c90009;
    color: #fff;
    background-color: #c90009;
  }
}
.btn-saved-filters {
  background-color: #edf0ff;
  color: #4d69fa !important;
  border-radius: 8px;
  border: 1px solid #4d69fa !important;
  padding: 8px 10px;
  // margin-right: 8px;
  width: 120px;
  // margin-top: 10px;
  font-size: 12px;
  font-weight: 600;
  height: 40px;
}

.btn-saved-filters:hover {
  background-color: #4d69fa !important;
  border-color: #4d69fa !important;
  color: white !important;
}
.imported-data-x {
  background-color: #2a7066;
  border: none;
  font-size: 13px;
  padding: 0;
  color: #fff;
  position: absolute;
  z-index: 1;
  // top: 95px;
  // margin-left: 127px;
  right: 0;
  margin-right: 4px;
  // top: 0;
  padding: 5px 6px;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: #64c5b7;
    // background-color: #5c636a;
    color: #2a7066;
  }
}
.choose-saved-filter{
  .react-select__control{
    border-radius: 8px !important;
  }
}
.carousel-icon{
  position: absolute;
  margin-top: 167px;
  left: 9.6rem;
  z-index: 1;
  font-size: 16px;
  color: #e4e6eb;
}
.reels-icon{
  position: absolute;
  margin-top: 167px;
  left: 9.6rem;
  z-index: 999;
  width: 18px;
  height: 18px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(10%) saturate(2568%) hue-rotate(177deg) brightness(89%) contrast(109%);
}
.submit-btn {
  background-color: #000;
  color: white;
  border: 1px solid #000;
  border-radius: 8px;

}
.submit-btn:hover{
  background-color: #0d0d0d !important;
  border: 1px solid #0d0d0d;
  color: white;
  border-radius: 8px;

}
// @media screen and (min-width: 513px) {
//   .mobile-date, .saved-filters-mobile{
//     display: none !important;
//   }
  
// }
@media screen and (max-width: 767px) {
  .web-date, .analytics-filter-btn-web, .web-expand-dropdown{
    display: none;
  }
  .analytics-search{
    width: 100%;
  }
  .sort-saved-filter-mobile{
    width: 100%;
    justify-content: space-between;
  }
  .tab-all{
    width: 100%;
  }
  .btn-reset-payload{
    width: 140px;
  }
  .tabs-right-buttons{
    justify-content: center;
  }
}
@media screen and (min-width: 768px) {
  .mobile-date{
    display: none !important;
  }
  .tabs-right-buttons{
    justify-content: end;
  }
  // .tab-all{
  //   width: 60%;
  // }
  
}
@media screen and (min-width: 484px) {
  .mobile-tabs{
    display: none !important;
  }
}
.image-star{
  background: #323643;
  border: none;
  opacity: 0.7;
  color: #f3bf37;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  // left: "28px",
  font-size: 20px;
  // margin-top: 6px;
  margin-top: 38px;
  z-index: 1;
  margin-left: 5px;
  &::after{
    display: none !important;
  }
  &:hover{
    i{
      color: #f3bf37;
    }
  }
}
// @media screen and (max-width: 415px) {
//   .analytics-avatar-mobile{
//     justify-content: center;
//     width: 100%;
//   }
//   .image-star{
//     left: 78px;
//   }
  
// }
.filter-btn-mobile{
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 10px;
  /* height: 42px; */
  padding: 2px 6px;
  width: 33px;
  height: 33px;
}

.show-more-btn{
  color: white !important;
    background-color: #000 !important;
    border-radius: 8px;
    border: none !important;
    padding: 9px 25px !important;
    margin-left: 2px;
    margin-right: 8px;
    width: 140px !important;
    margin-top: 10px;
}
@media screen and (max-width: 1345px) and (min-width: 646px) {
  // .analytics-statistic-section{
  //   width: 560px;
  // }
  .badge-icon{
    display: none;
  }
}
@media screen and (max-width: 645px) {
  // .analytics-statistic-section{
  //   width: 300px;
  // }
  .badge-text{
    display: none;
  }
  // .mobile-badge-icon{
  //   display: flex;
  //   justify-content: center;
  // }
  .analytics-statistic-section{
    width: 315px;
    .share-badge {
      width: 70px;
      padding: 5px 10px !important;
    }

    .like-badge {
      width: 70px;
      padding: 5px 10px !important;
    }

    .comment-badge {
      width: 70px;
      padding: 5px 10px !important;
    }

    .save-badge {
      width: 70px;
      padding: 5px 10px !important;
    }

    .view-badge {
      width: 70px;
      padding: 5px 10px !important;
    }

    .impression-badge {
      width: 70px;
      padding: 5px 10px !important;
    }

    .reach-badge {
      width: 70px;
      padding: 5px 10px !important;
    }
  }
}
// @media screen and (max-width: 1390px) {
//   .tabs-right-buttons{
//     width: 100% !important;
//   }
// }
@media screen and (max-width: 1153px) {
  .instagram-profile-tab{
    flex-wrap: wrap;
  }
  // .tab-all{
  //   width: 100%;
  // }
}
@media screen and (max-width: 1220px) {
  .tab-all{
    width: 100%;
  }
  .tabs-right-buttons{
    width: 100%;
  }
  .swiper-all{
    .swiper{
      max-width: 100% !important;
    }
  }
}

.social-media-search:placeholder-shown{
  font-weight: 500;
  }

  .btn-smart-filters{
    // background-color: rgba(250, 223, 232, .2);
    // border: 1px solid rgb(201,28,78);
    background-color: rgba(243,191,55, .2);;
    border: 1px solid #f3bf37;
    color: #f3bf37;
    padding: 8px 10px;
    width: 120px !important;
    // margin-top: 10px;
    font-size: 12px;
    font-weight: 600;
    // color: rgb(201,28,78);
    border-radius: 8px;
    &:hover{
      background-color: #f3bf37;
      border: 1px solid #f3bf37;
    }
  }
  .smart-filters{
    background-color: #e4e4e4;
    border-radius: 8px;
    padding: 12px 25px;
    margin-right: 6px;
  }

  .analytics-tabs {
    background-color: transparent !important;
  padding: 0;
  margin-bottom: 0;
  margin-top: 0;

  .accordion-arrow {
    display: flex !important;
    justify-content: end !important;
    align-items: center !important;
    padding: 0 5px 0 0  !important;

    .social-accordion-title {
      color: #303538 !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      padding: 0 !important;

    }

    i {
      color: #35373e;
      font-size: 12px !important;
      padding-left: 8px;
      width: 20px;
    }
  }
    
  }
  .analytics-scrollable-card{
    ::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 6px #e4e4e4;
      background-color: #555;
  }
  }
  .btn-group{
    button{
      color: #000;
      background-color: #e7eef8;
      border: 1px solid #e7eef8;
      &:hover{
        background-color: #c4cad3;
        border: 1px solid #b9bec6;
      }

      &.active{
        color: #fff !important;
        background-color: #46bcaa !important;
        border: 1px solid #46bcaa !important;
        &:hover{
          background-color: #62c6b7 !important;
          border: 1px solid #59c3b3;
        }
      }
    }
  }
  .custom-tab{
    background-color: white;
    color: black !important;
    border-radius: 10px;
    border: none !important;
    padding: 8px 10px;
    margin-left: 2px;
    margin-right: 8px;
    margin-top: 20px !important;
  }
  .fav-dropdown-input{
    background-clip: padding-box !important;
    background-color: #f8f9fa !important;
    border: 1px solid #f8f9fa !important;
    border-radius: 8px !important;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
    color: #000 !important;
  }
  .filters-badge{
    background-color: #000;
    color: #dedddd;
  }
  .analytics-badge{
    background-color: #f1f1f1;
  }