.rbc-calendar {
    font-family: $font-family-base;
    .rbc-toolbar {
        display: flex;
        justify-content: space-between;
        margin-top: 0;

        .rbc-btn-group {
            button{
                color: #6c5dd3;
                background-color: #f0effb;
                border-color: #f0effb;
                // :hover{
                //     color: #fff;
                //     background-color: #6c5dd3;
                //     border-color: #6c5dd3;
                // }
            }
            button:first-child {
                border-radius: 8px 0px 0px 8px;
            }

            button:last-child {
                border-radius: 0px 8px 8px 0px;
            }


            .rbc-active {
                background-color: #7b6dd7;
                color: #e7eef8;
            }
        }
    }
    .rbc-month-view{
        border-radius: 1rem !important;
        margin-top: 2rem;
        border: 1px solid #dee2e6;
        display: flex;
        flex: 1 0;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
        position: relative;
        user-select: none;
        width: 100%;
        
        .rbc-header{
            font-weight: 600;
        }
        .rbc-event{
            background-color: #d3dafe;
            color: #4d69fa;
            border: none;
            border-radius: 3px;
            box-shadow: none;
            box-sizing: border-box;
            cursor: pointer;
            font-weight: 400;
            font-size: 12px !important;
            margin: 0;
            padding: 0 .5rem;
            text-align: left;
            width: 100%;
            transition: all .2s ease-in-out;
        }
        .rbc-show-more{
            color: #adb5bd;
            transition: all .2s ease-in-out;
        }
        .rbc-show-more:hover{
            color: #1f2127 !important;
            
        }
    }

    .rbc-time-view{
        border-radius: 1rem !important;
        margin-top: 2rem;
        border: 1px solid #dee2e6;
        display: flex;
        flex: 1 0;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
        position: relative;
        user-select: none;
        width: 100%;

        .rbc-event{
            background-color: #d3dafe;
            color: #4d69fa;
            border: none;
            border-radius: 8px;
            box-shadow: none;
            box-sizing: border-box;
            cursor: pointer;
            font-weight: 400;
            font-size: 12px !important;
            margin: 0;
            padding: 0.2rem .5rem;
            text-align: left;
            width: 100%;
            transition: all .2s ease-in-out;
        }
        .rbc-header{
            border-left: 0 !important;
        }
    }

}
.dnd-img-div{
    width: 50px;
    height: 50px;
    .dnd-img{
        width: 50px;
        height: 50px;
        // border: 1px solid #818286;
        border-radius: 15px;
    }
}
.dnd-img-div-modal{
    width: 70px;
    height: 70px;
    .dnd-img{
        width: 70px;
        height: 70px;
        border: 1px solid #818286;
        border-radius: 15px;
    }
}
.calendar-img{
    width: 20px;
    height: 20px;
}
.calendar-modal-img{
    width: 180px;
    height: auto;
    border-radius: 20px;
}
.scheduler-post-caption{
    width: 100%;
    .react-emoji{
        padding-bottom: 100px !important;
        z-index: 999;
        min-height: 150px !important;

    }
}
.schedule-post {
    background-color: #EDF0FF;
    border: 1px solid #EDF0FF;
    border-radius: 15px;
    color: #4D69FA;
    font-size: 12px;
    font-weight: 600;

    &:hover {
        background-color: #4D69FA;
        border: 1px solid #4D69FA;
        border-radius: 15px;
        color: #fff;
    }
}
.change-img{
    border-radius: 20px;
    border: 1px solid #949fa8;
    background-color: transparent;
    color: #949fa8;
    font-size: 12px;
    font-weight: 500;
    padding: .3rem .7rem;
}
.not-selected-post{
    color: #e6e6e6;
}
.seleted-post{
    color: #44b8a7;
    .dnd-img{
        width: 50px;
        height: 50px;
        border: 3px solid #44b8a7;
        border-radius: 15px;
    }
}
.scheduler-form-select{
    width: 120px;
    padding: 7px 7px 7px 15px !important;
    background-clip: padding-box !important;
    background-color: #f8f9fa !important;
    border: 1px solid #f8f9fa !important;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
}
.carousel-inner{
    width: 180px !important;
}
.save-changes-btn{
    border: 1px solid #798efd;
    border-radius: 10px;
    color:#798efd;
    font-size: 12px;
    font-weight: 400;
    background: transparent;
    &:hover {
        background-color: rgba(121, 142, 253, 0.2);
        border: 1px solid #798EFD;
        border-radius: 10px;
        color: #798EFD;
    }
}
.scheduler-imgs-card{
    height: 84vh;
    .card-body{
        overflow-y: scroll;
    }
}
// .scheduler-dropdown-button{
//     display: flex;
//     align-items: center;
//     background: transparent;
//     border: none !important;
//     color: #000;
// }
.react-dropdown-select{
    border-radius: 20px;
    padding: 3px 10px 3px 10px !important;
    background-clip: padding-box !important;
    background-color: #f8f9fa !important;
    border: 1px solid #f8f9fa !important;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
    cursor: pointer;
    min-width: 100px;

    .react-dropdown-select-option{
        border-radius: 20px;
        background-color: #d4dbff;
        color:#4d69fa;
        display: flex;
        align-items: center;
        padding: 3px 10px;
    
    }
}
.react-dropdown-select-dropdown{
    border-radius: 20px !important;
    border: none !important;
    width: 100%;
    padding: .5rem .5rem;
}
.react-dropdown-select-item-selected{
    background-color: #f2f2f2 !important;
    border-radius: 1rem;
    color: #999999 !important;
}
.react-dropdown-select-item:hover{
    border-radius: 20px;
    background-color: #f2f2f2;
}
.scheduler-tab{
    .nav-link{
        width: 155px !important;
        // padding: 10px 22px 10px 22px;
    }
}
.header-account-date{
    padding-bottom: 1rem;
    border-bottom: 2px solid #f3f3f3;
}
.drag-drop-icon{
    max-width: 300px;
}
.drag-drop-file{
    display: flex;

    label{
        min-height: 15rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: dashed 2px #b7bcbf;
        
        div{
            span{
                color: #b7bcbf !important;
                text-align: center;
            }
        }
    }
    svg{
        margin-top: 2rem;
        height: 90px;
        width: 90px;
        filter: brightness(0) saturate(100%) invert(98%) sepia(1%) saturate(1979%) hue-rotate(173deg) brightness(80%) contrast(84%);
    }
}
.sc-beqWaB{
    max-width: none !important;
    min-width: 100% !important;
}
.back_today_next{
    display: inline-flex;
    position: relative;
    vertical-align: middle;

    .back_icon{
        padding: 0.5rem 0.8rem;
        position: relative;
        border-radius: 8px 0 0 8px !important;
        height: calc(21px + 1rem);
        display: flex;
        align-items: center;

    }
    .next_icon{
        padding: 0.5rem 0.8rem;
        position: relative;
        border-radius: 0 8px 8px 0 !important;
        height: calc(21px + 1rem);
        display: flex;
        align-items: center;
        
    }
    .today_btn{
        padding: 0.5rem 0.8rem;
        position: relative;
        border-radius: 0 !important;
        height: calc(21px + 1rem);
        display: flex;
        align-items: center;

    }
}
.calendar{
    .css-b62m3t-container{
        background: transparent !important;
    }

    .css-13cymwt-control{
        background-color: #17191e !important;
        color: #fff !important;
        border-color: #17191e !important;
        border-radius: 8px;
        min-height: 20px !important;
        max-height: 38px !important;
        .css-1dimb5e-singleValue{
            color: #fff !important;
        }
    
    }
    .css-t3ipsp-control{
        background-color: #17191e !important;
        color: #fff !important;
        border-color: #17191e !important;
        border-radius: 8px;
        min-height: 20px !important;
        max-height: 38px !important;
        .css-1dimb5e-singleValue{
            color: #fff !important;
        }
    }
    .react-select__value-container{
        padding: 6px 15px !important;
    }
}
.instagram-account{
    background-color: #E4405F;
    color: #e4e6eb !important;
}
.twitter-account{
    background-color: #00acee;
    color: #e4e6eb !important;
}
.tiktok-account{
    background-color: #000;
    color: #e4e6eb !important;
}
.selected-account{
    border: 3px solid #4d69f8;
}
.not-selected-account{
    border: 3px solid #f2f2f2;
    
}
.calendar-select-account{
    width: 350px;
    border: 1px solid #939ea7;
    border-radius: 8px;
    height: 40px;
    display: flex;
    align-items: center;
    
    button{
        width: 100%;
        display: flex !important;
        justify-content: space-between !important;
        padding-inline: 1rem !important;
    }
    .account-badge{
        background-color:#08070f  !important;
        color: #e4e6eb !important;
        padding: 3px 10px !important;
        border-radius: 8px;
        margin-right: .5rem;
        font-size: 12px;
        display: flex !important;
        align-items: center !important;
        .close-account:hover{
            color:#f35321;
        }
    }
    .dropdown-toggle::after{
        display: flex;
        justify-content: end !important;
    }
}
.calendar-dropdown-menu{
    width: inherit;
  
    .selected-option {
      border-radius: 10px;
    }
}
.inside-input-btn{
    background: transparent;
    border: none;
    font-size: 12px;
    padding: 0;
    color: #212529;
    :hover{
        color: #4d69fa !important;
    }
}
.scheduler-textarea{
    textarea{
        background-clip: padding-box !important;
        background-color: transparent;
        border: 1px solid #949fa8 !important;
        border-radius: 10px !important;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
        color: #fff;
        padding: 10px !important;
    }
    textarea::placeholder{
        font-size: 14px;
        font-weight: 400;
        padding-left: 3px;
        color: #a1a5b4 ;
    }
}
.ai-caption{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.save-generate-captions-btn{
    width: 80%;
    border: 1px solid #798efd;
    border-radius: 10px;
    color:#798efd;
    font-size: 12px;
    font-weight: 400;
    background: transparent;
    &:hover {
        background-color: rgba(121, 142, 253, 0.2);
        border: 1px solid #798EFD;
        border-radius: 10px;
        color: #798EFD;
    }
}
.generared-captions{
    border-style: dashed;
    border-radius: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.selected-caption{
    border-color: #44bca9 !important;
}
.captions{
    border-color: #dedede;
}
.scheduler-ai-caption{
    div{
        margin-top: 0 !important;
        width: 80%;
        .form-control{
            background-clip: padding-box !important;
            background-color: #f8f9fa !important;
            border: 1px solid #f8f9fa !important;
            border-radius: 10px !important;
            box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
            color: #fff !important;
        }
    }
}
.tag-people-img-big{
    width: 300px;
    border-radius: 10px;
}
.scheduler-input{
    div{
        margin-top: 0 !important;
        .form-control{
            background-clip: padding-box !important;
            background-color: #f8f9fa !important;
            border: 1px solid #f8f9fa !important;
            border-radius: 10px !important;
            box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
            color: #fff !important;
        }
    }
}
.show-hide-span{
    padding: 0;
    background: transparent;
    border: none;
    font-size: 14px;
    font-weight: 500;
   cursor: pointer;
}
.upcoming-img{
    width: 30px;
    height: 30px;
}
.scheduler-table{
    .material-table__wrap {
        background-color: transparent !important;
        color: black !important;
        .MuiTableBody-root{
            tr{
              &:hover{
                background-color: #e8edf4 !important;
              } 
            }
      
       }
        .MuiTableRow-root {
          td {
            // color: #e4e6eb !important;
  
          }
          &:nth-child(even) {
            background-color: #f2f5f9;
          }
  
          td:first-child {
            border-radius: 20px 0 0 20px;
          }
  
          td:last-child {
            border-radius: 0 20px 20px 0;
  
          }
  
          .MuiTableRow-head {
  
            .MuiTableRow-head:first-child {
              border-radius: 20px 0 0 20px;
            }
  
  
            .MuiTableRow-head:last-child {
              border-radius: 0 20px 20px 0;
            }
          }
        }
        .MuiTableRow-footer{
          padding-top: 1rem !important;
        }
      }
  
      
      .MuiTableHead-root {
        tr {
          th {
            background-color: #f2f5f9 !important;
          }
  
          th:first-child {
            border-radius: 20px 0 0 20px;
          }
  
          th:last-child {
            border-radius: 0 20px 20px 0;
          }
        }
      }
}
.upload-media{
    label{
        height: 5rem !important;
    }
}