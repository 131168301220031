.modal {
    .modal-content {
      border-radius: 20px;
      border: none;
      background-color: #1f2127;
  
      .modal-header {
        border-bottom: 2px solid #e3e6ea;
        padding: 20px 55px 20px 55px;
        color: white !important;
  
        @media (max-width: 768px) {
          padding: 50px 45px 15px 45px;
        }
  
        .modal-title {
          font-weight: 400 !important;
          font-size: 18px !important;
  
          // @media (max-width: 768px) {
          //   font-size: 24px !important;
          // }
        }
  
        .close {
          width: 14px;
          height: 14px;
          position: absolute;
          top: 27px;
          right: 36px;
          padding: 0;
          background: transparent escape-svg(url("../../images/icon/modal_close.svg")) center / 1em auto no-repeat;
          background-size: 24px 24px;
          border: 0;
          z-index: 2;
          span {
            display: none;
          }
        }
      }
  
      .modal-body {
        overflow: visible;
        padding:20px 20px;
        // max-height: calc(100vh - 280px);
        // overflow-y: auto;
  
        @media (max-width: 768px) {
          padding: 20px 45px 10px 45px;
        }
  
        .title {
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 22px;
        }
  
        .description {
          font-size: 14px;
          font-weight: 400;
          display: block;
          margin-bottom: 12px;
          color: $muted;
        }

        .form{
          .form-label{
            color: white;
          }
          .form-control{
            background-clip: padding-box !important;
            background-color: #2b2f38 !important;
            border: 1px solid #2b2f38 !important;
            border-radius: 8px !important;
            box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
            color: #fff;
          }
        }
      }
  
      .modal-footer {
        padding: 10px;
        border: none;
  
        @media (max-width: 768px) {
          padding: 10px 45px 20px 45px;
        }
        .create-form{
          background-color: #0f8279;
        }
      }

      .modal-save-credit-card {
        color: white;
        background-color: #4d69fa !important;
        border-color: #4d69fa !important;
        border-radius: 15px !important;
        width: 110px;
        height: 43px;
        padding: 0px 10px 0px 10px !important;
    
        &:hover {
            background-color: #6880fb !important;
            background-color: #5f78fb !important;
    
        }
    }
    }
  }
  