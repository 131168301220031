* {
  font-size: 12px;
}
.custom-badge {
  border-radius: 40%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #e3ebfa;
  }


}


.custom-table-filter {
  background-image: linear-gradient(#F2F2F2, #ffff);
  border-radius: 8px;
}

// .social-media-tab {

//     li {
//         button {
//             &.active {
//                 color: black !important;
//             }
//         }

//     }

//     .nav-item-all{
//       background-color: #e9e9ea;
//       border-radius: 20px;
//       --bs-btn-focus-shadow-rgb: 203,203,205;
//       padding: 3px;
//     }


//     .nav-link {
//         &.active {
//             border-bottom: 4px solid #46bcaa !important;
//             color: black !important;
//         }
//     }
// }

.social-media-tab {

  .nav-link {
    background-color: white;
    color: black !important;
    border-radius: 8px;
    border: none !important;
    padding: 8px 10px  !important;
    // margin-top: 0.3rem;
    // margin-bottom: 0.3rem;
    margin-right: 8px;
    // width: 140px !important;
    width: 110px !important;
    // margin-top: 10px;
  }

  .nav-link:hover {
    background-color: #414248 !important;
    border-color: #35373e !important;
    color: white !important;
  }

  .nav-link {
    &.active {
      border-bottom: none !important;
      color: white !important;
      background-color: black !important;

      &:hover {
        background-color: #414248 !important;
        border-color: #35373e !important;
      }
    }
  }

  .social-media-filter {
    color: #6c5dd3;
    background-color: #f0effb;
    border-color: #f0effb;
    border-radius: 10px;
    width: calc(21px + 1rem);
    height: calc(21px + 1rem);
    position: relative;
    padding: 0.5rem 0;

    i {
      font-size: medium;
    }

    &:hover {
      color: #fff;
      background-color: #6c5dd3;
      border-color: #6c5dd3;
    }
  }
  

}
.saved-filters-tab-web{
  .saved-filter-x{
  // background-color: #dee2e6;
  border: none;
  font-size: 18px;
  padding: 0;
  // color: #e4e4e4;
  position: absolute;
  z-index: 1;
  right: 6px;
  // margin-left: 155px;
  // margin-top: 12px;
  padding: 2px 3px;
  border-radius: 50%;
  cursor: pointer;
  &:hover{
    background-color: #e8ebed;
    color: #f35321;
  }
}
}
.accordion-permission {
  padding: 15px 0px 15px 0;
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 0.5rem;

  &.active {
    border: 2px solid #007bff
  }

  .accordion-permission-arrow {
    display: flex !important;
    padding: 0px 30px 0px 0px;
    align-items: center;
  }
}

.permissions-card {
  border-bottom: 2px #cacfe5 dotted !important;
}

.date-range {
  input {
    height: 48px !important;
    min-height: 48px !important;
    max-height: 48px !important;
    background-color: #f5f8fa !important;
    border: none;
    border-radius: 8px !important;
    border: 1px solid #f5f8fa;
  }
}

.user-list-card {
  border-radius: 10px;
  border-color: #fff;

  .user-info-btn {
    color: #1f2128;
    background-color: #e9e9ea;
    border-color: #e9e9ea;
    padding: 10px;
    border-radius: 15px;
    transition: .3s;

    &:hover {
      color: #f2f2f2;
      background-color: #1f2128;
      border-color: #1f2128;
    }
  }
}

.table-icon-container {
  display: flex;
}

.user-table-top-icon {
  color: #38637F;
  width: 50px;
  height: 50px;
  background-color: #F7F7F7;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #efeeee;

  }

}

.analytics-table {
  border: none !important;
  border-radius: 20px !important;
}

.analytics-product-badge {
  position: absolute;
  right: 0;
}

.analytic-store-name {
  position: absolute;
  right: 0;
  margin-top: 38px;
  margin-right: 35px;
}

.analytic-filter-container {
  // max-height: 35rem;
  overflow-y: scroll !important;
  overflow-x: clip !important;
  padding-left: 2rem !important;
  max-height: calc(100vh - 250px); // to adjust you can change 180px
  overflow-y: auto;
  .mt-4 {
    margin-top: 10px !important;

  }

  .filter-value-middle {
    display: flex;
    justify-content: start;
  }

  .form-control {
    border-radius: 50px !important;
    background-clip: padding-box !important;
    background-color: #f8f9fa !important;
    border: 1px solid #f8f9fa !important;
    border-radius: 8px !important;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
  }

  .form-control.right {
    border-radius: 8px !important;

  }

  .form__form-group {
    width: 100% !important;
  }
}

.social-media-search {
  border: 0 !important;
  background-color: transparent !important;
}

.product-accordion {
  background-color: #6A40BB !important;
}

.social-accordion {
  background-color: #ffff !important;

}

.nav-social-medias {
  margin-top: 0rem !important;

  .accordion-arrow {

    .social-accordion-title {
      color: #303538 !important;
      font-weight: 600 !important;
      font-size: 26px !important;
      padding-left: 2.5rem !important;

    }

    i {
      color: #35373e;
    }
  }
}


//Notification
.notification {
  border-radius: 10px !important;
  height: 90px !important;
  width: 350px !important;
  border: none !important;
  right: 54px !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.7) !important;
  backdrop-filter: blur(6px) !important;
  color: #DBDCE2 !important;

  .notification-dismiss {
    background-color: black !important;
    color: #DBDCE2 !important;
  }

  .square {
    position: relative;
    height: 3px;
  }

  .left,
  .center,
  .right {
    position: absolute;
    top: 0;
    bottom: 0;
    background: rgb(220, 220, 220);
  }

  .left,
  .right {
    width: 20px
  }

  .left {
    border-radius: 20px 0 0 20px
  }

  .right {
    border-radius: 0 20px 20px 0
  }

  .center {

    left: 20px;
    width: 2.2px;
    transform: scaleX(60);
    transform-origin: left;
  }

  .right {
    transform: translateX(80px);
  }

  .center {
    animation: center-animate 6s ease infinite alternate;
  }

  .right {
    animation: left-animate 6s ease infinite alternate;
  }

  @keyframes left-animate {
    0% {
      transform: translateX(200px)
    }

    100% {
      transform: translateX(20px)
    }
  }

  @keyframes center-animate {
    0% {
      transform: scaleX(121)
    }

    100% {
      transform: scaleX(1)
    }
  }

  .square {
    position: absolute;
    left: calc(50% - 149px);
    top: calc(50% - -30px)
  }
}


.img-hover-middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;

  .img-hover-button {
    background-color: #04AA6D;
    color: white;
    font-size: 16px;
    padding: 16px 32px;
  }
}

// .analytics-avatar {
//   img{
//     border-radius: 20px !important;

//     }
//   .btn {
//     backdrop-filter: blur(6px) !important;
//     background-color: rgba(0, 0, 0, 0.7);
//     //padding: 6px 32.4px;
//     padding-inline: 34px;
//     border: none !important;
//     border-radius: 20px 0px 0px 20px;
//   }
//   .btn-second {
//     border-radius: 0px 20px 20px 0px;

//   }
//   .analytics-image-button{
//     opacity: 0;
//     position: relative;
//     bottom: 49px;
//     width: 340px;
//     padding: 0 !important;
//     margin: 0 !important;
//   }
//   &:hover {
//     .analytics-image-button{
//       opacity: 1;
//     }
//   }
// }
// .analytic-select {
//   .form-control {
//     padding: 13px 35px 13px 13px !important;
//   }

// }

.trending-table.product-shared {
  .MuiTableHead-root {
    tr {
      th {
        background-color: none !important;

      }
    }
  }
}

// .react-select {
//   &:hover {
//     .react-select__menu {
//       opacity: 1;
//     }
//   }


// }

// .react-select__menu {
//   opacity: 0;

//   &:hover {
//     opacity: 1;
//   }
// }

.daterangepicker {
  z-index: 9999 !important;

}

@mixin tooltip($class-name: tooltip) {
  .#{$class-name} {
    display: block;
    position: absolute;
    left: 50%;
    bottom: 2.75em;
    transform: translateX(-50%);
    width: max-content;
    border-radius: 0.25em;
    padding: 0.5em;
    background-color: rgba(0, 0, 0, 0.55);
    backdrop-filter: blur(2px) invert(80%);
    color: #eee;
    font-size: 0.8em;
    opacity: 0;
    scale: 0;
    transition: opacity, scale 0.3s;
    border-radius: 10px;
    transform-origin: left bottom;

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -0.35em;
      transform: translateX(-50%);
      border: 0.35em solid rgba(0, 0, 0, 0);
      border-top-color: #000;
      border-bottom-width: 0;
    }
  }

  &:hover {
    .#{$class-name} {
      opacity: 1;
      scale: 1;
    }
  }
}

.box {
  display: flex;
  justify-content: space-evenly;
  margin: 5em auto;
  width: 100%;
  font-size: 2em;
}

.button {
  position: relative;

  @include tooltip;
}

.link {
  position: relative;

  @include tooltip;
}

.MuiTableSortLabel-icon {
  opacity: 1 !important;
}

.dashboard-section5-content2 {
  width: 1920px;

  @media (max-width: 992px) {
    margin: auto;
  }

  @media (max-width: 600px) {
    width: 100% !important;
  }
}

.form-check-input {
  width: 1rem !important;
  height: 1rem !important;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3) !important;

  &:checked {
    background-color: #6c757d !important;
    border-color: #6c757d  !important;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3) !important;
  }
  &:focus{
  border-color: #6c757d;

  }
}

.loader {
  position: relative;
  width: 2.5em;
  height: 2.5em;
  transform: rotate(165deg);
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0.5em;
  height: 0.5em;
  border-radius: 0.25em;
  transform: translate(-50%, -50%);
}

.loader:before {
  animation: before8 2s infinite;
}

.loader:after {
  animation: after6 2s infinite;
}

@keyframes before8 {
  0% {
    width: 0.5em;
    box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75), -1em 0.5em rgba(111, 202, 220, 0.75);
  }

  35% {
    width: 2.5em;
    box-shadow: 0 -0.5em rgba(225, 20, 98, 0.75), 0 0.5em rgba(111, 202, 220, 0.75);
  }

  70% {
    width: 0.5em;
    box-shadow: -1em -0.5em rgba(225, 20, 98, 0.75), 1em 0.5em rgba(111, 202, 220, 0.75);
  }

  100% {
    box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75), -1em 0.5em rgba(111, 202, 220, 0.75);
  }
}

@keyframes after6 {
  0% {
    height: 0.5em;
    box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75), -0.5em -1em rgba(233, 169, 32, 0.75);
  }

  35% {
    height: 2.5em;
    box-shadow: 0.5em 0 rgba(61, 184, 143, 0.75), -0.5em 0 rgba(233, 169, 32, 0.75);
  }

  70% {
    height: 0.5em;
    box-shadow: 0.5em -1em rgba(61, 184, 143, 0.75), -0.5em 1em rgba(233, 169, 32, 0.75);
  }

  100% {
    box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75), -0.5em -1em rgba(233, 169, 32, 0.75);
  }
}

.loader {
  position: absolute;
  top: calc(50% - 1.25em);
  left: calc(50% - 1.25em);
}

.timezone {
  .react-select__control {
    // background-color: transparent !important;
    // box-shadow: none !important;
    // border: 1px solid #dedede !important;
      background-clip: padding-box !important;
  background-color: #f8f9fa !important;
  border: 1px solid #f8f9fa !important;
  border-radius: 8px !important;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
  padding-left: 10px;
  min-height: 40px;
  }
}

.product-name {
  position: absolute;
  margin-left: 22rem;
  margin-bottom: 4rem;
}

@media only screen and (max-width: 830px) {
  .product-name {
    margin-left: 11rem;
    margin-bottom: -14rem;
  }
}

.product-detail-image-cardbody {
  textarea {
    border: 2px solid #E3E7EB;
    border-radius: 20px !important;
    padding: 18px !important;
    font-size: 14px;
  }

}

.see-more-post {
  background-color: #EAF3FF;
  height: 60px;
  border-radius: 30px;
  color: #007bff;

  &:hover {
    background-color: #007bff;
    color: #fff;
  }
}

@media screen and (min-width: 512px){
  .filter-btn {
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 10px;
    height: 42px;
    padding: 7px 15px 9px 15px;
    // min-width: 90px;
  }
  
}

@media screen and (max-width: 512px){
  .filter-btn{
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 10px;
    /* height: 42px; */
    padding: 2px 6px;
    > span:first-of-type{
      display: none !important;
    }
  }
  
}

.rdrDateRangePickerWrapper {
  height: 380px;
  width: 400px;
  position: absolute;
  inset: 0px 0px auto auto;
  margin: 0px;
  transform: translate(50px, 120px);
  z-index: 9999 !important;
  border-radius: 20px !important;

  .rdrCalendarWrapper {
    width: 500px !important;
    border-radius: 20px !important;
  }

  @media (max-width: 600px) {
    height: 420px;
    width: 350px;
  }

}

.btn-cancel {
  background-color: transparent;
  border: none;
  border-radius: 20px;
  color: #a0a6aa;
  font-size: 14px;
  padding: 7px 25px 7px 25px;

  &:hover {
    background-color: #a0a6aa;
    border-color: #a0a6aa;
  }
}

.btn-delete {
  background-color: #fdeee9;
  border-color: #fdeee9;
  border-radius: 20px;
  color: #e14a56;
  font-size: 14px;
  padding: 7px 25px 7px 25px;

  &:hover {
    background-color: #e14a56;
    border-color: #fdeee9;
  }

}

@media only screen and (max-width: 700px) {
  .user_information {
    display: none;
  }
}
.not-scrollable{
  overflow:hidden;
}
      /* width */
      ::-webkit-scrollbar {
        width: 6px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 20px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 20px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
.facebook_loader{
  .wrapper-cell {
     display: flex;
     margin-bottom: 30px;
  border-radius: 20px;

  }
  
  // Animation
  @keyframes placeHolderShimmer{
      0%{
          background-position: -468px 0
      }
      100%{
          background-position: 468px 0
      }
  }
  
  .animated-background {
      animation-duration: 1.25s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeHolderShimmer;
      animation-timing-function: linear;
      background: #F6F6F6;
      background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
      background-size: 800px 104px;
      height: 96px;
      position: relative;
  }
  
  // Page Elements
  .image {
    height: 190px !important;
    width: 200px !important;
    border-radius: 20px;
    margin-top: .8rem;
    margin-left: .4rem;
    @extend .animated-background;
  }
  
  .text {
    margin-left: 20px;
    margin-top: 1rem;

  }
  
  .text-line {
    height: 20px;
    width: 100%;
    //  background: #F6F6F6;
    margin: 4px 0;
    border-radius: 8px;
    @extend .animated-background;
  }
  .badge-line {
    height: 30px;
    max-width: 700px;
    //  background: #F6F6F6;
    margin: 5px 0;
    border-radius: 8px;
    @extend .animated-background;
  }
}
.page-name{
  position: absolute;
  top: 30px;
  z-index: 1000;
  left: 280px;

}
.btn-light-info {
  background-color: #17191e;
  border: 1px solid #17191e;
  border-radius: 15px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  &:hover {
      background-color: #323643;
      border: 1px solid #323643;
      border-radius: 15px;
      color: #fff;
  }
}
.btn-light-success{
  background-color: #edf8f7;
  border: 1px solid #edf8f7;
  border-radius: 8px;
  color: #46bcaa;
  font-size: 12px;
  font-weight: 600;

  &:hover {
      background-color: #46bcaa;
      border: 1px solid #46bcaa;
      border-radius: 15px;
      color: #fff;
  }
}
.details-icon{
  color:#4d69f8;
  font-size: 16px;
  padding-inline: 10px;
}
.reminder_past_due{
    // color: #f35321;
    background-color: #f35321;
    padding: 4px 5px;
    font-size: 13px;
    cursor: pointer;
    color:#f2f2f2;
    border-radius: 50%;
}
.reminder_not_yet{
  // color: #d3d4d5;
  // font-size: 17px;
  cursor: pointer;
  background-color: #d3d4d5;
  padding: 4px 5px;
  font-size: 13px;
  cursor: pointer;
  color:#f2f2f2;
  border-radius: 50%;
}
.reminder-already{
  // color: #44bca9;
  // font-size: 17px;
  cursor: pointer;
  background-color: #44bca9;
  padding: 4px 5px;
  font-size: 13px;
  cursor: pointer;
  color:#f2f2f2;
  border-radius: 50%;
}
.filter-count{
  position: absolute;
  // top: 2px;
  // right: 2.5px;
  background-color: $orange;
  width: 18px;
  height: 18px;
  line-height: 14px;
  font-size: 11px !important;
  font-weight: 500 !important;
  color: $white !important;
  text-align: center;
  padding-top: 2px;
  margin-top: -12px;
  border-radius: 20px 20px 20px 20px;
  z-index: 1;
}

.red-badge{
  color: #f35421;
  background-color: #feeee9!important;
}
.yellow-badge{
  color: #ffcf52;
  background-color: #fff3da;
  border-color: rgba(255,207,82,.1);
}
.green-badge{
  color: #46bcaa;
  // background-color: rgba(70,188,170,.3);
  background-color: #edf8f7!important;
  // border-color: rgba(70,188,170,.1);
}
.navbar-page-name{
  span{
    font-size: 16px !important;

  }
}
@media screen and (max-width: 480px) {
  .navbar-page-name{
    display: none;
  }
  
}
// @media screen and (max-width: 483px) {
//   .saved-filters-tab-web{
//     display: none;
//   }
// }
.btn-green{
  background-color: #edf8f7;
  border: 1px solid #edf8f7;
  border-radius: 8px;
  color: #48bdaa;
  font-size: 12px;
  font-weight: 500;
  &:hover {
    background-color: #48bdaa;
    border: 1px solid #48bdaa;
    // border-radius: 8px;
    color: #fff;
}
}
.btn-blue{
  background-color: #edf0ff;
  border: 1px solid #edf0ff;
  border-radius: 8px;
  color: #4d69fa;
  font-size: 12px;
  font-weight: 500;
  &:hover {
    background-color: #4d69fa;
    border: 1px solid #4d69fa;
    // border-radius: 8px;
    color: #fff;
  }
}
.btn-pink{
  background-color: #fff6f9;
  border: 1px solid #fff6f9;
  border-radius: 8px;
  color: #ffa2bf;
  font-size: 12px;
  font-weight: 500;
  span{
    color: #ffa2bf;
  }
  &:hover {
    background-color: #ffa2bf;
    border: 1px solid #ffa2bf;
    // border-radius: 8px;
    color: #fff;
    span{
      color: #fff;
    }
  }
}
.btn-pink-text-white{
  background-color: #fff6f9;
  border: 1px solid #ffa2bf;
  border-radius: 8px;
  color: #ffa2bf;
  font-size: 12px;
  font-weight: 500;
  span{
    color: #ffa2bf;
  }
  &:hover {
    background-color: #ffa2bf;
    border: 1px solid #ffa2bf;
    // border-radius: 8px;
    color: #fff;
    span{
      color: #fff;
    }
  }
}
.btn-purple{
  background-color: #f0effb !important;
  border: 1px solid #f0effb !important;
  border-radius: 8px;
  color: #6c5dd3;
  font-size: 12px;
  &:hover {
    background-color: #6c5dd3 !important;
  border: 1px solid #6c5dd3 !important;
    // border-radius: 8px;
    color: #fff;
  }
}
.btn-purple.disabled{
  background-color: #6c5dd3 !important;
  color: #a099cf !important;
}
.copy-icon{
  color:#42aa9a;
  font-size: 16px;
}
.active-green-badge{
  color: #46bcaa;
  background-color: rgba(70,188,170,.3);
  border-color: rgba(70,188,170,.1);
}
.deactive-badge{
  color: #f35421;
  background-color: rgba(243,84,33,.3);
  border-color: rgba(243,84,33,.1);
}

.clickable{
  &:hover{
    color: #4d69fa !important;
  }
}
.icon-btn{
  background-color: transparent !important;
  border: none !important;
  padding: 0;
  color: #6c757d !important;
}
.text-green{
  color:#44bca9 !important;
}

.text-red{
  color: #f35321 !important;
}

.search-loader {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: 
    radial-gradient(farthest-side,#000 94%,#0000) top/4px 4px no-repeat,
    conic-gradient(#f9f9f9 30%,#000);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 2px),#f9f9f9 0);
  animation:s3 1s infinite linear;
}

@keyframes s3{ 
  100%{transform: rotate(1turn)}
}

.search-area:focus-within {
  padding-left: 1rem;
  border-radius: 10px;
  background-color: #e4e4e4 !important;
  // box-shadow:5px 10px #000;
  // transition: 0.5s ease;
  transition: all 1s;
}
// .material-table-toolbar-search:focus-within{
//   border-radius: 8px;
//   box-shadow: .8em .8em  rgba(0, 0, 0, 0.5);
//   transition: 0.5s ease;
// }
.focused-search{
  border-radius: 8px;
  // box-shadow: .6em .8em  rgba(228, 228, 228, 0.8);
  box-shadow: 0 0 5px 5px  rgba(228, 228, 228, 0.9);
  transition: 0.5s ease;
}
.select-close-icon{
  &:hover{
    color: #c30010;
}
}
.notification-error-icon{
  font-size: 35px;
  color: #c30010;
}
.notification-success-icon{
  font-size: 35px;
  color: #49a255;
}
.err-image-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.err-image-container img {
  // width: 100%;
  max-width: 600px;
  height: auto;
}

.centered-text {
  position: absolute;
  top: 50%;
  // transform: translate(-50%, -50%);
  max-width: 480px;
  height: auto;
  margin-right: 5rem;
  p{
    color: #537fc8;
  }
}
.emoji-input-container{
  position: relative;
  img{
    position: absolute;
    right: 8px;
    top: 10px;
    filter: invert(56%) sepia(28%) saturate(10%) hue-rotate(46deg) brightness(89%) contrast(86%);
  }
  em-emoji-picker{
    position: absolute;
    z-index: 1000;
    height: 380px;
    box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 20px 0px;
  }
}
.createble-select{
  // background-color: #f2f2f2;
  background-color: #dddddd;
  padding: 3px 6px;
  border-radius: 8px;
  font-size: 10px;
  font-weight: 500;
  i{
    padding: 3px;
    &:hover{
      background-color: #efefef !important;
      color: red;
    }
  }
}
.blue-text{
  color: #536efa;
}
.not-found-404{
  color: #8e8e8e;
  text-shadow: 3px 3px 4px rgba(142,142,142, .3);
}
.bordered-bottom{
  border-bottom: 1px solid #f1f1f1;
}
.card-tabs {
  .nav-link {
      color: #4d69fa !important;

  }

  .nav-link:hover {
      background-color: #edf0ff !important;
      color: #4d69fa !important;
  }

  .nav-link {
      &.active {
        background-color: #4d69fa !important;
        color: #fff !important;
      }
  }
}
.dark-red-btn{
  background-color: #e23b05;
  border: 1px solid #e23b05;
  font-size: 12px;
  font-weight: 500;

  &:hover {
    background-color: #fa6332;
    border: 1px solid #fa6332;
  }
}
.dark-green-btn{
  background-color: #48bdaa;
  border: 1px solid #48bdaa;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  &:hover {
    background-color: #76cdbf;
    border: 1px solid #76cdbf;
}
}
.dark-gray-badge{
  background-color: #ebeaea;

}
.bg-top-img{
  background-color: #e9e9e9 !important;
  color: #6e777f !important;
}
.clickable-card{
  // box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  cursor: pointer;
  &:hover {
    box-shadow: 1px 8px 20px grey !important;
    -webkit-transition: box-shadow .6s ease-in !important;
}
}
.to-do-list-badge{
  color: #e6e4e4;
  background-color: #25282f;
  border: 1px solid #25282f;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px;
}
.training-list-card{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 20px 0px;
  cursor: pointer;
  border: 0;
}
.badge-blue {
  background-color: #edf0ff!important;
    color: #4d69fa !important;

}
.bg-light-purple{
  background-color: #e1deff;
}
.training-tips{
  .react-select__control{
    background-color: #fff !important;
    border: 1px solid #fff !important;
    height: 40px !important;
    min-height: 40px !important;
  }
  .modal-search-input{
    background-color: #fff !important;
  }
}
.training-tips-search-input{
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid #d1d1d1;
  transition: border 0.3s ease-in-out;

}
.training-tips-search-input:focus-within {
  border: 1px solid rgb(36, 36, 36);
}
.helpers-card{
  .card-footer{
    background: transparent;
    border-color: #eaeaea;
  }

}
.user-img{
  border: 2px solid #303538;
  background: transparent !important;
  color: #303538;
}

.badge-gray {
  background-color: #e6e6e6 !important;
  color: #494949 !important;

}
.badge-pink {
  background-color: #ffe8ef!important;
  color: #ffa6c2 !important;
}
.badge-purple {
  background-color: #d9d7f4!important;
  color: #6c5dd3 !important;
}
.badge-dark{
  color: #2e3441;
  background-color: #121419;
  border-color: #121419;
}