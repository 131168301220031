.dashboard {
  .dark-red-text {
    color: #f35321;
  }
  .dark-green-text {
    color: #44bca9;
  }

  .darshboard-line {
    border-right: 1px solid black;
    height: 30px;
  }

  .total-sales-card {
    background-color: #FFF3D4;
    border-radius: 20px !important;
    border: none !important;
    min-height: 14rem !important;
    max-height: 14rem !important;

    .dolar-icon {
      color: #FFCF53;
    }

  }
  .net-revenue-card {
    color: #000 !important;

    .card-header {
      border-bottom: 0 !important;
    }

    background-color: #edf8f7;
    border-radius: 20px !important;
    border: none !important;
    height: 14rem !important;

    i {
      color:#46bdaa;
    }
  }

  .compaigns-card {
    background-color: #FFE8EF;
    border-radius: 20px !important;
    border: none !important;
    min-height: 14rem !important;

    .rocket-icon {
      color: #FFA2C0;
    }
  }

  .total-order-card {
    background-color: #DAD7F4;
    border-radius: 20px !important;
    border: none !important;
    height: 14rem !important;

    .order-icon {
      color: #6C5DD3;
    }
  }

  .top-product {
    .MuiTableHead-root {

      tr {
        padding-bottom: 5rem !important;
        border: none !important;

        th:first-child {
          border-radius: 20px 0 0 20px;
        }

        th:last-child {
          border-radius: 0 20px 20px 0;
        }
      }
    }
    .MuiTableBody-root{
      tr{
        &:hover{
          background-color: #e8edf4 !important;
        } 
      }
      .MuiTableRow-root {
        //background-color: "#f5f5f5";
        
        td:first-child{
          border-radius:20px 0 0 20px;
        }
        
      td:last-child{
          border-radius:0 20px 20px 0;

        }
    
    
        &:nth-child(even) {
          background-color: #f2f5f9;
        }
      }

 }

    .MuiTableRow-root {
      td:first-child {
        border-radius: 20px 0 0 20px;
      }

      td:last-child {
        border-radius: 0 20px 20px 0;

      }

      &:nth-child(even) {
        background-color: #f2f5f9;
      }
    }
    .MuiTableRow-footer{
      padding-top: 1rem !important;
    }
  }

  .leaderboard {
    .id-badge {
      background-color: #edecf7;
      border-radius: 30px;
      padding-top: 8px;
      padding-bottom: 8px;
      color: #000;
    }

    .leaderboard-card {
      height: 35.2rem;

      // .card-body {
      //   max-height: 30rem;
      //   overflow-y: scroll;
      // }

      .MuiTableSortLabel-root {
        font-size: 14px !important;
      }
    }
    .material-table__wrap {
      padding: 0 !important;

      .MuiTableHead-root {
        tr {
          padding-bottom: 5rem !important;

          th {
            padding-left: 10px;
            padding-right: 10px;
            border-bottom: 0 !important;
            background-color: #f2f5f9 !important;
          }

          th:first-child {
            border-radius: 20px 0 0 20px !important;
            padding: 0 0 0 5px !important;
          }

          th:last-child {
            border-radius: 0 20px 20px 0;
          }
        }
      }

      .MuiTableBody-root {
        font-weight: 500;
        font-size: 16px;

        .MuiTableRow-root {
          &:hover{
            background-color: #e8edf4 !important;
          } 

          td:first-child {
            border-radius: 20px 0 0 20px !important;
          }

          td:last-child {
            border-radius: 0 20px 20px 0 !important;

          }
          &:nth-child(even) {
            background-color: #f2f5f9;
          }
        }

        tr {
          // border: 1px solid gray !important;
          border-radius: 20px !important;

          td {
            // border: 2px solid gray !important;
            text-align: start !important;
          }

          td:first-child {
            border-radius: 20px 0 0 20px !important;
            margin-right: 0px !important;
            padding-right: 0px !important;
          }

          td:last-child {
            border-radius: 0 20px 20px 0;
            // border: 2px solid gray !important;
          }
        }

      }
      .MuiTableRow-footer {
        padding-top: 1rem !important;
      }

    }
    // .material-table__wrap {
    //   padding: 0 !important;

    //   .MuiTableHead-root {
    //     tr {
    //       padding-bottom: 5rem !important;

    //       th {
    //         padding-left: 10px;
    //         padding-right: 10px;
    //       }

    //       th:first-child {
    //         padding: 0 0 0 5px !important;
    //       }
    //     }
    //   }

    //   .MuiTableBody-root {
    //     font-weight: 500;
    //     font-size: 16px;

    //     .MuiTableRow-root {
    //       border-radius: 20px !important;
    //     }

    //     tr {
    //       // border: 1px solid gray !important;
    //       border-radius: 20px !important;

    //       td {
    //         // border: 2px solid gray !important;
    //         text-align: start !important;
    //       }

    //       td:first-child {
    //         border-radius: 20px 0 0 20px !important;
    //         margin-right: 0px !important;
    //         padding-right: 0px !important;
    //       }

    //       td:last-child {
    //         border-radius: 0 20px 20px 0;
    //         // border: 2px solid gray !important;
    //       }
    //     }

    //   }

    //   .MuiTableHead-root {
    //     .MuiTableCell-head {
    //       font-size: 15px !important;
    //     }

    //     tr {
    //       th {
    //         background-color: transparent !important;
    //         border-bottom: 2px solid #daddde !important;
    //       }

    //       th:first-child {
    //         border-radius: 20px 0 0 20px;
    //       }

    //       th:last-child {
    //         border-radius: 0 20px 20px 0;
    //         padding-right: 0px !important;
    //         margin-right: 0px !important;
    //       }
    //     }

    //   }

    //   .MuiTableRow-footer {
    //     padding-top: 1rem !important;
    //   }

    // }
  }

  

}

@media screen and (min-width: 900px) {
  .dashboard-tab {
    .nav-link {
      background-color: white;
      color: black !important;
      border-radius: 8px;
      border: none !important;
      padding: 8px 10px !important;
      margin-left: 2px;
      margin-right: 8px;
      width: 110px !important;
    }
  
    .nav-link:hover {
      background-color: #414248 !important;
      border-color: #35373e !important;
      color: white !important;
    }
  
    .nav-link {
      &.active {
        border-bottom: none !important;
        color: white !important;
        background-color: black !important;
  
        &:hover {
          background-color: #414248 !important;
          border-color: #35373e !important;
        }
      }
    }
  }
  
}

@media screen and (min-width: 400px) {
  .date-div{
    margin-right: .5rem;
  }
}
@media screen and (max-width: 383px) {
  .date-div{
    // width: 100%;
    justify-content: end;
    margin-left: 1rem;
    // padding-right: 2rem;
  }
  
}
@media screen and (max-width: 900px) {
  .dashboard-tab {
    .nav-link {
      background-color: white;
      color: black !important;
      border-radius: 8px;
      border: none !important;
      padding: 10px 6px !important;
      margin-left: 2px;
      margin-right: 5px;
      width: 95px !important;
    }
  
    .nav-link:hover {
      background-color: #414248 !important;
      border-color: #35373e !important;
      color: white !important;
    }
  
    .nav-link {
      &.active {
        border-bottom: none !important;
        color: white !important;
        background-color: black !important;
  
        &:hover {
          background-color: #414248 !important;
          border-color: #35373e !important;
        }
      }
    }
  }
  
}
.fix-height {
  min-height: 30rem;
}

.top-customers {


  .top-customers-card {
    height: 24.3rem;

    .card-body {
      max-height: 30rem;
      overflow-y: hidden;
      padding-bottom: 0;
    }
    .card-body:hover{
      overflow-y: scroll;
    }

    .MuiTableSortLabel-root {
      font-size: 14px !important;
    }
    .down_icon{
      // opacity: 0;
      position: relative;
      bottom: 10;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: #E1E9F350;

      &:hover{
        background-color: #dee2e6;
      }
    }
    &:hover{
      // .down_icon{
      //   opacity: 1;
      // }
    }

    &:hover > ::-webkit-scrollbar-thumb   {
      opacity: 1;
      color: #000;
    }

    .card-footer{
      background-color: transparent;
      border-top: 0;
      padding-bottom: 0.8rem;
      padding-top: 5px;
    }
  }

  .material-table__wrap {
    padding: 0 !important;

    .MuiTableHead-root {
      tr {
        padding-bottom: 5rem !important;
        th{
          border: none !important;
        }

        th:first-child {
          border-radius: 20px 0 0 20px;
        }

        th:last-child {
          border-radius: 0 20px 20px 0;
        }
      }
    }

    .MuiTableRow-root {
      tr {
        border-radius: 20px !important;
      }

      td:first-child {
        border-radius: 20px 0 0 20px;
      }

      td:last-child {
        border-radius: 0 20px 20px 0 !important;

      }

      &:nth-child(even) {
        background-color: #f2f5f9;
      }
    }

  }
}

.line-chart {
  background-color: #d1eeea;
  border-radius: 20px;
  margin-top: 1.5rem;
}

.tab-all-small{
  .nav-link {
    background-color: white;
    color: black !important;
    border-radius: 10px;
    border: none !important;
    padding: 10px !important;
    // margin-left: 2px;
    margin-right: 4px;
    width: 110px !important;
    margin-bottom: 5px;
  }

  .nav-link:hover {
    background-color: #414248 !important;
    border-color: #35373e !important;
    color: white !important;
  }

  .nav-link {
    &.active {
      border-bottom: none !important;
      color: white !important;
      background-color: black !important;

      &:hover {
        background-color: #414248 !important;
        border-color: #35373e !important;
      }
    }
  }
}
.overview-scrollable-table{
  .card-body{
    max-height: 470px;
    overflow-y: scroll;
  }
}
.selected-option{
  background-color: #d1cfec !important;
      color: $white !important;
      border-radius: 10px;
}
.dashboard-dropdowns{
  color: rgba(33, 37, 41, 0.5) !important;
}
.bar-chart-loader{
  span{
    display: flex;
    justify-content:end !important;
    width: 100%;
    span{
      min-width: 15px;
      // max-width: 150px;
    }
  }
}
@media screen and (min-width: 1300px) {
  .bar-chart-loader{
    span{
      span{
        margin-inline: 2rem;
      }
    }
  }
  
}
@media screen and (max-width: 1300px) {
  .bar-chart-loader{
    span{
      span{
        margin-inline: 0.8rem;
      }
    }
  }
  
}
.table-body-scroll{
  .MuiPaper-root > div{
    max-height: 23rem !important;
    overflow-y: scroll !important;
  }
}
.top-product-table{
  .MuiTablePagination-selectRoot{
    display: none !important;
  }
}
.MuiTableRow-head{
  border: none !important;
}

.dashboard-dropdown-menu {
  background-color: #fff !important;
  border:  1px solid rgba(0,0,0,.175) !important;
  padding: .75rem;
  font-weight: 500;
  .dropdown-item{
    color: #323232 !important;
    border-radius: 8px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    &:hover{
      background-color: #e5ebf3 !important;
      color: #000 !important;

    }
  }

  .selected-option {
    background-color: #d4deeb !important;
    color: #4e74a8 !important;
    border-radius: 8px;
  }
}
.store-total-sales-card {
  color: #000 !important;

  .card-header {
    border-bottom: 0 !important;
  }

  background-color: #fff2d4;
  border-radius: 20px !important;
  border: none !important;

  .dolar-icon {
    color: #fecf53;
  }

}