.react-select {
    width: 100%;
    height: 55px;
    max-height: 55px;

    font-size: 16px;
  }
  
  .react-select__control {
    // height: 50px !important;
    min-height: 50px !important;
    max-height: 50px !important;
    background-clip: padding-box !important;
    background-color: #f8f9fa !important;
    border: 1px solid #f8f9fa !important;
    border-radius: 8px !important;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
  
  &.react-select__control--is-focused,
  &:hover {
    border: 1px solid $primary !important;
    box-shadow: none;
  }
  }
  
  // .react-select__value-container {
  //   padding: 10px 24px !important;
  // }
  
  .react-select__input {
    color: $content !important;
  }
  
  .react-select__placeholder {
    font-size: 12px;
    font-weight: 500;
    color: #c7c8cb !important;
  }
  .react-select__single-value {
    color: $content !important;
  }
  
  .react-select__option {
    border-radius: 8px;
    padding-left: .6rem !important;
    &.react-select__option--is-focused {
      background: rgba(47, 85, 212, 0.1) !important;
      color: $content !important;

    }
  
    &.react-select__option--is-selected {
      background: rgba(47, 85, 212, 0.1) !important;
    color: $content !important;

    }
  }
  
  .react-select-skill-interest {
    width: 100%;
    height: 40px;
    max-height: 40px;
    font-size: 14px;
  
    .react-select__control {
      &.react-select__control--is-focused {
        .react-select__placeholder {
          display: none;
        }
      }
    }
  
    .react-select__indicator-separator {
      display: none;
    }
  
    .react-select__menu-list {
      max-height: 170px !important;
    }
  }
  .react-select__menu{
    border-radius: 8px !important;
    z-index: 9999 !important;
  }
  .react-select__menu-list {
    background-color: #fff !important;
    border-radius: 8px !important;
    max-height: 170px !important;

  }
  
  .dateselect {
    .react-select__control {

    border: none !important;
    background-color: #000000!important;
    border-radius: 20px !important;
    box-shadow: none !important;
    height: 30px !important;
    min-height: 30px !important;
    bottom: -1px !important;
    cursor: pointer;
    right: 41px !important;
    &:hover {
      background-color: transparent!important;
      border: 1px solid #000000 !important;
      border-radius: 20px !important;

      i{
        color: #000000 !important;
        font-size: 14px !important;
      }

    }
    }
    &:hover,&::after, &::before, &:active {
      background-color: transparent !important;
      border-radius: 0px 20px 20px 0px !important;

    }
  }
  .react-select__multi-value{
    background-color:#dddddd  !important;
    padding: 3px 5px !important;
    border-radius: 8px;
    color: #08070f !important;
  
    .react-select__multi-value__label{
      font-size: 12px;
      color: #08070f !important;
    }
    .react-select__multi-value__remove{
      padding-left: 2px;
      padding-right: 2px;
      &:hover{
        background-color: #efefef !important;
      }
    }
  }