.modal {
    .modal-content {
      border-radius: 20px;
      border: none;
  
      .modal-header {
        border-bottom: 2px solid #f3f3f3;
        padding: 20px;
  
        @media (max-width: 768px) {
          padding: 50px 45px 15px 45px;
        }
  
        .modal-title {
          font-weight: 400 !important;
          font-size: 18px !important;
  
          // @media (max-width: 768px) {
          //   font-size: 24px !important;
          // }
        }
  
        .close {
          width: 14px;
          height: 14px;
          position: absolute;
          top: 27px;
          right: 36px;
          padding: 0;
          background: transparent escape-svg(url("../images/icon/modal_close.svg")) center / 1em auto no-repeat;
          background-size: 24px 24px;
          border: 0;
          z-index: 2;
          span {
            display: none;
          }
        }
      }
  
      .modal-body {
        overflow: visible;
        padding: 20px 20px;
        // max-height: calc(100vh - 280px);
        // overflow-y: auto;
  
        @media (max-width: 768px) {
          padding: 20px 45px 10px 45px;
        }
  
        .title {
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 22px;
        }
  
        .description {
          font-size: 14px;
          font-weight: 400;
          display: block;
          margin-bottom: 12px;
          color: $muted;
        }
      }
  
      .modal-footer {
        padding: 10px ;
        border: none;
  
        @media (max-width: 768px) {
          padding: 10px 45px 20px 45px;
        }
      }
    }
  }
  