* {
  font-size: 12px;
}

.custom-badge {
  border-radius: 40%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #e3ebfa;
  }


}


.custom-table-filter {
  //background-color: rgba(0, 0, 0, 1) !important;
  background-image: linear-gradient(#1f2127, #17191e);
  border-radius: 8px;

}

// .social-media-tab {
//   li {
//       button {
//           &.active {
//               color: black !important; 
//           }
//       }

//   }

//   .nav-item-all{
//     position: absolute;
//     border-radius: 20px;
//     --bs-btn-focus-shadow-rgb: 203,203,205;
//     padding: 3px;
//   }
//   .nav-link-1 {
//     background-color: #c4cad3;
//       color: black !important;
//       border-radius: 20px 0px 0px 20px;
//       border: none !important;
//    padding: 12px 30px 12px 30px;
//   }
//   .nav-link-2{
//     background-color: #c4cad3;
//     color: black !important;
//     border-radius: 0px 20px 20px 0px;
//     border: none !important;  
//     padding: 12px 30px 12px 30px;

//   }
//   .nav-link-1:hover{
//     background-color: #b9bec6;
//   }
//   .nav-link-2:hover{
//     background-color: #b9bec6;
//   }

//   .nav-link-1 {
//       &.active {
//         border-bottom: none !important;
//         color: white !important;
//         background-color: #46bcaa !important;
//         border-radius: 20px 0px 0px 20px;
//       } 
//   }
//   .nav-link-2 {
//     &.active {
//         border-bottom: none !important;
//         color: white !important;
//         background-color: #46bcaa !important;
//         border-radius: 0px 20px 20px 0px;
//     }
// }
// }

.accordion-permission {
  padding: 15px 0px 15px 0;
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 0.5rem;

  &.active {
    border: 2px solid #007bff
  }

  .accordion-permission-arrow {
    display: flex !important;
    padding: 0px 30px 0px 0px;
    align-items: center;
  }
}

.permissions-card {
  border-bottom: 2px #cacfe5 dotted !important;
}

.date-range {
  input {
    height: 48px !important;
    min-height: 48px !important;
    max-height: 48px !important;
    background-color: #f5f8fa !important;
    border: none;
    border-radius: 8px !important;
    border: 1px solid #f5f8fa;
  }
}

.user-list-card {
  background-color: #242731;
  border-radius: 10px;

  .user-name {
    color: #ffff !important;
  }

  .user-info-btn {
    color: #fff;
    background-color: rgba(0, 0, 0, .3);
    border-color: rgba(31, 33, 40, .1);
    padding: 10px;
    border-radius: 15px;
    transition: .3s;

    &:hover {
      color: #fff;
      background-color: rgba(31, 33, 40, .8);
      border-color: rgba(31, 33, 40, .1);
    }
  }
}

.avatar-square {
  background-color: #4c4b50 !important;
}

.user-table-top-icon {
  color: #4D69FA;
  width: 50px;
  height: 50px;
  background-color: #313A6E;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #4D69FA;
    color: white;

  }

}

.social-media-table-title {
  color: #ffff !important;
}

.social-media-name {
  color: #ffff !important;

}

.social-media-followers {
  color: #4358C7 !important;
}

.social-media-followed {
  color: #CA3442 !important;
}

.social-media-post {
  color: #44BCA9 !important;
}

.social-media-info-icon {
  color: #ffff;
}

.empty-state-bg {
  background-color: #242731 !important;


  .dark-theme-title {
    color: #fff !important;
  }

  .dark-theme-message {
    color: #ffff;
  }
}

.social-followed-badge {
  background-color: #33407C !important;
  color: #fff !important;
  width: 80px;

  i {
    color: #1966E7 !important;
  }
}

.social-follower-badge {
  background-color: #893E2C !important;
  border: 1px solid #893E2C !important;
  width: 80px;

  span {
    color: #fff !important;
  }

  i {
    color: #DC3445 !important;

  }
}

.social-shared-badge {
  background-color: #294849 !important;
  border: 1px solid #294849 !important;
  color: #fff !important;
  width: 80px;


}

.social-media-tab {

  .nav-link {
    color: white !important;
    background-color: #323643 !important;
    border-radius: 8px;
    border: none !important;
    padding: 8px 10px !important;
    margin-right: 8px;
    // margin-top: 0.3rem;
    // margin-bottom: 0.3rem;
    // width: 140px !important;
    width: 110px !important;
    // margin-top: 10px;
  }

  .nav-link:hover {
    background-color: #464850 !important;
    border-color: #35373e !important;
    color: white !important;
  }

  .nav-link {
    &.active {
      border-bottom: none !important;
      background-color: #e9ecef !important;
      color: black !important;

      &:hover {
        background-color: #414248 !important;
        border-color: #35373e !important;
        color: #e9ecef !important;
      }
    }
  }

  .social-media-filter {
    color: #6c5dd3;
    background-color: rgba(108, 93, 211, .3) !important;
    border-color: rgba(108, 93, 211, .1) !important;
    border-radius: 10px;
    width: calc(21px + 1rem);
    height: calc(21px + 1rem);
    position: relative;
    padding: 0.5rem 0;

    i {
      font-size: medium;
    }

    &:hover {
      color: #fff;
      background-color: rgba(108, 93, 211, .8) !important;
      border-color: rgba(108, 93, 211, .1) !important;
    }
  }


}

.saved-filters-tab-web {
  .saved-filter-x {
    // background-color: #e9ecef;
    border: none;
    font-size: 18px;
    padding: 0;
    color: #e9ecef;
    position: absolute;
    z-index: 1;
    right: 6px;
    // top: 73px;

    // margin-left: 155px;
    // margin-top: 12px;
    padding: 2px 3px;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: #000;
      color: #f35321;
    }

  }
}

// @media screen and (max-width: 483px) {
//   .saved-filters-tab-web{
//     display: none;
//   }
// }
// @media screen and (min-width: 484px) {
//   .show-more-btn{
//     display: none;
//   }
// }


.social-media-search {
  border: 0 !important;
  background-color: transparent !important;
  color: white !important;
}

//date picker
// .filter-date-picker {
//   display: flex;
//   align-items: center;

//   ::placeholder {
//     color: #45BBA8;
//     opacity: 1; 
//   }

//   :-ms-input-placeholder { 
//    color: #45BBA8;
//   }

//   ::-ms-input-placeholder { 
//    color: #45BBA8;
//   }
//   .form-control {
//     padding: 13px 13px !important;
//     border: 1px solid #41A597 !important;
//     background-color: #41A597 !important;
//     color: #ECF7F6 !important;
//     border-radius: 20px !important;
//     font-size: 14px;
//     width: fit-content;

//   }
//   .react-select__menu.css-1nmdiq5-menu{
//     width: 200px !important;
//     border-radius: 20px !important;
//     z-index: 9999 !important;
//     position: absolute;
//     left: -147px;
//     .react-select__option {
//       border-radius: 20px !important;
//       &:focus,&:hover,&:active,&::before,&::after {
//         background-color: #ECF7F6 !important;

//       }
//     }
//     .react-select__option--is-selected{
//       background-color: #ECF7F6 !important;
//       color: rgba(0, 0, 0, 1) !important;
//       font-weight: 500;
//       font-size: 16px;
//     }
//   }
//   .react-select__control {
//     border: 1px solid transparent !important;
//     background-color: transparent !important;
//     border-radius: 20px !important;
//     position: relative;
//     bottom: 10px;
//     right: 36px;
//     .react-select__indicator-separator{
//       display: none !important;
//     }
//     &:hover{
//       background-color: #45BBA8 !important;
//       border-radius: 0px 10px 10px 0px !important;

//     }
//     .react-select__value-container {
//       border: none !important;
//       display: none !important;


//     }
//     .react-select__value-container--has-value{
//       .react-select__indicators.css-1hb7zxy-IndicatorsContainer{
//         position: relative !important;
//         left: 50px !important;
//       }
//     }

//     &:focus,&:hover,&:active,&::before,&::after {
//       border: none !important;
//     }
//   }
//   .react-select__control--is-focused {
//     border: none !important;
//   }
// }
// .react-select {
//   &:hover {
//     .react-select__menu {
//       opacity: 1;
//     }
//   }


// }

// .react-select__menu {
//   opacity: 0;

//   &:hover {
//     opacity: 1;
//   }
// }

.daterangepicker {
  z-index: 9999 !important;

}

.MuiTableSortLabel-icon {
  opacity: 1 !important;
}


@media screen and (min-width: 512px) {
  .filter-btn {
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 10px;
    height: 42px;
    // padding: 7px 20px 9px 20px;
    padding: 7px 15px 9px 15px;
    // min-width: 90px;
  }

}

@media screen and (max-width: 512px) {
  .filter-btn {
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 10px;
    /* height: 42px; */
    padding: 2px 6px;

    >span:first-of-type {
      display: none !important;
    }
  }

}

.dark-card-shadow {
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .5);
  ;
}

.loader {
  position: relative;
  width: 2.5em;
  height: 2.5em;
  transform: rotate(165deg);
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0.5em;
  height: 0.5em;
  border-radius: 0.25em;
  transform: translate(-50%, -50%);
}

.loader:before {
  animation: before8 2s infinite;
}

.loader:after {
  animation: after6 2s infinite;
}

@keyframes before8 {
  0% {
    width: 0.5em;
    box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75), -1em 0.5em rgba(111, 202, 220, 0.75);
  }

  35% {
    width: 2.5em;
    box-shadow: 0 -0.5em rgba(225, 20, 98, 0.75), 0 0.5em rgba(111, 202, 220, 0.75);
  }

  70% {
    width: 0.5em;
    box-shadow: -1em -0.5em rgba(225, 20, 98, 0.75), 1em 0.5em rgba(111, 202, 220, 0.75);
  }

  100% {
    box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75), -1em 0.5em rgba(111, 202, 220, 0.75);
  }
}

@keyframes after6 {
  0% {
    height: 0.5em;
    box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75), -0.5em -1em rgba(233, 169, 32, 0.75);
  }

  35% {
    height: 2.5em;
    box-shadow: 0.5em 0 rgba(61, 184, 143, 0.75), -0.5em 0 rgba(233, 169, 32, 0.75);
  }

  70% {
    height: 0.5em;
    box-shadow: 0.5em -1em rgba(61, 184, 143, 0.75), -0.5em 1em rgba(233, 169, 32, 0.75);
  }

  100% {
    box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75), -0.5em -1em rgba(233, 169, 32, 0.75);
  }
}

.loader {
  position: absolute;
  top: calc(50% - 1.25em);
  left: calc(50% - 1.25em);
}

.timezone {
  .react-select__control {
    // background-color: transparent !important;
    // box-shadow: none !important;
    // border: 1px solid #dedede !important;
    background-clip: padding-box !important;
    background-color: #2b2f38 !important;
    border: 1px solid #2b2f38 !important;
    border-radius: 8px !important;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
    color: #fff;
    padding-left: 10px;
    min-height: 40px;
  }
}

.product-name {
  position: absolute;
  margin-left: 22rem;
  margin-bottom: 4rem;
}

@media only screen and (max-width: 830px) {
  .product-name {
    margin-left: 11rem;
    margin-bottom: -14rem;
  }
}

.product-detail-image-cardbody {
  textarea {
    border-radius: 20px !important;
    padding: 18px !important;
    font-size: 14px;
    background-color: #17191e !important;
    background-clip: padding-box !important;
    border: 2px solid #17191e !important;
    color: #fff !important;
  }

}

@media only screen and (max-width: 700px) {
  .user_information {
    display: none;
  }
}

.not-scrollable {
  overflow: hidden;
}

.form-label {
  color: #fff !important;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.btn-cancel {
  background-color: transparent;
  border: none;
  border-radius: 20px;
  color: #a0a6aa;
  font-size: 14px;
  padding: 7px 25px 7px 25px;

  &:hover {
    background-color: #a0a6aa;
    border-color: #a0a6aa;
  }
}

.btn-delete {
  background-color: #e14a56;
  border-color: #e14a56;
  border-radius: 20px;
  color: #fdeee9;
  font-size: 14px;
  padding: 7px 25px 7px 25px;

  &:hover {
    background-color: #e45c67;
    border-color: #fdeee9;
    border-color: #e45c67;
  }

}

.see-more-post {
  background-color: rgba(77, 105, 250, .3);
  height: 60px;
  border-radius: 30px;
  color: #4d69fa;
  border-color: rgba(77, 105, 250, .1);

  &:hover {
    background-color: rgba(77, 105, 250, .8);
    color: #fff;
  }
}

.facebook_loader {
  .wrapper-cell {
    display: flex;
    margin-bottom: 30px;
    border-radius: 20px;
    height: 230px;

  }

  .product-wrapper-cell {
    display: flex;
    margin-bottom: 30px;
    border-radius: 20px;
    height: 150px;

  }

  // Animation
  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0
    }

    100% {
      background-position: 468px 0
    }
  }

  .animated-background {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #323643;
    // background: linear-gradient(to right, #545a70 8%, #4b5165 18%, #434859 33%);
    background: linear-gradient(to right, #2d313c 8%, #2b2e39 18%, #282b36 33%);
    // background-image: linear-gradient(to right, #3a3a3a 0%, #3f3f3f 10%, #4a4a4a 20%, #3f3f3f 30%, #3a3a3a 50%, #3a3a3a 100%);
    background-size: 800px 104px;
    height: 96px;
    position: relative;
  }

  // Page Elements
  .image {
    height: 190px !important;
    width: 200px !important;
    border-radius: 20px;
    margin-top: .8rem;
    margin-left: .4rem;
    @extend .animated-background;
  }

  .image-product {
    height: 100px !important;
    width: 100px !important;
    border-radius: 20px;
    margin-top: .8rem;
    margin-left: .4rem;
    @extend .animated-background;
  }

  .text {
    margin-left: 20px;
    margin-top: 1rem;
  }

  .text-product {
    margin-left: 20px;
    margin-top: 1rem;

  }

  .text-line {
    height: 20px;
    width: 100%;
    //  background: #F6F6F6;
    margin: 4px 0;
    border-radius: 8px;
    @extend .animated-background;
  }

  .badge-line {
    height: 30px;
    max-width: 700px;
    //  background: #F6F6F6;
    margin: 5px 0;
    border-radius: 8px;
    @extend .animated-background;
  }
}

.page-name {
  position: absolute;
  top: 30px;
  z-index: 1000;
  left: 280px;

}

.btn-light-info {
  background-color: #17191e;
  border: 1px solid #17191e;
  border-radius: 15px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;

  &:hover {
    background-color: #323643;
    border: 1px solid #323643;
    border-radius: 15px;
    color: #fff;
  }
}

.btn-light-success {
  background-color: rgba(70, 188, 170, .3);
  border: 1px solid rgba(70, 188, 170, .1);
  border-radius: 8px;
  color: #46bcaa;
  font-size: 12px;
  font-weight: 600;

  &:hover {
    background-color: rgba(70, 188, 170, .8);
    border: 1px solid rgba(70, 188, 170, .1);
    border-radius: 15px;
    color: #fff;
  }
}

.details-icon {
  color: #4d69f8;
  font-size: 16px;
  padding-inline: 10px;
}

.reminder_past_due {
  // color: #f35321;
  background-color: #f35321;
  padding: 4px 5px;
  font-size: 13px;
  cursor: pointer;
  color: #242731;
  border-radius: 50%;
}

.reminder_not_yet {
  // color: #d3d4d5;
  // font-size: 17px;
  cursor: pointer;
  background-color: #d3d4d5;
  padding: 4px 5px;
  font-size: 13px;
  cursor: pointer;
  color: #242731;
  border-radius: 50%;
}

.reminder-already {
  // color: #44bca9;
  // font-size: 17px;
  cursor: pointer;
  background-color: #44bca9;
  padding: 4px 5px;
  font-size: 13px;
  cursor: pointer;
  color: #242731;
  border-radius: 50%;
}

.filter-count {
  position: absolute;
  // top: 2px;
  // right: 2.5px;
  background-color: $orange;
  width: 18px;
  height: 18px;
  line-height: 14px;
  font-size: 11px !important;
  font-weight: 500 !important;
  color: $white !important;
  text-align: center;
  padding-top: 2px;
  margin-top: -12px;
  border-radius: 20px 20px 20px 20px;
  z-index: 1;
}

.form-check-input {
  width: 1rem ;
  height: 1rem ;
  // background-color: transparent !important;
  // border-color: #c7c8cb;
  background-color: #3d4351 !important;
  border-color: #3d4351;
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.3) !important;
  margin-top: 0;

  &:checked {
    background-color: #b0b1b4 !important;
    border-color: #b0b1b4 !important;
    // border-color: #2b2f38;
    box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.3) !important;
    // background: #16181c;
    // filter: brightness(0) saturate(100%) invert(6%) sepia(7%) saturate(1642%) hue-rotate(181deg) brightness(96%) contrast(91%);

  }

  &:focus {
    border-color: #2b2f38;
  }
}

.btn-confirm {
  background-color: rgba(70, 188, 170, .3);
  border-color: rgba(70, 188, 170, .1);
  border-radius: 20px;
  color: #46bcaa;
  font-size: 14px;
  padding: 7px 25px 7px 25px;

  &:hover {
    color: #fff;
    background-color: rgba(70, 188, 170, .8);
    border-color: rgba(70, 188, 170, .1);
  }

}

.red-badge {
  color: #f35421;
  background-color: rgba(243, 84, 33, .3);
  border-color: rgba(243, 84, 33, .1);

}

.yellow-badge {
  color: #ffcf52;
  background-color: rgba(255, 207, 82, .3);
  border-color: rgba(255, 207, 82, .1);

}

.green-badge {
  color: #46bcaa;
  background-color: rgba(70, 188, 170, .3);
  border-color: rgba(70, 188, 170, .1);
}

.content-loader-div {
  background-color: #17191e;
  border-radius: 20px;

  // Animation
  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0
    }

    100% {
      background-position: 468px 0
    }
  }

  rect {
    fill: #000 !important;
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(to right, #2d313c 8%, #2b2e39 18%, #282b36 33%);
  }

  .animated-background {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #323643;
    // background: linear-gradient(to right, #545a70 8%, #4b5165 18%, #434859 33%);
    background: linear-gradient(to right, #2d313c 8%, #2b2e39 18%, #282b36 33%);
    // background-image: linear-gradient(to right, #3a3a3a 0%, #3f3f3f 10%, #4a4a4a 20%, #3f3f3f 30%, #3a3a3a 50%, #3a3a3a 100%);
    background-size: 800px 104px;
    height: 96px;
    position: relative;
  }
}

.navbar-page-name {
  span {
    font-size: 16px !important;

  }
}

@media screen and (max-width: 480px) {
  .navbar-page-name {
    display: none;
  }

}

.btn-green {
  background-color: rgba(70, 188, 170, 0.3);
  border: 1px solid rgba(70, 188, 170, 0.1);
  border-radius: 8px;
  color: #46bcaa;
  font-size: 12px;
  font-weight: 500;

  &:hover {
    background-color: #4d8788;
    border: 1px solid #4d8788;
    // border-radius: 8px;
    color: #fff;
  }
}

.btn-blue {
  background-color: rgba(77, 105, 250, .3);
  border: 1px solid rgba(77, 105, 250, .1);
  border-radius: 8px;
  color: #4d69fa;
  font-size: 12px;
  font-weight: 500;

  &:hover {
    background-color: rgba(77, 105, 250, .8);
    border: 1px solid rgba(77, 105, 250, .1);
    // border-radius: 8px;
    color: #fff;
  }
}

.btn-pink {
  background-color: #57414e;
  border: 1px solid rgba(255, 162, 191, .1);
  border-radius: 8px;
  color: #ffa2bf !important;
  font-weight: 500;

  span {
    color: #ffa2bf !important;
    font-size: 12px;
  }

  &:hover {
    background-color: rgba(255, 162, 191, .8);
    border: 1px solid rgba(255, 162, 191, .1);
    // border-radius: 8px;
    color: #fff !important;

    span {
      color: #fff !important;
    }

  }
}
.btn-pink-text-white {
  background-color: #57414e;
  border: 1px solid rgba(255, 162, 191, .1);
  border-radius: 8px;
  color: #fff !important;
  font-weight: 500;
  font-size: 12px;

  span {
    color: #ffa2bf !important;
    font-size: 12px;
  }

  &:hover {
    background-color: rgba(255, 162, 191, .8);
    border: 1px solid rgba(255, 162, 191, .1);
    // border-radius: 8px;
    color: #fff !important;

    span {
      color: #fff !important;
    }

  }
}

.btn-purple {
  background-color: rgba(108, 93, 211, .3);
  border: 1px solid rgba(108, 93, 211, .1);
  border-radius: 8px;
  color: #6c5dd3;
  font-size: 12px;

  &:hover {
    background-color: rgba(108, 93, 211, .8) !important;
    border: 1px solid rgba(108, 93, 211, .1) !important;
    // border-radius: 8px;
    color: #fff;
  }
}

.btn-purple.disabled {
  background-color: #796cd1 !important;
  color: #aba6cf !important;
  border: none !important;
}

.copy-icon {
  color: #42aa9a;
  font-size: 16px;
}

.active-green-badge {
  color: #46bcaa;
  background-color: rgba(70, 188, 170, .3);
  border-color: rgba(70, 188, 170, .1);
}

.deactive-badge {
  color: #f35421;
  background-color: rgba(243, 84, 33, .3);
  border-color: rgba(243, 84, 33, .1);
}

.dropdown-btn {
  background-color: #e4e4e4 !important;
  border-color: #e4e4e4 !important;
  color: #000 !important;
  padding: 5px 10px !important;
  font-size: 12px !important;
}

.clickable {
  &:hover {
    color: #4d69fa !important;
  }
}


.icon-btn {
  background-color: transparent !important;
  border: none !important;
  padding: 0;
}

.text-green {
  color: #44bca9 !important;
}

.text-red {
  color: #f35321 !important;
}

.search-loader {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, #fff 94%, #0000) top/4px 4px no-repeat,
    conic-gradient(#0000 30%, #fff);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 2px), #000 0);
  animation: s3 1s infinite linear;
}

@keyframes s3 {
  100% {
    transform: rotate(1turn)
  }
}

.search-area:focus-within {
  padding-left: 1rem;
  border-radius: 10px;
  background-color: #101115 !important;
  // box-shadow:5px 10px #000;
  // transition: 0.5s ease;
  transition: all 1s;
}

// .material-table-toolbar-search:focus-within{
//   border-radius: 8px;
//   box-shadow: .8em .8em  rgba(0, 0, 0, 0.5);
//   transition: 0.5s ease;
// }
.focused-search {
  border-radius: 8px;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.4);
  transition: 0.5s ease;
}

.select-close-icon {
  &:hover {
    color: #c30010;
  }
}

.notification-error-icon {
  font-size: 35px;
  color: #c30010;
}

.notification-success-icon {
  font-size: 35px;
  color: #49a255;
}

//Notification
.notification {
  border-radius: 10px !important;
  height: 90px !important;
  width: 350px !important;
  border: none !important;
  right: 54px !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.7) !important;
  backdrop-filter: blur(6px) !important;
  color: #DBDCE2 !important;

  .notification-dismiss {
    background-color: black !important;
    color: #DBDCE2 !important;
  }

  .square {
    position: relative;
    height: 3px;
  }

  .left,
  .center,
  .right {
    position: absolute;
    top: 0;
    bottom: 0;
    background: rgb(220, 220, 220);
  }

  .left,
  .right {
    width: 20px
  }

  .left {
    border-radius: 20px 0 0 20px
  }

  .right {
    border-radius: 0 20px 20px 0
  }

  .center {

    left: 20px;
    width: 2.2px;
    transform: scaleX(60);
    transform-origin: left;
  }

  .right {
    transform: translateX(80px);
  }

  .center {
    animation: center-animate 6s ease infinite alternate;
  }

  .right {
    animation: left-animate 6s ease infinite alternate;
  }

  @keyframes left-animate {
    0% {
      transform: translateX(200px)
    }

    100% {
      transform: translateX(20px)
    }
  }

  @keyframes center-animate {
    0% {
      transform: scaleX(121)
    }

    100% {
      transform: scaleX(1)
    }
  }

  .square {
    position: absolute;
    left: calc(50% - 149px);
    top: calc(50% - -30px)
  }
}

// .error-img{
//   width: 50%;
// }
.err-image-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.err-image-container img {
  // width: 100%;
  max-width: 600px;
  height: auto;
}

.centered-text {
  position: absolute;
  top: 50%;
  // transform: translate(-50%, -50%);
  max-width: 480px;
  height: auto;
  margin-right: 5rem;

  p {
    color: #537fc8;
  }
}

.emoji-input-container {
  position: relative;

  img {
    position: absolute;
    right: 8px;
    top: 10px;
    filter: invert(56%) sepia(28%) saturate(10%) hue-rotate(46deg) brightness(89%) contrast(86%);
  }

  em-emoji-picker {
    position: absolute;
    z-index: 1000;
    height: 380px;
  }
}

.createble-select {
  background-color: #08070f;
  padding: 3px 6px;
  border-radius: 8px;
  font-size: 10px;
  font-weight: 500;

  i {
    padding: 3px;

    &:hover {
      background-color: #1f2127 !important;
      color: red;
    }
  }
}

.blue-text {
  color: #536efa;
}

.not-found-404 {
  color: #e4e6eb !important;
  text-shadow: 3px 3px 4px rgba(255, 255, 255, .3);
}

.bordered-bottom {
  border-bottom: 1px solid #1d1e26;
}

.card-tabs {

  .nav-link {
    color: #4d69fa !important;

  }

  .nav-link:hover {
    background-color: rgba(77, 105, 250, .3) !important;
    color: #4d69fa !important;
  }

  .nav-link {
    &.active {
      background-color: #4d69fa !important;
      color: #fff !important;
    }
  }
}

.dark-red-btn {
  background-color: rgba(128, 24, 44, .3);
  border: 1px solid rgba(128, 24, 44, .2);
  font-size: 12px;
  font-weight: 500;

  &:hover {
    background-color: #8f1b31;
    border: 1px solid #8f1b31;
  }
}

.dark-green-btn {
  background-color: rgba(70, 188, 170, 0.3);
  border: 1px solid rgba(70, 188, 170, 0.1);
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;

  &:hover {
    background-color: #4d8788;
    border: 1px solid #4d8788;
  }
}

.dark-gray-badge {
  background-color: #1c1c1c;

}

.bg-top-img {
  background-color: #4d4c5b !important;
  color: #e4e6eb !important;
}

.to-do-list-badge {
  background-color: #444a56;
  border: 1px solid #444a56;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px;
}

.clickable-card {
  cursor: pointer;

  &:hover {
    box-shadow: 1px 8px 20px #5a617a !important;
    -webkit-transition: box-shadow .6s ease-in !important;
  }
}

.training-list-card {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 20px 0px;
  cursor: pointer;
  background-color: #17191e !important;
  border: 0;
}

.badge-blue {
  background-color: #33407C !important;
  color: #1966E7 !important;

}

.bg-light-purple {
  background-color: #323053;
}

.training-tips {
  .react-select__control {
    background-color: #17191e !important;
    border: 1px solid #17191e !important;
    height: 40px !important;
    min-height: 40px !important;
  }
}

.training-tips-search-input {
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid #393c47;
  transition: border 0.3s ease-in-out;

}

.training-tips-search-input:focus-within {
  border: 1px solid #fff;
}

.helpers-card {
  .card-footer {
    background: transparent;
    border-color: #393c47;
  }
}
.user-img{
  border: 2px solid #e4e6eb;
  background: transparent !important;
}

.badge-gray {
  background-color: #464752 !important;
  color: #9c9c9c !important;
}
.badge-pink {
  background-color: #57414e!important;
  color: #ffa2bf !important;
}
.badge-purple {
  background-color: #323053 !important;
  color: #6b5cd0 !important;
}
.badge-dark{
  color: #2e3441;
  background-color: #121419;
  border-color: #121419;
}