.analytics-count {
  position: absolute;
  background-color: red;
  width: 20px;
  height: 20px;
  line-height: 18px;
  font-size: 14px;
  font-weight: 500 !important;
  color: $white !important;
  text-align: center;
  padding-top: 2px;
  margin-top: -12px;
  border-radius: 20px 20px 20px 20px;
}

.analytics-table {

  .MuiTable-root {
    .MuiTableHead-root {
      tr {
        padding-bottom: 5rem !important;

        th {
          background-color: #F5F5F5 !important;
          font-weight: 700;
          font-size: 13px;
          color: black !important;
          border-bottom: 0 !important;
        }

        th:first-child {
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
        }

        th:last-child {
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;


        }
      }
    }

    .MuiTableBody-root {
      .MuiTableRow-root {
        &:hover{
          background-color: hsla(0,0%,100%,.075) !important;
        }
        &:nth-child(even) {
          background-color: #F5F5F5 !important;
        }

        td:first-child {
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
        }

        td:last-child {
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;


        }
      }
    }
  }

  .analytics-filter {
    background-color: rgba($color: #ffff, $alpha: 0.5);
    backdrop-filter: blur(35px);
    width: 325px;
    position: absolute;
    top: 6rem;
    z-index: 9999;
    right: 0;
    border-radius: 20px 20px 20px 20px;

    .form-label {
      font-size: 14px;
      font-weight: 500px;
    }

    .form-check {
      font-size: 13px;
      font-weight: 400;
    }

    .form-control {
      background-clip: padding-box !important;
      background-color: #383838 !important;
      border: 1px solid #383838 !important;
      border-radius: 8px;
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
      font-size: 6px;
      font-weight: 400;
      color: #ced4da !important;
    }

  }

  .card {
    border: none !important;
    border-radius: 20px !important;

    .card-header {
      background-color: transparent !important;
      border-radius: 20px;
      border: none !important;
      font-size: 20px;
      font-weight: 600;
      margin-top: 1rem;
      margin-left: 2rem;
      height: 100px;
    }

    .card-body {
      padding: 0;

      .material-table__wrap {
        padding-top: 0;
      }
    }

    .card-footer {
      border: none !important;
      border-radius: 20px !important;
      background-color: transparent !important;
      margin-top: 2rem;
    }
  }

}

.analytic-select {
  select {
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;
    font-size: 26px;
    background: #fff !important;
    color: white !important;
  }

  .form-select {
    // background-color: #17191e !important;
    background-color: #000 !important;
    border: none !important;
    border-radius: 10px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    height: 42px !important;
    // margin-top: 0.5rem;
    color: #000 !important;
    padding: 0px 32px 0px 10px !important;
    &:hover{
      background-color: #5c636a !important;
      color: #5c636a !important;
      transition: all 0.3s;
  }
    option{
      color: #fff !important;
    }

    .css-1xc3v61-indicatorContainer {
      color: #4D68F5 !important;
      color: #CCCCCC !important;
    }
  }

  .form-control {
    //padding-left: 55px !important;
    border-radius: 8px !important;
  }
}

.custom-form {
  width: 50px;
}

.analytics-card-wrapper {
  .analytics-image-section {
    position: relative;

    .star-image {
      // position: absolute;
      // background-color: rgba(136, 141, 152, 0.3);
      // color: #ffff;
      // border-radius: 10px;
      // width: 34px;
      // height: 34px;
      // font-size: 18px;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // left: 15px;
      // margin-top: 10px;
      // backdrop-filter: blur(6px);

    }
  }

  .analytics-info-wrapper {
    .date-dotted {
      width: 6px;
      height: 6px;
      border-radius: 20px;
      background-color: #F58431;

    }
  }
}

.add-comment-icon {
  background-color: rgba(77, 105, 250, .3);
  border: 1px solid rgba(77, 105, 250, .1);
  border-radius: 15px;
  color: #4d69fa;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    background-color: rgba(77, 105, 250, .8);
    border: 1px solid rgba(77, 105, 250, .1);
    border-radius: 15px;
    color: #fff;
  }
}

.add-project-icon {
  cursor: pointer;
  background-color: rgba(77, 105, 250, .3);
  border: 1px solid rgba(77, 105, 250, .1);
  border-radius: 15px;
  color: #4d69fa;
  font-size: 12px;
  font-weight: 600;

  &:hover {
    background-color: rgba(77, 105, 250, .8);
    border: 1px solid rgba(77, 105, 250, .1);
    border-radius: 15px;
    color: #fff;
  }

}

.analytics-follow-icon {
  background-color: #E4ECF8 !important;
  color: #2D87FF !important;

  &:hover {
    border: 1px solid #2D87FF !important;
    background-color: #2D87FF !important;
    color: #fff !important;

  }
}

// .analytics-count {
//     position: absolute;
//     background-color: red;
//     width: 20px;
//     height: 20px;
//     line-height: 18px;
//     font-size: 14px;
//     font-weight: 500;
//     color: $white;
//     text-align: center;
//     padding-top: 2px;
//     margin-top: -12px;
//     border-radius: 20px 20px 20px 20px;
// }
.material-table__wrap {
  padding: 0 !important;
  background-color: transparent !important;
}

.analytic-card {
  background-color: #17191e !important;
  color: white;
  padding-top: .9rem;
  padding-bottom: .9rem;

  .badge-views-shadow {
    border: 2px solid #d44c23;
    box-shadow: 1px 1px 10px #d44c23;

  }

  .badge-like-shadow {
    border: 2px solid #4c68f5;
    box-shadow: 1px 1px 10px #4c68f5;

  }

  .badge-comment-shadow {
    border: 2px solid #fdce50;
    box-shadow: 1px 1px 10px #fdce50;
  }

  .badge-share-shadow {
    border: 2px solid #42b2a0;
    box-shadow: 1px 1px 10px #42b2a0;
  }

  .badge-reach-shadow {
    border: 2px solid #ff59b2;
    box-shadow: 1px 1px 10px #ff59b2;
  }

  .badge-impression-shadow {
    border: 2px solid #6c5dd3;
    box-shadow: 1px 1px 10px #6c5dd3;
  }



  // .analytics-avatar {

  //     img{
  //     //border-radius: 20px !important;

  //     }
  //   .btn {
  //     backdrop-filter: blur(6px) !important;
  //     background-color: rgba(0, 0, 0, 0.7);
  //     //padding: 6px 32.4px;
  //     padding-inline: 34px;
  //     border: none !important;
  //     border-radius: 20px 0px 0px 20px;
  //   }
  //   .btn-second {
  //     border-radius: 0px 20px 20px 0px;

  //   }
  //   .analytics-image-button{
  //     opacity: 0;
  //     position: relative;
  //     bottom: 49px;
  //     max-width: 280px;
  //     padding: 0 !important;
  //     margin: 0 !important;
  //      display: flex;
  //      justify-content: center;
  //   }
  //   &:hover {
  //     .analytics-image-button{
  //       opacity: 1;
  //     }
  //   }
  // }

}

.see-caption {
  background-color: transparent !important;
  color: white;
  cursor: pointer;
  padding: 0;
  margin-bottom: 0;
  margin-top: 0;

  .accordion-arrow {
    display: flex !important;
    justify-content: start !important;
    align-items: center !important;
    color: white !important;

    .social-accordion-title {
      color: white !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      padding: 0 !important;

    }

    i {
      color: white;
      font-size: 12px !important;
      padding-top: 0.1rem;
      padding-left: 8px;
      width: 20px;
    }
  }
}

@media screen and (max-width: 1346px) {
  .analytic-card {
    padding-bottom: 1rem;

  }
}
.analytics-statistic-section {
  .share-badge {
    color: white;
    height: 30px;
    margin-right: .5rem;
    padding: 5px !important;

    i {
      color: #42b2a1;
    }
  }

  .like-badge {
    color: white !important;
    height: 30px;
    margin-right: .5rem;
    padding: 5px !important;


    i {
      color: #4c68f6;
    }
  }

  .comment-badge {
    color: white !important;
    height: 30px;
    margin-right: .5rem;
    padding: 5px !important;


    i {
      color: #fdce50;
    }
  }

  .save-badge {
    color: white !important;
    height: 30px;
    margin-right: .5rem;
    padding: 5px !important;


    i {
      color: #4c68f7;
    }
  }

  .view-badge {
    color: white;
    height: 30px;
    margin-right: .5rem;
    padding: 5px !important;


    i {
      color: #f35321;
    }
  }

  .impression-badge {
    color: white;
    height: 30px;
    margin-right: .5rem;
    padding: 5px !important;

    i {
      color: #6c5dd3;
    }
  }

  .reach-badge {
    color: white;
    height: 30px;
    margin-right: .5rem;
    padding: 5px !important;
    i {
      color: #ff59b2;
    }
  }
}
// @media(min-width: 1346px) {
//   .analytics-statistic-section {
//     .share-badge {
//       background-color: #294849;
//       color: white;
//       width: 125px;
//       height: 30px;
//       margin-right: .5rem;
//       padding: 5px !important;

//       i {
//         color: #42b2a1;
//       }
//     }

//     .like-badge {
//       background-color: #2b335d;
//       color: white !important;
//       width: 125px;
//       height: 30px;
//       margin-right: .5rem;
//       padding: 5px !important;


//       i {
//         color: #4c68f6;
//       }
//     }

//     .comment-badge {
//       background-color: #564c33;
//       color: white !important;
//       width: 125px;
//       height: 30px;
//       margin-right: .5rem;
//       padding: 5px !important;


//       i {
//         color: #fdce50;
//       }
//     }

//     .save-badge {
//       background-color: #2b335d;
//       color: white !important;
//       width: 125px;
//       height: 30px;
//       margin-right: .5rem;
//       padding: 5px !important;


//       i {
//         color: #4c68f7;
//       }
//     }

//     .view-badge {
//       background-color: #542e26 !important;
//       color: white;
//       width: 125px;
//       height: 30px;
//       margin-right: .5rem;
//       padding: 5px !important;


//       i {
//         color: #f35321;
//       }
//     }

//     .impression-badge {
//       background-color: #323053 !important;
//       color: white;
//       width: 125px;
//       height: 30px;
//       margin-right: .5rem;
//       padding: 5px !important;

//       i {
//         color: #6c5dd3;
//       }
//     }

//     .reach-badge {
//       background-color: #57414d !important;
//       color: white;
//       width: 125px;
//       height: 30px;
//       margin-right: .5rem;
//       padding: 5px !important;
//       i {
//         color: #ff59b2;
//       }
//     }
//   }
// }

// @media(max-width: 1346px) {
//   .analytics-statistic-section {
//     .share-badge {
//       background-color: #294849;
//       color: white;
//       width: 125px;
//       height: 36px;
//       margin-right: .5rem;
//       padding: 5px 0px !important;

//       i {
//         color: #42b2a1;
//       }
//     }

//     .like-badge {
//       background-color: #2b335d;
//       color: white !important;
//       width: 125px;
//       height: 36px;
//       margin-right: .5rem;
//       padding: 5px 0px !important;

//       i {
//         color: #4c68f6;
//       }
//     }

//     .comment-badge {
//       background-color: #564c33;
//       color: white !important;
//       width: 125px;
//       height: 36px;
//       margin-right: .5rem;
//       padding: 5px 0px !important;

//       i {
//         color: #fdce50;
//       }
//     }

//     .save-badge {
//       background-color: #2b335d;
//       color: white !important;
//       width: 125px;
//       height: 36px;
//       margin-right: .5rem;
//       padding: 5px 0px !important;

//       i {
//         color: #4c68f7;
//       }
//     }

//     .view-badge {
//       background-color: #542e26 !important;
//       color: white;
//       width: 125px;
//       height: 36px;
//       margin-right: .5rem;
//       padding: 5px 0px !important;

//       i {
//         color: #f35321;
//       }
//     }

//     .impression-badge {
//       background-color: #323053 !important;
//       color: white;
//       width: 125px;
//       height: 36px;
//       margin-right: .5rem;
//       padding: 5px 0px !important;

//       i {
//         color: #6c5dd3;
//       }
//     }

//     .reach-badge {
//       background-color: #57414d !important;
//       color: white;
//       width: 125px;
//       height: 36px;
//       margin-right: .5rem;
//       padding: 5px 0px !important;
//       i {
//         color: #ff59b2;
//       }
//     }
//   }
// }

.not-shared-accounts {
  background-color: transparent !important;
  display: flex;
  justify-content: end;
  padding: 0px !important;
  margin-top: 1rem;
  cursor: pointer;

  .accordion-arrow {
    display: flex !important;
    justify-content: start !important;
    align-items: center !important;
    padding: 0px !important;


    .social-accordion-title {
      color: white !important;
      font-weight: 500 !important;
      font-size: 12px !important;
      padding: 0 !important;

    }

    i {
      color: #2D87FF;
      font-size: 12px !important;
      padding-top: 0.1rem;
      padding-left: 8px;
      width: 20px;
    }
  }
}

// @media (min-width: 720px) {
//   .not-shared-accounts-mobil {
//     display: none !important;
//   }
// }

// @media (max-width: 720px) {
//   .not-shared-accounts {
//     display: none !important;
//   }
// }

.not-shared-accounts-mobil {
  background-color: transparent !important;
  padding: 0px !important;
  margin-top: .3rem;
  cursor: pointer;

  .accordion-arrow {
    display: flex !important;
    justify-content: start !important;
    align-items: center !important;
    padding: 0px !important;


    .social-accordion-title {
      color: white !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      padding: 0 !important;

    }

    i {
      color: #2D87FF;
      font-size: 12px !important;
      padding-top: 0.1rem;
      padding-left: 8px;
      width: 20px;
    }
  }
}

.posted-by-label {
  color: #c9cacb !important;
}

.image-container {

  height: 190px;
  max-width: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 8px !important;

  .bg-image {
    filter: blur(10px);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .img-reels {
    max-width: 100%;
    height: auto;
    position: relative;
    border-radius: 0 !important;

  }

}

@media (min-width: 415px) {
  // .image-container {
  //   height: 190px;
  //   max-width: 200px;
  //   position: relative;
  //   display: flex;
  //   justify-content: center;
  //   overflow: hidden;
  //   border-radius: 8px !important;

  //   .bg-image {
  //     filter: blur(10px);
  //     position: absolute;
  //     left: 0;
  //     top: 0;
  //     width: 100%;
  //     height: 100%;
  //     object-fit: fill;
  //   }

  //   .img-reels {
  //     max-width: 100%;
  //     height: auto;
  //     position: relative;
  //     border-radius: 0 !important;

  //   }
  // }

  // @media (max-width: 414px) {
  //   .nav-tabs {
  //     display: none !important;
  //   }
  // }
  // @media (min-width: 415px) {
  //   .nav-tabs-bottom {
  //     display: none !important;
  //   }
  // }
  // @media (max-width: 414px) {
  //   .nav-tabs-bottom {
  //       width: 390px;
  //       height: 70px;
  //       // left: 0;
  //       // right: 0;
  //       // top: 774px;
  //       bottom: 0 !important;
  //       position: sticky;
  //       position: -webkit-sticky;
  //       z-index: 1000 !important;

  //       display: flex;
  //       justify-content: space-around;

  //       background: #05050B !important;
  //       box-shadow: 0px 4px 50px rgba(31, 49, 73, 0.08);
  //       border-radius: 20px 20px 0px 0px;
  //   }
  // }
}

// @media (max-width: 1346px) {
//   .image-container {
//     height: 200px;
//     width: 250px;
//     position: relative;
//     display: flex;
//     justify-content: center;
//     overflow: hidden;
//     border-radius: 8px !important;

//     .bg-image {
//       filter: blur(10px);
//       position: absolute;
//       left: 0;
//       top: 0;
//       width: 100%;
//       height: 100%;
//       object-fit: fill;
//     }

//     .img-reels {
//       max-width: 100%;
//       height: auto;
//       position: relative;
//       border-radius: 0 !important;

//     }
//   }

//   // @media (max-width: 414px) {
//   //   .nav-tabs {
//   //     display: none !important;
//   //   }
//   // }
//   // @media (min-width: 415px) {
//   //   .nav-tabs-bottom {
//   //     display: none !important;
//   //   }
//   // }
//   // @media (max-width: 414px) {
//   //   .nav-tabs-bottom {
//   //       width: 390px;
//   //       height: 70px;
//   //       // left: 0;
//   //       // right: 0;
//   //       // top: 774px;
//   //       bottom: 0 !important;
//   //       position: sticky;
//   //       position: -webkit-sticky;
//   //       z-index: 1000 !important;

//   //       display: flex;
//   //       justify-content: space-around;

//   //       background: #05050B !important;
//   //       box-shadow: 0px 4px 50px rgba(31, 49, 73, 0.08);
//   //       border-radius: 20px 20px 0px 0px;
//   //       .nav-link{
//   //           color: white !important;
//   //       }
//   //   }
//   // }
// }

@media (max-width: 414px) {
  .analytics-avatar {
    img {
      border-radius: 8px !important;

    }

    .btn {
      backdrop-filter: blur(6px) !important;
      background-color: rgba(0, 0, 0, 0.7);
      //padding: 6px 32.4px;
      //padding-inline: 34px;
      border: none !important;
      border-radius: 8px 0px 0px 8px !important;
      // font-size: 9px !important;
      padding: 0px !important;
    }

    .btn-second {
      border-radius: 0px 8px 8px 0px !important;

    }

    .analytics-image-button {
      opacity: 1;
      position: relative;
      bottom: 38px;
      width: 120px;
      padding: 0 !important;
      margin: 0 !important;
      z-index: 1000;
      padding: 5px 0px !important;
    }

    &:hover {
      .analytics-image-button {
        opacity: 1;
      }
    }
  }

}

// @media (min-width: 415px) {
  .analytics-avatar {
    max-width: 150px !important;
    max-height: 190px !important;

    img {
      border-radius: 8px !important;

    }

    .btn {
      backdrop-filter: blur(6px) !important;
      background-color: rgba(0, 0, 0, 0.7);
      //padding: 6px 32.4px;
      // padding-inline: 20px;
      border: none !important;
      border-radius: 8px 0px 0px 8px !important;
      // padding: 9px 14.2px !important;
      padding: 5px 0 !important;
    }

    .btn-second {
      border-radius: 0px 8px 8px 0px !important;

    }

    .analytics-image-button {
      // opacity: 0;
      position: relative;
      bottom: 38px;
      width: 150px;
      padding: 0 !important;
      margin: 0 !important;
      z-index: 1000;
      a, Link, .btn{
        width: 76px !important;
      }
    }

    // &:hover {
    //   .analytics-image-button {
    //     opacity: 1;
    //   }
    // }
  }
// }
@media (min-width: 900px) {
  .analytics-avatar {
  .analytics-image-button {
    opacity: 0;
  }
      &:hover {
      .analytics-image-button {
        opacity: 1;
      }
    }
  }
}

.analytics-filter-scroll {
  max-height: 38rem !important;
  overflow-y: scroll !important;

  .analytics-filter {
    padding: 0px 10px 5px 30px;

  }

  .form-control {
    background-color: #383838 !important;
    border: 1px solid #383838 !important;
    color: whitesmoke;

  }

  .submit-btn {
    background-color: #000;
    color: white;
    border: 1px solid #000;
  }
}

.submit-btn {
  background-color: #000 !important;
  color: white;
  border: 1px solid #000 !important;
  border-radius: 8px;

}

.submit-btn:hover {
  background-color: #0d0d0d !important;
  border: 1px solid #0d0d0d;
  color: white;
  border-radius: 8px;
}

.submit-outline-btn {

  background: transparent;

  &:hover {
    background-color: #000 !important;
    border: 1.5px solid #000 !important;
  }
}

// @media (min-width: 415px) {
//   .analytics-search {
//     width: 500px;
//   }
// }

// @media (max-width: 1253px) {
//   .analytics-search {
//     width: 100%;
//   }
// }

@media (max-width: 1346px) {
  .web_badge {
    display: none !important;

  }

  .analytics-right-side-web {
    display: none !important;
  }
}

@media (min-width: 1346px) {
  .mobile_badge {
    display: none !important;
  }
}

@media (max-width: 1014px) {
  .web-info {
    display: none !important;
  }
}

@media (min-width: 1014px) {
  .tablet-info {
    display: none !important;
  }
}

.analytics-modal-body {
  // max-height: 35rem;
  // overflow-y: scroll;
  overflow-x: auto;
  // padding-inline: inherit;
  padding: 0 !important;
}

.btn-save-this-filter {
  // background-color: rgba(77,105,250,.3);
  // border: 1px solid rgba(77,105,250,.1);
  // border-radius: 15px;
  // color: #4d69fa;
  // font-size: 12px;
  // font-weight: 600;
  // &:hover {
  //     background-color: rgba(77,105,250,.8);
  //     border: 1px solid rgba(77,105,250,.1);
  //     border-radius: 15px;
  //     color: #fff;
  // }
  background-color: rgba(70, 188, 170, .3);
  border: 1px solid rgba(70, 188, 170, .1);
  border-radius: 8px;
  color: #e4e6eb;
  font-size: 12px;
  font-weight: 600;
  width: 140px;
  padding: 9px 20px;

  &:hover {
    background-color: #4d8788;
    border: 1px solid #4d8788;
    border-radius: 8px;
    color: #fff;
  }
}

.save-this-filter-body {
  .form-control {
    background-clip: padding-box !important;
    background-color: #2b2f38 !important;
    border: 1px solid #2b2f38 !important;
    border-radius: 8px !important;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
    color: #fff;
  }
}


@media (max-width: 893px) {
  .analiytics-web-right-side {
    display: none !important;
  }
}

@media (min-width: 893px) {
  .analiytics-tablet-right-side {
    display: none !important;
  }
}

@media (min-width: 1346px) {
  .product_name_tablet {
    display: none !important;
  }
}

.alert-importing {
  margin-top: 1rem;
  border-radius: 8px;
  background-color: #2d3767;
  color: #94a5fc;
  border-color: rgba(77, 105, 250, .3);
  display: flex;
  flex-wrap: wrap;
}

.alert-imported {
  margin-top: 1rem;
  border-radius: 20px;
  background-color: #2b504f;
  color: #90d7cc;
  border-color: rgba(70, 188, 170, .3);
  display: flex;
  flex-wrap: wrap;
}

.btn-reset-payload {
  // background-color: #4e5a67;
  // border: 1px solid #4e5a67;
  // border-radius: 8px;
  // color: #fff;
  // font-size: 12px;
  // font-weight: 600;
  // padding: 8px 15px !important;

  // &:hover {
  //   background-color: #414c58;
  //   border: 1px solid #414c58;
  //   border-radius: 10px;
  //   color: #fff;
  // }
  background-color: rgba(69,0,3, .8);
  color: #e4e6eb;
  border: 1px solid rgba(69,0,3, 0.1);
    font-size: 12px;
  font-weight: 600;
  padding: 9px 20px !important;
  &:hover{
    color: #fff;
    background-color: #9d0007;
    border: 1px solid #9d0007;
  }
}

.btn-saved-filters {
  color: #e4e6eb !important;
  background-color: rgba(77,105,250,.3) !important;
  border-radius: 8px;
  border: 1px solid rgba(77,105,250,.1) !important;
  padding: 8px 10px;
  // margin-right: 8px;
  width: 120px;
  // margin-top: 10px;
  font-size: 12px;
  font-weight: 600;
  height: 40px;
}

.btn-saved-filters:hover {
  background-color: rgba(77,105,250,.8) !important;
  border-color: rgba(77,105,250,.1) !important;
  color: white !important;
}

.analytic-filter-container {
  // max-height: 35rem;
  // overflow-y: scroll !important;
  overflow-x: clip !important;
  padding-left: 2rem !important;
  max-height: calc(100vh - 250px); // to adjust you can change 180px
  overflow-y: auto;

  .filter-value-middle {
    display: flex;
    justify-content: start;
  }

  .form-control {
    background-clip: padding-box !important;
    background-color: #2b2f38 !important;
    border: 1px solid #2b2f38 !important;
    border-radius: 8px !important;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
    color: #fff;
  }

  .form-control.right {
    border-radius: 8px !important;

  }

  .form__form-group {
    width: 100% !important;
  }
}

.imported-data-x {
  background-color: #90d7cc;
  border: none;
  font-size: 13px;
  padding: 0;
  color: #000;
  position: absolute;
  z-index: 1;
  // top: 95px;
  // margin-left: 127px;
  right: 0;
  margin-right: 4px;
  // top: 0;
  padding: 5px 6px;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: #399e8f;
    // background-color: #5c636a;
    color: #fff;
  }
}

.choose-saved-filter {
  .react-select__control {
    border-radius: 8px !important;
  }
}

.carousel-icon {
  position: absolute;
  margin-top: 167px;
  left: 9.6rem;
  z-index: 1;
  font-size: 16px;
  color: #e4e6eb;
}

.reels-icon {
  position: absolute;
  margin-top: 167px;
  left: 9.6rem;
  z-index: 999;
  width: 18px;
  height: 18px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(10%) saturate(2568%) hue-rotate(177deg) brightness(89%) contrast(109%);
}

@media screen and (max-width: 1345px) and (min-width: 646px) {
  // .analytics-statistic-section{
  //   width: 560px;
  // }
  .badge-icon{
    display: none;
  }
}
@media screen and (max-width: 645px) {
  // .analytics-statistic-section{
  //   width: 300px;
  // }
  .badge-text{
    display: none;
  }
  // .mobile-badge-icon{
  //   display: flex;
  //   justify-content: center;
  // }
  .analytics-statistic-section{
    width: 315px;
    .share-badge {
      width: 70px;
      padding: 5px 10px !important;
    }

    .like-badge {
      width: 70px;
      padding: 5px 10px !important;
    }

    .comment-badge {
      width: 70px;
      padding: 5px 10px !important;
    }

    .save-badge {
      width: 70px;
      padding: 5px 10px !important;
    }

    .view-badge {
      width: 70px;
      padding: 5px 10px !important;
    }

    .impression-badge {
      width: 70px;
      padding: 5px 10px !important;
    }

    .reach-badge {
      width: 70px;
      padding: 5px 10px !important;
    }
  }
}

@media screen and (min-width: 768px) {
  .mobile-date{
    display: none !important;
  }
  // .tab-all{
  //   width: 60%;
  // }
  .tabs-right-buttons{
    justify-content: end;
  }
}
@media screen and (max-width: 767px) {
  .web-date, .analytics-filter-btn-web,  .web-expand-dropdown{
    display: none !important;
  }
  .analytics-search{
    width: 100%;
  }
  .sort-saved-filter-mobile{
    width: 100%;
    justify-content: space-between;
  }
  .tab-all{
    width: 100%;
  }
  .btn-reset-payload{
    width: 140px;
  }
  .tabs-right-buttons{
    justify-content: center;
  }
}
@media screen and (min-width: 1380px) {
  .analytics-search{
    width: 70%;
  }
}
@media screen and (min-width: 1105px) and (max-width: 1380px) {
  .analytics-search{
    width: 60%;
  }
}
.filter-btn-mobile{
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 10px;
  /* height: 42px; */
  padding: 2px 6px;
  width: 33px;
  height: 33px;
}
.image-star{
  background: #323643;
  border: none;
  opacity: 0.7;
  color: #f3bf37;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  // left: "28px",
  font-size: 20px;
  // margin-top: 6px;
  margin-top: 38px;
  z-index: 1;
  margin-left: 5px;
  &::after{
    display: none !important;
  }
  &:hover{
    i{
      color: #f3bf37;
    }
  }
}
// @media screen and (max-width: 415px) {
//   .analytics-avatar-mobile{
//     justify-content: center;
//     width: 100%;
//   }
//   .image-star{
//     left: 78px;
//   }
  
// }
.show-more-btn{
  color: white !important;
    background-color: #000 !important;
    border-radius: 8px;
    border: none !important;
    padding: 9px 25px !important;
    margin-left: 2px;
    margin-right: 8px;
    width: 140px !important;
    margin-top: 10px;
}


@media screen and (min-width: 484px) {
  .mobile-tabs{
    display: none !important;
  }
}

@media screen and (max-width: 1390px) {
  .tabs-right-buttons{
    // width: 100% !important;
  }
}
@media screen and (max-width: 1153px) {
  .instagram-profile-tab{
    flex-wrap: wrap;
  }
  // .tab-all{
  //   width: 100%;
  // }
}
@media screen and (max-width: 1220px) {
  .tab-all{
    width: 100%;
  }
  .tabs-right-buttons{
    width: 100%;
  }
  .swiper-all{
    .swiper{
      max-width: 100% !important;
    }
  }
}

.social-media-search:placeholder-shown{
font-weight: 500;
}
.btn-smart-filters{
  // background-color: rgba(201, 28, 78,.3);
  // border: 1px solid rgba(201,28,78,.1);
  background-color: rgba(179,132,14, 0.4);
  border: 1px solid rgba(179,132,14, 0.1);
  padding: 8px 10px;
  width: 120px !important;
  // margin-top: 10px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 8px;
  &:hover{
    background-color: #e5aa14;
    border: 1px solid #e5aa14;
  }
}
.exist-tab-modal{
  background-color: #2d303c;
  color: #fff;
  padding: 0.5rem 1rem;
  width: 140px;
  text-align: center;
  border-radius: 8px;
}
.smart-filters{
  background-color: #323643;
  border-radius: 8px;
  padding: 12px 25px;
  margin-right: 6px;
}
.analytics-tabs {
  background-color: transparent !important;
  color: white;
  cursor: pointer;
  padding: 0;
  margin-bottom: 0;
  margin-top: 0;

  .accordion-arrow {
    display: flex !important;
    justify-content: end !important;
    align-items: center !important;
    color: white !important;
    padding: 0 5px 0 0  !important;

    .social-accordion-title {
      color: white !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      padding: 0 !important;

    }

    i {
      color: white;
      font-size: 12px !important;
      padding-left: 8px;
      width: 20px;
    }
  }
}
.analytics-scrollable-card{
  ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
    background-color: #555;
}
}
.btn-group{
  button{
    // color: #000;
    // background-color: #e7eef8;
    // border: 1px solid #e7eef8;
    // :hover{
    //   background-color: #c4cad3;
    //   border: 1px solid #b9bec6;
    // }
    color: #fff;
    background-color: #1f2128;
    border: 1px solid #1f2128;
    &:hover{
      background-color: #414248 !important;
      border: 1px solid #35373e;
    }
    &.active{
      color: #fff !important;
      background-color: #46bcaa !important;
      border: 1px solid #46bcaa !important;
      &:hover{
        background-color: #62c6b7 !important;
        border: 1px solid #59c3b3;
      }
    }
  }
}
.custom-tab{
  color: white !important;
  background-color: #323643 !important;
  border-radius: 10px;
  border: none !important;
  padding: 8px 10px;
  margin-left: 2px;
  margin-right: 8px;
  margin-top: 20px !important;
}
.fav-dropdown-input{
  background-clip: padding-box !important;
  background-color: #2b2f38 !important;
  border: 1px solid #2b2f38 !important;
  border-radius: 8px !important;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
  color: #fff !important;
  &:focus{
    border: 1px solid #fff !important;
  }
}
.fav-dropdown-input.border-red{
  &:focus{
    border: 1px solid rgb(121, 13, 13) !important;
  }
}
.filters-badge{
  background-color: #e9ecef;
  color: #434242;
}
.analytics-badge{
  background-color: #323643;
}