.social-media {
    height: calc(100% - 0.5rem);
    border-radius: 20px;
    border: 0;
    // max-height: 300px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 20px 0px;
    &:hover{
        box-shadow: 1px 8px 20px grey;
        -webkit-transition:  box-shadow .6s ease-in;
       }
 
    .social-media-header{
        border-radius: 20px 20px 0px 0px;
        background-color: transparent !important;
        border-bottom: 0;

        .instagram-info-btn{
            color: #1f2128;
            background-color: #e9e9ea;
            border-color: #e9e9ea;
            padding:  4px 7px;
            border-radius: 8px;
            transition: .3s;
            &:hover{
                color:#f2f2f2;
                background-color: #1f2128;
                border-color: #1f2128;
            }
        }
    }    
    .social-media-body{
        .div-bio{
            min-height: 50px;
        }
        .bio{
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; 
        white-space: normal;
        overflow:hidden;
        font-size: 14px;
        font-weight: 500;
    
    } 
    }
.card-footer{
  background: transparent;
}

}

.add-card {
    height: 98%;
    border-radius: 20px;
    // opacity: 0.5;
    // background: rgba(255, 255, 255, 0.7) !important;
    border: none !important;
    .card-body{
        min-height: 200px !important;
    }
    .add-card-body {
        min-height: 260px;
    }
    .add-icon{
        cursor: pointer;
    }

    .btn-instagram-add {
        border-radius: 20px;
        color: #bebebe;
        border-color: #F2F2F2;
        background-color: #F2F2F2;
        min-height: 150px;
            &:hover{
            // border-color: #F100DC;
            // background-color: #F100DC; 
            // color: #fff;
            border-radius: 20px;
            color: #fff;
            background: #d6249f;
            background:linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
            }
    }
    .btn-facebook-add {
            border-radius: 20px;
            color: #bebebe;
            border-color: #F2F2F2;
            background-color: #F2F2F2;
                &:hover{
                border-color: #4d69fa;
                background-color: #4d69fa; 
                color: #fff;
             }
    }
    .btn-youtube-add {
        border-radius: 20px;
        color: #bebebe;
        border-color: #F2F2F2;
        background-color: #F2F2F2;
            &:hover{
            border-color: #FF0000;
            background-color: #FF0000; 
            color: #fff;
            }
    }
    .btn-tiktok-add {
        border-radius: 20px;
        color: #bebebe;
        border-color: #F2F2F2;
        background-color: #F2F2F2;
            &:hover{
            border-color: #000;
            background-color: #000; 
            color: #fff;
            }
    }
    .btn-shopify-add {
        border-radius: 20px;
        color: #bebebe;
        border-color: #F2F2F2;
        background-color: #F2F2F2;
            &:hover{
            border-color: #96bf48;
            background-color: #96bf48; 
            color: #fff;
            }
    }
    .btn-woocommerce-add {
        border-radius: 20px;
        color: #bebebe;
        border-color: #F2F2F2;
        background-color: #F2F2F2;
            &:hover{
            border-color: #875DB8;
            background-color: #875DB8; 
            color: #fff;
            }
    }
    .btn-bigcommerce-add {
        border-radius: 20px;
        color: #bebebe;
        border-color: #F2F2F2;
        background-color: #F2F2F2;
            &:hover{
            border-color: #fff;
            background-color: #ffff; 
            color: #fff;
            }
    }
    .btn-custom-add {
        border-radius: 20px;
        color: #bebebe;
        border-color: #F2F2F2;
        background-color: #F2F2F2;
            &:hover{
            border-color: rgb(0, 0, 0);
            background-color: rgb(0, 0, 0); 
            color: #fff;
            }
    }
    .btn-twitter-add{
      border-radius: 20px;
      color: #bebebe;
      border-color: #F2F2F2;
      background-color: #F2F2F2;
          &:hover{
          border-color: #1aa1f1;
          background-color: #1aa1f1; 
          color: #fff;
          }
    }
        
}

.social_media_platform{
    border: 3px solid rgb(70,188,170);
    border-radius: 15px;
    color: rgb(70,188,170);
    
}


   .nav-social-medias{
   
    position: relative;
    background-color: white;
    border-radius: 20px 20px 20px 20px!important;
    top: 40px;

    .social-media-label{
        width: 100%;
        border-radius: 20px 20px 20px 20px !important;
        background-color: transparent !important;
        border: none;
    }
   }




    .instagram-profile-tab {
        margin-bottom: 2rem;
        .nav-link {
            background-color: white;
              color: black !important;
              border-radius: 10px;
              border: none !important;
              padding: 8px 10px;
              margin-left: 2px;
              margin-right: 8px;
              width: 142px !important;
              margin-top: 15px !important;
          }
          .nav-link:hover{
            background-color: #414248 !important;
            border-color: #35373e !important;
            color: white !important;
          }
        
          .nav-link {
              &.active {
                border-bottom: none !important;
                color: white !important;
                background-color: black !important;
                .saved-filter-x{
                  color: #e4e4e4;
                  &:hover{
                    color: #f35321;
                  }
                }
                &:hover{
                    background-color: #414248 !important;
                    border-color: #35373e !important;
                }
              } 
          }
       
      }

    .redirect-btn{
        background-color: #4d69fa;
        border-radius: 15px;
        border-color:  #4d69fa;
        color: #fff;        
        &:hover{
            background-color: #6880fb;
            border-radius: 15px;
        }
    }
    // .list-items{
    //     border-radius: 20px;
    //     &:nth-child(even) {
    //         background-color: #f2f5f9;
    //         padding: 1rem;
    //       }
    //       &:nth-child(odd) {
    //         padding: 1rem;
    //       }
    // }
.back-to-page{
    background-color: transparent;
    color: #5b5d6f;
    font-size: 12px;
    padding: 5px 10px !important;
    &:hover{
      color: #000;
    }
}
.social-card-badge {
    width: 80px;

  }
  .exist-badge{
    background-color: #fdeee9;
    color:#f35321;
    border-radius: 20px;
  }
  .update-badge{
    background-color: #edf8f7;
    color:#44bca9;
    border-radius: 20px;
  }
  .add-badge{
    background-color: #edf0ff;
    color:#6079fa;
    border-radius: 20px;
  }
  @media screen and (min-width: 1333px) {
    .social-media-list-avatar{
        width: 100px !important;
        height: 100px !important;
        min-width: 100px;
        min-height: 100px;
        max-width: 100px;
        max-height: 100px;
    }
  }

  @media screen and (max-width: 1333px) {
    .social-media-list-avatar{
        width: 80px !important;
        height: 80px !important;
        min-width: 80px;
        min-height: 80px;
        max-width: 80px;
        max-height: 80px;
    }
  }
  @media screen and (max-width: 432px) {
    .date-mobile{
      margin-top: 1rem;
    }
  }
  .social-media-profile{
    .recent-activity-card {

        min-height: 34rem !important;
        max-height: 34rem !important;
        border-radius: 20px !important;
        

        .card-header {
            border-bottom: none !important;
            background-color: #ffff;
            border-radius: 20px !important;

        }
        .card-body{
            overflow-y: scroll;
        }

        .timeline {
            padding-left: 110px;


            ul,
            li {
                list-style: none;
                padding: 0;
                height: 60px;
            }

            li {
                padding-bottom: 0rem;
                position: relative;
                padding-left: 0px;
                margin-left: 10px;

                &:last-child {
                    border: 0px;
                    padding-bottom: 0;
                }
            }

            .timeline-dotted {
                width: 20px;
                height: 20px;
                border-radius: 50px !important;
                position: relative;
                right: 26px;
                top: 24px;

                .timeline-dotted-line {
                    background-color: #a7a7a7;
                    top: 20px;
                    left: 2.7px;
                    position: relative;
                    min-height: 46px;
                    width: 3px;
                    display: flex;
                    justify-content: center;
                    margin-left: 5px;

                }
            }

            .time {
                color: #2a2839;
                font-weight: 700;
                position: absolute;
                left: -110px;
                margin-top: 1rem;

            }

            h6 {
                color: #4f4f4f;
                line-height: 1.3;
                // margin-top: 0.4rem;
            }
        }



    }
    
  }
  .view-details-btn{
    background-color: #dee2e6;
    border: 1px solid #dee2e6;
    font-size: 12px;
    font-weight: 500;
    color: #000;
    &:hover {
        background-color: #c4cbd3;
        border: 1px solid #c4cbd3;
        color: #000;
      }
  }
  .green-btn{
    background-color: #286d33;
    border: 1px solid #286d33 !important;
    font-size: 12px;
    font-weight: 500;
    cursor:default;
    
       &:hover{
        background-color: #286d33;
        border: 1px solid #286d33;
    }
    // &:hover{
    //     background-color: #379544;
    //     border: 1px solid #379544;
    // }
  }
  .delete-icon-btn{
    color: #262626;
    &:hover{
        color: #c30010;
    }
  }
  .red-btn{
    background-color: #80182c;
    border: 1px solid #80182c;
    font-size: 12px;
    font-weight: 500;
    &:hover {
        background-color: #8f1b31;
        border: 1px solid #8f1b31;
      }
  }

  @media screen and (min-width: 717px){
    .card-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
      grid-gap: 1rem;
    }
  }
  @media screen and (max-width: 717px){
    .card-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      grid-gap: 1rem;
      .social-media, .add-card{
        width: 20rem;
      }
    }
  }
  .instagram-icon{

    &:hover{
      background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
      transition: all 0.4s;
      border-color: #fff;
      // border-color: #d6249f50;
      // -webkit-background-clip: text;
      //         background-clip: text;
      // -webkit-text-fill-color: transparent;
    
      // background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
      // background-clip: text;
      // border-radius: 20%;
      // transform: scale(1.5);
      // color:transparent;
    
    }
    }
    
    .border-icon{
    // padding: 12px 15px;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: #303538;
    border-radius: 50px;
    border: 2.5px solid;
    border-color: #303538;
    &:hover{
      color: #fff;
    }
    
    }
    .youtube-icon{
      padding: 12px 12px;
      &:hover{
        background-color: #FF0000;
        border-color: #fff;
        transition: all 0.4s;
      }
    }
    .tiktok-icon{
      &:hover{
        background-color: #000;
        border-color: #fff;
        transition: all 0.4s;
      }
    }
    .twitter-icon{
      &:hover{
        background-color: #1DA1F2;
        border-color: #fff;
        transition: all 0.4s;
      }
    }
    .shopify-icon{
      &:hover{
        background-color: #96bf48;
        border-color: #fff;
        transition: all 0.4s;
      }
    }
    .bigcommerce-icon{
      img{
        filter: invert(15%) sepia(22%) saturate(267%) hue-rotate(159deg) brightness(20%) contrast(84%);
    
      }
      &:hover{
        background-color: #34323e;
        border-color: #fff;
        transition: all 0.4s;
        img{
          filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(159deg) brightness(20) contrast(101%);
      
        }
      }
    }
    .woocommerce-icon{
      img{
        filter: invert(15%) sepia(22%) saturate(267%) hue-rotate(159deg) brightness(15%) contrast(84%);
      }
      &:hover{
        background-color: #995d8d;
        border-color: #fff;
        transition: all 0.4s;
        img{
          filter: invert(1%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(1);    

      
        }
      }
    }