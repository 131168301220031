
.react-select__value-container {
    // padding: 8px 10px !important;
    padding: 8px 0 8px 4px !important;
  }
  .react-select__multi-value{
    margin: 0 !important;
    margin-right: 2px !important;
  }
  .instagram-profile-tab{
    .react-select{
      width: 142px;
      height: 30px;
      margin-top: 20px;
  
      .react-select__indicators{
        display: none;
      }
      .react-select__control{
        min-height: 30px !important;
        height: 30px !important;
        border-radius: 8px !important;
        border: none !important;
        // background: transparent !important;
        border: none !important;
      }
  }
  .react-select__value-container{
    padding: 0 !important;
  }

  }
.dashboard-currency-select{
  .form__form-group{
    margin-top: 0 !important;
    margin-bottom: .8rem;
  }
  .react-select__value-container{
    padding: 4px 0px 4px 10px !important;
  }
  .react-select__control{
    width: 7rem;
  }
}