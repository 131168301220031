.user-profile-card {

    -moz-box-shadow: 0 15px 0px -5px rgba(168, 178, 233, 1) !important;
    -webkit-box-shadow: 0 15px 0px -5px rgba(168, 178, 233, 1) !important;
    box-shadow: 0 15px 0px -5px rgba(168, 178, 233, 1) !important;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;

    .user-personel-information {
        .mail-info {
            align-items: center;
            margin-top: 40px;

            i {
                color: #6079FA !important;
                font-size: 30px;
                padding-right: 15px;
            }

            span {
                font-weight: 600;
            }
        }

        .tag-info {
            // display: inline-block;
            align-items: center;
            margin-top: 25px;

            i {
                color: #6079FA !important;
                font-size: 30px;
                padding-right: 15px;
            }

            span {
                font-weight: 600;
            }

            .info-icon {
                color: #35373e !important;
                font-size: 18px !important;
                margin-left: 10px !important;

            }
        }

        .social-poster {
            align-items: center;
            margin-top: 25px;

            .main-i {
                color: #6079FA !important;
                font-size: 30px;
                padding-right: 15px;
            }

            .pin-icon {
                color: red !important;
                font-size: 20px;
                transform: rotate(30deg);
            }

            span {
                font-weight: 600;
            }

            .info-icon {
                color: #35373e !important;
                font-size: 18px !important;
                margin-left: 10px !important;

            }
        }
    }
}

.user-skill-card {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;

    .skill-title {
        display: flex;

        i {
            font-size: 30px;
            color: #FECF51;

        }
    }
}

.user-static-card {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    border: none !important;
    .card-header {
        border-bottom: 0;
        background-color: transparent !important;
        margin-top: 1rem;

        .product-button {
            border-radius: 15px;
            color: #4d69fa;
            background-color: #edf0ff;
            border-color: #edf0ff;

            &:hover {
                color: #fff;
                background-color: #4d69fa;
                border-color: #4d69fa;
            }
        }

   
    }

    .static-title {
        display: flex;
        align-items: center;
        margin-left: .5rem;

        i {
            font-size: 30px;
            color: #FEA0BE;
        }
    }

    .user-static-information {
        display: flex;
        width: 100%;
        // height: 80px;

        .completed-task {
            background-color: #FFFAEE;
            border-radius: 10px;
            margin-right: 5px;

            i {
                color: #FECF51;
                font-size: 25px;
                font-weight: 600;
            }
        }

        .earning {
            background-color: #EDF0FF;
            border-radius: 10px;
            margin-right: 5px;

            i {
                color: #4D69FA;
                font-size: 25px;
                font-weight: 600;
            }
        }

        .view {
            background-color: #F0EFFB;
            border-radius: 10px;
            margin-right: 5px;

            i {
                color: #6C5DD3;
                font-size: 25px;
                font-weight: 600;
            }
        }

        .time {
            background-color: #EDF8F7;
            border-radius: 10px;
            margin-right: 5px;

            i {
                color: #44BCA9;
                font-size: 25px;
                font-weight: 600;
            }
        }

        .post_shared {
            background-color: #e3edde;
            border-radius: 10px;
            margin-right: 5px;

            i {
                color: #3d8361;
                font-size: 25px;
                font-weight: 600;
            }
        }

        .likes {
            background-color: #FFE6E8;
            border-radius: 10px;
            margin-right: 5px;

            i {
                color: #E04756;
                font-size: 25px;
                font-weight: 600;
            }
        }
        .unfollows{
            background-color: #feeee9!important;
            border-radius: 10px;
            i {
                color: #f35424;
                font-size: 25px;
                font-weight: 600;
            }
        }
        .red-stats{
            background-color: #fdeee9;
            border-radius: 10px;
            i{
                color: #f35321;
                font-size: 25px;
                font-weight: 600;
            }
          }
          .pink-stats{
            background-color: #ffdcdf;
            border-radius: 10px;
            margin-right: 5px;

            i {
                color: #E04756;
                font-size: 25px;
                font-weight: 600;
            }
          }
          .green-stats{
            background-color: #dfecd8;
            border-radius: 10px;
            margin-right: 5px;

            i {
                color: #3d8361;
                font-size: 25px;
                font-weight: 600;
            }
          }
          .blue-stats{
            background-color: #d1d8fc;
            border-radius: 10px;
            margin-right: 5px;

            i {
                color: #4D69FA;
                font-size: 25px;
                font-weight: 600;
            }
          }
          .orange-stats{
            background-color: #ffdace;
            border-radius: 10px;
            i{
                color: #f35321;
                font-size: 25px;
                font-weight: 600;
            }
          }
          .purple-stats{
            background-color: #e1deff;
            border-radius: 10px;
            margin-right: 5px;

            i {
                color: #6C5DD3;
                font-size: 25px;
                font-weight: 600;
            }
          }
          .yellow-stats{
            background-color: #f9e6bd;
            border-radius: 10px;
            margin-right: 5px;

            i {
                color: #FECF51;
                font-size: 25px;
                font-weight: 600;
            }
          }
    }
}

.user-profile-general {
    -moz-box-shadow: 0 15px 0px -5px rgba(168, 178, 233, 1) !important;
    -webkit-box-shadow: 0 15px 0px -5px rgba(168, 178, 233, 1) !important;
    box-shadow: 0 15px 0px -5px rgba(168, 178, 233, 1) !important;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    min-height: 10rem;
}


.profile {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    .profile-recent-activities {
        .timeline {
            ul,
            li {
                list-style: none;
                padding: 0;
            }

            li {
                padding-bottom: 0rem;
                position: relative;
                padding-left: 30px;
                margin-left: 10px;

                &:last-child {
                    border: 0px;
                    padding-bottom: 0;
                }
            }

            .timeline-dotted {
                width: 20px;
                height: 20px;
                border-radius: 50px !important;
                position: relative;
                right: 27px;
                top: 24px;

                .timeline-dotted-line {
                    background-color: #a7a7a7;
                    top: 22.5px;
                    left: 2.7px;
                    position: relative;
                    height: 32px;
                    width: 3px;
                    display: flex;
                    justify-content: center;
                    margin-left: 5px;

                }
            }

            .time {
                color: #2a2839;
                font-weight: 700;
                position: absolute;
                left: -95px;
                top: 17px;

            }

            h6 {
                color: #4f4f4f;
                line-height: 1;
                //  margin-top:0.4rem;
            }
        }
        @media (max-width: 770px) {
            .timeline{
                padding-left: 95px;
                padding-right: 0;
            }
            
        }
        @media (min-width: 770px) {
            .timeline{
                padding-left: 120px;
                padding-right: 20px;
            }
            
        }
    }

    .card-header {
        border: none !important;
        border-radius: 20px;
        background-color: white;
        padding-bottom: 0;
        margin-bottom: 0;
    }


    .recent-activity-card {

        min-height: 40rem !important;
        max-height: 40rem !important;
        border-radius: 20px !important;

        .card-header {
            border-bottom: none !important;
            background-color: #ffff;
            border-radius: 20px !important;

        }

        .timeline {
            padding-left: 110px;
            padding-right: 20px;


            ul,
            li {
                list-style: none;
                padding: 0;
                height: 60px;
            }

            li {
                padding-bottom: 0rem;
                position: relative;
                padding-left: 20px;
                margin-left: 10px;

                &:last-child {
                    border: 0px;
                    padding-bottom: 0;
                }
            }

            .timeline-dotted {
                width: 20px;
                height: 20px;
                border-radius: 50px !important;
                position: relative;
                right: 27px;
                top: 24px;

                .timeline-dotted-line {
                    background-color: #a7a7a7;
                    top: 22.5px;
                    left: 2.7px;
                    position: relative;
                    height: 32px;
                    width: 3px;
                    display: flex;
                    justify-content: center;
                    margin-left: 5px;

                }
            }

            .time {
                color: #2a2839;
                font-weight: 700;
                position: absolute;
                left: -100px;

            }

            h6 {
                color: #4f4f4f;
                line-height: 1.5;
                margin-top: 0.4rem;
            }
        }



    }

    .card {
        height: calc(100% - 1.5rem);
        max-height: 31rem !important;
        border-radius: 20px;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;

        // ::-webkit-scrollbar {
        //     width: 0em;
        //     height: 0em;
        //   }
        .card-body-scrollable {
            @media (min-width: 576px) {
                height: 10rem;
                overflow: auto;
            }

            @media (min-width: 1200px) {
                padding: 1.5rem;

            }

        }

        .card-body {
            color: var(--bs-card-color);
            flex: 1 1 auto;
            max-height: 40rem !important;
            overflow-y: scroll;
            margin-bottom: 2rem;


        }

    }
}

.user-details-card {
    border-radius: 20px;

    .card-header {
        border: none !important;
        border-radius: 20px;
        background-color: white;
        padding-bottom: 0;
        margin-bottom: 0;

        i {
            font-size: 25px !important;
        }
    }

    // .form-control {
    //     background-clip: padding-box !important;
    //     background-color: #f8f9fa !important;
    //     border: 1px solid #f8f9fa !important;
    //     border-radius: 1rem !important;
    //     box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important
    // }
    .form-control{
        margin-top: 1rem;
    }

    .btn {
        background-color: transparent;
        border: 1px solid #798EFD;
        border-radius: 10px;
        color: #798EFD;
        font-size: 12px;
        font-weight: 400;

        &:hover {
            background-color: rgba(121, 142, 253, 0.2);
            border: 1px solid #798EFD;
            border-radius: 10px;
            color: #798EFD;
        }
    }

}

.user-analiytics-social-media-tab {
    .tabs-bg {
        background-color: #dddddf !important;
        padding: .5rem;
        border-radius: 20px;
    }

    .nav-link {
        margin-left: 5px;
        margin-right: 5px;
        border-radius: 15px;
        color: black;
        background-color: transparent;
        border: 0;
        padding: .6rem;
        padding-left: 1rem;
        padding-right: 1rem;
        width: 130px;
        // &.active{
        //     background-color: black !important;
        //     color: white;
        // }

    }

    // .nav-link:hover{
    //     background-color: #414248 !important;
    //     border-color: #35373e !important;
    //     color: white !important;
    //   }

    .nav-link {
        &.active {
            border-bottom: none !important;
            color: white !important;
            background-color: black !important;

            &:hover {
                background-color: #414248 !important;
                border-color: #35373e !important;
            }
        }
    }
}

.user-card-header{
    border: 0;
    padding: 5px 0px 0px 5px;
    background-color: #fff;
    .form-check-input{
        width: 1rem !important;
        height: 1rem !important;
    }
}
.user-filter-dropdown{
    background-color: #000;
    border: 0;
    border-radius: 8px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    button{
        background-color: #000;
    }
    .user-filter-menu{
        background-color: #fff !important;
        color: #000;
        // width: max-content;
        width: 250px;
        // transform: translate3d(-320px, 25px, 0px) !important;
      
        .selected-option {
          background-color: #323643 !important;
          color: $white !important;
          border-radius: 10px;
        }
    }

}

.user-actions-dropdown{
    button{
    background: #000;
    border: 1px solid #000;
    padding: 6px 12px !important;
    font-size: 14px;
    font-weight: 500;
    }
    .user-actions-menu{
        background-color: #fff !important;
        color: #000;
        width: max-content;
      
        .selected-option {
          background-color: #323643 !important;
          color: $white !important;
          border-radius: 10px;
        }
       .menu-div-item{
            cursor: pointer;
            
            &:hover{
                background-color: #1a1c21;
                color: #fff;
                border-radius: 10px;
            }
        }
    }

}
.roles-tags{
    min-height: 230px;
    max-height: 230px;
    overflow-y: scroll;
    background-clip: padding-box !important;
    background-color: #f8f9fa !important;
    border: 1px solid #f8f9fa !important;
    border-radius: 10px !important;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
    color: #000 !important;
    padding: 10px 0px 10px 5px;
  }
  .email-card{
    .loading div {
        width: 3px !important;
        height: 3px !important;
        margin: 5px !important;
      }
  }
  .store-tag-container{
    em-emoji-picker{
        top: -24rem;
        right: 0;
    }

}