.navbar {
  // position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1000 !important;

  background-color: rgba(20, 21, 26, 0.7);
  backdrop-filter: blur(6px);
  padding: 0px 2%;
  border-radius: 8px !important;
  /* define flex */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
// @media screen and (max-width: 800px) and (min-width: 575px) {
//   .navbar{
//     justify-content: end;
//   }
  
// }
// @media screen and (min-width: 801px) {
//   .navbar{
//     justify-content: space-between;
//   }
  
// }
// @media screen and (max-width: 575px) {
//   .navbar{
//     justify-content: space-between;
//   }
  
// }

.logo-image {
  display: block;
  height: 48px
}

.nav-menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
  list-style-image: none;
}

// .menu-item {
//   display: inline-block;
//   margin-left: 10px;
// }
// .bar {
//   width: 20px;
//   height: 3px;
//   background-color: black;
//   margin: 4px auto;
// }
// .menu-icon {
//   display: none;
// }
@media only screen and (max-width: 600px) {
  .navbar {
    // flex-direction: column;
  }

  .logo-container {
    width: 100%;
  }

  .nav-menu {
    width: 100%;
  }

  .menu-item {
    display: block;
    width: 100%;
    padding: 5px 0;
    text-align: end;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 20px;
    right: 5%;
  }

  .menu-icon:hover .bar {
    background-color: darkcyan;
  }

  .nav-menu {
    display: none;
    width: 100%;
  }

  .active {
    display: block;
  }


}

.toolbar-search {
  @media (max-width: 600px) {
    width: 100% !important;
  }

  .form-search {

    width: 100% !important;
    background: transparent;
    padding: 18px 30px 18px 65px !important;
    border-radius: 6px;
    background-image: url("../../images/icon/search.svg");
    background-position: 24px 15px;
    background-repeat: no-repeat;
    border: none !important;

    @media (max-width: 600px) {
      width: 100% !important;
    }

    &::-webkit-input-placeholder {
      font-size: 16px;
      font-weight: 400;
      color: #B5AEE9;
    }

    &::-moz-placeholder {
      font-size: 18px;
      font-weight: 500;
      color: #B5AEE9;
    }

    &:-ms-input-placeholder {
      font-size: 18px;
      font-weight: 500;
      color: #B5AEE9;
    }

    &:-moz-placeholder {
      font-size: 18px;
      font-weight: 500;
      color: #B5AEE9;
    }

  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }
}






.navbar-nav {
  .nav-link {
    &.active {
      color: $secondary !important;
    }

    &:hover {
      color: $secondary !important;
    }
  }
}

.navbar-toggler {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}


.topbar-right {
  .topbar-icon-container {
    width: 40px;
    height: 40px;
    background-color: #1D1F25;
    border-radius: 50px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 22px;

    &:hover {
      background-color: #3a3e4a;
    }

  }

  .user-name {
    color: #ffff !important;
  }

  .btn-icon {
    width: auto !important;
    height: auto !important;
    background: transparent !important;
    border: 0 !important;
  }

  .notification-count {

    position: absolute;
    top: 2px;
    right: 2.5px;
    background-color: $orange;
    width: 16px;
    height: 16px;
    line-height: 12px;
    font-size: 10px;
    font-weight: 500;
    color: $white;
    text-align: center;
    padding-top: 2px;
  }

  .dropdown-toggle {
    &::after {
      display: none;
    }
  }

  .notifications-dropdown-menu {
    height: 500px;
    width: 400px;
    position: absolute;
    inset: 0px 0px auto auto;
    margin: 0px;
    transform: translate(0px, 38px);
    border-radius: 20px !important;
    background-color: #1F2128 !important;

    @media (max-width: 600px) {
      height: 420px;
      width: 350px;
    }

    .nav-link {
      margin-left: 0;
      margin-right: 15px;

      @media (max-width: 600px) {
        padding: 8px 25px;
        margin-right: 0;
      }
    }

    .tab-content {
      // overflow-y: scroll;
      height: 335px;

      @media (max-width: 600px) {
        height: 259px;
      }
    }
  }

  .user-dropdown-menu {
    width: 170px;
    position: absolute;
    inset: 0px 0px auto auto;
    margin: 0px;
    transform: translate(0px, 38px);
    padding: 1rem 0 1rem 0;
    border-radius: 20px !important;
    // background-color: #1D1F25 !important;
    background-color: #08070f !important;

    .user-dropdown-item:hover {
      // background-color: #BDBBE5;
      background-color: hsla(0, 0%, 100%, .15);
      color: $white;
      border-radius: 10px;


    }

    .user-dropdown-item {
      display: block;
      width: 100%; // For `<button>`s
      padding: $dropdown-item-padding-y $dropdown-item-padding-x;
      clear: both;
      font-weight: $font-weight-normal;
      color: $dropdown-link-color;
      text-align: inherit; // For `<button>`s
      text-decoration: if($link-decoration ==none, null, none);
      white-space: nowrap; // prevent links from randomly breaking onto new lines
      background-color: transparent; // For `<button>`s
      border: 0; // For `<button>`

  
    }
    .user-dropdown-item:focus{
      background-color: #323643 !important;
      color: $white !important;
      border-radius: 10px;
    }

    @media (max-width: 600px) {
      // height: 360px;
      width: 335px;
    }
  }


  .empty-dropdown-menu {
    height: 145px;
  }


}

@media (max-width: 1500px) {
  #topnav {
    padding: 18px 0px;
  }

  .navbar-brand {
    img {
      width: 240px;

    }
  }

  .navbar-nav {
    .nav-link {
      font-size: 16px !important;
      padding-right: 0.6rem !important;
      padding-left: 0.6rem !important;
    }
  }
}

@media (max-width: 1300px) {
  .navbar-brand {
    img {
      width: 220px;
      height: 74px
    }
  }

  .navbar-nav {
    .nav-link {
      font-size: 15px !important;
    }
  }
}

@media (min-width: 1200px) {
  .navbar-mobile {
    display: none !important;
  }
}

@media (max-width: 1199.95px) {
  .navbar-web {
    display: none !important;
  }

  .navbar-toggler {
    margin-left: auto;
  }
}

@media (max-width: 600px) {
  #topnav {
    padding: 10px 0px;
  }

  .navbar-brand {
    img {
      width: 180px;
      height: 60px;
    }
  }

  .topbar-right {
    // width: 100%;
    justify-content: space-around;
  }
}

.tour-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 22px;
  border: 0;
  border-radius: 20px !important;
  background-color: #1F2128 !important;

  &:hover {
    background-color: #3a3e4a !important;
  }

}
@media screen and (min-width: 900px) {
  .mobil-menu{
    display: none !important;
  }
}
.mobil-menu{
  // position: absolute;
  // left: 20px;
  // top: 22px;
  color: #fff;
}
.footer {
  position: sticky;
  position: -webkit-sticky !important;
  bottom: 0;
  top:140rem;
  z-index: 1000 !important;
  margin-top: 3rem;

  background-color: rgba(20, 21, 26, 0.7);
  backdrop-filter: blur(6px);
  padding: 1rem 0;
  border-radius: 8px !important;
  /* define flex */
  display: flex;
  justify-content:space-around;
  align-items: center;

 
  .footer-link{
    color: #f6f6f6;
    margin-inline: 1rem;
    &:hover{
      color:#e6e6e6 !important;
    }
  }

}