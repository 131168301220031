.terms-policy-tab {

    .nav-link {
      color: white !important;
      background-color: #323643 !important;
      border-radius: 20px;
      border: none !important;
      padding: 12px 30px 12px 30px !important;
      margin-left: 2px;
      margin-right: 8px;
      width: 170px !important;
      margin-top: 10px;
    }
  
    .nav-link:hover {
      background-color: #464850 !important;
      border-color: #35373e !important;
      color: white !important;
    }
  
    .nav-link {
      &.active {
        border-bottom: none !important;
        background-color: #e9ecef !important;
        color: black !important;
  
        &:hover {
          background-color: #414248 !important;
          border-color: #35373e !important;
          color: #e9ecef !important;
        }
      }
    }
  
    .social-media-filter {
      color: #6c5dd3;
      background-color: rgba(108, 93, 211, .3) !important;
      border-color: rgba(108, 93, 211, .1) !important;
      border-radius: 10px;
      width: calc(21px + 1rem);
      height: calc(21px + 1rem);
      position: relative;
      padding: 0.5rem 0;
  
      i {
        font-size: medium;
      }
  
      &:hover {
        color: #fff;
        background-color: rgba(108, 93, 211, .8) !important;
        border-color: rgba(108, 93, 211, .1) !important;
      }
    }
  

  }

  @media screen and (min-width: 730px){
    .terms-services-img{
      height: 400px;
      width: 400px;
    }
    .refund-img{
      height: 300px;
      width: 400px;
    }
    .about-img{
      height: 330px;
      width: 500px;
    }
  }
  @media screen and (max-width: 730px) {
    .terms-services-img{
      height: 300px;
      width: 300px;
    }
    .refund-img{
      height: 200px;
      width: 300px;
    }
    .about-img{
      height: 200px;
      width: 300px;
    }
  }