.dashboard {
  .dark-red-text {
    color: #f35321;
  }

  .dark-green-text {
    color: #42bca8;
  }

  .total-sales-info {
    color: #c7cce0 !important;
  }

  .darshboard-line {
    border-right: 1px solid white;
    height: 30px;
  }

  .total-sales-card {
    color: #000 !important;

    .card-header {
      border-bottom: 0 !important;
    }

    background-color: #564c33;
    border-radius: 20px !important;
    border: none !important;
    min-height: 14rem !important;
    max-height: 14rem !important;

    .dolar-icon {
      color: #FFCF53;
    }

  }

  .compaigns-card {
    .card-header {
      border-bottom: 0 !important;
    }

    color:#000 !important;
    background-color: #8e6273;
    border-radius: 20px !important;
    border: none !important;
    min-height: 14rem !important;

    .rocket-icon {
      color: #FFA2C0;
    }
  }

  .total-order-card {
    color: #000 !important;

    .card-header {
      border-bottom: 0 !important;
    }

    background-color: #3e396d;
    border-radius: 20px !important;
    border: none !important;
    height: 14rem !important;

    .order-icon {
      color: #6C5DD3;
    }
  }
  .net-revenue-card {
    color: #000 !important;

    .card-header {
      border-bottom: 0 !important;
    }

    background-color: #294849;
    border-radius: 20px !important;
    border: none !important;
    height: 14rem !important;

    i {
      color: #43b3a1;
    }
  }

  .top-product {
    .material-table__wrap {
      background-color: #242630 !important;
      color: black !important;

      .MuiTableRow-root {
        td {
          color: #e4e6eb !important;

        }

        td:first-child {
          border-radius: 20px 0 0 20px;
        }

        td:last-child {
          border-radius: 0 20px 20px 0;

        }

        .MuiTableRow-head {

          .MuiTableRow-head:first-child {
            border-radius: 20px 0 0 20px;
          }


          .MuiTableRow-head:last-child {
            border-radius: 0 20px 20px 0;
          }
        }
      }

      .MuiTableRow-footer {
        padding-top: 1rem !important;
      }
    }

    .MuiTableHead-root {
      tr {
        th {
          background-color: #2E2F39 !important;
        }

        th:first-child {
          border-radius: 20px 0 0 20px;
        }

        th:last-child {
          border-radius: 0 20px 20px 0;
        }
      }
    }
  }

  .leaderboard {
    .id-badge {
      background-color: #191b22;
      border-radius: 30px;
      padding-top: 8px;
      padding-bottom: 8px;
      color: white;
    }

    .leaderboard-card {
      height: 35.2rem;

      // .card-body {
      //   //max-height: calc(100% - 1.5rem);
      //   max-height: 30rem;
      //   overflow-y: scroll;

      // }
    }

    .MuiTableSortLabel-root {
      font-size: 14px !important;
    }

    .material-table__wrap {
      padding: 0 !important;
      background-color: transparent !important;

      .MuiTableHead-root {
        tr {
          padding-bottom: 5rem !important;

          th {
            background-color: #2E2F39 !important;
            padding-left: 10px;
            padding-right: 10px;
            border-bottom: 0 !important;
          }

          th:first-child {
            border-radius: 20px 0 0 20px !important;
            padding: 0 0 0 5px !important;
          }

          th:last-child {
            border-radius: 0 20px 20px 0;
          }
        }
      }

      .MuiTableBody-root {
        font-weight: 500;
        font-size: 16px;

        .MuiTableRow-root {
          &:hover{
            background-color: hsla(0,0%,100%,.075) !important;
          }
          td {
            color: #e4e6eb !important;

          }

          td:first-child {
            border-radius: 20px 0 0 20px !important;
          }

          td:last-child {
            border-radius: 0 20px 20px 0 !important;

          }
        }

        tr {
          // border: 1px solid gray !important;
          border-radius: 20px !important;

          td {
            // border: 2px solid gray !important;
            text-align: start !important;
            color: #e3e6ea !important;
          }

          td:first-child {
            border-radius: 20px 0 0 20px !important;
            margin-right: 0px !important;
            padding-right: 0px !important;
          }

          td:last-child {
            border-radius: 0 20px 20px 0;
            // border: 2px solid gray !important;
          }
        }

      }

      .MuiTableRow-footer {
        padding-top: 1rem !important;
      }

      // .MuiTableHead-root {
      //     .MuiTableCell-head {
      //         font-size: 15px !important;
      //     }

      //     tr {
      //         th {
      //             background-color: #D1CFEC !important;
      //             border-bottom: 0 !important;
      //         }

      //         th:first-child {
      //             border-radius: 20px 0 0 20px;
      //         }

      //         th:last-child {
      //             border-radius: 0 20px 20px 0;
      //             padding-right: 0px !important;
      //             margin-right: 0px !important;
      //         }
      //     }

      // }

    }
  }

}
@media screen and (min-width: 400px) {
  .date-div{
    margin-right: .5rem;
  }
}
@media screen and (max-width: 383px) {
  .date-div{
    // width: 100%;
    justify-content: end;
    margin-left: 1rem;
    // padding-right: 2rem;
  }
  
}

@media screen and (min-width: 900px) {
  .dashboard-tab {
    .nav-link {
      color: white !important;
      background-color: #323643 !important;
      border-radius: 8px;
      border: none !important;
      padding: 8px 10px !important;
      margin-left: 2px;
      margin-right: 8px;
      width: 110px !important;
    }

    .nav-link:hover {
      background-color: #464850 !important;
      border-color: #35373e !important;
      color: white !important;
    }

    .nav-link {
      &.active {
        border-bottom: none !important;
        background-color: #e9ecef !important;
        color: black !important;

        &:hover {
          background-color: #414248 !important;
          border-color: #35373e !important;
          color: #e9ecef !important;
        }
      }
    }
  }
  
}
@media screen and (max-width: 900px) {
  .dashboard-tab {
    .nav-link {
      color: white !important;
      background-color: #323643 !important;
      border-radius: 8px;
      border: none !important;
      padding: 10px 6px !important;
      margin-left: 2px;
      margin-right: 5px;
      width: 95px !important;
    }

    .nav-link:hover {
      background-color: #464850 !important;
      border-color: #35373e !important;
      color: white !important;
    }

    .nav-link {
      &.active {
        border-bottom: none !important;
        background-color: #e9ecef !important;
        color: black !important;

        &:hover {
          background-color: #414248 !important;
          border-color: #35373e !important;
          color: #e9ecef !important;
        }
      }
    }
  }
  
}

.fix-height {
  height: 32rem;
}

.top-customers {
  .id-badge {
    background-color: #edecf7;
    border-radius: 30px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #000;
  }

  .top-customers-card {
    height: 24.3rem;

    .card-body {
      max-height: 30rem;
      overflow: hidden;
      padding-bottom: 0;


    }

    .card-body:hover {
      overflow-y: scroll;

    }

    .MuiTableSortLabel-root {
      font-size: 14px !important;
    }

    .down_icon {
      // opacity: 0;
      position: relative;
      bottom: 10;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: #E1E9F350;

      &:hover {
        background-color: #dee2e6;
      }
    }

    // &:hover{
    //   .down_icon{
    //     opacity: 1;
    //   }
    // }

    .card-footer {
      background-color: transparent;
      border-top: 0;
      padding-bottom: 0.8rem;
      padding-top: 5px;
    }

    .MuiTableSortLabel-root {
      font-size: 14px !important;
    }
  }

  .material-table__wrap {
    padding: 0 !important;

    .MuiTableHead-root {

      tr {
        padding-bottom: 5rem !important;
        th:first-child {
          border-radius: 20px 0 0 20px;
        }

        th:last-child {
          border-radius: 0 20px 20px 0;
        }


        th{
          background-color: #2e2f39 !important;
        }
      }
    }

    .MuiTableRow-root {
      color: #fff !important;
      tr {
        border-radius: 20px !important;
      }

      td:first-child {
        border-radius: 20px 0 0 20px;
      }

      td:last-child {
        border-radius: 0 20px 20px 0 !important;

      }

      &:nth-child(even) {
        background-color: #2e2f39;
      }
    }

  }
}

.line-chart {
  margin-top: 1.5rem;
  border-radius: 20px;
}

// @media (max-width: 1048px) {
//   .dashboard-tab {
//     .tab-all-large {
//       display: none !important;
//     }
//   }
// }

// @media (min-width: 1048px) {
//   .tab-all-small {
//     display: none !important;
//   }

// }

.tab-all-small {
  .nav-link {
    color: white !important;
    background-color: #323643 !important;
    border-radius: 10px;
    border: none !important;
    padding: 10px !important;
    // margin-left: 2px;
    margin-right: 4px;
    width: 110px !important;
    margin-bottom: 5px;
  }

  .nav-link:hover {
    background-color: #464850 !important;
    border-color: #35373e !important;
    color: white !important;
  }

  .nav-link {
    &.active {
      border-bottom: none !important;
      background-color: #e9ecef !important;
      color: black !important;

      &:hover {
        background-color: #414248 !important;
        border-color: #35373e !important;
        color: #e9ecef !important;
      }
    }
  }
}

.overview-scrollable-table {
  .card-body {
    max-height: 470px;
    overflow-y: scroll;
  }
}

.dashboard-dropdown-menu {
  background-color: #08070f !important;
  border:  1px solid hsla(0,0%,100%,.15) !important;
  padding: .75rem;
  font-weight: 500;
  cursor: default;
  .dropdown-item{
    color: #dee2e6 !important;
    border-radius: 8px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    &:hover{
      background-color: hsla(0,0%,100%,.15) !important;
      color: $white !important;

    }
  }

  .selected-option {
    background-color: #323643 !important;
    color: $white !important;
    border-radius: 8px;
  }
}
.bar-chart-loader{
  span{
    display: flex;
    justify-content:end !important;
    width: 100%;
    span{
      min-width: 15px;
      // max-width: 150px;
    }
  }
}
@media screen and (min-width: 1300px) {
  .bar-chart-loader{
    span{
      span{
        margin-inline: 2rem;
      }
    }
  }
  
}
@media screen and (max-width: 1300px) {
  .bar-chart-loader{
    span{
      span{
        margin-inline: 0.8rem;
      }
    }
  }
  
}
.store-total-sales-card {
  color: #000 !important;

  .card-header {
    border-bottom: 0 !important;
  }

  background-color: #564c33;
  border-radius: 20px !important;
  border: none !important;

  .dolar-icon {
    color: #FFCF53;
  }

}
.table-body-scroll{
  .MuiPaper-root > div{
    max-height: 23rem !important;
    overflow-y: scroll !important;
  }
}
.top-product-table{
  .MuiTablePagination-selectRoot{
    display: none !important;
  }
}