.settings-tab.col-xl-4 {
    @media (min-width: 1200px) {
        flex: 0 0 auto;
        width: 27%;
    }

}

.settings-page.col-xl-8 {
    @media (min-width: 1200px) {
        flex: 0 0 auto;
        width: 73%;
    }

}

.settings-tab {
    // margin-top: 1rem;


    .card {
        border: none !important;
        border-radius: 8px;
        background-color: #FDFDFD;

        .card-body {
         

        }

        .card-header {
            background-color: #FCFCFC;
            border: none !important;
            margin-right: 1rem;
            margin-left: 1rem;

        }

        .settings-card-footer {
            padding: 20px 30px 0px 30px;

        }

        .user-delete-button {
            border-radius: 10px !important;
            background-color: transparent !important;
            border: 1px solid #F7825F !important;
            color: #F7825F;
            font-size: 14px;
            font-weight: 500;
            padding: 10px 0px 10px 0px;

            &:hover {
                background-color: #F7825F !important;
                border: none !important;
                color: #FEEEE9;
            }

        }
    }

    .tabs {
       

        overflow: auto;
        margin-top: 70px;

        .nav-tabs {
            width: 100%;
            overflow-x: auto;
            border-bottom: none !important;
            flex-wrap: wrap !important;
            display: block !important;

            li {
                .nav-link {
                    display: flex;
                    align-items: center;
                    background-color: #323643!important;
                    border: none !important;
                    height: 100%;
                    font-size: 12px;
                    font-weight: 500;
                    color: #fff;
                    text-align: start;
                    padding: 8px 45px;
                    margin-left: 15px;
                    margin-right: 15px;
                    margin-top: 14px;
                    transition: background-color 0.3s;
                    border-radius: 8px;
                    cursor: pointer;
                    padding-top: 0.9rem !important;
                    padding-bottom: 0.9rem !important;

                    &:hover{
                        background-color: #464850 !important;
                        border-color: #35373e !important;
                        color: white !important;
                    }

                    &:focus,
                    &:active {}

                    &.nav-link-sm {
                        padding: 8px 30px;
                    }

                    &.nav-link-xs {
                        padding: 8px 10px;
                    }
                }

                .nav-link.active,
                .nav-link.active:focus,
               

                &.disabled .nav-link {
                    color: #999999;
                }
            }
        }

        &.tabs--bordered-bottom {
            .nav-tabs {
                .nav-link {
                    border-color: transparent;
                    padding-bottom: 14px;
                }

                .nav-link.active {

                    &,
                    &:focus,
                    &:hover {
                        border-bottom: none !important;
                        background-color: #e9ecef!important;
                        color: #000 !important;

                    }
                }

            }
        }
    }

    .nav-link {
        border: 1px solid #33407C !important;
        background-color: transparent !important;

        &:hover {
            background-color: #33407C !important;

        }
    }
}


.settings-plans-card.card {
    background-color: #3F3C6E !important;
    min-height: 170px !important;
    border-radius: 20px;
    margin-left: 17px;
    margin-right: 17px;
    margin-top: 14px;
    box-shadow: 0 15px 2px -2px #323058;
    border-collapse: collapse;

    .card-body {
        overflow-y: hidden !important;
    }

}

.settings-members-card.card {
    background-color: rgba(136, 116, 66, 1) !important;
    height: 170px !important;
    border-radius: 20px;
    margin-left: 17px;
    margin-right: 17px;
    margin-top: 14px;
    margin-bottom: 14px;
    -moz-box-shadow: 0 15px 2px -5px rgb(68, 58, 33) !important;
    -webkit-box-shadow: 0 15px 2px -5px rgb(68, 58, 33) !important;
    box-shadow: 0 15px 2px -5px rgb(68, 58, 33) !important;
    border-collapse: collapse;

    .card-body {

        overflow-y: hidden !important;
    }

}

.change-information-wrapper {
    border: none !important;
    border-radius: 8px;
    background-color: #FDFDFD;

    .card-header {
        border: none !important;
        border-radius: 8px;
        background-color: #FDFDFD;
    }

    // .card-body {
    //     max-height: 37rem;
    //     overflow-y: scroll;
    // }

    
}
.change-personal-information-card {
    border: none !important;
    border-radius: 8px;
    // background-color: #ffff;

    .form-control {
        background-clip: padding-box !important;
        background-color: #2b2f38 !important;
        border: 1px solid #2b2f38 !important;
        border-radius: 8px !important;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
        color: #fff;
    }
    
    .form-control:not(:read-only):focus {
        border: 1px solid #60697d !important;
    }
    

    .change-password-footer {
        background-color: transparent !important;
        border: 1px solid #FFF5DC;
        border-radius: 20px;

        &:hover {
            background-color: #FFF5DC !important;
            border: 1px solid #FFF5DC;
        }

    }

    // .btn {
    //     background-color: transparent;
    //     border: 1px solid #798EFD;
    //     border-radius: 10px;
    //     color: #798EFD;
    //     font-size: 12px;
    //     font-weight: 400;

    //     &:hover {
    //         background-color: rgba(121, 142, 253, 0.2);
    //         border: 1px solid #798EFD;
    //         border-radius: 10px;
    //         color: #798EFD;
    //     }
    // }
}
.settings-avatar-upload {
    overflow: hidden;

    &:before {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        border-radius: 0;
        opacity: 0.6;
        transition: 0.5s ease;
        width: 100%;
        height: 40px;
        bottom: 0;
        left: 0;
        background-color: #5570F9;
        color: #cebfe9;
    }

    &:hover {
        &:before {
            font-size: 30px;
            height: 100%;
            background-color: #5570F9;
        }
    }

    .photo-upload {
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
    }
}

.plan-upgrade-container {
    .plan-upgrade-card.active {
        border-radius: 20px;
        border: none;
        border: 4px solid #116BF4;
    }

    .plan-upgrade-card {
        border-radius: 20px;
        border: none;

        .plan-upgrade-cardbody {
            margin-top: 30px;

            .upgrade-plan-title {
                display: flex;
                justify-content: center;

                .title-wrapper {
                    text-align: center;

                    .price {
                        font-size: 55px;
                        margin-top: 15px;
                    }

                    .feautes-list {
                        width: 100%;
                        margin-top: 20px;

                        ul {
                            padding-left: 0;
                            list-style-type: none;
                            font-size: 18px;
                            font-weight: 400;

                            li {
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }

        }

        .rocket-icon {
            font-size: 75px;
            color: #4C69FA;
            display: flex;
            justify-content: center;
        }

        .building-icon {
            font-size: 75px;
            color: #45BBA8;
            display: flex;
            justify-content: center;
        }

        .industry-icon {
            font-size: 75px;
            color: #ADA8D5;
            display: flex;
            justify-content: center;
        }

        .cookie-icon {
            font-size: 75px;
            color: #ADA8D5;
            display: flex;
            justify-content: center;
        }
    }
}

.plans-detail-container {
    .current-plan-wrapper {
        .card {
            border-radius: 20px;
            border: none;

            .card-header {
                background-color: #ffff;
                border-radius: 20px 20px 0px 0px;

            }

            .current-plan-detail {
                border-top: 3px dotted $muted;
                margin-top: 30px;
            }
        }

    }
}
.current-plan-times {
    color: $red;
}

input[type=file] {
    width: 350px;
    max-width: 100%;
    color: #fff;
    padding: 0px;
    border: none;
    font-size: 12px;
    font-weight: 600;
    background-clip: padding-box !important;
    background-color: #2b2f38 !important;
    border: 1px solid #2b2f38 !important;
    border-radius: 20px !important;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
    display: block;
  }
  input[type=file]::file-selector-button {
    margin-right: 20px;
    border: none;
    background: #6c757d;
    padding: 10px 10px;
    border-radius: 20px;
    color: #fff;
    cursor: pointer;
    transition: background .2s ease-in-out;
    border-radius: 20px 0px 0px 20px;
  }
  
    .personal-information-card {
    border: none !important;
    border-radius: 30px;
    background-color: #242731 !important;
    height: 45rem !important;
    .card-header{
        border: none !important;
        border-radius: 30px;
        background-color: #242731 !important;
    }
    .card-body{
        max-height: 37rem;
        overflow-y: scroll;
    }
        .form-control {
            background-clip: padding-box !important;
            background-color: #212529 !important;
            border: 1px solid #343a40 !important;
            border-radius: 1rem !important;
            box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
            color: #FFCF53;
        }
       .change-password-footer {
        background-color: #FFF5DC !important;
        border-radius: 20px;
       }
       .btn {
        background-color: transparent;
        border: 1px solid #798EFD;
        border-radius: 10px;
        color: #798EFD;
        font-size: 12px;
        font-weight: 400;
        &:hover {
            background-color: rgba(121,142,253, 0.2);
            border: 1px solid #798EFD;
            border-radius: 10px;
            color: #798EFD;
        }
       }
  
}

.wallet-card {
    border: none !important;
    border-radius: 30px;
    background-color: #242731 !important;
    height: 45rem !important;
    .card-header{
        border: none !important;
        border-radius: 30px;
        background-color: #242731 !important;
    }
       .btn-add {
        background-color: rgba(77,105,250,.3);
        border: 1px solid rgba(77,105,250,.1);
        border-radius: 15px;
        color: #4d69fa;
        font-size: 12px;
        font-weight: 600;
        &:hover {
            background-color: rgba(77,105,250,.8);
            border: 1px solid rgba(77,105,250,.1);
            border-radius: 15px;
            color: #fff;
        }
       }
       .btn-all{
        background-color: #1F2128 !important;
        border-radius: 20px;
        padding: 1rem;

        .wallet-btn-active{
            background-color: black !important;
            color: #E7EEF8 !important;
        }
        
        .btn-card{
            margin-top: 10px;
            background-color: transparent;
            border-radius: 15px;
            color: #1F2128;
            font-size: 0.9rem !important;
            border: 0;
            padding-top: .4rem;
            padding-bottom: .4rem;
            &:active{
                background-color: #1F2128 !important;
                color: #E7EEF8;
                margin-top: 10px;
            }
            &:hover{
                background-color: black ;
                color: #E7EEF8;
            }
        }
       }


}
.email-notifications-card {
    border: none !important;
    border-radius: 30px;
    background-color: #FDFDFD;
    height: auto !important;
    .card-header{
        border: none !important;
        border-radius: 30px;
        background-color: #FDFDFD;
    }
    .btn {
        background-color: transparent;
        border: 1px solid #798EFD;
        border-radius: 10px;
        color: #798EFD;
        font-size: 12px;
        font-weight: 400;

        &:hover {
            background-color: rgba(121, 142, 253, 0.2);
            border: 1px solid #798EFD;
            border-radius: 10px;
            color: #798EFD;
        }
    }


    
}

.modal-delete-credit-card{
    border-radius: 10px !important;
    background-color: transparent !important;
    border: 1px solid #F7825F !important;
    color: #F7825F;
    font-size: 14px;
    font-weight: 500;
    width: 120px;

    &:hover {
        background-color: #F7825F !important;
        border: none !important;
        color: #FEEEE9;
    }
}
.notifications-card{
    border: none !important;
    border-radius: 30px;
    background-color: #FDFDFD;
    height: auto !important;
    padding-bottom: 2rem;
    .card-header{
        border-radius: 20px !important;
    }

}
.change-password-card {
    border: none !important;
    border-radius: 30px !important;

    .card-header {
        border: none !important;
        border-radius: 30px;
        background-color: #242731 !important;
    }

    .form-control {
        background-clip: padding-box !important;
        background-color: #383838 !important;
        border: 1px solid #383838 !important;
        border-radius: 1rem !important;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
        color: #FFCF53;
    }

    .change-password-footer {
        background-color: #625535 !important;
        border-radius: 20px;
        span{
        color: #f2d68f !important;
            
        }
        i{
        color: #f2d68f !important;

        }

    }

    .btn {
        background-color: transparent;
        border: 1px solid #798EFD;
        border-radius: 10px;
        color: #798EFD;
        font-size: 12px;
        font-weight: 400;

        &:hover {
            background-color: rgba(121, 142, 253, 0.2);
            border: 1px solid #798EFD;
            border-radius: 10px;
            color: #798EFD;
        }
    }
}
.react-emoji{
    background-clip: padding-box !important;
    background-color: #2b2f38 !important;
    border: 1px solid #2b2f38 !important;
    border-radius: 8px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
    font-size: 6px;
    font-weight: 400;
    padding: 0 !important;
    margin-top: 1rem;
    height: 45px;
    .react-input-emoji--container{
        background-color: #2b2f38 !important;
        border: none !important;
        color: #fff !important;

        .react-input-emoji--placeholder{
            font-size: 14px;
            font-weight: 400;
            color: #fff !important;
            font-family: 'Poppins', sans-serif;
        }
    }
    
}
@media screen and (max-width: 710px) {
    .inputfile{
        width: 250px !important;
    }
}
@media screen and (min-width: 710px) {
    .inputfile{
        width: 350px !important;
    }
}
.settings-textarea{
    textarea{
    background-clip: padding-box !important;
  background-color: #2b2f38 !important;
  border: 1px solid #2b2f38 !important;
  border-radius: 8px !important;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
  color: #fff;
        padding: 10px !important;
    }
    textarea::placeholder{
        font-size: 14px;
        font-weight: 400;
        padding-left: 3px;
        color: #a1a5b4 ;
    }
}