//Filter Comp

.filter-dropdown-menu {
    min-height: 300px;
    width: 350px;
    position: absolute;
    background-color: #ffff;
    inset: 0px 0px auto auto;
    margin: 0px;
    transform: translate(0px, 38px);
    border-radius: 20px !important;
   
  }
  
  // Loader
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(45deg, #1f2127, #1f2127);
  z-index: 9999999;
  #status {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    .spinner {
      width: 40px;
      height: 40px;
      position: relative;
      margin: 100px auto;
      .double-bounce1,
      .double-bounce2 {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: $primary;
        opacity: 0.6;
        position: absolute;
        top: 0;
        left: 0;
        animation: sk-bounce 2s infinite ease-in-out;
      }
      .double-bounce2 {
        animation-delay: -1s;
      }
    }
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

//Theme Mode Switch
.toggle-theme-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.toggle-theme-wrapper span {
  font-size: 28px;
}

.toggle-theme {
  position: relative;
  display: inline-block;
  height: 24px;
  width: 40px;
}

.toggle-theme input {
  display: none;
}

.slider {
  background-color: #ccc;
  position: absolute;
  cursor: pointer;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition: 0.2s;
}

.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 16px;
  left: -5px;
  position: absolute;
  transition: 0.4s;
  width: 16px;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

input:checked + .slider {
  background-color: rgb(64, 63, 63);
}

.slider.round {
  border-radius: 8px;
}

.slider.round:before {
  border-radius: 8px;
}
//Switch 
.form-switch {
  padding-left: 0 !important;
  input[type="checkbox"] {
    & {
      width: 54px;
      height: 24px;
      padding: 0;
      position: relative;
      background-color: #CECFD2;
      border-radius: 20px;
      outline: none;
      cursor: pointer;
      -webkit-appearance: none;


      &:before {
        content: " ";
        position: absolute;
        z-index: 2;
        height: 17px;
        width: 17px;
        top: 3.5px;
        left: 3px;
        border-radius: 20px;
        background-color: #ffff;
      }

      &:checked {
        background: #6C5DD3;

        &:before {
          content: "";
          left: auto;
          right: 3px;

        }
      }
    }
  }
}
.btn-icon-table {
  width: 34px;
  height: 34px;
  font-size: 18px;
  border-radius: 4px;
  background-color: transparent;
  color: #A1ABA7 !important;
  transition: all 0.4s;
  overflow: hidden;
  z-index: 0;
  border: 0;
  justify-content: center;


  &:hover, &:focus, &:active, &:active:focus {
    color: #ffffff !important;
  }
}
@media screen and (max-width: 767px) {
  .filter-date-picker{

    .input-group{
      input{
        display: none !important;
      }
    }
    .date-range-toggle{
      // position: absolute;
      // left: 7rem;
      border-radius: 10px;
      padding: 4px 8px;
      font-size: 14px;
      z-index: 990;
      background-color: #f3f3f4;
      color: #000;
      width: 33px;
      height: 33px;

    }
  }
  
}
@media screen and (min-width: 768px) {
  .filter-date-picker{
    .input-group{
      input{
        font-size: 13px !important;
        // text-align: center;
      }
    }
    .date-range-toggle{
      position: absolute;
      left: 11.4rem;
      border-radius: 10px;
      padding: 2px 6px 2px 6px;
      font-size: 14px;
      margin-top: 6px;
      z-index: 990;
      background-color: #f3f3f4;
      color: #000;
    }
  }
  
}
  //Filter Date
.filter-date-picker {
  display: flex;
  align-items: center;


  .react-select css-b62m3t-container{
    background-color: transparent !important;
    &:hover {
      background-color: transparent !important;
    }
  }
  ::placeholder {
    color: #45BBA8;
    opacity: 1; 
  }
  
  :-ms-input-placeholder { 
   color: #45BBA8;
  }
  
  ::-ms-input-placeholder { 
   color: #45BBA8;
  }
  .form-control {
    padding: 10px 15px 10px 10px !important;
    // border: 1px solid #17191e !important;
    border: 1px solid #000 !important;
    border-radius: 10px !important;
    font-size: 14px;
    // background-color: #17191e !important;
    background-color: #000 !important;
    color: #fff ;
    font-weight: 500;
    cursor: pointer;
    width: 210px;
    // padding-left: 11px !important;
   
  }
  .react-select__menu.css-1nmdiq5-menu{
    width: 200px !important;
    border-radius: 20px !important;
    z-index: 9999 !important;
    position: absolute;
    left: -147px;
    top: 1.8rem;
    .react-select__option {
      border-radius: 20px !important;
      &:focus,&:hover,&:active,&::before,&::after {
        background-color: #ECF7F6 !important;
      
      }
    }
    .react-select__option--is-selected{
      background-color: #ECF7F6 !important;
      color: rgba(0, 0, 0, 1) !important;
      font-weight: 500;
      font-size: 16px;
    }
  }
  .react-select__control {
    border: 1px solid transparent !important;
    background-color: transparent !important;
    border-radius: 20px !important;
    position: relative;
    bottom: 10px;
    right: 36px;
    .react-select__indicator-separator{
      display: none !important;
    }
    &:hover{
      background-color: #45BBA8 !important;
      border-radius: 0px 20px 20px 0px !important;

    }
    .react-select__value-container {
      border: none !important;
      display: none !important;
    
     
    }
    .react-select__value-container--has-value{
      .react-select__indicators.css-1hb7zxy-IndicatorsContainer{
        position: relative !important;
        left: 50px !important;
      }
    }
  
    &:focus,&:hover,&:active,&::before,&::after {
      border: none !important;
    }
  }
  .react-select__control--is-focused {
    border: none !important;
  }
}

//Accordion
.accordion {
  padding: 15px 0px 15px 0;
  background-color: #17191e !important;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  color: #fff;
  &.active {
    border: 2px solid #007bff

  }
  .accordion-arrow {
    display: flex !important;
    padding: 0px 30px 0px 0px;
    align-items: center;
    justify-content: space-between;
  }
}
// Button Loading
.loading {
  background-color: rgba(255, 0, 0, 0);
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  bottom: 17px;
}

.loading div {
  background-color: rgb(175, 175, 175);
  width: 4px;
  height: 4px;
  margin: 10px;
  border-radius: 50px;
  animation-name: load912355;
  animation-duration: .5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  text-align: center;
  justify-content: center;
}

.loading .a {
  animation-delay: .2s;
}

.loading .u {
  animation-delay: .3s;
}

@keyframes load912355 {
  0% {
    transform: translateY(25px) scale(1);
  }

  100% {
    transform: translateY(10px) scale(1.8) rotate(360deg);
  }
}
