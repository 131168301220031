.daterangepicker{
    border-radius: 20px !important;
    border: none !important;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    background-color: #08070f;
    color: white;
    .ranges {
        ul{
            text-align: center;
            li.active{
                background-color: #1f2126 !important;
                color: #fff;
                border-radius: 8px !important;
                
            }
            li{
                &:hover{
                    border-radius: 8px !important;
                    background-color: #1f2126;
                }
            }
            
        }
    }
    .daterangepicker.opensright {
        &::after,&::before,&:active{
            display: none !important;

        }
        display: none !important;
    }
   
        
    
    .calendar-table {
        background-color: transparent;
        border: none;
        table{
            width: 85% !important;
        }
        td{
            // width: 100px !important;
           &:hover{
            background-color: #1f2126 !important;

           }
        }
        td.in-range{
            background-color:#1f2126 ;
            color: #fff;
        }
        td.active{
            background-color: #e4e4e4;
            color: #000;
        }
        .off.ends.active{
            cursor: default;
            &:hover{
                background-color: #000 !important;
            }
        }
        .off{
            background-color: transparent !important;
            color: gray;
        }
        .next, .prev{
            span{
                filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(59deg) brightness(106%) contrast(101%);
            }
            &:hover{
                background-color: #1f2126 !important;
    
               }
        }
    }
    .applyBtn{
        background-color: #e4e4e4 !important;
        border-color: #e4e4e4 !important;
        color: #000 !important;
    }
    .cancelBtn{
        background-color: transparent;
        border-color: #e4e4e4;
        color: #e4e4e4 !important;

    }
    .monthselect{
        border: none !important;
        background-color: #1f2126;
    }
    .yearselect {
        border: none !important;
    }
}
.react-datepicker-popper{
    z-index: 1000;
}
.react-datepicker__input-container{
    input{
        border-radius: 20px !important;
        border: none !important;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
        background-color: #08070f;
        color: white;
        // padding: .6rem 2rem .6rem 1rem;
        padding: .6rem 1rem;
    }

}
.date-time-toggle{
    position: absolute;
    left: 29.2rem;
    border-radius: 50%;
    padding: 2px 6px 2px 6px;
    font-size: 14px;
    margin-top: 7px;
    z-index: 990;
    background-color: #000;
  }

  .react-datepicker{
    border-radius: 20px;
    border: none;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    padding: .7rem 1rem 0rem 1rem;
    background-color: #08070f;
    .react-datepicker__header {
        background: transparent;
        border-bottom: 2px solid #f3f3f3;
        .react-datepicker__current-month{
            color: #fff;
        }
        .react-datepicker__day-name{
            font-size: 12px;
            color: #fff;

        }
    }
    .react-datepicker__day--selected{
        background-color: #6a40bb !important;
        color: #fff;
    }
    .react-datepicker-time__input {
        border-radius: 8px;
        padding: .3rem .5rem;
        input {
           filter: invert(100%) sepia(5%) saturate(1%) hue-rotate(27deg) brightness(105%) contrast(100%);
           border: 2px inherit;
        }
    }
    
    .react-datepicker__day{
        font-size: 13px;
        color: #fff;

    }
    .react-datepicker__day:hover{
        background-color: #1f2126 !important;
    }
    .react-datepicker__input-time-container{
        display: flex;
        align-items: center;
        justify-content: center;
        .react-datepicker-time__caption{
            color: #fff;
        }
    }

  }
  .ant-picker-panel-container{
    background-color: #000 !important;
  }
  .ant-picker-time-panel-cell-inner{
    color: #fff !important;
  }
  .ant-picker-time-panel-cell:hover{
    background-color: #1f2126 !important;
  }
  .ant-picker-time-panel-cell-selected{
    .ant-picker-time-panel-cell-inner{
        background-color: #1f2126 !important;
    }
    
  }
  .ant-btn-primary:disabled{
    background-color: #1677ff !important;
    color: #fff;
    border: 1px solid #1677ff;
  }
  .ant-picker-time-panel-column::-webkit-scrollbar-thumb {
    background-color: #323643 ;
    border-radius: 6px;
    border: 3px solid #323643;
  }
  .ant-picker-cell-in-view, .ant-picker-header, .ant-picker-header>button, .ant-picker-content th{
    color: #fff !important;
  }
  .ant-picker-header{
    border-bottom: 1px solid #323643;
  }
 .ant-picker-cell{
    color: #323643;
 }
 .ant-picker-cell-inner:hover{
    background-color: #1f2126 !important;
 }
 .daterangepicker {
    .monthselect, .yearselect{
        width: auto;
        background-color: #1f2126;
        color: #fff;
        border: 1px solid #292c32 !important;
        border-radius: 5px;
     }
 }