.social-media {
  border-radius: 20px;
  background-color: #242731;
  height: calc(100% - 0.5rem);
  border: 0;
  // min-height: 230px;
  // width: 23rem;


  .social-media-header {
    border-radius: 20px 20px 0px 0px;
    background-color: #242731 !important;
    border-bottom: 0;

    .instagram-info-btn {
      color: #fff;
      background-color: rgba(0, 0, 0, .3);
      border-color: rgba(31, 33, 40, .1);
      padding: 8px 11px 8px 11px;
      border-radius: 15px;
      transition: .3s;

      &:hover {
        color: #fff;
        background-color: rgba(31, 33, 40, .8);
        border-color: rgba(31, 33, 40, .1);
      }
    }
  }

  .social-media-body {
    .div-bio {
      min-height: 50px;
    }

    .bio {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
      overflow: hidden;
      font-size: 14px;
      font-weight: 500;

    }
  }

  .card-footer {
    background: transparent;
  }
}

.add-card {
  height: 100%;
  border-radius: 20px;
  border: none !important;

  .card-body {
    min-height: 200px !important;
  }

  .add-card-body {
    min-height: 310px !important;
    background-color: #4C4B50;

  }

  .add-icon {
    cursor: pointer;
  }

  .btn-instagram-add {
    border-radius: 20px;
    color: #bebebe;
    border-color: #4c4b50;
    background-color: #4c4b50;
    min-height: 150px;

    &:hover {
      border-radius: 20px;
      color: #fff;
      background: #d6249f;
      background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
    }
  }

  .btn-facebook-add {
    border-radius: 20px;
    color: #bebebe;
    border-color: #4c4b50;
    background-color: #4c4b50;

    &:hover {
      border-color: #33407C;
      background-color: #33407C;
      color: #fff;
    }
  }

  .btn-youtube-add {
    border-radius: 20px;
    color: #bebebe;
    border-color: #4c4b50;
    background-color: #4c4b50;

    &:hover {
      border-color: #FF0000;
      background-color: #FF0000;
      color: #fff;
    }
  }

  .btn-tiktok-add {
    border-radius: 20px;
    color: #bebebe;
    border-color: #4c4b50;
    background-color: #4c4b50;

    &:hover {
      border-color: #000;
      background-color: #000;
      color: #fff;
    }
  }

  .btn-shopify-add {
    border-radius: 20px;
    color: #bebebe;
    border-color: #4c4b50;
    background-color: #4c4b50;

    &:hover {
      border-color: #96bf48;
      background-color: #96bf48;
      color: #fff;
    }
  }

  .btn-woocommerce-add {
    border-radius: 20px;
    color: #bebebe;
    border-color: #4c4b50;
    background-color: #4c4b50;

    &:hover {
      border-color: #875DB8;
      background-color: #875DB8;
      color: #fff;
    }
  }

  .btn-bigcommerce-add {
    border-radius: 20px;
    color: #bebebe;
    border-color: #4c4b50;
    background-color: #4c4b50;

    &:hover {
      border-color: #F2F2F2;
      background-color: #F2F2F2;
      color: #fff;
    }
  }

  .btn-custom-add {
    border-radius: 20px;
    color: #bebebe;
    border-color: #4c4b50;
    background-color: #4c4b50;

    &:hover {
      border-color: #000000;
      background-color: #000000;
      color: #fff;
    }
  }

  .btn-twitter-add {
    border-radius: 20px;
    color: #bebebe;
    border-color: #4c4b50;
    background-color: #4c4b50;

    &:hover {
      border-color: #1aa1f1;
      background-color: #1aa1f1;
      color: #fff;
    }
  }
}

.social_media_platform {
  border: 3px solid #366B69;
  background-color: #728f8e;
  border-radius: 15px;
  color: rgb(70, 188, 170);

}

.nav-social-medias {
  position: relative;
  background-color: #0f1014;
  border-radius: 20px 20px 20px 20px !important;
  top: 40px;

  margin-top: 0rem !important;

  .accordion-arrow {

    .social-accordion-title {
      color: white !important;
      font-weight: 600 !important;
      font-size: 26px !important;
      padding-left: 2.5rem !important;

    }

    i {
      color: #35373e;
    }
  }

  .social-media-label {
    width: 100%;
    border-radius: 20px 20px 20px 20px !important;
    background-color: transparent !important;
    border: none;
  }

  i {
    color: #fff !important
  }
}



.instagram-profile-tab {
  margin-bottom: 2rem;

  .nav-link {
    color: white !important;
    background-color: #323643 !important;
    border-radius: 10px;
    border: none !important;
    padding: 8px 10px;
    margin-left: 2px;
    margin-right: 8px;
    width: 142px !important;
    margin-top: 15px !important;
  }

  .nav-link:hover {
    background-color: #414248 !important;
    border-color: #35373e !important;
    color: white !important;
  }

  .nav-link {
    &.active {
      background-color: #e9ecef !important;
      color: black !important;
      border-bottom: none !important;
      .saved-filter-x{
        color: #000;
        &:hover{
          color: #f35321;
        }
      }

      &:hover {
        background-color: #414248 !important;
        border-color: #35373e !important;
      }
    }
  }

}

.new_insta {
  background-color: #242731;
  color: #e6eef8;
  border-radius: 20px;

  .list-items {
    border-radius: 20px;

    &:nth-child(even) {
      background-color: #2e2f39;
      padding: 1rem;
    }

    &:nth-child(odd) {
      padding: 1rem;
    }
  }
}

.redirect-btn {
  background-color: rgba(77, 105, 250, .3);
  border-radius: 15px;
  border-color: rgba(77, 105, 250, .1);
  color: #4d69fa;

  &:hover {
    background-color: rgba(77, 105, 250, .8);
    border-color: rgba(77, 105, 250, .1);
    border-radius: 15px;
    color: #fff;
  }
}

.social-card-badge {
  width: 80px;
}

.exist-badge {
  background-color: #542e26;
  color: #e34f21;
  border-radius: 20px;
}

.update-badge {
  background-color: #294849;
  color: #44bca9;
  border-radius: 20px;
}

.add-badge {
  background-color: #2b335d;
  color: #4b67f3;
  border-radius: 20px;
}

.exist-icon {
  border-radius: 10px;
  color: #f35421;
  background-color: rgba(243, 84, 33, .3);
  border-color: rgba(243, 84, 33, .1);
  // padding: 6px 10px;
  width: 28px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  i {
    font-size: 16px;
  }

  // &:hover{
  //     color: #fff;
  //     background-color: #f35421;
  //     border-color: #f35421;
  // }
}

.added-icon {
  border-radius: 10px;
  color: #4d69fa;
  background-color: rgba(77, 105, 250, .3);
  border-color: rgba(77, 105, 250, .1);
  // padding: .4rem;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  i {
    font-size: 16px;
  }
}

.updated-icon {
  border-radius: 10px;
  color: #46bcaa;
  background-color: rgba(70, 188, 170, .3);
  border-color: rgba(70, 188, 170, .1);
  // padding: 6px 10px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  i {
    font-size: 16px;
  }
}

@media screen and (min-width: 1333px) {
  .social-media-list-avatar {
    width: 100px !important;
    height: 100px !important;
    min-width: 100px;
    min-height: 100px;
    max-width: 100px;
    max-height: 100px;
  }
}

@media screen and (max-width: 1333px) {
  .social-media-list-avatar {
    width: 80px !important;
    height: 80px !important;
    min-width: 80px;
    min-height: 80px;
    max-width: 80px;
    max-height: 80px;
  }
}

@media screen and (max-width: 432px) {
  .date-mobile {
    margin-top: 1rem;
  }
}

.social-media-profile {
  .recent-activity-card {

    min-height: 34rem !important;
    max-height: 34rem !important;
    border-radius: 20px !important;


    .card-header {
      border-bottom: none !important;
      background-color: transparent;
      border-radius: 20px !important;

    }

    .card-body {
      overflow-y: scroll;
    }

    .timeline {
      padding-left: 110px;


      ul,
      li {
        list-style: none;
        padding: 0;
        height: 60px;
      }

      li {
        padding-bottom: 0rem;
        position: relative;
        padding-left: 0px;
        margin-left: 10px;

        &:last-child {
          border: 0px;
          padding-bottom: 0;
        }
      }

      .timeline-dotted {
        width: 20px;
        height: 20px;
        border-radius: 50px !important;
        position: relative;
        right: 26px;
        top: 24px;

        .timeline-dotted-line {
          background-color: #a7a7a7;
          top: 20px;
          left: 2.7px;
          position: relative;
          min-height: 46px;
          width: 3px;
          display: flex;
          justify-content: center;
          margin-left: 5px;

        }
      }

      .time {
        color: #2a2839;
        font-weight: 700;
        position: absolute;
        left: -110px;
        margin-top: 1rem;

      }

      h6 {
        color: #4f4f4f;
        line-height: 1.3;
        // margin-top: 0.4rem;
      }
    }



  }

}

.view-details-btn {
  background-color: #1c1d21;
  border: 1px solid #1c1d21;
  font-size: 12px;
  font-weight: 500;

  &:hover {
    background-color: #000;
    border: 1px solid #000;
  }
}

.green-btn {
  background-color: #286d33;
  border: 1px solid #286d33 !important;
  font-size: 12px;
  font-weight: 500;
  cursor: default;

  &:hover {
    background-color: #286d33;
    border: 1px solid #286d33;
  }

  // &:hover{
  //     background-color: #379544;
  //     border: 1px solid #379544;
  // }
}

.delete-icon-btn {
  color: #e3e6ea;

  &:hover {
    color: #c30010;
  }
}

.red-btn {
  background-color: #80182c;
  border: 1px solid #80182c;
  font-size: 12px;
  font-weight: 500;

  &:hover {
    background-color: #8f1b31;
    border: 1px solid #8f1b31;
  }
}

@media screen and (min-width: 717px) {
  .card-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    grid-gap: 1rem;
  }
}

@media screen and (max-width: 717px) {
  .card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 1rem;

    .social-media,
    .add-card {
      width: 20rem;
    }
  }
}
.instagram-icon{

&:hover{
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  transition: all 0.4s;
  border-color: #1f2126;
  // border-color: #d6249f50;
  // -webkit-background-clip: text;
  //         background-clip: text;
  // -webkit-text-fill-color: transparent;

  // background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  // background-clip: text;
  // border-radius: 20%;
  // transform: scale(1.5);
  // color:transparent;

}
}

.border-icon{
// padding: 12px 15px;
width: 70px;
height: 70px;
display: flex;
justify-content: center;
align-items: center;
font-size: 30px;
color: #e4e6eb;
border-radius: 50px;
border: 2.5px solid;
border-color: #e4e6eb;
&:hover{
  color: #fff;
}

}
.youtube-icon{
  padding: 12px 12px;
  &:hover{
    background-color: #FF0000;
    border-color: #1f2126;
    transition: all 0.4s;
  }
}
.tiktok-icon{
  &:hover{
    background-color: #000;
    border-color: #1f2126;
    transition: all 0.4s;
  }
}
.twitter-icon{
  &:hover{
    background-color: #1DA1F2;
    border-color: #1f2126;
    transition: all 0.4s;
  }
}
.shopify-icon{
  &:hover{
    background-color: #96bf48;
    border-color: #1f2126;
    transition: all 0.4s;
  }
}
.bigcommerce-icon{
  img{
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(159deg) brightness(20) contrast(101%);

  }
  &:hover{
    background-color: #34323e;
    border-color: #1f2126;
    transition: all 0.4s;
  }
}
.woocommerce-icon{
  &:hover{
    background-color: #995d8d;
    border-color: #1f2126;
    transition: all 0.4s;
  }
}
.back-to-page{
  background-color: transparent;
  color: #5b5d6f;
  font-size: 12px;
  padding: 5px 10px !important;
  &:hover{
    color: #fff;
  }
}
