.fc-toolbar-title{
    color: #000 ;
}
.calendar-day{
    color: #3e3e3e;
}
.calendar-day-name{
    color: #7f7f7f;
}
.calendar-day.active{
    background-color: #3176dc;
    // padding: 5px 10px;
    border-radius: 50rem;
    width: fit-content;
    color: #fff;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.border-line{
    border-bottom: 1.6px solid #93939342;
}
.upload-button{
    border: none;
    outline: none;
    padding: 10px 30px;
    border-radius: 8px;
    background: #fff;
    color: #000;
    box-shadow: 0px 1px 6px 2px #00000030;
}
.fc-timeGridWeek-view .fc-day-today:not(.fc-col-header-cell)  {
    background-color: #dddddd; 
}
.fc-timeGridDay-view{
    .fc-timegrid-slot.fc-timegrid-slot-lane{
        background-color: #dddddd !important;
    }
}
.fc-daygrid-day.fc-day-today{
    background-color: #dddddd !important; 

}
// .fc-scrollgrid-sync-inner{
//     background-color: #f2f2f2;
// }
fc-theme-standard td, .fc-theme-standard th {
    border-bottom: 0.2px solid #c2c1c1;
    border-right: 0.2px solid #e2dfdf;
}
.calendar-post-type{
    border: 2px solid #e8e8e8;
    border-radius: 8px;
    padding: 5px 10px;
}
.calendar-close-btn{
    background: #ffffff;
    color: #000 !important;
    box-shadow: 2px 1px 6px 1px #0000000f;
    &:hover{
        color: #fff !important;
    }
}
.upload-area{
    background: #fafafa;
    padding: 30px 40px;
    border-radius: 16px;
    border: 2px dashed #ccc;
}
.calendar-card{
    border: 2px solid #d4d4d4;
    padding: 15px;
    margin-top: 15px;
    border-radius: 10px;
    height: 290px;
    box-shadow: 1px 1px 5px 2px #0000001c;
    .calendar-avatar{
        border: 1px solid #000;
        border-radius: 50rem;
        width: 35px;
        height: 35px;
    }
}
.border-left{
    border-left: 2px solid #e8e8e8;
}
.calendar-textarea{
    border: 2px solid #ccc;
    border-radius: 16px;
    margin-top: 20px;
    overflow: hidden;
    position: relative;
    textarea{
        border-bottom: 2px solid #ccc !important;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        width: 100%;
        height: 100px;
        padding: 10px;
        outline: none;
    }
}
.fc-theme-standard{
    th{
        border: 0 !important;
        border-bottom: 1px solid #c2c1c1 !important;
        border-top: 1px solid #c2c1c1 !important;
    }
    .fc-scrollgrid{
        border: 0 !important;
    }
}
.fc-timegrid-slot-label-cushion{
    color: #000;
}
.fc .fc-timegrid-slot,
.fc .fc-timegrid-slot-lane {
  border-color: #c2c1c1 !important; 
}
.fc-dayGridMonth-button, .fc-timeGridWeek-button, .fc-timeGridDay-button{
    color: #000 !important;
    border: 1.5px solid #c8c5c5 !important;
    background: transparent ;
    &:hover{
        background-color: rgba(200,197,197, .5);
    }
}
.fc-button-active{
    background-color: #575757 !important;
    color: #fff !important;
    z-index: 0 !important;
}
.fc-next-button, .fc-prev-button, .fc-today-button{
    outline: none;
    padding: 6px 12px;
    border-radius: 8px;
    margin: 0px 7px;
    border: 1.8px solid #c6c4c4;
    box-shadow: 0px 1px 5px 2px #0000002e;
    background: #fff;
    color: #000;
    font-size: 14px;
    &:hover{
        border: 1.8px solid #c6c4c4;
        box-shadow: 0px 1px 5px 2px #0000002e;
        background: #fff;
        color: #000;
    }
}
.fc-today-button:disabled {
    border: 1.8px solid #c6c4c4;
    box-shadow: 0px 1px 5px 2px #0000002e;
    background: #fff;
    color: #000;
}
.new-post{
    .form-control{
        background-clip: padding-box !important;
    background-color: #f8f9fa !important;
    border: 1px solid #f8f9fa !important;
    border-radius: 8px !important;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
  }
}
.ant-picker{
    padding: 8px 15px;
    border-radius: 8px;
    background: #fff;
    color: #000;
    box-shadow: 0px 1px 6px 2px #00000030;
    .anticon svg{
        width: 16px;
        height: 16px;
    }
    &:focus{
        border: none !important;
    }
}