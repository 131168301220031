.ai-icons {
    margin-left: 1rem;
    font-size: 30px;
}

.ai-list-card {
    height: 210px;
    border-radius: 20px;
    border: 0;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 20px 0px;
    z-index: 0;
    cursor: pointer;

    &:hover {
        box-shadow: 1px 8px 20px grey;
        -webkit-transition: box-shadow .6s ease-in;
    }

    .ai-header {
        border-radius: 20px 20px 0px 0px;
        background-color: transparent !important;
        border-bottom: 0;
        margin-top: .8rem;
    }

    .ai-body {
        h6 {
            font-weight: 400;
            padding-left: 15px;
            padding-right: 10px;
        }
    }
    .ai-starred-btn{
        padding: 8px !important;
        background-color: transparent !important;
        border: none !important;
        display: flex;
        align-items: center;
        justify-content: center;
        i{
            padding: 6px;
            &:hover{
                background-color: #ffee9050 !important;
                border-radius: 10px;
                
                
            }
            
        }
    }
}

.ai-card {
    border-radius: 20px;

    .card-header {
        border: none !important;
        border-radius: 20px;
        background-color: white;
        padding-bottom: 0;
        margin-bottom: 0;

        i {
            font-size: 35px !important;
        }
    }

    .form-control {
        background-clip: padding-box !important;
        background-color: #f8f9fa !important;
        border: 1px solid #f8f9fa !important;
        border-radius: 8px !important;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important
    }

    .form__form-group-input-wrap{
        textarea{
            margin-top: 5px;
            background-clip: padding-box !important;
            background-color: #f8f9fa !important;
            border: 1px solid #f8f9fa !important;
            border-radius: 1rem !important;
            box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
            padding: 16px !important;
            
        }
        textarea::placeholder{
            font-size: 14px;
            font-weight: 400;
            padding-left: 3px;
            padding-top: 4px;
            font-size: 14px;
            font-weight: 500px;
            color: #a1a5b4 ;
        }

    }

    .submit-btn {
        background-color: transparent;
        border: 1px solid #798EFD;
        border-radius: 10px;
        color: #798EFD;
        font-size: 12px;
        font-weight: 400;

        &:hover {
            background-color: rgba(121, 142, 253, 0.2);
            border: 1px solid #798EFD;
            border-radius: 10px;
            color: #798EFD;
        }
    }

    .ai-tab {
        .tabs-bg {
            background-color: #f8f9fa !important;
            padding: .5rem;
            border-radius: 20px;
        }

        .nav-link {
            margin-left: 5px;
            margin-right: 5px;
            border-radius: 15px;
            color: black;
            background-color: transparent;
            border: 0;
            padding: .6rem;
            padding-left: 1rem;
            padding-right: 1rem;
            width: 130px;
            // &.active{
            //     background-color: black !important;
            //     color: white;
            // }

        }

        .nav-link:hover {
            background-color: #414248 !important;
            border-color: #35373e !important;
            color: white !important;
        }

        .nav-link {
            &.active {
                border-bottom: none !important;
                color: white !important;
                background-color: black !important;

                &:hover {
                    background-color: #414248 !important;
                    border-color: #35373e !important;
                }
            }
        }
    }

    .output-history {
        background-clip: padding-box !important;
        background-color: #f8f9fa !important;
        border: 1px solid #f8f9fac3 !important;
        border-radius: 20px;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
        padding-top: 1rem;
        // box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px, rgba(0, 0, 0, 0.12) 0px 1px 1px;
    }

    .nav-language {
        margin-top: 0 !important;
              
        .accordion-arrow{
            display: flex !important;
            justify-content: start !important;
            align-items: center !important;
      
          .social-accordion-title{
            color: #303538 !important;
            font-weight: 600 !important;
            font-size: 14px !important;
            padding-left: 1rem !important;
            padding-right: .5rem !important;
            
          }
   
          i {
            color: #35373e;
            font-size: 12px !important;
            padding-top: 0.2rem;
          }
        }
      }
}

.output-list{
    list-style: none;
    padding-left: .5rem;
    padding-right: .5rem;

        max-height: 25rem !important;
        overflow-y: scroll !important;


    .li-item{
        border: 2px solid #a1a5bc;
        border-style: dotted;
        border-radius: 20px;
        margin-bottom: 1rem;
        padding: 1rem;
    }
    .output-delete-btn{
        padding: 8px !important;
        background-color: transparent !important;
        border: none !important;
        display: flex;
        align-items: center;
        justify-content: center;
  
        &:hover{
            background-color: #fdd8cd !important;
            
        }
        i{
            color:#f35523;
        }
    }
    .ai-icons-output{
        font-size: 20px;
        margin-right: .5rem;

    }
    .output-fav-btn{
        padding: 8px !important;
        background-color: transparent !important;
        border: none !important;
        display: flex;
        align-items: center;
        justify-content: center;
        i{
            color: #8e959b;
        }
        &:hover{
            background-color: #fff7cc !important;
            i{
                color: #ffd800 !important;
            }
            
        }

    }
}
.ai-select {
    select {
        -moz-appearance:none; /* Firefox */
        -webkit-appearance:none; /* Safari and Chrome */
        appearance:none;
        font-size: 26px;
        // background: #fff !important;
        // color: white !important ;
    }
   
    .form-select{
        border: none !important;
        border-radius: 30px !important;
        font-size: 13px !important;
        font-weight: 500 !important ;
        height: 50px !important;
        margin-top: 0.5rem;
        color: #000 !important;
        // .css-1xc3v61-indicatorContainer{
        //     color: #4D68F5 !important;
        //     color: #CCCCCC !important;
        // }
    }
    // .form-control {
    //     padding-left: 55px !important;
    // }    
}