
.order_table{

    .material-table__wrap {
        background-color: #242630 !important;
        color: black !important;

        .MuiTableRow-root {
          td {
            color: #e4e6eb !important;
  
          }
  
          td:first-child {
            border-radius: 20px 0 0 20px;
          }
  
          td:last-child {
            border-radius: 0 20px 20px 0;
  
          }
          
  
          .MuiTableRow-head {
  
            .MuiTableRow-head:first-child {
              border-radius: 20px 0 0 20px;
            }
  
  
            .MuiTableRow-head:last-child {
              border-radius: 0 20px 20px 0;
            }
          }
        }
 
      }
      .MuiTableHead-root {
        tr {
          th {
            background-color: #2E2F39 !important;
            border-bottom-color: #e1e4e8 !important;
          }
  
          th:first-child {
            border-radius: 20px 0 0 20px;
          }
  
          th:last-child {
            border-radius: 0 20px 20px 0;
          }
        }
      }



    .location-icon{
        color: #da3343;
    }
    .order-link{
      color: #d7dade;
      &:hover{
        color: #506bf6;
      }
      
    }

}
.payment_method_badge{
  color: #44bca9;
  background-color: #294849;
  min-width: 80px;
}
.fulfilment_status_badge{
  background-color: #564c33;
  color: #eabf4e;
}
.location-icon-orders{
  width: 40px;
  i{
    font-size: 20px;
    color:#da3242;
  }
}
.shipping-icon{
  width: 40px;
  i{
    font-size: 20px;
    color: #626367;
  }

}
.paid-icon{
  font-size: 20px;
  color: #44bca9;
}
.unfulfill-icon{
  font-size: 20px;
  color: #fecf51;
}
.edit-icon{
  font-size: 18px;
  color: #4d69fa;
}
.tags-btn {
  background-color: transparent;
  border: 1px solid #798EFD;
  border-radius: 10px;
  color: #798EFD;
  font-size: 12px;
  font-weight: 400;

  &:hover {
      background-color: rgba(121, 142, 253, 0.2);
      border: 1px solid #798EFD;
      border-radius: 10px;
      color: #798EFD;
  }
}
.tags-form{
  .form-control{
    background-clip: padding-box !important;
    background-color: #2b2f38 !important;
    border: 1px solid #2b2f38 !important;
    border-radius: 1rem !important;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
    color: #fff;
  }
}
.tags-list{
  max-height: 4rem;
  overflow-y: scroll;
  ul {
    list-style: none; 
  }
  
  ul li::before {
    content: "\2022";
    color: #727b81; 
    font-weight: bold; 
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
  }
}
.payment-status-icon{
  font-size: 19px;
  color: #42bca8;

}