/*!
 * Tabler Icons 1.48.0 by tabler - https://tabler.io
 * License - https://github.com/tabler/tabler-icons/blob/master/LICENSE
 */
$ti-font-family: 'tabler-icons' !default;
$ti-font-path: 'fonts' !default;
$ti-font-display: null !default;
$ti-prefix: 'ti' !default;

@font-face {
  font-family: $ti-font-family;
  font-style: normal;
  font-weight: 400;
  font-display: $ti-font-display;
  src: url('#{$ti-font-path}/tabler-icons.eot');
  src: url('#{$ti-font-path}/tabler-icons.eot?#iefix') format('embedded-opentype'),
    url('#{$ti-font-path}/tabler-icons.woff2') format('woff2'),
    url('#{$ti-font-path}/tabler-icons.woff') format('woff'),
    url('#{$ti-font-path}/tabler-icons.ttf') format('truetype'),
    url("#{$ti-font-path}/tabler-icons.svg\##{$ti-font-family}") format("svg");
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: $ti-font-family;
    src: url("#{$ti-font-path}/tabler-icons.svg\##{$ti-font-family}") format("svg");
  }
}

.#{$ti-prefix} {
  font-family: $ti-font-family !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@function unicode($str) {
  @return unquote("\"")+unquote(str-insert($str, "\\", 1))+unquote("\"")
}


$ti-icon-2fa: unicode('eca0');
$ti-icon-3d-cube-sphere: unicode('ecd7');
$ti-icon-a-b: unicode('ec36');
$ti-icon-access-point: unicode('ed1b');
$ti-icon-access-point-off: unicode('ed1a');
$ti-icon-accessible: unicode('eba9');
$ti-icon-activity: unicode('ed23');
$ti-icon-ad: unicode('ea02');
$ti-icon-ad-2: unicode('ef1f');
$ti-icon-adjustments: unicode('ea03');
$ti-icon-adjustments-alt: unicode('ec37');
$ti-icon-adjustments-horizontal: unicode('ec38');
$ti-icon-aerial-lift: unicode('edfe');
$ti-icon-affiliate: unicode('edff');
$ti-icon-alarm: unicode('ea04');
$ti-icon-alert-circle: unicode('ea05');
$ti-icon-alert-octagon: unicode('ecc6');
$ti-icon-alert-triangle: unicode('ea06');
$ti-icon-alien: unicode('ebde');
$ti-icon-align-center: unicode('ea07');
$ti-icon-align-justified: unicode('ea08');
$ti-icon-align-left: unicode('ea09');
$ti-icon-align-right: unicode('ea0a');
$ti-icon-ambulance: unicode('ebf5');
$ti-icon-anchor: unicode('eb76');
$ti-icon-angle: unicode('ef20');
$ti-icon-antenna-bars-1: unicode('ecc7');
$ti-icon-antenna-bars-2: unicode('ecc8');
$ti-icon-antenna-bars-3: unicode('ecc9');
$ti-icon-antenna-bars-4: unicode('ecca');
$ti-icon-antenna-bars-5: unicode('eccb');
$ti-icon-aperture: unicode('eb58');
$ti-icon-apple: unicode('ef21');
$ti-icon-apps: unicode('ebb6');
$ti-icon-archive: unicode('ea0b');
$ti-icon-armchair: unicode('ef9e');
$ti-icon-arrow-autofit-content: unicode('ef31');
$ti-icon-arrow-autofit-down: unicode('ef32');
$ti-icon-arrow-autofit-height: unicode('ef33');
$ti-icon-arrow-autofit-left: unicode('ef34');
$ti-icon-arrow-autofit-right: unicode('ef35');
$ti-icon-arrow-autofit-up: unicode('ef36');
$ti-icon-arrow-autofit-width: unicode('ef37');
$ti-icon-arrow-back: unicode('ea0c');
$ti-icon-arrow-back-up: unicode('eb77');
$ti-icon-arrow-bar-down: unicode('ea0d');
$ti-icon-arrow-bar-left: unicode('ea0e');
$ti-icon-arrow-bar-right: unicode('ea0f');
$ti-icon-arrow-bar-to-down: unicode('ec88');
$ti-icon-arrow-bar-to-left: unicode('ec89');
$ti-icon-arrow-bar-to-right: unicode('ec8a');
$ti-icon-arrow-bar-to-up: unicode('ec8b');
$ti-icon-arrow-bar-up: unicode('ea10');
$ti-icon-arrow-big-down: unicode('edda');
$ti-icon-arrow-big-left: unicode('eddb');
$ti-icon-arrow-big-right: unicode('eddc');
$ti-icon-arrow-big-top: unicode('eddd');
$ti-icon-arrow-bottom-bar: unicode('ed98');
$ti-icon-arrow-bottom-circle: unicode('ed99');
$ti-icon-arrow-bottom-square: unicode('ed9a');
$ti-icon-arrow-bottom-tail: unicode('ed9b');
$ti-icon-arrow-down: unicode('ea16');
$ti-icon-arrow-down-circle: unicode('ea11');
$ti-icon-arrow-down-left: unicode('ea13');
$ti-icon-arrow-down-left-circle: unicode('ea12');
$ti-icon-arrow-down-right: unicode('ea15');
$ti-icon-arrow-down-right-circle: unicode('ea14');
$ti-icon-arrow-forward: unicode('ea17');
$ti-icon-arrow-forward-up: unicode('eb78');
$ti-icon-arrow-left: unicode('ea19');
$ti-icon-arrow-left-bar: unicode('ed9c');
$ti-icon-arrow-left-circle: unicode('ea18');
$ti-icon-arrow-left-square: unicode('ed9d');
$ti-icon-arrow-left-tail: unicode('ed9e');
$ti-icon-arrow-loop-left: unicode('ed9f');
$ti-icon-arrow-loop-right: unicode('eda0');
$ti-icon-arrow-narrow-down: unicode('ea1a');
$ti-icon-arrow-narrow-left: unicode('ea1b');
$ti-icon-arrow-narrow-right: unicode('ea1c');
$ti-icon-arrow-narrow-up: unicode('ea1d');
$ti-icon-arrow-ramp-left: unicode('ed3c');
$ti-icon-arrow-ramp-right: unicode('ed3d');
$ti-icon-arrow-right: unicode('ea1f');
$ti-icon-arrow-right-bar: unicode('eda1');
$ti-icon-arrow-right-circle: unicode('ea1e');
$ti-icon-arrow-right-square: unicode('eda2');
$ti-icon-arrow-right-tail: unicode('eda3');
$ti-icon-arrow-top-bar: unicode('eda4');
$ti-icon-arrow-top-circle: unicode('eda5');
$ti-icon-arrow-top-square: unicode('eda6');
$ti-icon-arrow-top-tail: unicode('eda7');
$ti-icon-arrow-up: unicode('ea25');
$ti-icon-arrow-up-circle: unicode('ea20');
$ti-icon-arrow-up-left: unicode('ea22');
$ti-icon-arrow-up-left-circle: unicode('ea21');
$ti-icon-arrow-up-right: unicode('ea24');
$ti-icon-arrow-up-right-circle: unicode('ea23');
$ti-icon-arrow-wave-left-down: unicode('eda8');
$ti-icon-arrow-wave-left-up: unicode('eda9');
$ti-icon-arrow-wave-right-down: unicode('edaa');
$ti-icon-arrow-wave-right-up: unicode('edab');
$ti-icon-arrows-diagonal: unicode('ea27');
$ti-icon-arrows-diagonal-2: unicode('ea26');
$ti-icon-arrows-diagonal-minimize: unicode('ef39');
$ti-icon-arrows-diagonal-minimize-2: unicode('ef38');
$ti-icon-arrows-double-ne-sw: unicode('edde');
$ti-icon-arrows-double-nw-se: unicode('eddf');
$ti-icon-arrows-double-se-nw: unicode('ede0');
$ti-icon-arrows-double-sw-ne: unicode('ede1');
$ti-icon-arrows-down: unicode('edad');
$ti-icon-arrows-down-up: unicode('edac');
$ti-icon-arrows-horizontal: unicode('eb59');
$ti-icon-arrows-join: unicode('edaf');
$ti-icon-arrows-join-2: unicode('edae');
$ti-icon-arrows-left: unicode('edb1');
$ti-icon-arrows-left-down: unicode('ee00');
$ti-icon-arrows-left-right: unicode('edb0');
$ti-icon-arrows-maximize: unicode('ea28');
$ti-icon-arrows-minimize: unicode('ea29');
$ti-icon-arrows-right: unicode('edb3');
$ti-icon-arrows-right-down: unicode('ee01');
$ti-icon-arrows-right-left: unicode('edb2');
$ti-icon-arrows-sort: unicode('eb5a');
$ti-icon-arrows-split: unicode('edb5');
$ti-icon-arrows-split-2: unicode('edb4');
$ti-icon-arrows-up: unicode('edb7');
$ti-icon-arrows-up-down: unicode('edb6');
$ti-icon-arrows-up-left: unicode('ee02');
$ti-icon-arrows-up-right: unicode('ee03');
$ti-icon-arrows-vertical: unicode('eb5b');
$ti-icon-artboard: unicode('ea2a');
$ti-icon-aspect-ratio: unicode('ed30');
$ti-icon-at: unicode('ea2b');
$ti-icon-atom: unicode('eb79');
$ti-icon-atom-2: unicode('ebdf');
$ti-icon-award: unicode('ea2c');
$ti-icon-axe: unicode('ef9f');
$ti-icon-axis-x: unicode('ef45');
$ti-icon-axis-y: unicode('ef46');
$ti-icon-backhoe: unicode('ed86');
$ti-icon-backpack: unicode('ef47');
$ti-icon-backspace: unicode('ea2d');
$ti-icon-ball-american-football: unicode('ee04');
$ti-icon-ball-baseball: unicode('efa0');
$ti-icon-ball-basketball: unicode('ec28');
$ti-icon-ball-bowling: unicode('ec29');
$ti-icon-ball-football: unicode('ee06');
$ti-icon-ball-football-off: unicode('ee05');
$ti-icon-ball-tennis: unicode('ec2a');
$ti-icon-ball-volleyball: unicode('ec2b');
$ti-icon-ballon: unicode('ef3a');
$ti-icon-ban: unicode('ea2e');
$ti-icon-bandage: unicode('eb7a');
$ti-icon-barcode: unicode('ebc6');
$ti-icon-basket: unicode('ebe1');
$ti-icon-bath: unicode('ef48');
$ti-icon-battery: unicode('ea34');
$ti-icon-battery-1: unicode('ea2f');
$ti-icon-battery-2: unicode('ea30');
$ti-icon-battery-3: unicode('ea31');
$ti-icon-battery-4: unicode('ea32');
$ti-icon-battery-automotive: unicode('ee07');
$ti-icon-battery-charging: unicode('ea33');
$ti-icon-battery-charging-2: unicode('ef3b');
$ti-icon-battery-eco: unicode('ef3c');
$ti-icon-battery-off: unicode('ed1c');
$ti-icon-beach: unicode('ef3d');
$ti-icon-bed: unicode('eb5c');
$ti-icon-beer: unicode('efa1');
$ti-icon-bell: unicode('ea35');
$ti-icon-bell-minus: unicode('ede2');
$ti-icon-bell-off: unicode('ece9');
$ti-icon-bell-plus: unicode('ede3');
$ti-icon-bell-ringing: unicode('ed07');
$ti-icon-bell-ringing-2: unicode('ede4');
$ti-icon-bell-x: unicode('ede5');
$ti-icon-bike: unicode('ea36');
$ti-icon-binary: unicode('ee08');
$ti-icon-biohazard: unicode('ecb8');
$ti-icon-blockquote: unicode('ee09');
$ti-icon-bluetooth: unicode('ea37');
$ti-icon-bluetooth-connected: unicode('ecea');
$ti-icon-bluetooth-off: unicode('eceb');
$ti-icon-blur: unicode('ef8c');
$ti-icon-bold: unicode('eb7b');
$ti-icon-bolt: unicode('ea38');
$ti-icon-bolt-off: unicode('ecec');
$ti-icon-bone: unicode('edb8');
$ti-icon-book: unicode('ea39');
$ti-icon-bookmark: unicode('ea3a');
$ti-icon-bookmark-off: unicode('eced');
$ti-icon-bookmarks: unicode('ed08');
$ti-icon-border-all: unicode('ea3b');
$ti-icon-border-bottom: unicode('ea3c');
$ti-icon-border-horizontal: unicode('ea3d');
$ti-icon-border-inner: unicode('ea3e');
$ti-icon-border-left: unicode('ea3f');
$ti-icon-border-none: unicode('ea40');
$ti-icon-border-outer: unicode('ea41');
$ti-icon-border-radius: unicode('eb7c');
$ti-icon-border-right: unicode('ea42');
$ti-icon-border-style: unicode('ee0a');
$ti-icon-border-style-2: unicode('ef22');
$ti-icon-border-top: unicode('ea43');
$ti-icon-border-vertical: unicode('ea44');
$ti-icon-bottle: unicode('ef0b');
$ti-icon-box: unicode('ea45');
$ti-icon-box-margin: unicode('ee0b');
$ti-icon-box-model: unicode('ee0c');
$ti-icon-box-model-2: unicode('ef23');
$ti-icon-box-multiple: unicode('ee17');
$ti-icon-box-multiple-0: unicode('ee0d');
$ti-icon-box-multiple-1: unicode('ee0e');
$ti-icon-box-multiple-2: unicode('ee0f');
$ti-icon-box-multiple-3: unicode('ee10');
$ti-icon-box-multiple-4: unicode('ee11');
$ti-icon-box-multiple-5: unicode('ee12');
$ti-icon-box-multiple-6: unicode('ee13');
$ti-icon-box-multiple-7: unicode('ee14');
$ti-icon-box-multiple-8: unicode('ee15');
$ti-icon-box-multiple-9: unicode('ee16');
$ti-icon-box-padding: unicode('ee18');
$ti-icon-braces: unicode('ebcc');
$ti-icon-brackets: unicode('ebcd');
$ti-icon-brand-airbnb: unicode('ed68');
$ti-icon-brand-airtable: unicode('ef6a');
$ti-icon-brand-android: unicode('ec16');
$ti-icon-brand-angular: unicode('ef6b');
$ti-icon-brand-apple: unicode('ec17');
$ti-icon-brand-apple-arcade: unicode('ed69');
$ti-icon-brand-appstore: unicode('ed24');
$ti-icon-brand-asana: unicode('edc5');
$ti-icon-brand-behance: unicode('ec6e');
$ti-icon-brand-bing: unicode('edc6');
$ti-icon-brand-bitbucket: unicode('edc7');
$ti-icon-brand-booking: unicode('edc8');
$ti-icon-brand-bootstrap: unicode('ef3e');
$ti-icon-brand-chrome: unicode('ec18');
$ti-icon-brand-codepen: unicode('ec6f');
$ti-icon-brand-codesandbox: unicode('ed6a');
$ti-icon-brand-css3: unicode('ed6b');
$ti-icon-brand-cucumber: unicode('ef6c');
$ti-icon-brand-debian: unicode('ef57');
$ti-icon-brand-deviantart: unicode('ecfb');
$ti-icon-brand-discord: unicode('ece3');
$ti-icon-brand-disqus: unicode('edc9');
$ti-icon-brand-docker: unicode('edca');
$ti-icon-brand-doctrine: unicode('ef6d');
$ti-icon-brand-dribbble: unicode('ec19');
$ti-icon-brand-edge: unicode('ecfc');
$ti-icon-brand-facebook: unicode('ec1a');
$ti-icon-brand-figma: unicode('ec93');
$ti-icon-brand-firebase: unicode('ef6e');
$ti-icon-brand-firefox: unicode('ecfd');
$ti-icon-brand-flickr: unicode('ecfe');
$ti-icon-brand-foursquare: unicode('ecff');
$ti-icon-brand-framer: unicode('ec1b');
$ti-icon-brand-git: unicode('ef6f');
$ti-icon-brand-github: unicode('ec1c');
$ti-icon-brand-gitlab: unicode('ec1d');
$ti-icon-brand-gmail: unicode('efa2');
$ti-icon-brand-google: unicode('ec1f');
$ti-icon-brand-google-analytics: unicode('edcb');
$ti-icon-brand-google-drive: unicode('ec1e');
$ti-icon-brand-google-play: unicode('ed25');
$ti-icon-brand-gravatar: unicode('edcc');
$ti-icon-brand-hipchat: unicode('edcd');
$ti-icon-brand-html5: unicode('ed6c');
$ti-icon-brand-instagram: unicode('ec20');
$ti-icon-brand-javascript: unicode('ef0c');
$ti-icon-brand-kickstarter: unicode('edce');
$ti-icon-brand-kotlin: unicode('ed6d');
$ti-icon-brand-linkedin: unicode('ec8c');
$ti-icon-brand-loom: unicode('ef70');
$ti-icon-brand-mastercard: unicode('ef49');
$ti-icon-brand-medium: unicode('ec70');
$ti-icon-brand-messenger: unicode('ec71');
$ti-icon-brand-netbeans: unicode('ef71');
$ti-icon-brand-netflix: unicode('edcf');
$ti-icon-brand-notion: unicode('ef7b');
$ti-icon-brand-nytimes: unicode('ef8d');
$ti-icon-brand-open-source: unicode('edd0');
$ti-icon-brand-opera: unicode('ec21');
$ti-icon-brand-pagekit: unicode('edd1');
$ti-icon-brand-patreon: unicode('edd2');
$ti-icon-brand-paypal: unicode('ec22');
$ti-icon-brand-php: unicode('ef72');
$ti-icon-brand-pinterest: unicode('ec8d');
$ti-icon-brand-pocket: unicode('ed00');
$ti-icon-brand-producthunt: unicode('edd3');
$ti-icon-brand-python: unicode('ed01');
$ti-icon-brand-react-native: unicode('ef73');
$ti-icon-brand-reddit: unicode('ec8e');
$ti-icon-brand-safari: unicode('ec23');
$ti-icon-brand-sass: unicode('edd4');
$ti-icon-brand-sentry: unicode('edd5');
$ti-icon-brand-shazam: unicode('edd6');
$ti-icon-brand-sketch: unicode('ec24');
$ti-icon-brand-skype: unicode('ed02');
$ti-icon-brand-slack: unicode('ec72');
$ti-icon-brand-snapchat: unicode('ec25');
$ti-icon-brand-soundcloud: unicode('ed6e');
$ti-icon-brand-spotify: unicode('ed03');
$ti-icon-brand-stackoverflow: unicode('ef58');
$ti-icon-brand-steam: unicode('ed6f');
$ti-icon-brand-stripe: unicode('edd7');
$ti-icon-brand-sublime-text: unicode('ef74');
$ti-icon-brand-tabler: unicode('ec8f');
$ti-icon-brand-tailwind: unicode('eca1');
$ti-icon-brand-telegram: unicode('ec26');
$ti-icon-brand-tidal: unicode('ed70');
$ti-icon-brand-tiktok: unicode('ec73');
$ti-icon-brand-tinder: unicode('ed71');
$ti-icon-brand-tumblr: unicode('ed04');
$ti-icon-brand-twitch: unicode('ed05');
$ti-icon-brand-twitter: unicode('ec27');
$ti-icon-brand-uber: unicode('ef75');
$ti-icon-brand-ubuntu: unicode('ef59');
$ti-icon-brand-unsplash: unicode('edd8');
$ti-icon-brand-vercel: unicode('ef24');
$ti-icon-brand-vimeo: unicode('ed06');
$ti-icon-brand-visual-studio: unicode('ef76');
$ti-icon-brand-vk: unicode('ed72');
$ti-icon-brand-whatsapp: unicode('ec74');
$ti-icon-brand-windows: unicode('ecd8');
$ti-icon-brand-yahoo: unicode('ed73');
$ti-icon-brand-ycombinator: unicode('edd9');
$ti-icon-brand-youtube: unicode('ec90');
$ti-icon-bread: unicode('efa3');
$ti-icon-briefcase: unicode('ea46');
$ti-icon-brightness: unicode('eb7f');
$ti-icon-brightness-2: unicode('ee19');
$ti-icon-brightness-down: unicode('eb7d');
$ti-icon-brightness-half: unicode('ee1a');
$ti-icon-brightness-up: unicode('eb7e');
$ti-icon-browser: unicode('ebb7');
$ti-icon-brush: unicode('ebb8');
$ti-icon-bucket: unicode('ea47');
$ti-icon-bug: unicode('ea48');
$ti-icon-building: unicode('ea4f');
$ti-icon-building-arch: unicode('ea49');
$ti-icon-building-bank: unicode('ebe2');
$ti-icon-building-bridge: unicode('ea4b');
$ti-icon-building-bridge-2: unicode('ea4a');
$ti-icon-building-carousel: unicode('ed87');
$ti-icon-building-castle: unicode('ed88');
$ti-icon-building-church: unicode('ea4c');
$ti-icon-building-community: unicode('ebf6');
$ti-icon-building-cottage: unicode('ee1b');
$ti-icon-building-factory: unicode('ee1c');
$ti-icon-building-fortress: unicode('ed89');
$ti-icon-building-hospital: unicode('ea4d');
$ti-icon-building-lighthouse: unicode('ed8a');
$ti-icon-building-monument: unicode('ed26');
$ti-icon-building-pavilon: unicode('ebf7');
$ti-icon-building-skyscraper: unicode('ec39');
$ti-icon-building-store: unicode('ea4e');
$ti-icon-building-warehouse: unicode('ebe3');
$ti-icon-bulb: unicode('ea51');
$ti-icon-bulb-off: unicode('ea50');
$ti-icon-bulldozer: unicode('ee1d');
$ti-icon-bus: unicode('ebe4');
$ti-icon-businessplan: unicode('ee1e');
$ti-icon-calculator: unicode('eb80');
$ti-icon-calendar: unicode('ea53');
$ti-icon-calendar-event: unicode('ea52');
$ti-icon-calendar-minus: unicode('ebb9');
$ti-icon-calendar-off: unicode('ee1f');
$ti-icon-calendar-plus: unicode('ebba');
$ti-icon-calendar-stats: unicode('ee20');
$ti-icon-calendar-time: unicode('ee21');
$ti-icon-camera: unicode('ea54');
$ti-icon-camera-minus: unicode('ec3a');
$ti-icon-camera-off: unicode('ecee');
$ti-icon-camera-plus: unicode('ec3b');
$ti-icon-camera-rotate: unicode('ee22');
$ti-icon-camera-selfie: unicode('ee23');
$ti-icon-candy: unicode('ef0d');
$ti-icon-capture: unicode('ec3c');
$ti-icon-car: unicode('ebbb');
$ti-icon-car-crane: unicode('ef25');
$ti-icon-car-crash: unicode('efa4');
$ti-icon-caravan: unicode('ec7c');
$ti-icon-cardboards: unicode('ed74');
$ti-icon-caret-down: unicode('eb5d');
$ti-icon-caret-left: unicode('eb5e');
$ti-icon-caret-right: unicode('eb5f');
$ti-icon-caret-up: unicode('eb60');
$ti-icon-cash: unicode('ea55');
$ti-icon-cash-banknote: unicode('ee25');
$ti-icon-cash-banknote-off: unicode('ee24');
$ti-icon-cast: unicode('ea56');
$ti-icon-ce: unicode('ed75');
$ti-icon-certificate: unicode('ed76');
$ti-icon-charging-pile: unicode('ee26');
$ti-icon-chart-arcs: unicode('ee28');
$ti-icon-chart-arcs-3: unicode('ee27');
$ti-icon-chart-area: unicode('ea58');
$ti-icon-chart-area-line: unicode('ea57');
$ti-icon-chart-arrows: unicode('ee2a');
$ti-icon-chart-arrows-vertical: unicode('ee29');
$ti-icon-chart-bar: unicode('ea59');
$ti-icon-chart-bubble: unicode('ec75');
$ti-icon-chart-candle: unicode('ea5a');
$ti-icon-chart-circles: unicode('ee2b');
$ti-icon-chart-donut: unicode('ea5b');
$ti-icon-chart-donut-2: unicode('ee2c');
$ti-icon-chart-donut-3: unicode('ee2d');
$ti-icon-chart-donut-4: unicode('ee2e');
$ti-icon-chart-dots: unicode('ee2f');
$ti-icon-chart-infographic: unicode('ee30');
$ti-icon-chart-line: unicode('ea5c');
$ti-icon-chart-pie: unicode('ea5d');
$ti-icon-chart-pie-2: unicode('ee31');
$ti-icon-chart-pie-3: unicode('ee32');
$ti-icon-chart-pie-4: unicode('ee33');
$ti-icon-chart-radar: unicode('ed77');
$ti-icon-check: unicode('ea5e');
$ti-icon-checkbox: unicode('eba6');
$ti-icon-checks: unicode('ebaa');
$ti-icon-checkup-list: unicode('ef5a');
$ti-icon-cheese: unicode('ef26');
$ti-icon-chevron-down: unicode('ea5f');
$ti-icon-chevron-down-left: unicode('ed09');
$ti-icon-chevron-down-right: unicode('ed0a');
$ti-icon-chevron-left: unicode('ea60');
$ti-icon-chevron-right: unicode('ea61');
$ti-icon-chevron-up: unicode('ea62');
$ti-icon-chevron-up-left: unicode('ed0b');
$ti-icon-chevron-up-right: unicode('ed0c');
$ti-icon-chevrons-down: unicode('ea63');
$ti-icon-chevrons-down-left: unicode('ed0d');
$ti-icon-chevrons-down-right: unicode('ed0e');
$ti-icon-chevrons-left: unicode('ea64');
$ti-icon-chevrons-right: unicode('ea65');
$ti-icon-chevrons-up: unicode('ea66');
$ti-icon-chevrons-up-left: unicode('ed0f');
$ti-icon-chevrons-up-right: unicode('ed10');
$ti-icon-christmas-tree: unicode('ed78');
$ti-icon-circle: unicode('ea6b');
$ti-icon-circle-0: unicode('ee34');
$ti-icon-circle-1: unicode('ee35');
$ti-icon-circle-2: unicode('ee36');
$ti-icon-circle-3: unicode('ee37');
$ti-icon-circle-4: unicode('ee38');
$ti-icon-circle-5: unicode('ee39');
$ti-icon-circle-6: unicode('ee3a');
$ti-icon-circle-7: unicode('ee3b');
$ti-icon-circle-8: unicode('ee3c');
$ti-icon-circle-9: unicode('ee3d');
$ti-icon-circle-check: unicode('ea67');
$ti-icon-circle-dashed: unicode('ed27');
$ti-icon-circle-dotted: unicode('ed28');
$ti-icon-circle-half: unicode('ee3f');
$ti-icon-circle-half-vertical: unicode('ee3e');
$ti-icon-circle-minus: unicode('ea68');
$ti-icon-circle-off: unicode('ee40');
$ti-icon-circle-plus: unicode('ea69');
$ti-icon-circle-square: unicode('ece4');
$ti-icon-circle-x: unicode('ea6a');
$ti-icon-circles: unicode('ece5');
$ti-icon-clear-all: unicode('ee41');
$ti-icon-clear-formatting: unicode('ebe5');
$ti-icon-click: unicode('ebbc');
$ti-icon-clipboard: unicode('ea6f');
$ti-icon-clipboard-check: unicode('ea6c');
$ti-icon-clipboard-list: unicode('ea6d');
$ti-icon-clipboard-x: unicode('ea6e');
$ti-icon-clock: unicode('ea70');
$ti-icon-cloud: unicode('ea76');
$ti-icon-cloud-download: unicode('ea71');
$ti-icon-cloud-fog: unicode('ecd9');
$ti-icon-cloud-off: unicode('ed3e');
$ti-icon-cloud-rain: unicode('ea72');
$ti-icon-cloud-snow: unicode('ea73');
$ti-icon-cloud-storm: unicode('ea74');
$ti-icon-cloud-upload: unicode('ea75');
$ti-icon-code: unicode('ea77');
$ti-icon-code-minus: unicode('ee42');
$ti-icon-code-plus: unicode('ee43');
$ti-icon-coffee: unicode('ef0e');
$ti-icon-coin: unicode('eb82');
$ti-icon-color-picker: unicode('ebe6');
$ti-icon-color-swatch: unicode('eb61');
$ti-icon-column-insert-left: unicode('ee44');
$ti-icon-column-insert-right: unicode('ee45');
$ti-icon-columns: unicode('eb83');
$ti-icon-comet: unicode('ec76');
$ti-icon-command: unicode('ea78');
$ti-icon-compass: unicode('ea79');
$ti-icon-components: unicode('efa5');
$ti-icon-confetti: unicode('ee46');
$ti-icon-container: unicode('ee47');
$ti-icon-contrast: unicode('ec4e');
$ti-icon-cookie: unicode('ef0f');
$ti-icon-copy: unicode('ea7a');
$ti-icon-copyleft: unicode('ec3d');
$ti-icon-copyright: unicode('ea7b');
$ti-icon-corner-down-left: unicode('ea7c');
$ti-icon-corner-down-left-double: unicode('ee48');
$ti-icon-corner-down-right: unicode('ea7d');
$ti-icon-corner-down-right-double: unicode('ee49');
$ti-icon-corner-left-down: unicode('ea7e');
$ti-icon-corner-left-down-double: unicode('ee4a');
$ti-icon-corner-left-up: unicode('ea7f');
$ti-icon-corner-left-up-double: unicode('ee4b');
$ti-icon-corner-right-down: unicode('ea80');
$ti-icon-corner-right-down-double: unicode('ee4c');
$ti-icon-corner-right-up: unicode('ea81');
$ti-icon-corner-right-up-double: unicode('ee4d');
$ti-icon-corner-up-left: unicode('ea82');
$ti-icon-corner-up-left-double: unicode('ee4e');
$ti-icon-corner-up-right: unicode('ea83');
$ti-icon-corner-up-right-double: unicode('ee4f');
$ti-icon-cpu: unicode('ef8e');
$ti-icon-crane: unicode('ef27');
$ti-icon-credit-card: unicode('ea84');
$ti-icon-credit-card-off: unicode('ed11');
$ti-icon-crop: unicode('ea85');
$ti-icon-cross: unicode('ef8f');
$ti-icon-crosshair: unicode('ec3e');
$ti-icon-crown: unicode('ed12');
$ti-icon-crown-off: unicode('ee50');
$ti-icon-crutches: unicode('ef5b');
$ti-icon-cup: unicode('ef28');
$ti-icon-curly-loop: unicode('ecda');
$ti-icon-currency: unicode('efa6');
$ti-icon-currency-bahraini: unicode('ee51');
$ti-icon-currency-bath: unicode('ee52');
$ti-icon-currency-bitcoin: unicode('ebab');
$ti-icon-currency-cent: unicode('ee53');
$ti-icon-currency-dinar: unicode('ee54');
$ti-icon-currency-dirham: unicode('ee55');
$ti-icon-currency-dogecoin: unicode('ef4b');
$ti-icon-currency-dollar: unicode('eb84');
$ti-icon-currency-dollar-australian: unicode('ee56');
$ti-icon-currency-dollar-canadian: unicode('ee57');
$ti-icon-currency-dollar-singapore: unicode('ee58');
$ti-icon-currency-ethereum: unicode('ee59');
$ti-icon-currency-euro: unicode('eb85');
$ti-icon-currency-forint: unicode('ee5a');
$ti-icon-currency-frank: unicode('ee5b');
$ti-icon-currency-krone-czech: unicode('ee5c');
$ti-icon-currency-krone-danish: unicode('ee5d');
$ti-icon-currency-krone-swedish: unicode('ee5e');
$ti-icon-currency-leu: unicode('ee5f');
$ti-icon-currency-lira: unicode('ee60');
$ti-icon-currency-litecoin: unicode('ee61');
$ti-icon-currency-naira: unicode('ee62');
$ti-icon-currency-pound: unicode('ebac');
$ti-icon-currency-real: unicode('ee63');
$ti-icon-currency-renminbi: unicode('ee64');
$ti-icon-currency-ripple: unicode('ee65');
$ti-icon-currency-riyal: unicode('ee66');
$ti-icon-currency-rubel: unicode('ee67');
$ti-icon-currency-rupee: unicode('ebad');
$ti-icon-currency-shekel: unicode('ee68');
$ti-icon-currency-taka: unicode('ee69');
$ti-icon-currency-tugrik: unicode('ee6a');
$ti-icon-currency-won: unicode('ee6b');
$ti-icon-currency-yen: unicode('ebae');
$ti-icon-currency-zloty: unicode('ee6c');
$ti-icon-current-location: unicode('ecef');
$ti-icon-cursor-text: unicode('ee6d');
$ti-icon-cut: unicode('ea86');
$ti-icon-dashboard: unicode('ea87');
$ti-icon-database: unicode('ea88');
$ti-icon-database-export: unicode('ee6e');
$ti-icon-database-import: unicode('ee6f');
$ti-icon-database-off: unicode('ee70');
$ti-icon-details: unicode('ee71');
$ti-icon-device-analytics: unicode('ee72');
$ti-icon-device-audio-tape: unicode('ee73');
$ti-icon-device-cctv: unicode('ee74');
$ti-icon-device-computer-camera: unicode('ee76');
$ti-icon-device-computer-camera-off: unicode('ee75');
$ti-icon-device-desktop: unicode('ea89');
$ti-icon-device-desktop-analytics: unicode('ee77');
$ti-icon-device-desktop-off: unicode('ee78');
$ti-icon-device-floppy: unicode('eb62');
$ti-icon-device-gamepad: unicode('eb63');
$ti-icon-device-laptop: unicode('eb64');
$ti-icon-device-mobile: unicode('ea8a');
$ti-icon-device-mobile-message: unicode('ee79');
$ti-icon-device-mobile-rotated: unicode('ecdb');
$ti-icon-device-mobile-vibration: unicode('eb86');
$ti-icon-device-speaker: unicode('ea8b');
$ti-icon-device-tablet: unicode('ea8c');
$ti-icon-device-tv: unicode('ea8d');
$ti-icon-device-watch: unicode('ebf9');
$ti-icon-device-watch-stats: unicode('ef7d');
$ti-icon-device-watch-stats-2: unicode('ef7c');
$ti-icon-devices: unicode('eb87');
$ti-icon-devices-2: unicode('ed29');
$ti-icon-devices-pc: unicode('ee7a');
$ti-icon-diamond: unicode('eb65');
$ti-icon-dice: unicode('eb66');
$ti-icon-dimensions: unicode('ee7b');
$ti-icon-direction: unicode('ebfb');
$ti-icon-direction-horizontal: unicode('ebfa');
$ti-icon-directions: unicode('ea8e');
$ti-icon-disabled: unicode('ea8f');
$ti-icon-disabled-2: unicode('ebaf');
$ti-icon-disc: unicode('ea90');
$ti-icon-discount: unicode('ebbd');
$ti-icon-discount-2: unicode('ee7c');
$ti-icon-divide: unicode('ed5c');
$ti-icon-dna: unicode('ee7d');
$ti-icon-dna-2: unicode('ef5c');
$ti-icon-dog-bowl: unicode('ef29');
$ti-icon-door: unicode('ef4e');
$ti-icon-door-enter: unicode('ef4c');
$ti-icon-door-exit: unicode('ef4d');
$ti-icon-dots: unicode('ea95');
$ti-icon-dots-circle-horizontal: unicode('ea91');
$ti-icon-dots-diagonal: unicode('ea93');
$ti-icon-dots-diagonal-2: unicode('ea92');
$ti-icon-dots-vertical: unicode('ea94');
$ti-icon-download: unicode('ea96');
$ti-icon-drag-drop: unicode('eb89');
$ti-icon-drag-drop-2: unicode('eb88');
$ti-icon-drone: unicode('ed79');
$ti-icon-drone-off: unicode('ee7e');
$ti-icon-droplet: unicode('ea97');
$ti-icon-droplet-filled: unicode('ee80');
$ti-icon-droplet-filled-2: unicode('ee7f');
$ti-icon-droplet-half: unicode('ee82');
$ti-icon-droplet-half-2: unicode('ee81');
$ti-icon-droplet-off: unicode('ee83');
$ti-icon-ear: unicode('ebce');
$ti-icon-ear-off: unicode('ee84');
$ti-icon-edit: unicode('ea98');
$ti-icon-edit-circle: unicode('ee85');
$ti-icon-egg: unicode('eb8a');
$ti-icon-emergency-bed: unicode('ef5d');
$ti-icon-emphasis: unicode('ebcf');
$ti-icon-engine: unicode('ef7e');
$ti-icon-equal: unicode('ee87');
$ti-icon-equal-not: unicode('ee86');
$ti-icon-eraser: unicode('eb8b');
$ti-icon-exchange: unicode('ebe7');
$ti-icon-exposure: unicode('eb8c');
$ti-icon-external-link: unicode('ea99');
$ti-icon-eye: unicode('ea9a');
$ti-icon-eye-check: unicode('ee88');
$ti-icon-eye-off: unicode('ecf0');
$ti-icon-eye-table: unicode('ef5e');
$ti-icon-eyeglass: unicode('ee8a');
$ti-icon-eyeglass-2: unicode('ee89');
$ti-icon-face-id: unicode('ea9b');
$ti-icon-face-id-error: unicode('efa7');
$ti-icon-fall: unicode('ecb9');
$ti-icon-feather: unicode('ee8b');
$ti-icon-fence: unicode('ef2a');
$ti-icon-file: unicode('eaa4');
$ti-icon-file-alert: unicode('ede6');
$ti-icon-file-analytics: unicode('ede7');
$ti-icon-file-certificate: unicode('ed4d');
$ti-icon-file-check: unicode('ea9c');
$ti-icon-file-code: unicode('ebd0');
$ti-icon-file-code-2: unicode('ede8');
$ti-icon-file-diff: unicode('ecf1');
$ti-icon-file-digit: unicode('efa8');
$ti-icon-file-dislike: unicode('ed2a');
$ti-icon-file-download: unicode('ea9d');
$ti-icon-file-export: unicode('ede9');
$ti-icon-file-horizontal: unicode('ebb0');
$ti-icon-file-import: unicode('edea');
$ti-icon-file-info: unicode('edec');
$ti-icon-file-invoice: unicode('eb67');
$ti-icon-file-like: unicode('ed2b');
$ti-icon-file-minus: unicode('ea9e');
$ti-icon-file-music: unicode('ea9f');
$ti-icon-file-off: unicode('ecf2');
$ti-icon-file-phone: unicode('ecdc');
$ti-icon-file-plus: unicode('eaa0');
$ti-icon-file-report: unicode('eded');
$ti-icon-file-search: unicode('ed5d');
$ti-icon-file-shredder: unicode('eaa1');
$ti-icon-file-symlink: unicode('ed53');
$ti-icon-file-text: unicode('eaa2');
$ti-icon-file-upload: unicode('ec91');
$ti-icon-file-x: unicode('eaa3');
$ti-icon-file-zip: unicode('ed4e');
$ti-icon-files: unicode('edef');
$ti-icon-files-off: unicode('edee');
$ti-icon-filter: unicode('eaa5');
$ti-icon-filter-off: unicode('ed2c');
$ti-icon-fingerprint: unicode('ebd1');
$ti-icon-firetruck: unicode('ebe8');
$ti-icon-first-aid-kit: unicode('ef5f');
$ti-icon-fish: unicode('ef2b');
$ti-icon-flag: unicode('eaa6');
$ti-icon-flag-2: unicode('ee8c');
$ti-icon-flag-3: unicode('ee8d');
$ti-icon-flame: unicode('ec2c');
$ti-icon-flare: unicode('ee8e');
$ti-icon-flask: unicode('ebd2');
$ti-icon-flask-2: unicode('ef60');
$ti-icon-flip-horizontal: unicode('eaa7');
$ti-icon-flip-vertical: unicode('eaa8');
$ti-icon-float-center: unicode('ebb1');
$ti-icon-float-left: unicode('ebb2');
$ti-icon-float-none: unicode('ed13');
$ti-icon-float-right: unicode('ebb3');
$ti-icon-focus: unicode('eb8d');
$ti-icon-focus-2: unicode('ebd3');
$ti-icon-fold: unicode('ed56');
$ti-icon-fold-down: unicode('ed54');
$ti-icon-fold-up: unicode('ed55');
$ti-icon-folder: unicode('eaad');
$ti-icon-folder-minus: unicode('eaaa');
$ti-icon-folder-off: unicode('ed14');
$ti-icon-folder-plus: unicode('eaab');
$ti-icon-folder-x: unicode('eaac');
$ti-icon-folders: unicode('eaae');
$ti-icon-forbid: unicode('ebd5');
$ti-icon-forbid-2: unicode('ebd4');
$ti-icon-forklift: unicode('ebe9');
$ti-icon-forms: unicode('ee8f');
$ti-icon-frame: unicode('eaaf');
$ti-icon-friends: unicode('eab0');
$ti-icon-gas-station: unicode('ec7d');
$ti-icon-gauge: unicode('eab1');
$ti-icon-gavel: unicode('ef90');
$ti-icon-geometry: unicode('ee90');
$ti-icon-ghost: unicode('eb8e');
$ti-icon-gift: unicode('eb68');
$ti-icon-git-branch: unicode('eab2');
$ti-icon-git-commit: unicode('eab3');
$ti-icon-git-compare: unicode('eab4');
$ti-icon-git-fork: unicode('eb8f');
$ti-icon-git-merge: unicode('eab5');
$ti-icon-git-pull-request: unicode('eab6');
$ti-icon-git-pull-request-closed: unicode('ef7f');
$ti-icon-glass: unicode('eab8');
$ti-icon-glass-full: unicode('eab7');
$ti-icon-glass-off: unicode('ee91');
$ti-icon-globe: unicode('eab9');
$ti-icon-golf: unicode('ed8c');
$ti-icon-gps: unicode('ed7a');
$ti-icon-grain: unicode('ee92');
$ti-icon-grid-dots: unicode('eaba');
$ti-icon-grill: unicode('efa9');
$ti-icon-grip-horizontal: unicode('ec00');
$ti-icon-grip-vertical: unicode('ec01');
$ti-icon-growth: unicode('ee93');
$ti-icon-h-1: unicode('ec94');
$ti-icon-h-2: unicode('ec95');
$ti-icon-h-3: unicode('ec96');
$ti-icon-h-4: unicode('ec97');
$ti-icon-h-5: unicode('ec98');
$ti-icon-h-6: unicode('ec99');
$ti-icon-hammer: unicode('ef91');
$ti-icon-hand-click: unicode('ef4f');
$ti-icon-hand-finger: unicode('ee94');
$ti-icon-hand-little-finger: unicode('ee95');
$ti-icon-hand-middle-finger: unicode('ec2d');
$ti-icon-hand-move: unicode('ef50');
$ti-icon-hand-off: unicode('ed15');
$ti-icon-hand-ring-finger: unicode('ee96');
$ti-icon-hand-rock: unicode('ee97');
$ti-icon-hand-stop: unicode('ec2e');
$ti-icon-hand-three-fingers: unicode('ee98');
$ti-icon-hand-two-fingers: unicode('ee99');
$ti-icon-hanger: unicode('ee9a');
$ti-icon-hash: unicode('eabc');
$ti-icon-haze: unicode('efaa');
$ti-icon-heading: unicode('ee9b');
$ti-icon-headphones: unicode('eabd');
$ti-icon-headphones-off: unicode('ed1d');
$ti-icon-headset: unicode('eb90');
$ti-icon-heart: unicode('eabe');
$ti-icon-heart-broken: unicode('ecba');
$ti-icon-heart-rate-monitor: unicode('ef61');
$ti-icon-heartbeat: unicode('ef92');
$ti-icon-helicopter: unicode('ed8e');
$ti-icon-helicopter-landing: unicode('ed8d');
$ti-icon-help: unicode('eabf');
$ti-icon-hexagon: unicode('ec02');
$ti-icon-hexagon-off: unicode('ee9c');
$ti-icon-hierarchy: unicode('ee9e');
$ti-icon-hierarchy-2: unicode('ee9d');
$ti-icon-highlight: unicode('ef3f');
$ti-icon-history: unicode('ebea');
$ti-icon-home: unicode('eac1');
$ti-icon-home-2: unicode('eac0');
$ti-icon-hotel-service: unicode('ef80');
$ti-icon-hourglass: unicode('ef93');
$ti-icon-ice-cream: unicode('eac2');
$ti-icon-ice-cream-2: unicode('ee9f');
$ti-icon-id: unicode('eac3');
$ti-icon-inbox: unicode('eac4');
$ti-icon-indent-decrease: unicode('eb91');
$ti-icon-indent-increase: unicode('eb92');
$ti-icon-infinity: unicode('eb69');
$ti-icon-info-circle: unicode('eac5');
$ti-icon-info-square: unicode('eac6');
$ti-icon-italic: unicode('eb93');
$ti-icon-jump-rope: unicode('ed8f');
$ti-icon-karate: unicode('ed32');
$ti-icon-key: unicode('eac7');
$ti-icon-keyboard: unicode('ebd6');
$ti-icon-keyboard-hide: unicode('ec7e');
$ti-icon-keyboard-off: unicode('eea0');
$ti-icon-keyboard-show: unicode('ec7f');
$ti-icon-lamp: unicode('efab');
$ti-icon-language: unicode('ebbe');
$ti-icon-language-hiragana: unicode('ef77');
$ti-icon-language-katakana: unicode('ef78');
$ti-icon-lasso: unicode('efac');
$ti-icon-layers-difference: unicode('eac8');
$ti-icon-layers-intersect: unicode('eac9');
$ti-icon-layers-linked: unicode('eea1');
$ti-icon-layers-subtract: unicode('eaca');
$ti-icon-layers-union: unicode('eacb');
$ti-icon-layout: unicode('eadb');
$ti-icon-layout-2: unicode('eacc');
$ti-icon-layout-align-bottom: unicode('eacd');
$ti-icon-layout-align-center: unicode('eace');
$ti-icon-layout-align-left: unicode('eacf');
$ti-icon-layout-align-middle: unicode('ead0');
$ti-icon-layout-align-right: unicode('ead1');
$ti-icon-layout-align-top: unicode('ead2');
$ti-icon-layout-board: unicode('ef95');
$ti-icon-layout-board-split: unicode('ef94');
$ti-icon-layout-bottombar: unicode('ead3');
$ti-icon-layout-cards: unicode('ec13');
$ti-icon-layout-columns: unicode('ead4');
$ti-icon-layout-distribute-horizontal: unicode('ead5');
$ti-icon-layout-distribute-vertical: unicode('ead6');
$ti-icon-layout-grid: unicode('edba');
$ti-icon-layout-grid-add: unicode('edb9');
$ti-icon-layout-kanban: unicode('ec3f');
$ti-icon-layout-list: unicode('ec14');
$ti-icon-layout-navbar: unicode('ead7');
$ti-icon-layout-rows: unicode('ead8');
$ti-icon-layout-sidebar: unicode('eada');
$ti-icon-layout-sidebar-right: unicode('ead9');
$ti-icon-leaf: unicode('ed4f');
$ti-icon-lego: unicode('eadc');
$ti-icon-lemon: unicode('ef10');
$ti-icon-lemon-2: unicode('ef81');
$ti-icon-letter-a: unicode('ec50');
$ti-icon-letter-b: unicode('ec51');
$ti-icon-letter-c: unicode('ec52');
$ti-icon-letter-case: unicode('eea5');
$ti-icon-letter-case-lower: unicode('eea2');
$ti-icon-letter-case-toggle: unicode('eea3');
$ti-icon-letter-case-upper: unicode('eea4');
$ti-icon-letter-d: unicode('ec53');
$ti-icon-letter-e: unicode('ec54');
$ti-icon-letter-f: unicode('ec55');
$ti-icon-letter-g: unicode('ec56');
$ti-icon-letter-h: unicode('ec57');
$ti-icon-letter-i: unicode('ec58');
$ti-icon-letter-j: unicode('ec59');
$ti-icon-letter-k: unicode('ec5a');
$ti-icon-letter-l: unicode('ec5b');
$ti-icon-letter-m: unicode('ec5c');
$ti-icon-letter-n: unicode('ec5d');
$ti-icon-letter-o: unicode('ec5e');
$ti-icon-letter-p: unicode('ec5f');
$ti-icon-letter-q: unicode('ec60');
$ti-icon-letter-r: unicode('ec61');
$ti-icon-letter-s: unicode('ec62');
$ti-icon-letter-spacing: unicode('eea6');
$ti-icon-letter-t: unicode('ec63');
$ti-icon-letter-u: unicode('ec64');
$ti-icon-letter-v: unicode('ec65');
$ti-icon-letter-w: unicode('ec66');
$ti-icon-letter-x: unicode('ec67');
$ti-icon-letter-y: unicode('ec68');
$ti-icon-letter-z: unicode('ec69');
$ti-icon-letters-case: unicode('ec6a');
$ti-icon-license: unicode('ebc0');
$ti-icon-lifebuoy: unicode('eadd');
$ti-icon-line: unicode('ec40');
$ti-icon-line-dashed: unicode('eea7');
$ti-icon-line-dotted: unicode('eea8');
$ti-icon-line-height: unicode('eb94');
$ti-icon-link: unicode('eade');
$ti-icon-list: unicode('eb6b');
$ti-icon-list-check: unicode('eb6a');
$ti-icon-list-details: unicode('ef40');
$ti-icon-list-numbers: unicode('ef11');
$ti-icon-list-search: unicode('eea9');
$ti-icon-live-photo: unicode('eadf');
$ti-icon-live-view: unicode('ec6b');
$ti-icon-loader: unicode('eca3');
$ti-icon-loader-quarter: unicode('eca2');
$ti-icon-location: unicode('eae0');
$ti-icon-lock: unicode('eae2');
$ti-icon-lock-access: unicode('eeaa');
$ti-icon-lock-off: unicode('ed1e');
$ti-icon-lock-open: unicode('eae1');
$ti-icon-lock-square: unicode('ef51');
$ti-icon-login: unicode('eba7');
$ti-icon-logout: unicode('eba8');
$ti-icon-luggage: unicode('efad');
$ti-icon-lungs: unicode('ef62');
$ti-icon-macro: unicode('eeab');
$ti-icon-magnet: unicode('eae3');
$ti-icon-mail: unicode('eae5');
$ti-icon-mail-forward: unicode('eeac');
$ti-icon-mail-opened: unicode('eae4');
$ti-icon-mailbox: unicode('eead');
$ti-icon-man: unicode('eae6');
$ti-icon-manual-gearbox: unicode('ed7b');
$ti-icon-map: unicode('eae9');
$ti-icon-map-2: unicode('eae7');
$ti-icon-map-pin: unicode('eae8');
$ti-icon-map-pin-off: unicode('ecf3');
$ti-icon-map-pins: unicode('ed5e');
$ti-icon-map-search: unicode('ef82');
$ti-icon-markdown: unicode('ec41');
$ti-icon-marquee: unicode('ec77');
$ti-icon-marquee-2: unicode('eeae');
$ti-icon-mars: unicode('ec80');
$ti-icon-mask: unicode('eeb0');
$ti-icon-mask-off: unicode('eeaf');
$ti-icon-massage: unicode('eeb1');
$ti-icon-math: unicode('ebeb');
$ti-icon-math-function: unicode('eeb2');
$ti-icon-math-symbols: unicode('eeb3');
$ti-icon-maximize: unicode('eaea');
$ti-icon-meat: unicode('ef12');
$ti-icon-medal: unicode('ec78');
$ti-icon-medical-cross: unicode('ec2f');
$ti-icon-medicine-syrup: unicode('ef63');
$ti-icon-menu: unicode('eaeb');
$ti-icon-menu-2: unicode('ec42');
$ti-icon-message: unicode('eaef');
$ti-icon-message-2: unicode('eaec');
$ti-icon-message-circle: unicode('eaed');
$ti-icon-message-circle-2: unicode('ed3f');
$ti-icon-message-circle-off: unicode('ed40');
$ti-icon-message-dots: unicode('eaee');
$ti-icon-message-language: unicode('efae');
$ti-icon-message-off: unicode('ed41');
$ti-icon-message-plus: unicode('ec9a');
$ti-icon-message-report: unicode('ec9b');
$ti-icon-messages: unicode('eb6c');
$ti-icon-messages-off: unicode('ed42');
$ti-icon-microphone: unicode('eaf0');
$ti-icon-microphone-2: unicode('ef2c');
$ti-icon-microphone-off: unicode('ed16');
$ti-icon-microscope: unicode('ef64');
$ti-icon-milk: unicode('ef13');
$ti-icon-minimize: unicode('eaf1');
$ti-icon-minus: unicode('eaf2');
$ti-icon-minus-vertical: unicode('eeb4');
$ti-icon-mist: unicode('ec30');
$ti-icon-mood-boy: unicode('ed2d');
$ti-icon-mood-confuzed: unicode('eaf3');
$ti-icon-mood-crazy-happy: unicode('ed90');
$ti-icon-mood-cry: unicode('ecbb');
$ti-icon-mood-empty: unicode('eeb5');
$ti-icon-mood-happy: unicode('eaf4');
$ti-icon-mood-kid: unicode('ec03');
$ti-icon-mood-nervous: unicode('ef96');
$ti-icon-mood-neutral: unicode('eaf5');
$ti-icon-mood-sad: unicode('eaf6');
$ti-icon-mood-smile: unicode('eaf7');
$ti-icon-mood-suprised: unicode('ec04');
$ti-icon-mood-tongue: unicode('eb95');
$ti-icon-moon: unicode('eaf8');
$ti-icon-moon-2: unicode('ece6');
$ti-icon-moon-stars: unicode('ece7');
$ti-icon-moped: unicode('ecbc');
$ti-icon-motorbike: unicode('eeb6');
$ti-icon-mountain: unicode('ef97');
$ti-icon-mouse: unicode('eaf9');
$ti-icon-movie: unicode('eafa');
$ti-icon-mug: unicode('eafb');
$ti-icon-multiplier-0-5x: unicode('ef41');
$ti-icon-multiplier-1-5x: unicode('ef42');
$ti-icon-multiplier-1x: unicode('ef43');
$ti-icon-multiplier-2x: unicode('ef44');
$ti-icon-mushroom: unicode('ef14');
$ti-icon-music: unicode('eafc');
$ti-icon-new-section: unicode('ebc1');
$ti-icon-news: unicode('eafd');
$ti-icon-nfc: unicode('eeb7');
$ti-icon-note: unicode('eb6d');
$ti-icon-notebook: unicode('eb96');
$ti-icon-notes: unicode('eb6e');
$ti-icon-notification: unicode('eafe');
$ti-icon-number-0: unicode('edf0');
$ti-icon-number-1: unicode('edf1');
$ti-icon-number-2: unicode('edf2');
$ti-icon-number-3: unicode('edf3');
$ti-icon-number-4: unicode('edf4');
$ti-icon-number-5: unicode('edf5');
$ti-icon-number-6: unicode('edf6');
$ti-icon-number-7: unicode('edf7');
$ti-icon-number-8: unicode('edf8');
$ti-icon-number-9: unicode('edf9');
$ti-icon-nurse: unicode('ef65');
$ti-icon-octagon: unicode('ecbd');
$ti-icon-octagon-off: unicode('eeb8');
$ti-icon-old: unicode('eeb9');
$ti-icon-olympics: unicode('eeba');
$ti-icon-omega: unicode('eb97');
$ti-icon-outlet: unicode('ebd7');
$ti-icon-overline: unicode('eebb');
$ti-icon-package: unicode('eaff');
$ti-icon-pacman: unicode('eebc');
$ti-icon-page-break: unicode('ec81');
$ti-icon-paint: unicode('eb00');
$ti-icon-palette: unicode('eb01');
$ti-icon-panorama-horizontal: unicode('ed33');
$ti-icon-panorama-vertical: unicode('ed34');
$ti-icon-paperclip: unicode('eb02');
$ti-icon-parachute: unicode('ed7c');
$ti-icon-parentheses: unicode('ebd8');
$ti-icon-parking: unicode('eb03');
$ti-icon-peace: unicode('ecbe');
$ti-icon-pencil: unicode('eb04');
$ti-icon-pennant: unicode('ed7d');
$ti-icon-pepper: unicode('ef15');
$ti-icon-percentage: unicode('ecf4');
$ti-icon-perspective: unicode('eebd');
$ti-icon-phone: unicode('eb09');
$ti-icon-phone-call: unicode('eb05');
$ti-icon-phone-calling: unicode('ec43');
$ti-icon-phone-check: unicode('ec05');
$ti-icon-phone-incoming: unicode('eb06');
$ti-icon-phone-off: unicode('ecf5');
$ti-icon-phone-outgoing: unicode('eb07');
$ti-icon-phone-pause: unicode('eb08');
$ti-icon-phone-plus: unicode('ec06');
$ti-icon-phone-x: unicode('ec07');
$ti-icon-photo: unicode('eb0a');
$ti-icon-photo-off: unicode('ecf6');
$ti-icon-physotherapist: unicode('eebe');
$ti-icon-picture-in-picture: unicode('ed35');
$ti-icon-picture-in-picture-off: unicode('ed43');
$ti-icon-picture-in-picture-on: unicode('ed44');
$ti-icon-pig: unicode('ef52');
$ti-icon-pill: unicode('ec44');
$ti-icon-pills: unicode('ef66');
$ti-icon-pin: unicode('ec9c');
$ti-icon-pinned: unicode('ed60');
$ti-icon-pinned-off: unicode('ed5f');
$ti-icon-pizza: unicode('edbb');
$ti-icon-plane: unicode('eb6f');
$ti-icon-plane-arrival: unicode('eb99');
$ti-icon-plane-departure: unicode('eb9a');
$ti-icon-plane-inflight: unicode('ef98');
$ti-icon-planet: unicode('ec08');
$ti-icon-plant: unicode('ed50');
$ti-icon-plant-2: unicode('ed7e');
$ti-icon-play-card: unicode('eebf');
$ti-icon-player-pause: unicode('ed45');
$ti-icon-player-play: unicode('ed46');
$ti-icon-player-record: unicode('ed47');
$ti-icon-player-skip-back: unicode('ed48');
$ti-icon-player-skip-forward: unicode('ed49');
$ti-icon-player-stop: unicode('ed4a');
$ti-icon-player-track-next: unicode('ed4b');
$ti-icon-player-track-prev: unicode('ed4c');
$ti-icon-playlist: unicode('eec0');
$ti-icon-plug: unicode('ebd9');
$ti-icon-plus: unicode('eb0b');
$ti-icon-point: unicode('eb0c');
$ti-icon-pokeball: unicode('eec1');
$ti-icon-polaroid: unicode('eec2');
$ti-icon-pool: unicode('ed91');
$ti-icon-power: unicode('eb0d');
$ti-icon-pray: unicode('ecbf');
$ti-icon-prescription: unicode('ef99');
$ti-icon-presentation: unicode('eb70');
$ti-icon-presentation-analytics: unicode('eec3');
$ti-icon-printer: unicode('eb0e');
$ti-icon-prison: unicode('ef79');
$ti-icon-prompt: unicode('eb0f');
$ti-icon-propeller: unicode('eec4');
$ti-icon-puzzle: unicode('eb10');
$ti-icon-puzzle-2: unicode('ef83');
$ti-icon-pyramid: unicode('eec5');
$ti-icon-qrcode: unicode('eb11');
$ti-icon-question-mark: unicode('ec9d');
$ti-icon-radio: unicode('ef2d');
$ti-icon-radioactive: unicode('ecc0');
$ti-icon-radius-bottom-left: unicode('eec6');
$ti-icon-radius-bottom-right: unicode('eec7');
$ti-icon-radius-top-left: unicode('eec8');
$ti-icon-radius-top-right: unicode('eec9');
$ti-icon-rainbow: unicode('edbc');
$ti-icon-receipt: unicode('edfd');
$ti-icon-receipt-2: unicode('edfa');
$ti-icon-receipt-off: unicode('edfb');
$ti-icon-receipt-refund: unicode('edfc');
$ti-icon-receipt-tax: unicode('edbd');
$ti-icon-recharging: unicode('eeca');
$ti-icon-record-mail: unicode('eb12');
$ti-icon-rectangle: unicode('ed37');
$ti-icon-rectangle-vertical: unicode('ed36');
$ti-icon-recycle: unicode('eb9b');
$ti-icon-refresh: unicode('eb13');
$ti-icon-refresh-alert: unicode('ed57');
$ti-icon-registered: unicode('eb14');
$ti-icon-relation-many-to-many: unicode('ed7f');
$ti-icon-relation-one-to-many: unicode('ed80');
$ti-icon-relation-one-to-one: unicode('ed81');
$ti-icon-repeat: unicode('eb72');
$ti-icon-repeat-once: unicode('eb71');
$ti-icon-replace: unicode('ebc7');
$ti-icon-report: unicode('eece');
$ti-icon-report-analytics: unicode('eecb');
$ti-icon-report-medical: unicode('eecc');
$ti-icon-report-money: unicode('eecd');
$ti-icon-report-search: unicode('ef84');
$ti-icon-resize: unicode('eecf');
$ti-icon-ripple: unicode('ed82');
$ti-icon-road-sign: unicode('ecdd');
$ti-icon-rocket: unicode('ec45');
$ti-icon-rotate: unicode('eb16');
$ti-icon-rotate-2: unicode('ebb4');
$ti-icon-rotate-360: unicode('ef85');
$ti-icon-rotate-clockwise: unicode('eb15');
$ti-icon-rotate-clockwise-2: unicode('ebb5');
$ti-icon-rotate-rectangle: unicode('ec15');
$ti-icon-route: unicode('eb17');
$ti-icon-router: unicode('eb18');
$ti-icon-row-insert-bottom: unicode('eed0');
$ti-icon-row-insert-top: unicode('eed1');
$ti-icon-rss: unicode('eb19');
$ti-icon-ruler: unicode('eb1a');
$ti-icon-ruler-2: unicode('eed2');
$ti-icon-run: unicode('ec82');
$ti-icon-sailboat: unicode('ec83');
$ti-icon-salt: unicode('ef16');
$ti-icon-satellite: unicode('eed3');
$ti-icon-sausage: unicode('ef17');
$ti-icon-scale: unicode('ebc2');
$ti-icon-scale-outline: unicode('ef53');
$ti-icon-scan: unicode('ebc8');
$ti-icon-school: unicode('ecf7');
$ti-icon-scissors: unicode('eb1b');
$ti-icon-scooter: unicode('ec6c');
$ti-icon-scooter-electric: unicode('ecc1');
$ti-icon-screen-share: unicode('ed18');
$ti-icon-screen-share-off: unicode('ed17');
$ti-icon-scuba-mask: unicode('eed4');
$ti-icon-search: unicode('eb1c');
$ti-icon-section: unicode('eed5');
$ti-icon-seeding: unicode('ed51');
$ti-icon-select: unicode('ec9e');
$ti-icon-selector: unicode('eb1d');
$ti-icon-send: unicode('eb1e');
$ti-icon-separator: unicode('ebda');
$ti-icon-separator-horizontal: unicode('ec79');
$ti-icon-separator-vertical: unicode('ec7a');
$ti-icon-server: unicode('eb1f');
$ti-icon-servicemark: unicode('ec09');
$ti-icon-settings: unicode('eb20');
$ti-icon-settings-automation: unicode('eed6');
$ti-icon-shadow: unicode('eed8');
$ti-icon-shadow-off: unicode('eed7');
$ti-icon-shape: unicode('eb9c');
$ti-icon-shape-2: unicode('eed9');
$ti-icon-shape-3: unicode('eeda');
$ti-icon-share: unicode('eb21');
$ti-icon-shield: unicode('eb24');
$ti-icon-shield-check: unicode('eb22');
$ti-icon-shield-checkered: unicode('ef9a');
$ti-icon-shield-chevron: unicode('ef9b');
$ti-icon-shield-lock: unicode('ed58');
$ti-icon-shield-off: unicode('ecf8');
$ti-icon-shield-x: unicode('eb23');
$ti-icon-ship: unicode('ec84');
$ti-icon-shirt: unicode('ec0a');
$ti-icon-shopping-cart: unicode('eb25');
$ti-icon-shopping-cart-discount: unicode('eedb');
$ti-icon-shopping-cart-off: unicode('eedc');
$ti-icon-shopping-cart-plus: unicode('eedd');
$ti-icon-shopping-cart-x: unicode('eede');
$ti-icon-shredder: unicode('eedf');
$ti-icon-signature: unicode('eee0');
$ti-icon-sitemap: unicode('eb9d');
$ti-icon-skateboard: unicode('ecc2');
$ti-icon-sleigh: unicode('ef9c');
$ti-icon-slice: unicode('ebdb');
$ti-icon-slideshow: unicode('ebc9');
$ti-icon-smart-home: unicode('ecde');
$ti-icon-smoking: unicode('ecc4');
$ti-icon-smoking-no: unicode('ecc3');
$ti-icon-snowflake: unicode('ec0b');
$ti-icon-soccer-field: unicode('ed92');
$ti-icon-social: unicode('ebec');
$ti-icon-sock: unicode('eee1');
$ti-icon-sofa: unicode('efaf');
$ti-icon-sort-ascending: unicode('eb26');
$ti-icon-sort-ascending-2: unicode('eee2');
$ti-icon-sort-ascending-letters: unicode('ef18');
$ti-icon-sort-ascending-numbers: unicode('ef19');
$ti-icon-sort-descending: unicode('eb27');
$ti-icon-sort-descending-2: unicode('eee3');
$ti-icon-sort-descending-letters: unicode('ef1a');
$ti-icon-sort-descending-numbers: unicode('ef1b');
$ti-icon-soup: unicode('ef2e');
$ti-icon-space: unicode('ec0c');
$ti-icon-spacing-horizontal: unicode('ef54');
$ti-icon-spacing-vertical: unicode('ef55');
$ti-icon-speakerphone: unicode('ed61');
$ti-icon-speedboat: unicode('ed93');
$ti-icon-sport-billard: unicode('eee4');
$ti-icon-square: unicode('eb2c');
$ti-icon-square-0: unicode('eee5');
$ti-icon-square-1: unicode('eee6');
$ti-icon-square-2: unicode('eee7');
$ti-icon-square-3: unicode('eee8');
$ti-icon-square-4: unicode('eee9');
$ti-icon-square-5: unicode('eeea');
$ti-icon-square-6: unicode('eeeb');
$ti-icon-square-7: unicode('eeec');
$ti-icon-square-8: unicode('eeed');
$ti-icon-square-9: unicode('eeee');
$ti-icon-square-check: unicode('eb28');
$ti-icon-square-dot: unicode('ed59');
$ti-icon-square-forbid: unicode('ed5b');
$ti-icon-square-forbid-2: unicode('ed5a');
$ti-icon-square-minus: unicode('eb29');
$ti-icon-square-off: unicode('eeef');
$ti-icon-square-plus: unicode('eb2a');
$ti-icon-square-root: unicode('eef1');
$ti-icon-square-root-2: unicode('eef0');
$ti-icon-square-rotated: unicode('ecdf');
$ti-icon-square-rotated-off: unicode('eef2');
$ti-icon-square-toggle: unicode('eef4');
$ti-icon-square-toggle-horizontal: unicode('eef3');
$ti-icon-square-x: unicode('eb2b');
$ti-icon-squares-diagonal: unicode('eef5');
$ti-icon-squares-filled: unicode('eef6');
$ti-icon-stack: unicode('eb2d');
$ti-icon-stack-2: unicode('eef7');
$ti-icon-stack-3: unicode('ef9d');
$ti-icon-stairs: unicode('eca6');
$ti-icon-stairs-down: unicode('eca4');
$ti-icon-stairs-up: unicode('eca5');
$ti-icon-star: unicode('eb2e');
$ti-icon-star-half: unicode('ed19');
$ti-icon-star-off: unicode('ed62');
$ti-icon-stars: unicode('ed38');
$ti-icon-steering-wheel: unicode('ec7b');
$ti-icon-step-into: unicode('ece0');
$ti-icon-step-out: unicode('ece1');
$ti-icon-stethoscope: unicode('edbe');
$ti-icon-sticker: unicode('eb2f');
$ti-icon-strikethrough: unicode('eb9e');
$ti-icon-submarine: unicode('ed94');
$ti-icon-subscript: unicode('eb9f');
$ti-icon-subtask: unicode('ec9f');
$ti-icon-sum: unicode('eb73');
$ti-icon-sun: unicode('eb30');
$ti-icon-sun-off: unicode('ed63');
$ti-icon-sunrise: unicode('ef1c');
$ti-icon-sunset: unicode('ec31');
$ti-icon-superscript: unicode('eba0');
$ti-icon-swimming: unicode('ec92');
$ti-icon-switch: unicode('eb33');
$ti-icon-switch-2: unicode('edbf');
$ti-icon-switch-3: unicode('edc0');
$ti-icon-switch-horizontal: unicode('eb31');
$ti-icon-switch-vertical: unicode('eb32');
$ti-icon-table: unicode('eba1');
$ti-icon-table-export: unicode('eef8');
$ti-icon-table-import: unicode('eef9');
$ti-icon-table-off: unicode('eefa');
$ti-icon-tag: unicode('eb34');
$ti-icon-tags: unicode('ef86');
$ti-icon-tallymark-1: unicode('ec46');
$ti-icon-tallymark-2: unicode('ec47');
$ti-icon-tallymark-3: unicode('ec48');
$ti-icon-tallymark-4: unicode('ec49');
$ti-icon-tallymarks: unicode('ec4a');
$ti-icon-tank: unicode('ed95');
$ti-icon-target: unicode('eb35');
$ti-icon-temperature: unicode('eb38');
$ti-icon-temperature-celsius: unicode('eb36');
$ti-icon-temperature-fahrenheit: unicode('eb37');
$ti-icon-temperature-minus: unicode('ebed');
$ti-icon-temperature-plus: unicode('ebee');
$ti-icon-template: unicode('eb39');
$ti-icon-tent: unicode('eefb');
$ti-icon-terminal: unicode('ebdc');
$ti-icon-terminal-2: unicode('ebef');
$ti-icon-test-pipe: unicode('eb3a');
$ti-icon-text-direction-ltr: unicode('eefc');
$ti-icon-text-direction-rtl: unicode('eefd');
$ti-icon-text-resize: unicode('ef87');
$ti-icon-text-wrap: unicode('ebdd');
$ti-icon-text-wrap-disabled: unicode('eca7');
$ti-icon-thermometer: unicode('ef67');
$ti-icon-thumb-down: unicode('eb3b');
$ti-icon-thumb-up: unicode('eb3c');
$ti-icon-ticket: unicode('eb3d');
$ti-icon-tilt-shift: unicode('eefe');
$ti-icon-tir: unicode('ebf0');
$ti-icon-toggle-left: unicode('eb3e');
$ti-icon-toggle-right: unicode('eb3f');
$ti-icon-tool: unicode('eb40');
$ti-icon-tools: unicode('ebca');
$ti-icon-tools-kitchen: unicode('ed64');
$ti-icon-tools-kitchen-2: unicode('eeff');
$ti-icon-tornado: unicode('ece2');
$ti-icon-tournament: unicode('ecd0');
$ti-icon-track: unicode('ef00');
$ti-icon-tractor: unicode('ec0d');
$ti-icon-trademark: unicode('ec0e');
$ti-icon-traffic-cone: unicode('ec0f');
$ti-icon-traffic-lights: unicode('ed39');
$ti-icon-train: unicode('ed96');
$ti-icon-transfer-in: unicode('ef2f');
$ti-icon-transfer-out: unicode('ef30');
$ti-icon-trash: unicode('eb41');
$ti-icon-trash-off: unicode('ed65');
$ti-icon-trash-x: unicode('ef88');
$ti-icon-tree: unicode('ef01');
$ti-icon-trees: unicode('ec10');
$ti-icon-trending-down: unicode('eb42');
$ti-icon-trending-down-2: unicode('edc1');
$ti-icon-trending-down-3: unicode('edc2');
$ti-icon-trending-up: unicode('eb43');
$ti-icon-trending-up-2: unicode('edc3');
$ti-icon-trending-up-3: unicode('edc4');
$ti-icon-triangle: unicode('eb44');
$ti-icon-triangle-off: unicode('ef02');
$ti-icon-triangle-square-circle: unicode('ece8');
$ti-icon-trident: unicode('ecc5');
$ti-icon-trophy: unicode('eb45');
$ti-icon-truck: unicode('ebc4');
$ti-icon-truck-delivery: unicode('ec4b');
$ti-icon-truck-off: unicode('ef03');
$ti-icon-truck-return: unicode('ec4c');
$ti-icon-typography: unicode('ebc5');
$ti-icon-umbrella: unicode('ebf1');
$ti-icon-underline: unicode('eba2');
$ti-icon-unlink: unicode('eb46');
$ti-icon-upload: unicode('eb47');
$ti-icon-urgent: unicode('eb48');
$ti-icon-user: unicode('eb4d');
$ti-icon-user-check: unicode('eb49');
$ti-icon-user-circle: unicode('ef68');
$ti-icon-user-exclamation: unicode('ec12');
$ti-icon-user-minus: unicode('eb4a');
$ti-icon-user-off: unicode('ecf9');
$ti-icon-user-plus: unicode('eb4b');
$ti-icon-user-search: unicode('ef89');
$ti-icon-user-x: unicode('eb4c');
$ti-icon-users: unicode('ebf2');
$ti-icon-vaccine: unicode('ef04');
$ti-icon-vaccine-bottle: unicode('ef69');
$ti-icon-variable: unicode('ef05');
$ti-icon-vector: unicode('eca9');
$ti-icon-vector-beizer-2: unicode('ed3a');
$ti-icon-vector-bezier: unicode('ef1d');
$ti-icon-vector-triangle: unicode('eca8');
$ti-icon-venus: unicode('ec86');
$ti-icon-versions: unicode('ed52');
$ti-icon-video: unicode('ed22');
$ti-icon-video-minus: unicode('ed1f');
$ti-icon-video-off: unicode('ed20');
$ti-icon-video-plus: unicode('ed21');
$ti-icon-view-360: unicode('ed84');
$ti-icon-viewfinder: unicode('eb4e');
$ti-icon-viewport-narrow: unicode('ebf3');
$ti-icon-viewport-wide: unicode('ebf4');
$ti-icon-virus: unicode('eb74');
$ti-icon-virus-off: unicode('ed66');
$ti-icon-virus-search: unicode('ed67');
$ti-icon-vocabulary: unicode('ef1e');
$ti-icon-volume: unicode('eb51');
$ti-icon-volume-2: unicode('eb4f');
$ti-icon-volume-3: unicode('eb50');
$ti-icon-walk: unicode('ec87');
$ti-icon-wall: unicode('ef7a');
$ti-icon-wallet: unicode('eb75');
$ti-icon-wallpaper: unicode('ef56');
$ti-icon-wand: unicode('ebcb');
$ti-icon-wave-saw-tool: unicode('ecd3');
$ti-icon-wave-sine: unicode('ecd4');
$ti-icon-wave-square: unicode('ecd5');
$ti-icon-wifi: unicode('eb52');
$ti-icon-wifi-0: unicode('eba3');
$ti-icon-wifi-1: unicode('eba4');
$ti-icon-wifi-2: unicode('eba5');
$ti-icon-wifi-off: unicode('ecfa');
$ti-icon-wind: unicode('ec34');
$ti-icon-windmill: unicode('ed85');
$ti-icon-window: unicode('ef06');
$ti-icon-wiper: unicode('ecab');
$ti-icon-wiper-wash: unicode('ecaa');
$ti-icon-woman: unicode('eb53');
$ti-icon-world: unicode('eb54');
$ti-icon-world-download: unicode('ef8a');
$ti-icon-world-latitude: unicode('ed2e');
$ti-icon-world-longitude: unicode('ed2f');
$ti-icon-world-upload: unicode('ef8b');
$ti-icon-wrecking-ball: unicode('ed97');
$ti-icon-writing: unicode('ef08');
$ti-icon-writing-sign: unicode('ef07');
$ti-icon-x: unicode('eb55');
$ti-icon-yin-yang: unicode('ec35');
$ti-icon-zodiac-aquarius: unicode('ecac');
$ti-icon-zodiac-aries: unicode('ecad');
$ti-icon-zodiac-cancer: unicode('ecae');
$ti-icon-zodiac-capricorn: unicode('ecaf');
$ti-icon-zodiac-gemini: unicode('ecb0');
$ti-icon-zodiac-leo: unicode('ecb1');
$ti-icon-zodiac-libra: unicode('ecb2');
$ti-icon-zodiac-pisces: unicode('ecb3');
$ti-icon-zodiac-sagittarius: unicode('ecb4');
$ti-icon-zodiac-scorpio: unicode('ecb5');
$ti-icon-zodiac-taurus: unicode('ecb6');
$ti-icon-zodiac-virgo: unicode('ecb7');
$ti-icon-zoom-cancel: unicode('ec4d');
$ti-icon-zoom-check: unicode('ef09');
$ti-icon-zoom-in: unicode('eb56');
$ti-icon-zoom-money: unicode('ef0a');
$ti-icon-zoom-out: unicode('eb57');
$ti-icon-zoom-question: unicode('edeb');


.#{$ti-prefix}-2fa:before { content: $ti-icon-2fa; }
.#{$ti-prefix}-3d-cube-sphere:before { content: $ti-icon-3d-cube-sphere; }
.#{$ti-prefix}-a-b:before { content: $ti-icon-a-b; }
.#{$ti-prefix}-access-point:before { content: $ti-icon-access-point; }
.#{$ti-prefix}-access-point-off:before { content: $ti-icon-access-point-off; }
.#{$ti-prefix}-accessible:before { content: $ti-icon-accessible; }
.#{$ti-prefix}-activity:before { content: $ti-icon-activity; }
.#{$ti-prefix}-ad:before { content: $ti-icon-ad; }
.#{$ti-prefix}-ad-2:before { content: $ti-icon-ad-2; }
.#{$ti-prefix}-adjustments:before { content: $ti-icon-adjustments; }
.#{$ti-prefix}-adjustments-alt:before { content: $ti-icon-adjustments-alt; }
.#{$ti-prefix}-adjustments-horizontal:before { content: $ti-icon-adjustments-horizontal; }
.#{$ti-prefix}-aerial-lift:before { content: $ti-icon-aerial-lift; }
.#{$ti-prefix}-affiliate:before { content: $ti-icon-affiliate; }
.#{$ti-prefix}-alarm:before { content: $ti-icon-alarm; }
.#{$ti-prefix}-alert-circle:before { content: $ti-icon-alert-circle; }
.#{$ti-prefix}-alert-octagon:before { content: $ti-icon-alert-octagon; }
.#{$ti-prefix}-alert-triangle:before { content: $ti-icon-alert-triangle; }
.#{$ti-prefix}-alien:before { content: $ti-icon-alien; }
.#{$ti-prefix}-align-center:before { content: $ti-icon-align-center; }
.#{$ti-prefix}-align-justified:before { content: $ti-icon-align-justified; }
.#{$ti-prefix}-align-left:before { content: $ti-icon-align-left; }
.#{$ti-prefix}-align-right:before { content: $ti-icon-align-right; }
.#{$ti-prefix}-ambulance:before { content: $ti-icon-ambulance; }
.#{$ti-prefix}-anchor:before { content: $ti-icon-anchor; }
.#{$ti-prefix}-angle:before { content: $ti-icon-angle; }
.#{$ti-prefix}-antenna-bars-1:before { content: $ti-icon-antenna-bars-1; }
.#{$ti-prefix}-antenna-bars-2:before { content: $ti-icon-antenna-bars-2; }
.#{$ti-prefix}-antenna-bars-3:before { content: $ti-icon-antenna-bars-3; }
.#{$ti-prefix}-antenna-bars-4:before { content: $ti-icon-antenna-bars-4; }
.#{$ti-prefix}-antenna-bars-5:before { content: $ti-icon-antenna-bars-5; }
.#{$ti-prefix}-aperture:before { content: $ti-icon-aperture; }
.#{$ti-prefix}-apple:before { content: $ti-icon-apple; }
.#{$ti-prefix}-apps:before { content: $ti-icon-apps; }
.#{$ti-prefix}-archive:before { content: $ti-icon-archive; }
.#{$ti-prefix}-armchair:before { content: $ti-icon-armchair; }
.#{$ti-prefix}-arrow-autofit-content:before { content: $ti-icon-arrow-autofit-content; }
.#{$ti-prefix}-arrow-autofit-down:before { content: $ti-icon-arrow-autofit-down; }
.#{$ti-prefix}-arrow-autofit-height:before { content: $ti-icon-arrow-autofit-height; }
.#{$ti-prefix}-arrow-autofit-left:before { content: $ti-icon-arrow-autofit-left; }
.#{$ti-prefix}-arrow-autofit-right:before { content: $ti-icon-arrow-autofit-right; }
.#{$ti-prefix}-arrow-autofit-up:before { content: $ti-icon-arrow-autofit-up; }
.#{$ti-prefix}-arrow-autofit-width:before { content: $ti-icon-arrow-autofit-width; }
.#{$ti-prefix}-arrow-back:before { content: $ti-icon-arrow-back; }
.#{$ti-prefix}-arrow-back-up:before { content: $ti-icon-arrow-back-up; }
.#{$ti-prefix}-arrow-bar-down:before { content: $ti-icon-arrow-bar-down; }
.#{$ti-prefix}-arrow-bar-left:before { content: $ti-icon-arrow-bar-left; }
.#{$ti-prefix}-arrow-bar-right:before { content: $ti-icon-arrow-bar-right; }
.#{$ti-prefix}-arrow-bar-to-down:before { content: $ti-icon-arrow-bar-to-down; }
.#{$ti-prefix}-arrow-bar-to-left:before { content: $ti-icon-arrow-bar-to-left; }
.#{$ti-prefix}-arrow-bar-to-right:before { content: $ti-icon-arrow-bar-to-right; }
.#{$ti-prefix}-arrow-bar-to-up:before { content: $ti-icon-arrow-bar-to-up; }
.#{$ti-prefix}-arrow-bar-up:before { content: $ti-icon-arrow-bar-up; }
.#{$ti-prefix}-arrow-big-down:before { content: $ti-icon-arrow-big-down; }
.#{$ti-prefix}-arrow-big-left:before { content: $ti-icon-arrow-big-left; }
.#{$ti-prefix}-arrow-big-right:before { content: $ti-icon-arrow-big-right; }
.#{$ti-prefix}-arrow-big-top:before { content: $ti-icon-arrow-big-top; }
.#{$ti-prefix}-arrow-bottom-bar:before { content: $ti-icon-arrow-bottom-bar; }
.#{$ti-prefix}-arrow-bottom-circle:before { content: $ti-icon-arrow-bottom-circle; }
.#{$ti-prefix}-arrow-bottom-square:before { content: $ti-icon-arrow-bottom-square; }
.#{$ti-prefix}-arrow-bottom-tail:before { content: $ti-icon-arrow-bottom-tail; }
.#{$ti-prefix}-arrow-down:before { content: $ti-icon-arrow-down; }
.#{$ti-prefix}-arrow-down-circle:before { content: $ti-icon-arrow-down-circle; }
.#{$ti-prefix}-arrow-down-left:before { content: $ti-icon-arrow-down-left; }
.#{$ti-prefix}-arrow-down-left-circle:before { content: $ti-icon-arrow-down-left-circle; }
.#{$ti-prefix}-arrow-down-right:before { content: $ti-icon-arrow-down-right; }
.#{$ti-prefix}-arrow-down-right-circle:before { content: $ti-icon-arrow-down-right-circle; }
.#{$ti-prefix}-arrow-forward:before { content: $ti-icon-arrow-forward; }
.#{$ti-prefix}-arrow-forward-up:before { content: $ti-icon-arrow-forward-up; }
.#{$ti-prefix}-arrow-left:before { content: $ti-icon-arrow-left; }
.#{$ti-prefix}-arrow-left-bar:before { content: $ti-icon-arrow-left-bar; }
.#{$ti-prefix}-arrow-left-circle:before { content: $ti-icon-arrow-left-circle; }
.#{$ti-prefix}-arrow-left-square:before { content: $ti-icon-arrow-left-square; }
.#{$ti-prefix}-arrow-left-tail:before { content: $ti-icon-arrow-left-tail; }
.#{$ti-prefix}-arrow-loop-left:before { content: $ti-icon-arrow-loop-left; }
.#{$ti-prefix}-arrow-loop-right:before { content: $ti-icon-arrow-loop-right; }
.#{$ti-prefix}-arrow-narrow-down:before { content: $ti-icon-arrow-narrow-down; }
.#{$ti-prefix}-arrow-narrow-left:before { content: $ti-icon-arrow-narrow-left; }
.#{$ti-prefix}-arrow-narrow-right:before { content: $ti-icon-arrow-narrow-right; }
.#{$ti-prefix}-arrow-narrow-up:before { content: $ti-icon-arrow-narrow-up; }
.#{$ti-prefix}-arrow-ramp-left:before { content: $ti-icon-arrow-ramp-left; }
.#{$ti-prefix}-arrow-ramp-right:before { content: $ti-icon-arrow-ramp-right; }
.#{$ti-prefix}-arrow-right:before { content: $ti-icon-arrow-right; }
.#{$ti-prefix}-arrow-right-bar:before { content: $ti-icon-arrow-right-bar; }
.#{$ti-prefix}-arrow-right-circle:before { content: $ti-icon-arrow-right-circle; }
.#{$ti-prefix}-arrow-right-square:before { content: $ti-icon-arrow-right-square; }
.#{$ti-prefix}-arrow-right-tail:before { content: $ti-icon-arrow-right-tail; }
.#{$ti-prefix}-arrow-top-bar:before { content: $ti-icon-arrow-top-bar; }
.#{$ti-prefix}-arrow-top-circle:before { content: $ti-icon-arrow-top-circle; }
.#{$ti-prefix}-arrow-top-square:before { content: $ti-icon-arrow-top-square; }
.#{$ti-prefix}-arrow-top-tail:before { content: $ti-icon-arrow-top-tail; }
.#{$ti-prefix}-arrow-up:before { content: $ti-icon-arrow-up; }
.#{$ti-prefix}-arrow-up-circle:before { content: $ti-icon-arrow-up-circle; }
.#{$ti-prefix}-arrow-up-left:before { content: $ti-icon-arrow-up-left; }
.#{$ti-prefix}-arrow-up-left-circle:before { content: $ti-icon-arrow-up-left-circle; }
.#{$ti-prefix}-arrow-up-right:before { content: $ti-icon-arrow-up-right; }
.#{$ti-prefix}-arrow-up-right-circle:before { content: $ti-icon-arrow-up-right-circle; }
.#{$ti-prefix}-arrow-wave-left-down:before { content: $ti-icon-arrow-wave-left-down; }
.#{$ti-prefix}-arrow-wave-left-up:before { content: $ti-icon-arrow-wave-left-up; }
.#{$ti-prefix}-arrow-wave-right-down:before { content: $ti-icon-arrow-wave-right-down; }
.#{$ti-prefix}-arrow-wave-right-up:before { content: $ti-icon-arrow-wave-right-up; }
.#{$ti-prefix}-arrows-diagonal:before { content: $ti-icon-arrows-diagonal; }
.#{$ti-prefix}-arrows-diagonal-2:before { content: $ti-icon-arrows-diagonal-2; }
.#{$ti-prefix}-arrows-diagonal-minimize:before { content: $ti-icon-arrows-diagonal-minimize; }
.#{$ti-prefix}-arrows-diagonal-minimize-2:before { content: $ti-icon-arrows-diagonal-minimize-2; }
.#{$ti-prefix}-arrows-double-ne-sw:before { content: $ti-icon-arrows-double-ne-sw; }
.#{$ti-prefix}-arrows-double-nw-se:before { content: $ti-icon-arrows-double-nw-se; }
.#{$ti-prefix}-arrows-double-se-nw:before { content: $ti-icon-arrows-double-se-nw; }
.#{$ti-prefix}-arrows-double-sw-ne:before { content: $ti-icon-arrows-double-sw-ne; }
.#{$ti-prefix}-arrows-down:before { content: $ti-icon-arrows-down; }
.#{$ti-prefix}-arrows-down-up:before { content: $ti-icon-arrows-down-up; }
.#{$ti-prefix}-arrows-horizontal:before { content: $ti-icon-arrows-horizontal; }
.#{$ti-prefix}-arrows-join:before { content: $ti-icon-arrows-join; }
.#{$ti-prefix}-arrows-join-2:before { content: $ti-icon-arrows-join-2; }
.#{$ti-prefix}-arrows-left:before { content: $ti-icon-arrows-left; }
.#{$ti-prefix}-arrows-left-down:before { content: $ti-icon-arrows-left-down; }
.#{$ti-prefix}-arrows-left-right:before { content: $ti-icon-arrows-left-right; }
.#{$ti-prefix}-arrows-maximize:before { content: $ti-icon-arrows-maximize; }
.#{$ti-prefix}-arrows-minimize:before { content: $ti-icon-arrows-minimize; }
.#{$ti-prefix}-arrows-right:before { content: $ti-icon-arrows-right; }
.#{$ti-prefix}-arrows-right-down:before { content: $ti-icon-arrows-right-down; }
.#{$ti-prefix}-arrows-right-left:before { content: $ti-icon-arrows-right-left; }
.#{$ti-prefix}-arrows-sort:before { content: $ti-icon-arrows-sort; }
.#{$ti-prefix}-arrows-split:before { content: $ti-icon-arrows-split; }
.#{$ti-prefix}-arrows-split-2:before { content: $ti-icon-arrows-split-2; }
.#{$ti-prefix}-arrows-up:before { content: $ti-icon-arrows-up; }
.#{$ti-prefix}-arrows-up-down:before { content: $ti-icon-arrows-up-down; }
.#{$ti-prefix}-arrows-up-left:before { content: $ti-icon-arrows-up-left; }
.#{$ti-prefix}-arrows-up-right:before { content: $ti-icon-arrows-up-right; }
.#{$ti-prefix}-arrows-vertical:before { content: $ti-icon-arrows-vertical; }
.#{$ti-prefix}-artboard:before { content: $ti-icon-artboard; }
.#{$ti-prefix}-aspect-ratio:before { content: $ti-icon-aspect-ratio; }
.#{$ti-prefix}-at:before { content: $ti-icon-at; }
.#{$ti-prefix}-atom:before { content: $ti-icon-atom; }
.#{$ti-prefix}-atom-2:before { content: $ti-icon-atom-2; }
.#{$ti-prefix}-award:before { content: $ti-icon-award; }
.#{$ti-prefix}-axe:before { content: $ti-icon-axe; }
.#{$ti-prefix}-axis-x:before { content: $ti-icon-axis-x; }
.#{$ti-prefix}-axis-y:before { content: $ti-icon-axis-y; }
.#{$ti-prefix}-backhoe:before { content: $ti-icon-backhoe; }
.#{$ti-prefix}-backpack:before { content: $ti-icon-backpack; }
.#{$ti-prefix}-backspace:before { content: $ti-icon-backspace; }
.#{$ti-prefix}-ball-american-football:before { content: $ti-icon-ball-american-football; }
.#{$ti-prefix}-ball-baseball:before { content: $ti-icon-ball-baseball; }
.#{$ti-prefix}-ball-basketball:before { content: $ti-icon-ball-basketball; }
.#{$ti-prefix}-ball-bowling:before { content: $ti-icon-ball-bowling; }
.#{$ti-prefix}-ball-football:before { content: $ti-icon-ball-football; }
.#{$ti-prefix}-ball-football-off:before { content: $ti-icon-ball-football-off; }
.#{$ti-prefix}-ball-tennis:before { content: $ti-icon-ball-tennis; }
.#{$ti-prefix}-ball-volleyball:before { content: $ti-icon-ball-volleyball; }
.#{$ti-prefix}-ballon:before { content: $ti-icon-ballon; }
.#{$ti-prefix}-ban:before { content: $ti-icon-ban; }
.#{$ti-prefix}-bandage:before { content: $ti-icon-bandage; }
.#{$ti-prefix}-barcode:before { content: $ti-icon-barcode; }
.#{$ti-prefix}-basket:before { content: $ti-icon-basket; }
.#{$ti-prefix}-bath:before { content: $ti-icon-bath; }
.#{$ti-prefix}-battery:before { content: $ti-icon-battery; }
.#{$ti-prefix}-battery-1:before { content: $ti-icon-battery-1; }
.#{$ti-prefix}-battery-2:before { content: $ti-icon-battery-2; }
.#{$ti-prefix}-battery-3:before { content: $ti-icon-battery-3; }
.#{$ti-prefix}-battery-4:before { content: $ti-icon-battery-4; }
.#{$ti-prefix}-battery-automotive:before { content: $ti-icon-battery-automotive; }
.#{$ti-prefix}-battery-charging:before { content: $ti-icon-battery-charging; }
.#{$ti-prefix}-battery-charging-2:before { content: $ti-icon-battery-charging-2; }
.#{$ti-prefix}-battery-eco:before { content: $ti-icon-battery-eco; }
.#{$ti-prefix}-battery-off:before { content: $ti-icon-battery-off; }
.#{$ti-prefix}-beach:before { content: $ti-icon-beach; }
.#{$ti-prefix}-bed:before { content: $ti-icon-bed; }
.#{$ti-prefix}-beer:before { content: $ti-icon-beer; }
.#{$ti-prefix}-bell:before { content: $ti-icon-bell; }
.#{$ti-prefix}-bell-minus:before { content: $ti-icon-bell-minus; }
.#{$ti-prefix}-bell-off:before { content: $ti-icon-bell-off; }
.#{$ti-prefix}-bell-plus:before { content: $ti-icon-bell-plus; }
.#{$ti-prefix}-bell-ringing:before { content: $ti-icon-bell-ringing; }
.#{$ti-prefix}-bell-ringing-2:before { content: $ti-icon-bell-ringing-2; }
.#{$ti-prefix}-bell-x:before { content: $ti-icon-bell-x; }
.#{$ti-prefix}-bike:before { content: $ti-icon-bike; }
.#{$ti-prefix}-binary:before { content: $ti-icon-binary; }
.#{$ti-prefix}-biohazard:before { content: $ti-icon-biohazard; }
.#{$ti-prefix}-blockquote:before { content: $ti-icon-blockquote; }
.#{$ti-prefix}-bluetooth:before { content: $ti-icon-bluetooth; }
.#{$ti-prefix}-bluetooth-connected:before { content: $ti-icon-bluetooth-connected; }
.#{$ti-prefix}-bluetooth-off:before { content: $ti-icon-bluetooth-off; }
.#{$ti-prefix}-blur:before { content: $ti-icon-blur; }
.#{$ti-prefix}-bold:before { content: $ti-icon-bold; }
.#{$ti-prefix}-bolt:before { content: $ti-icon-bolt; }
.#{$ti-prefix}-bolt-off:before { content: $ti-icon-bolt-off; }
.#{$ti-prefix}-bone:before { content: $ti-icon-bone; }
.#{$ti-prefix}-book:before { content: $ti-icon-book; }
.#{$ti-prefix}-bookmark:before { content: $ti-icon-bookmark; }
.#{$ti-prefix}-bookmark-off:before { content: $ti-icon-bookmark-off; }
.#{$ti-prefix}-bookmarks:before { content: $ti-icon-bookmarks; }
.#{$ti-prefix}-border-all:before { content: $ti-icon-border-all; }
.#{$ti-prefix}-border-bottom:before { content: $ti-icon-border-bottom; }
.#{$ti-prefix}-border-horizontal:before { content: $ti-icon-border-horizontal; }
.#{$ti-prefix}-border-inner:before { content: $ti-icon-border-inner; }
.#{$ti-prefix}-border-left:before { content: $ti-icon-border-left; }
.#{$ti-prefix}-border-none:before { content: $ti-icon-border-none; }
.#{$ti-prefix}-border-outer:before { content: $ti-icon-border-outer; }
.#{$ti-prefix}-border-radius:before { content: $ti-icon-border-radius; }
.#{$ti-prefix}-border-right:before { content: $ti-icon-border-right; }
.#{$ti-prefix}-border-style:before { content: $ti-icon-border-style; }
.#{$ti-prefix}-border-style-2:before { content: $ti-icon-border-style-2; }
.#{$ti-prefix}-border-top:before { content: $ti-icon-border-top; }
.#{$ti-prefix}-border-vertical:before { content: $ti-icon-border-vertical; }
.#{$ti-prefix}-bottle:before { content: $ti-icon-bottle; }
.#{$ti-prefix}-box:before { content: $ti-icon-box; }
.#{$ti-prefix}-box-margin:before { content: $ti-icon-box-margin; }
.#{$ti-prefix}-box-model:before { content: $ti-icon-box-model; }
.#{$ti-prefix}-box-model-2:before { content: $ti-icon-box-model-2; }
.#{$ti-prefix}-box-multiple:before { content: $ti-icon-box-multiple; }
.#{$ti-prefix}-box-multiple-0:before { content: $ti-icon-box-multiple-0; }
.#{$ti-prefix}-box-multiple-1:before { content: $ti-icon-box-multiple-1; }
.#{$ti-prefix}-box-multiple-2:before { content: $ti-icon-box-multiple-2; }
.#{$ti-prefix}-box-multiple-3:before { content: $ti-icon-box-multiple-3; }
.#{$ti-prefix}-box-multiple-4:before { content: $ti-icon-box-multiple-4; }
.#{$ti-prefix}-box-multiple-5:before { content: $ti-icon-box-multiple-5; }
.#{$ti-prefix}-box-multiple-6:before { content: $ti-icon-box-multiple-6; }
.#{$ti-prefix}-box-multiple-7:before { content: $ti-icon-box-multiple-7; }
.#{$ti-prefix}-box-multiple-8:before { content: $ti-icon-box-multiple-8; }
.#{$ti-prefix}-box-multiple-9:before { content: $ti-icon-box-multiple-9; }
.#{$ti-prefix}-box-padding:before { content: $ti-icon-box-padding; }
.#{$ti-prefix}-braces:before { content: $ti-icon-braces; }
.#{$ti-prefix}-brackets:before { content: $ti-icon-brackets; }
.#{$ti-prefix}-brand-airbnb:before { content: $ti-icon-brand-airbnb; }
.#{$ti-prefix}-brand-airtable:before { content: $ti-icon-brand-airtable; }
.#{$ti-prefix}-brand-android:before { content: $ti-icon-brand-android; }
.#{$ti-prefix}-brand-angular:before { content: $ti-icon-brand-angular; }
.#{$ti-prefix}-brand-apple:before { content: $ti-icon-brand-apple; }
.#{$ti-prefix}-brand-apple-arcade:before { content: $ti-icon-brand-apple-arcade; }
.#{$ti-prefix}-brand-appstore:before { content: $ti-icon-brand-appstore; }
.#{$ti-prefix}-brand-asana:before { content: $ti-icon-brand-asana; }
.#{$ti-prefix}-brand-behance:before { content: $ti-icon-brand-behance; }
.#{$ti-prefix}-brand-bing:before { content: $ti-icon-brand-bing; }
.#{$ti-prefix}-brand-bitbucket:before { content: $ti-icon-brand-bitbucket; }
.#{$ti-prefix}-brand-booking:before { content: $ti-icon-brand-booking; }
.#{$ti-prefix}-brand-bootstrap:before { content: $ti-icon-brand-bootstrap; }
.#{$ti-prefix}-brand-chrome:before { content: $ti-icon-brand-chrome; }
.#{$ti-prefix}-brand-codepen:before { content: $ti-icon-brand-codepen; }
.#{$ti-prefix}-brand-codesandbox:before { content: $ti-icon-brand-codesandbox; }
.#{$ti-prefix}-brand-css3:before { content: $ti-icon-brand-css3; }
.#{$ti-prefix}-brand-cucumber:before { content: $ti-icon-brand-cucumber; }
.#{$ti-prefix}-brand-debian:before { content: $ti-icon-brand-debian; }
.#{$ti-prefix}-brand-deviantart:before { content: $ti-icon-brand-deviantart; }
.#{$ti-prefix}-brand-discord:before { content: $ti-icon-brand-discord; }
.#{$ti-prefix}-brand-disqus:before { content: $ti-icon-brand-disqus; }
.#{$ti-prefix}-brand-docker:before { content: $ti-icon-brand-docker; }
.#{$ti-prefix}-brand-doctrine:before { content: $ti-icon-brand-doctrine; }
.#{$ti-prefix}-brand-dribbble:before { content: $ti-icon-brand-dribbble; }
.#{$ti-prefix}-brand-edge:before { content: $ti-icon-brand-edge; }
.#{$ti-prefix}-brand-facebook:before { content: $ti-icon-brand-facebook; }
.#{$ti-prefix}-brand-figma:before { content: $ti-icon-brand-figma; }
.#{$ti-prefix}-brand-firebase:before { content: $ti-icon-brand-firebase; }
.#{$ti-prefix}-brand-firefox:before { content: $ti-icon-brand-firefox; }
.#{$ti-prefix}-brand-flickr:before { content: $ti-icon-brand-flickr; }
.#{$ti-prefix}-brand-foursquare:before { content: $ti-icon-brand-foursquare; }
.#{$ti-prefix}-brand-framer:before { content: $ti-icon-brand-framer; }
.#{$ti-prefix}-brand-git:before { content: $ti-icon-brand-git; }
.#{$ti-prefix}-brand-github:before { content: $ti-icon-brand-github; }
.#{$ti-prefix}-brand-gitlab:before { content: $ti-icon-brand-gitlab; }
.#{$ti-prefix}-brand-gmail:before { content: $ti-icon-brand-gmail; }
.#{$ti-prefix}-brand-google:before { content: $ti-icon-brand-google; }
.#{$ti-prefix}-brand-google-analytics:before { content: $ti-icon-brand-google-analytics; }
.#{$ti-prefix}-brand-google-drive:before { content: $ti-icon-brand-google-drive; }
.#{$ti-prefix}-brand-google-play:before { content: $ti-icon-brand-google-play; }
.#{$ti-prefix}-brand-gravatar:before { content: $ti-icon-brand-gravatar; }
.#{$ti-prefix}-brand-hipchat:before { content: $ti-icon-brand-hipchat; }
.#{$ti-prefix}-brand-html5:before { content: $ti-icon-brand-html5; }
.#{$ti-prefix}-brand-instagram:before { content: $ti-icon-brand-instagram; }
.#{$ti-prefix}-brand-javascript:before { content: $ti-icon-brand-javascript; }
.#{$ti-prefix}-brand-kickstarter:before { content: $ti-icon-brand-kickstarter; }
.#{$ti-prefix}-brand-kotlin:before { content: $ti-icon-brand-kotlin; }
.#{$ti-prefix}-brand-linkedin:before { content: $ti-icon-brand-linkedin; }
.#{$ti-prefix}-brand-loom:before { content: $ti-icon-brand-loom; }
.#{$ti-prefix}-brand-mastercard:before { content: $ti-icon-brand-mastercard; }
.#{$ti-prefix}-brand-medium:before { content: $ti-icon-brand-medium; }
.#{$ti-prefix}-brand-messenger:before { content: $ti-icon-brand-messenger; }
.#{$ti-prefix}-brand-netbeans:before { content: $ti-icon-brand-netbeans; }
.#{$ti-prefix}-brand-netflix:before { content: $ti-icon-brand-netflix; }
.#{$ti-prefix}-brand-notion:before { content: $ti-icon-brand-notion; }
.#{$ti-prefix}-brand-nytimes:before { content: $ti-icon-brand-nytimes; }
.#{$ti-prefix}-brand-open-source:before { content: $ti-icon-brand-open-source; }
.#{$ti-prefix}-brand-opera:before { content: $ti-icon-brand-opera; }
.#{$ti-prefix}-brand-pagekit:before { content: $ti-icon-brand-pagekit; }
.#{$ti-prefix}-brand-patreon:before { content: $ti-icon-brand-patreon; }
.#{$ti-prefix}-brand-paypal:before { content: $ti-icon-brand-paypal; }
.#{$ti-prefix}-brand-php:before { content: $ti-icon-brand-php; }
.#{$ti-prefix}-brand-pinterest:before { content: $ti-icon-brand-pinterest; }
.#{$ti-prefix}-brand-pocket:before { content: $ti-icon-brand-pocket; }
.#{$ti-prefix}-brand-producthunt:before { content: $ti-icon-brand-producthunt; }
.#{$ti-prefix}-brand-python:before { content: $ti-icon-brand-python; }
.#{$ti-prefix}-brand-react-native:before { content: $ti-icon-brand-react-native; }
.#{$ti-prefix}-brand-reddit:before { content: $ti-icon-brand-reddit; }
.#{$ti-prefix}-brand-safari:before { content: $ti-icon-brand-safari; }
.#{$ti-prefix}-brand-sass:before { content: $ti-icon-brand-sass; }
.#{$ti-prefix}-brand-sentry:before { content: $ti-icon-brand-sentry; }
.#{$ti-prefix}-brand-shazam:before { content: $ti-icon-brand-shazam; }
.#{$ti-prefix}-brand-sketch:before { content: $ti-icon-brand-sketch; }
.#{$ti-prefix}-brand-skype:before { content: $ti-icon-brand-skype; }
.#{$ti-prefix}-brand-slack:before { content: $ti-icon-brand-slack; }
.#{$ti-prefix}-brand-snapchat:before { content: $ti-icon-brand-snapchat; }
.#{$ti-prefix}-brand-soundcloud:before { content: $ti-icon-brand-soundcloud; }
.#{$ti-prefix}-brand-spotify:before { content: $ti-icon-brand-spotify; }
.#{$ti-prefix}-brand-stackoverflow:before { content: $ti-icon-brand-stackoverflow; }
.#{$ti-prefix}-brand-steam:before { content: $ti-icon-brand-steam; }
.#{$ti-prefix}-brand-stripe:before { content: $ti-icon-brand-stripe; }
.#{$ti-prefix}-brand-sublime-text:before { content: $ti-icon-brand-sublime-text; }
.#{$ti-prefix}-brand-tabler:before { content: $ti-icon-brand-tabler; }
.#{$ti-prefix}-brand-tailwind:before { content: $ti-icon-brand-tailwind; }
.#{$ti-prefix}-brand-telegram:before { content: $ti-icon-brand-telegram; }
.#{$ti-prefix}-brand-tidal:before { content: $ti-icon-brand-tidal; }
.#{$ti-prefix}-brand-tiktok:before { content: $ti-icon-brand-tiktok; }
.#{$ti-prefix}-brand-tinder:before { content: $ti-icon-brand-tinder; }
.#{$ti-prefix}-brand-tumblr:before { content: $ti-icon-brand-tumblr; }
.#{$ti-prefix}-brand-twitch:before { content: $ti-icon-brand-twitch; }
.#{$ti-prefix}-brand-twitter:before { content: $ti-icon-brand-twitter; }
.#{$ti-prefix}-brand-uber:before { content: $ti-icon-brand-uber; }
.#{$ti-prefix}-brand-ubuntu:before { content: $ti-icon-brand-ubuntu; }
.#{$ti-prefix}-brand-unsplash:before { content: $ti-icon-brand-unsplash; }
.#{$ti-prefix}-brand-vercel:before { content: $ti-icon-brand-vercel; }
.#{$ti-prefix}-brand-vimeo:before { content: $ti-icon-brand-vimeo; }
.#{$ti-prefix}-brand-visual-studio:before { content: $ti-icon-brand-visual-studio; }
.#{$ti-prefix}-brand-vk:before { content: $ti-icon-brand-vk; }
.#{$ti-prefix}-brand-whatsapp:before { content: $ti-icon-brand-whatsapp; }
.#{$ti-prefix}-brand-windows:before { content: $ti-icon-brand-windows; }
.#{$ti-prefix}-brand-yahoo:before { content: $ti-icon-brand-yahoo; }
.#{$ti-prefix}-brand-ycombinator:before { content: $ti-icon-brand-ycombinator; }
.#{$ti-prefix}-brand-youtube:before { content: $ti-icon-brand-youtube; }
.#{$ti-prefix}-bread:before { content: $ti-icon-bread; }
.#{$ti-prefix}-briefcase:before { content: $ti-icon-briefcase; }
.#{$ti-prefix}-brightness:before { content: $ti-icon-brightness; }
.#{$ti-prefix}-brightness-2:before { content: $ti-icon-brightness-2; }
.#{$ti-prefix}-brightness-down:before { content: $ti-icon-brightness-down; }
.#{$ti-prefix}-brightness-half:before { content: $ti-icon-brightness-half; }
.#{$ti-prefix}-brightness-up:before { content: $ti-icon-brightness-up; }
.#{$ti-prefix}-browser:before { content: $ti-icon-browser; }
.#{$ti-prefix}-brush:before { content: $ti-icon-brush; }
.#{$ti-prefix}-bucket:before { content: $ti-icon-bucket; }
.#{$ti-prefix}-bug:before { content: $ti-icon-bug; }
.#{$ti-prefix}-building:before { content: $ti-icon-building; }
.#{$ti-prefix}-building-arch:before { content: $ti-icon-building-arch; }
.#{$ti-prefix}-building-bank:before { content: $ti-icon-building-bank; }
.#{$ti-prefix}-building-bridge:before { content: $ti-icon-building-bridge; }
.#{$ti-prefix}-building-bridge-2:before { content: $ti-icon-building-bridge-2; }
.#{$ti-prefix}-building-carousel:before { content: $ti-icon-building-carousel; }
.#{$ti-prefix}-building-castle:before { content: $ti-icon-building-castle; }
.#{$ti-prefix}-building-church:before { content: $ti-icon-building-church; }
.#{$ti-prefix}-building-community:before { content: $ti-icon-building-community; }
.#{$ti-prefix}-building-cottage:before { content: $ti-icon-building-cottage; }
.#{$ti-prefix}-building-factory:before { content: $ti-icon-building-factory; }
.#{$ti-prefix}-building-fortress:before { content: $ti-icon-building-fortress; }
.#{$ti-prefix}-building-hospital:before { content: $ti-icon-building-hospital; }
.#{$ti-prefix}-building-lighthouse:before { content: $ti-icon-building-lighthouse; }
.#{$ti-prefix}-building-monument:before { content: $ti-icon-building-monument; }
.#{$ti-prefix}-building-pavilon:before { content: $ti-icon-building-pavilon; }
.#{$ti-prefix}-building-skyscraper:before { content: $ti-icon-building-skyscraper; }
.#{$ti-prefix}-building-store:before { content: $ti-icon-building-store; }
.#{$ti-prefix}-building-warehouse:before { content: $ti-icon-building-warehouse; }
.#{$ti-prefix}-bulb:before { content: $ti-icon-bulb; }
.#{$ti-prefix}-bulb-off:before { content: $ti-icon-bulb-off; }
.#{$ti-prefix}-bulldozer:before { content: $ti-icon-bulldozer; }
.#{$ti-prefix}-bus:before { content: $ti-icon-bus; }
.#{$ti-prefix}-businessplan:before { content: $ti-icon-businessplan; }
.#{$ti-prefix}-calculator:before { content: $ti-icon-calculator; }
.#{$ti-prefix}-calendar:before { content: $ti-icon-calendar; }
.#{$ti-prefix}-calendar-event:before { content: $ti-icon-calendar-event; }
.#{$ti-prefix}-calendar-minus:before { content: $ti-icon-calendar-minus; }
.#{$ti-prefix}-calendar-off:before { content: $ti-icon-calendar-off; }
.#{$ti-prefix}-calendar-plus:before { content: $ti-icon-calendar-plus; }
.#{$ti-prefix}-calendar-stats:before { content: $ti-icon-calendar-stats; }
.#{$ti-prefix}-calendar-time:before { content: $ti-icon-calendar-time; }
.#{$ti-prefix}-camera:before { content: $ti-icon-camera; }
.#{$ti-prefix}-camera-minus:before { content: $ti-icon-camera-minus; }
.#{$ti-prefix}-camera-off:before { content: $ti-icon-camera-off; }
.#{$ti-prefix}-camera-plus:before { content: $ti-icon-camera-plus; }
.#{$ti-prefix}-camera-rotate:before { content: $ti-icon-camera-rotate; }
.#{$ti-prefix}-camera-selfie:before { content: $ti-icon-camera-selfie; }
.#{$ti-prefix}-candy:before { content: $ti-icon-candy; }
.#{$ti-prefix}-capture:before { content: $ti-icon-capture; }
.#{$ti-prefix}-car:before { content: $ti-icon-car; }
.#{$ti-prefix}-car-crane:before { content: $ti-icon-car-crane; }
.#{$ti-prefix}-car-crash:before { content: $ti-icon-car-crash; }
.#{$ti-prefix}-caravan:before { content: $ti-icon-caravan; }
.#{$ti-prefix}-cardboards:before { content: $ti-icon-cardboards; }
.#{$ti-prefix}-caret-down:before { content: $ti-icon-caret-down; }
.#{$ti-prefix}-caret-left:before { content: $ti-icon-caret-left; }
.#{$ti-prefix}-caret-right:before { content: $ti-icon-caret-right; }
.#{$ti-prefix}-caret-up:before { content: $ti-icon-caret-up; }
.#{$ti-prefix}-cash:before { content: $ti-icon-cash; }
.#{$ti-prefix}-cash-banknote:before { content: $ti-icon-cash-banknote; }
.#{$ti-prefix}-cash-banknote-off:before { content: $ti-icon-cash-banknote-off; }
.#{$ti-prefix}-cast:before { content: $ti-icon-cast; }
.#{$ti-prefix}-ce:before { content: $ti-icon-ce; }
.#{$ti-prefix}-certificate:before { content: $ti-icon-certificate; }
.#{$ti-prefix}-charging-pile:before { content: $ti-icon-charging-pile; }
.#{$ti-prefix}-chart-arcs:before { content: $ti-icon-chart-arcs; }
.#{$ti-prefix}-chart-arcs-3:before { content: $ti-icon-chart-arcs-3; }
.#{$ti-prefix}-chart-area:before { content: $ti-icon-chart-area; }
.#{$ti-prefix}-chart-area-line:before { content: $ti-icon-chart-area-line; }
.#{$ti-prefix}-chart-arrows:before { content: $ti-icon-chart-arrows; }
.#{$ti-prefix}-chart-arrows-vertical:before { content: $ti-icon-chart-arrows-vertical; }
.#{$ti-prefix}-chart-bar:before { content: $ti-icon-chart-bar; }
.#{$ti-prefix}-chart-bubble:before { content: $ti-icon-chart-bubble; }
.#{$ti-prefix}-chart-candle:before { content: $ti-icon-chart-candle; }
.#{$ti-prefix}-chart-circles:before { content: $ti-icon-chart-circles; }
.#{$ti-prefix}-chart-donut:before { content: $ti-icon-chart-donut; }
.#{$ti-prefix}-chart-donut-2:before { content: $ti-icon-chart-donut-2; }
.#{$ti-prefix}-chart-donut-3:before { content: $ti-icon-chart-donut-3; }
.#{$ti-prefix}-chart-donut-4:before { content: $ti-icon-chart-donut-4; }
.#{$ti-prefix}-chart-dots:before { content: $ti-icon-chart-dots; }
.#{$ti-prefix}-chart-infographic:before { content: $ti-icon-chart-infographic; }
.#{$ti-prefix}-chart-line:before { content: $ti-icon-chart-line; }
.#{$ti-prefix}-chart-pie:before { content: $ti-icon-chart-pie; }
.#{$ti-prefix}-chart-pie-2:before { content: $ti-icon-chart-pie-2; }
.#{$ti-prefix}-chart-pie-3:before { content: $ti-icon-chart-pie-3; }
.#{$ti-prefix}-chart-pie-4:before { content: $ti-icon-chart-pie-4; }
.#{$ti-prefix}-chart-radar:before { content: $ti-icon-chart-radar; }
.#{$ti-prefix}-check:before { content: $ti-icon-check; }
.#{$ti-prefix}-checkbox:before { content: $ti-icon-checkbox; }
.#{$ti-prefix}-checks:before { content: $ti-icon-checks; }
.#{$ti-prefix}-checkup-list:before { content: $ti-icon-checkup-list; }
.#{$ti-prefix}-cheese:before { content: $ti-icon-cheese; }
.#{$ti-prefix}-chevron-down:before { content: $ti-icon-chevron-down; }
.#{$ti-prefix}-chevron-down-left:before { content: $ti-icon-chevron-down-left; }
.#{$ti-prefix}-chevron-down-right:before { content: $ti-icon-chevron-down-right; }
.#{$ti-prefix}-chevron-left:before { content: $ti-icon-chevron-left; }
.#{$ti-prefix}-chevron-right:before { content: $ti-icon-chevron-right; }
.#{$ti-prefix}-chevron-up:before { content: $ti-icon-chevron-up; }
.#{$ti-prefix}-chevron-up-left:before { content: $ti-icon-chevron-up-left; }
.#{$ti-prefix}-chevron-up-right:before { content: $ti-icon-chevron-up-right; }
.#{$ti-prefix}-chevrons-down:before { content: $ti-icon-chevrons-down; }
.#{$ti-prefix}-chevrons-down-left:before { content: $ti-icon-chevrons-down-left; }
.#{$ti-prefix}-chevrons-down-right:before { content: $ti-icon-chevrons-down-right; }
.#{$ti-prefix}-chevrons-left:before { content: $ti-icon-chevrons-left; }
.#{$ti-prefix}-chevrons-right:before { content: $ti-icon-chevrons-right; }
.#{$ti-prefix}-chevrons-up:before { content: $ti-icon-chevrons-up; }
.#{$ti-prefix}-chevrons-up-left:before { content: $ti-icon-chevrons-up-left; }
.#{$ti-prefix}-chevrons-up-right:before { content: $ti-icon-chevrons-up-right; }
.#{$ti-prefix}-christmas-tree:before { content: $ti-icon-christmas-tree; }
.#{$ti-prefix}-circle:before { content: $ti-icon-circle; }
.#{$ti-prefix}-circle-0:before { content: $ti-icon-circle-0; }
.#{$ti-prefix}-circle-1:before { content: $ti-icon-circle-1; }
.#{$ti-prefix}-circle-2:before { content: $ti-icon-circle-2; }
.#{$ti-prefix}-circle-3:before { content: $ti-icon-circle-3; }
.#{$ti-prefix}-circle-4:before { content: $ti-icon-circle-4; }
.#{$ti-prefix}-circle-5:before { content: $ti-icon-circle-5; }
.#{$ti-prefix}-circle-6:before { content: $ti-icon-circle-6; }
.#{$ti-prefix}-circle-7:before { content: $ti-icon-circle-7; }
.#{$ti-prefix}-circle-8:before { content: $ti-icon-circle-8; }
.#{$ti-prefix}-circle-9:before { content: $ti-icon-circle-9; }
.#{$ti-prefix}-circle-check:before { content: $ti-icon-circle-check; }
.#{$ti-prefix}-circle-dashed:before { content: $ti-icon-circle-dashed; }
.#{$ti-prefix}-circle-dotted:before { content: $ti-icon-circle-dotted; }
.#{$ti-prefix}-circle-half:before { content: $ti-icon-circle-half; }
.#{$ti-prefix}-circle-half-vertical:before { content: $ti-icon-circle-half-vertical; }
.#{$ti-prefix}-circle-minus:before { content: $ti-icon-circle-minus; }
.#{$ti-prefix}-circle-off:before { content: $ti-icon-circle-off; }
.#{$ti-prefix}-circle-plus:before { content: $ti-icon-circle-plus; }
.#{$ti-prefix}-circle-square:before { content: $ti-icon-circle-square; }
.#{$ti-prefix}-circle-x:before { content: $ti-icon-circle-x; }
.#{$ti-prefix}-circles:before { content: $ti-icon-circles; }
.#{$ti-prefix}-clear-all:before { content: $ti-icon-clear-all; }
.#{$ti-prefix}-clear-formatting:before { content: $ti-icon-clear-formatting; }
.#{$ti-prefix}-click:before { content: $ti-icon-click; }
.#{$ti-prefix}-clipboard:before { content: $ti-icon-clipboard; }
.#{$ti-prefix}-clipboard-check:before { content: $ti-icon-clipboard-check; }
.#{$ti-prefix}-clipboard-list:before { content: $ti-icon-clipboard-list; }
.#{$ti-prefix}-clipboard-x:before { content: $ti-icon-clipboard-x; }
.#{$ti-prefix}-clock:before { content: $ti-icon-clock; }
.#{$ti-prefix}-cloud:before { content: $ti-icon-cloud; }
.#{$ti-prefix}-cloud-download:before { content: $ti-icon-cloud-download; }
.#{$ti-prefix}-cloud-fog:before { content: $ti-icon-cloud-fog; }
.#{$ti-prefix}-cloud-off:before { content: $ti-icon-cloud-off; }
.#{$ti-prefix}-cloud-rain:before { content: $ti-icon-cloud-rain; }
.#{$ti-prefix}-cloud-snow:before { content: $ti-icon-cloud-snow; }
.#{$ti-prefix}-cloud-storm:before { content: $ti-icon-cloud-storm; }
.#{$ti-prefix}-cloud-upload:before { content: $ti-icon-cloud-upload; }
.#{$ti-prefix}-code:before { content: $ti-icon-code; }
.#{$ti-prefix}-code-minus:before { content: $ti-icon-code-minus; }
.#{$ti-prefix}-code-plus:before { content: $ti-icon-code-plus; }
.#{$ti-prefix}-coffee:before { content: $ti-icon-coffee; }
.#{$ti-prefix}-coin:before { content: $ti-icon-coin; }
.#{$ti-prefix}-color-picker:before { content: $ti-icon-color-picker; }
.#{$ti-prefix}-color-swatch:before { content: $ti-icon-color-swatch; }
.#{$ti-prefix}-column-insert-left:before { content: $ti-icon-column-insert-left; }
.#{$ti-prefix}-column-insert-right:before { content: $ti-icon-column-insert-right; }
.#{$ti-prefix}-columns:before { content: $ti-icon-columns; }
.#{$ti-prefix}-comet:before { content: $ti-icon-comet; }
.#{$ti-prefix}-command:before { content: $ti-icon-command; }
.#{$ti-prefix}-compass:before { content: $ti-icon-compass; }
.#{$ti-prefix}-components:before { content: $ti-icon-components; }
.#{$ti-prefix}-confetti:before { content: $ti-icon-confetti; }
.#{$ti-prefix}-container:before { content: $ti-icon-container; }
.#{$ti-prefix}-contrast:before { content: $ti-icon-contrast; }
.#{$ti-prefix}-cookie:before { content: $ti-icon-cookie; }
.#{$ti-prefix}-copy:before { content: $ti-icon-copy; }
.#{$ti-prefix}-copyleft:before { content: $ti-icon-copyleft; }
.#{$ti-prefix}-copyright:before { content: $ti-icon-copyright; }
.#{$ti-prefix}-corner-down-left:before { content: $ti-icon-corner-down-left; }
.#{$ti-prefix}-corner-down-left-double:before { content: $ti-icon-corner-down-left-double; }
.#{$ti-prefix}-corner-down-right:before { content: $ti-icon-corner-down-right; }
.#{$ti-prefix}-corner-down-right-double:before { content: $ti-icon-corner-down-right-double; }
.#{$ti-prefix}-corner-left-down:before { content: $ti-icon-corner-left-down; }
.#{$ti-prefix}-corner-left-down-double:before { content: $ti-icon-corner-left-down-double; }
.#{$ti-prefix}-corner-left-up:before { content: $ti-icon-corner-left-up; }
.#{$ti-prefix}-corner-left-up-double:before { content: $ti-icon-corner-left-up-double; }
.#{$ti-prefix}-corner-right-down:before { content: $ti-icon-corner-right-down; }
.#{$ti-prefix}-corner-right-down-double:before { content: $ti-icon-corner-right-down-double; }
.#{$ti-prefix}-corner-right-up:before { content: $ti-icon-corner-right-up; }
.#{$ti-prefix}-corner-right-up-double:before { content: $ti-icon-corner-right-up-double; }
.#{$ti-prefix}-corner-up-left:before { content: $ti-icon-corner-up-left; }
.#{$ti-prefix}-corner-up-left-double:before { content: $ti-icon-corner-up-left-double; }
.#{$ti-prefix}-corner-up-right:before { content: $ti-icon-corner-up-right; }
.#{$ti-prefix}-corner-up-right-double:before { content: $ti-icon-corner-up-right-double; }
.#{$ti-prefix}-cpu:before { content: $ti-icon-cpu; }
.#{$ti-prefix}-crane:before { content: $ti-icon-crane; }
.#{$ti-prefix}-credit-card:before { content: $ti-icon-credit-card; }
.#{$ti-prefix}-credit-card-off:before { content: $ti-icon-credit-card-off; }
.#{$ti-prefix}-crop:before { content: $ti-icon-crop; }
.#{$ti-prefix}-cross:before { content: $ti-icon-cross; }
.#{$ti-prefix}-crosshair:before { content: $ti-icon-crosshair; }
.#{$ti-prefix}-crown:before { content: $ti-icon-crown; }
.#{$ti-prefix}-crown-off:before { content: $ti-icon-crown-off; }
.#{$ti-prefix}-crutches:before { content: $ti-icon-crutches; }
.#{$ti-prefix}-cup:before { content: $ti-icon-cup; }
.#{$ti-prefix}-curly-loop:before { content: $ti-icon-curly-loop; }
.#{$ti-prefix}-currency:before { content: $ti-icon-currency; }
.#{$ti-prefix}-currency-bahraini:before { content: $ti-icon-currency-bahraini; }
.#{$ti-prefix}-currency-bath:before { content: $ti-icon-currency-bath; }
.#{$ti-prefix}-currency-bitcoin:before { content: $ti-icon-currency-bitcoin; }
.#{$ti-prefix}-currency-cent:before { content: $ti-icon-currency-cent; }
.#{$ti-prefix}-currency-dinar:before { content: $ti-icon-currency-dinar; }
.#{$ti-prefix}-currency-dirham:before { content: $ti-icon-currency-dirham; }
.#{$ti-prefix}-currency-dogecoin:before { content: $ti-icon-currency-dogecoin; }
.#{$ti-prefix}-currency-dollar:before { content: $ti-icon-currency-dollar; }
.#{$ti-prefix}-currency-dollar-australian:before { content: $ti-icon-currency-dollar-australian; }
.#{$ti-prefix}-currency-dollar-canadian:before { content: $ti-icon-currency-dollar-canadian; }
.#{$ti-prefix}-currency-dollar-singapore:before { content: $ti-icon-currency-dollar-singapore; }
.#{$ti-prefix}-currency-ethereum:before { content: $ti-icon-currency-ethereum; }
.#{$ti-prefix}-currency-euro:before { content: $ti-icon-currency-euro; }
.#{$ti-prefix}-currency-forint:before { content: $ti-icon-currency-forint; }
.#{$ti-prefix}-currency-frank:before { content: $ti-icon-currency-frank; }
.#{$ti-prefix}-currency-krone-czech:before { content: $ti-icon-currency-krone-czech; }
.#{$ti-prefix}-currency-krone-danish:before { content: $ti-icon-currency-krone-danish; }
.#{$ti-prefix}-currency-krone-swedish:before { content: $ti-icon-currency-krone-swedish; }
.#{$ti-prefix}-currency-leu:before { content: $ti-icon-currency-leu; }
.#{$ti-prefix}-currency-lira:before { content: $ti-icon-currency-lira; }
.#{$ti-prefix}-currency-litecoin:before { content: $ti-icon-currency-litecoin; }
.#{$ti-prefix}-currency-naira:before { content: $ti-icon-currency-naira; }
.#{$ti-prefix}-currency-pound:before { content: $ti-icon-currency-pound; }
.#{$ti-prefix}-currency-real:before { content: $ti-icon-currency-real; }
.#{$ti-prefix}-currency-renminbi:before { content: $ti-icon-currency-renminbi; }
.#{$ti-prefix}-currency-ripple:before { content: $ti-icon-currency-ripple; }
.#{$ti-prefix}-currency-riyal:before { content: $ti-icon-currency-riyal; }
.#{$ti-prefix}-currency-rubel:before { content: $ti-icon-currency-rubel; }
.#{$ti-prefix}-currency-rupee:before { content: $ti-icon-currency-rupee; }
.#{$ti-prefix}-currency-shekel:before { content: $ti-icon-currency-shekel; }
.#{$ti-prefix}-currency-taka:before { content: $ti-icon-currency-taka; }
.#{$ti-prefix}-currency-tugrik:before { content: $ti-icon-currency-tugrik; }
.#{$ti-prefix}-currency-won:before { content: $ti-icon-currency-won; }
.#{$ti-prefix}-currency-yen:before { content: $ti-icon-currency-yen; }
.#{$ti-prefix}-currency-zloty:before { content: $ti-icon-currency-zloty; }
.#{$ti-prefix}-current-location:before { content: $ti-icon-current-location; }
.#{$ti-prefix}-cursor-text:before { content: $ti-icon-cursor-text; }
.#{$ti-prefix}-cut:before { content: $ti-icon-cut; }
.#{$ti-prefix}-dashboard:before { content: $ti-icon-dashboard; }
.#{$ti-prefix}-database:before { content: $ti-icon-database; }
.#{$ti-prefix}-database-export:before { content: $ti-icon-database-export; }
.#{$ti-prefix}-database-import:before { content: $ti-icon-database-import; }
.#{$ti-prefix}-database-off:before { content: $ti-icon-database-off; }
.#{$ti-prefix}-details:before { content: $ti-icon-details; }
.#{$ti-prefix}-device-analytics:before { content: $ti-icon-device-analytics; }
.#{$ti-prefix}-device-audio-tape:before { content: $ti-icon-device-audio-tape; }
.#{$ti-prefix}-device-cctv:before { content: $ti-icon-device-cctv; }
.#{$ti-prefix}-device-computer-camera:before { content: $ti-icon-device-computer-camera; }
.#{$ti-prefix}-device-computer-camera-off:before { content: $ti-icon-device-computer-camera-off; }
.#{$ti-prefix}-device-desktop:before { content: $ti-icon-device-desktop; }
.#{$ti-prefix}-device-desktop-analytics:before { content: $ti-icon-device-desktop-analytics; }
.#{$ti-prefix}-device-desktop-off:before { content: $ti-icon-device-desktop-off; }
.#{$ti-prefix}-device-floppy:before { content: $ti-icon-device-floppy; }
.#{$ti-prefix}-device-gamepad:before { content: $ti-icon-device-gamepad; }
.#{$ti-prefix}-device-laptop:before { content: $ti-icon-device-laptop; }
.#{$ti-prefix}-device-mobile:before { content: $ti-icon-device-mobile; }
.#{$ti-prefix}-device-mobile-message:before { content: $ti-icon-device-mobile-message; }
.#{$ti-prefix}-device-mobile-rotated:before { content: $ti-icon-device-mobile-rotated; }
.#{$ti-prefix}-device-mobile-vibration:before { content: $ti-icon-device-mobile-vibration; }
.#{$ti-prefix}-device-speaker:before { content: $ti-icon-device-speaker; }
.#{$ti-prefix}-device-tablet:before { content: $ti-icon-device-tablet; }
.#{$ti-prefix}-device-tv:before { content: $ti-icon-device-tv; }
.#{$ti-prefix}-device-watch:before { content: $ti-icon-device-watch; }
.#{$ti-prefix}-device-watch-stats:before { content: $ti-icon-device-watch-stats; }
.#{$ti-prefix}-device-watch-stats-2:before { content: $ti-icon-device-watch-stats-2; }
.#{$ti-prefix}-devices:before { content: $ti-icon-devices; }
.#{$ti-prefix}-devices-2:before { content: $ti-icon-devices-2; }
.#{$ti-prefix}-devices-pc:before { content: $ti-icon-devices-pc; }
.#{$ti-prefix}-diamond:before { content: $ti-icon-diamond; }
.#{$ti-prefix}-dice:before { content: $ti-icon-dice; }
.#{$ti-prefix}-dimensions:before { content: $ti-icon-dimensions; }
.#{$ti-prefix}-direction:before { content: $ti-icon-direction; }
.#{$ti-prefix}-direction-horizontal:before { content: $ti-icon-direction-horizontal; }
.#{$ti-prefix}-directions:before { content: $ti-icon-directions; }
.#{$ti-prefix}-disabled:before { content: $ti-icon-disabled; }
.#{$ti-prefix}-disabled-2:before { content: $ti-icon-disabled-2; }
.#{$ti-prefix}-disc:before { content: $ti-icon-disc; }
.#{$ti-prefix}-discount:before { content: $ti-icon-discount; }
.#{$ti-prefix}-discount-2:before { content: $ti-icon-discount-2; }
.#{$ti-prefix}-divide:before { content: $ti-icon-divide; }
.#{$ti-prefix}-dna:before { content: $ti-icon-dna; }
.#{$ti-prefix}-dna-2:before { content: $ti-icon-dna-2; }
.#{$ti-prefix}-dog-bowl:before { content: $ti-icon-dog-bowl; }
.#{$ti-prefix}-door:before { content: $ti-icon-door; }
.#{$ti-prefix}-door-enter:before { content: $ti-icon-door-enter; }
.#{$ti-prefix}-door-exit:before { content: $ti-icon-door-exit; }
.#{$ti-prefix}-dots:before { content: $ti-icon-dots; }
.#{$ti-prefix}-dots-circle-horizontal:before { content: $ti-icon-dots-circle-horizontal; }
.#{$ti-prefix}-dots-diagonal:before { content: $ti-icon-dots-diagonal; }
.#{$ti-prefix}-dots-diagonal-2:before { content: $ti-icon-dots-diagonal-2; }
.#{$ti-prefix}-dots-vertical:before { content: $ti-icon-dots-vertical; }
.#{$ti-prefix}-download:before { content: $ti-icon-download; }
.#{$ti-prefix}-drag-drop:before { content: $ti-icon-drag-drop; }
.#{$ti-prefix}-drag-drop-2:before { content: $ti-icon-drag-drop-2; }
.#{$ti-prefix}-drone:before { content: $ti-icon-drone; }
.#{$ti-prefix}-drone-off:before { content: $ti-icon-drone-off; }
.#{$ti-prefix}-droplet:before { content: $ti-icon-droplet; }
.#{$ti-prefix}-droplet-filled:before { content: $ti-icon-droplet-filled; }
.#{$ti-prefix}-droplet-filled-2:before { content: $ti-icon-droplet-filled-2; }
.#{$ti-prefix}-droplet-half:before { content: $ti-icon-droplet-half; }
.#{$ti-prefix}-droplet-half-2:before { content: $ti-icon-droplet-half-2; }
.#{$ti-prefix}-droplet-off:before { content: $ti-icon-droplet-off; }
.#{$ti-prefix}-ear:before { content: $ti-icon-ear; }
.#{$ti-prefix}-ear-off:before { content: $ti-icon-ear-off; }
.#{$ti-prefix}-edit:before { content: $ti-icon-edit; }
.#{$ti-prefix}-edit-circle:before { content: $ti-icon-edit-circle; }
.#{$ti-prefix}-egg:before { content: $ti-icon-egg; }
.#{$ti-prefix}-emergency-bed:before { content: $ti-icon-emergency-bed; }
.#{$ti-prefix}-emphasis:before { content: $ti-icon-emphasis; }
.#{$ti-prefix}-engine:before { content: $ti-icon-engine; }
.#{$ti-prefix}-equal:before { content: $ti-icon-equal; }
.#{$ti-prefix}-equal-not:before { content: $ti-icon-equal-not; }
.#{$ti-prefix}-eraser:before { content: $ti-icon-eraser; }
.#{$ti-prefix}-exchange:before { content: $ti-icon-exchange; }
.#{$ti-prefix}-exposure:before { content: $ti-icon-exposure; }
.#{$ti-prefix}-external-link:before { content: $ti-icon-external-link; }
.#{$ti-prefix}-eye:before { content: $ti-icon-eye; }
.#{$ti-prefix}-eye-check:before { content: $ti-icon-eye-check; }
.#{$ti-prefix}-eye-off:before { content: $ti-icon-eye-off; }
.#{$ti-prefix}-eye-table:before { content: $ti-icon-eye-table; }
.#{$ti-prefix}-eyeglass:before { content: $ti-icon-eyeglass; }
.#{$ti-prefix}-eyeglass-2:before { content: $ti-icon-eyeglass-2; }
.#{$ti-prefix}-face-id:before { content: $ti-icon-face-id; }
.#{$ti-prefix}-face-id-error:before { content: $ti-icon-face-id-error; }
.#{$ti-prefix}-fall:before { content: $ti-icon-fall; }
.#{$ti-prefix}-feather:before { content: $ti-icon-feather; }
.#{$ti-prefix}-fence:before { content: $ti-icon-fence; }
.#{$ti-prefix}-file:before { content: $ti-icon-file; }
.#{$ti-prefix}-file-alert:before { content: $ti-icon-file-alert; }
.#{$ti-prefix}-file-analytics:before { content: $ti-icon-file-analytics; }
.#{$ti-prefix}-file-certificate:before { content: $ti-icon-file-certificate; }
.#{$ti-prefix}-file-check:before { content: $ti-icon-file-check; }
.#{$ti-prefix}-file-code:before { content: $ti-icon-file-code; }
.#{$ti-prefix}-file-code-2:before { content: $ti-icon-file-code-2; }
.#{$ti-prefix}-file-diff:before { content: $ti-icon-file-diff; }
.#{$ti-prefix}-file-digit:before { content: $ti-icon-file-digit; }
.#{$ti-prefix}-file-dislike:before { content: $ti-icon-file-dislike; }
.#{$ti-prefix}-file-download:before { content: $ti-icon-file-download; }
.#{$ti-prefix}-file-export:before { content: $ti-icon-file-export; }
.#{$ti-prefix}-file-horizontal:before { content: $ti-icon-file-horizontal; }
.#{$ti-prefix}-file-import:before { content: $ti-icon-file-import; }
.#{$ti-prefix}-file-info:before { content: $ti-icon-file-info; }
.#{$ti-prefix}-file-invoice:before { content: $ti-icon-file-invoice; }
.#{$ti-prefix}-file-like:before { content: $ti-icon-file-like; }
.#{$ti-prefix}-file-minus:before { content: $ti-icon-file-minus; }
.#{$ti-prefix}-file-music:before { content: $ti-icon-file-music; }
.#{$ti-prefix}-file-off:before { content: $ti-icon-file-off; }
.#{$ti-prefix}-file-phone:before { content: $ti-icon-file-phone; }
.#{$ti-prefix}-file-plus:before { content: $ti-icon-file-plus; }
.#{$ti-prefix}-file-report:before { content: $ti-icon-file-report; }
.#{$ti-prefix}-file-search:before { content: $ti-icon-file-search; }
.#{$ti-prefix}-file-shredder:before { content: $ti-icon-file-shredder; }
.#{$ti-prefix}-file-symlink:before { content: $ti-icon-file-symlink; }
.#{$ti-prefix}-file-text:before { content: $ti-icon-file-text; }
.#{$ti-prefix}-file-upload:before { content: $ti-icon-file-upload; }
.#{$ti-prefix}-file-x:before { content: $ti-icon-file-x; }
.#{$ti-prefix}-file-zip:before { content: $ti-icon-file-zip; }
.#{$ti-prefix}-files:before { content: $ti-icon-files; }
.#{$ti-prefix}-files-off:before { content: $ti-icon-files-off; }
.#{$ti-prefix}-filter:before { content: $ti-icon-filter; }
.#{$ti-prefix}-filter-off:before { content: $ti-icon-filter-off; }
.#{$ti-prefix}-fingerprint:before { content: $ti-icon-fingerprint; }
.#{$ti-prefix}-firetruck:before { content: $ti-icon-firetruck; }
.#{$ti-prefix}-first-aid-kit:before { content: $ti-icon-first-aid-kit; }
.#{$ti-prefix}-fish:before { content: $ti-icon-fish; }
.#{$ti-prefix}-flag:before { content: $ti-icon-flag; }
.#{$ti-prefix}-flag-2:before { content: $ti-icon-flag-2; }
.#{$ti-prefix}-flag-3:before { content: $ti-icon-flag-3; }
.#{$ti-prefix}-flame:before { content: $ti-icon-flame; }
.#{$ti-prefix}-flare:before { content: $ti-icon-flare; }
.#{$ti-prefix}-flask:before { content: $ti-icon-flask; }
.#{$ti-prefix}-flask-2:before { content: $ti-icon-flask-2; }
.#{$ti-prefix}-flip-horizontal:before { content: $ti-icon-flip-horizontal; }
.#{$ti-prefix}-flip-vertical:before { content: $ti-icon-flip-vertical; }
.#{$ti-prefix}-float-center:before { content: $ti-icon-float-center; }
.#{$ti-prefix}-float-left:before { content: $ti-icon-float-left; }
.#{$ti-prefix}-float-none:before { content: $ti-icon-float-none; }
.#{$ti-prefix}-float-right:before { content: $ti-icon-float-right; }
.#{$ti-prefix}-focus:before { content: $ti-icon-focus; }
.#{$ti-prefix}-focus-2:before { content: $ti-icon-focus-2; }
.#{$ti-prefix}-fold:before { content: $ti-icon-fold; }
.#{$ti-prefix}-fold-down:before { content: $ti-icon-fold-down; }
.#{$ti-prefix}-fold-up:before { content: $ti-icon-fold-up; }
.#{$ti-prefix}-folder:before { content: $ti-icon-folder; }
.#{$ti-prefix}-folder-minus:before { content: $ti-icon-folder-minus; }
.#{$ti-prefix}-folder-off:before { content: $ti-icon-folder-off; }
.#{$ti-prefix}-folder-plus:before { content: $ti-icon-folder-plus; }
.#{$ti-prefix}-folder-x:before { content: $ti-icon-folder-x; }
.#{$ti-prefix}-folders:before { content: $ti-icon-folders; }
.#{$ti-prefix}-forbid:before { content: $ti-icon-forbid; }
.#{$ti-prefix}-forbid-2:before { content: $ti-icon-forbid-2; }
.#{$ti-prefix}-forklift:before { content: $ti-icon-forklift; }
.#{$ti-prefix}-forms:before { content: $ti-icon-forms; }
.#{$ti-prefix}-frame:before { content: $ti-icon-frame; }
.#{$ti-prefix}-friends:before { content: $ti-icon-friends; }
.#{$ti-prefix}-gas-station:before { content: $ti-icon-gas-station; }
.#{$ti-prefix}-gauge:before { content: $ti-icon-gauge; }
.#{$ti-prefix}-gavel:before { content: $ti-icon-gavel; }
.#{$ti-prefix}-geometry:before { content: $ti-icon-geometry; }
.#{$ti-prefix}-ghost:before { content: $ti-icon-ghost; }
.#{$ti-prefix}-gift:before { content: $ti-icon-gift; }
.#{$ti-prefix}-git-branch:before { content: $ti-icon-git-branch; }
.#{$ti-prefix}-git-commit:before { content: $ti-icon-git-commit; }
.#{$ti-prefix}-git-compare:before { content: $ti-icon-git-compare; }
.#{$ti-prefix}-git-fork:before { content: $ti-icon-git-fork; }
.#{$ti-prefix}-git-merge:before { content: $ti-icon-git-merge; }
.#{$ti-prefix}-git-pull-request:before { content: $ti-icon-git-pull-request; }
.#{$ti-prefix}-git-pull-request-closed:before { content: $ti-icon-git-pull-request-closed; }
.#{$ti-prefix}-glass:before { content: $ti-icon-glass; }
.#{$ti-prefix}-glass-full:before { content: $ti-icon-glass-full; }
.#{$ti-prefix}-glass-off:before { content: $ti-icon-glass-off; }
.#{$ti-prefix}-globe:before { content: $ti-icon-globe; }
.#{$ti-prefix}-golf:before { content: $ti-icon-golf; }
.#{$ti-prefix}-gps:before { content: $ti-icon-gps; }
.#{$ti-prefix}-grain:before { content: $ti-icon-grain; }
.#{$ti-prefix}-grid-dots:before { content: $ti-icon-grid-dots; }
.#{$ti-prefix}-grill:before { content: $ti-icon-grill; }
.#{$ti-prefix}-grip-horizontal:before { content: $ti-icon-grip-horizontal; }
.#{$ti-prefix}-grip-vertical:before { content: $ti-icon-grip-vertical; }
.#{$ti-prefix}-growth:before { content: $ti-icon-growth; }
.#{$ti-prefix}-h-1:before { content: $ti-icon-h-1; }
.#{$ti-prefix}-h-2:before { content: $ti-icon-h-2; }
.#{$ti-prefix}-h-3:before { content: $ti-icon-h-3; }
.#{$ti-prefix}-h-4:before { content: $ti-icon-h-4; }
.#{$ti-prefix}-h-5:before { content: $ti-icon-h-5; }
.#{$ti-prefix}-h-6:before { content: $ti-icon-h-6; }
.#{$ti-prefix}-hammer:before { content: $ti-icon-hammer; }
.#{$ti-prefix}-hand-click:before { content: $ti-icon-hand-click; }
.#{$ti-prefix}-hand-finger:before { content: $ti-icon-hand-finger; }
.#{$ti-prefix}-hand-little-finger:before { content: $ti-icon-hand-little-finger; }
.#{$ti-prefix}-hand-middle-finger:before { content: $ti-icon-hand-middle-finger; }
.#{$ti-prefix}-hand-move:before { content: $ti-icon-hand-move; }
.#{$ti-prefix}-hand-off:before { content: $ti-icon-hand-off; }
.#{$ti-prefix}-hand-ring-finger:before { content: $ti-icon-hand-ring-finger; }
.#{$ti-prefix}-hand-rock:before { content: $ti-icon-hand-rock; }
.#{$ti-prefix}-hand-stop:before { content: $ti-icon-hand-stop; }
.#{$ti-prefix}-hand-three-fingers:before { content: $ti-icon-hand-three-fingers; }
.#{$ti-prefix}-hand-two-fingers:before { content: $ti-icon-hand-two-fingers; }
.#{$ti-prefix}-hanger:before { content: $ti-icon-hanger; }
.#{$ti-prefix}-hash:before { content: $ti-icon-hash; }
.#{$ti-prefix}-haze:before { content: $ti-icon-haze; }
.#{$ti-prefix}-heading:before { content: $ti-icon-heading; }
.#{$ti-prefix}-headphones:before { content: $ti-icon-headphones; }
.#{$ti-prefix}-headphones-off:before { content: $ti-icon-headphones-off; }
.#{$ti-prefix}-headset:before { content: $ti-icon-headset; }
.#{$ti-prefix}-heart:before { content: $ti-icon-heart; }
.#{$ti-prefix}-heart-broken:before { content: $ti-icon-heart-broken; }
.#{$ti-prefix}-heart-rate-monitor:before { content: $ti-icon-heart-rate-monitor; }
.#{$ti-prefix}-heartbeat:before { content: $ti-icon-heartbeat; }
.#{$ti-prefix}-helicopter:before { content: $ti-icon-helicopter; }
.#{$ti-prefix}-helicopter-landing:before { content: $ti-icon-helicopter-landing; }
.#{$ti-prefix}-help:before { content: $ti-icon-help; }
.#{$ti-prefix}-hexagon:before { content: $ti-icon-hexagon; }
.#{$ti-prefix}-hexagon-off:before { content: $ti-icon-hexagon-off; }
.#{$ti-prefix}-hierarchy:before { content: $ti-icon-hierarchy; }
.#{$ti-prefix}-hierarchy-2:before { content: $ti-icon-hierarchy-2; }
.#{$ti-prefix}-highlight:before { content: $ti-icon-highlight; }
.#{$ti-prefix}-history:before { content: $ti-icon-history; }
.#{$ti-prefix}-home:before { content: $ti-icon-home; }
.#{$ti-prefix}-home-2:before { content: $ti-icon-home-2; }
.#{$ti-prefix}-hotel-service:before { content: $ti-icon-hotel-service; }
.#{$ti-prefix}-hourglass:before { content: $ti-icon-hourglass; }
.#{$ti-prefix}-ice-cream:before { content: $ti-icon-ice-cream; }
.#{$ti-prefix}-ice-cream-2:before { content: $ti-icon-ice-cream-2; }
.#{$ti-prefix}-id:before { content: $ti-icon-id; }
.#{$ti-prefix}-inbox:before { content: $ti-icon-inbox; }
.#{$ti-prefix}-indent-decrease:before { content: $ti-icon-indent-decrease; }
.#{$ti-prefix}-indent-increase:before { content: $ti-icon-indent-increase; }
.#{$ti-prefix}-infinity:before { content: $ti-icon-infinity; }
.#{$ti-prefix}-info-circle:before { content: $ti-icon-info-circle; }
.#{$ti-prefix}-info-square:before { content: $ti-icon-info-square; }
.#{$ti-prefix}-italic:before { content: $ti-icon-italic; }
.#{$ti-prefix}-jump-rope:before { content: $ti-icon-jump-rope; }
.#{$ti-prefix}-karate:before { content: $ti-icon-karate; }
.#{$ti-prefix}-key:before { content: $ti-icon-key; }
.#{$ti-prefix}-keyboard:before { content: $ti-icon-keyboard; }
.#{$ti-prefix}-keyboard-hide:before { content: $ti-icon-keyboard-hide; }
.#{$ti-prefix}-keyboard-off:before { content: $ti-icon-keyboard-off; }
.#{$ti-prefix}-keyboard-show:before { content: $ti-icon-keyboard-show; }
.#{$ti-prefix}-lamp:before { content: $ti-icon-lamp; }
.#{$ti-prefix}-language:before { content: $ti-icon-language; }
.#{$ti-prefix}-language-hiragana:before { content: $ti-icon-language-hiragana; }
.#{$ti-prefix}-language-katakana:before { content: $ti-icon-language-katakana; }
.#{$ti-prefix}-lasso:before { content: $ti-icon-lasso; }
.#{$ti-prefix}-layers-difference:before { content: $ti-icon-layers-difference; }
.#{$ti-prefix}-layers-intersect:before { content: $ti-icon-layers-intersect; }
.#{$ti-prefix}-layers-linked:before { content: $ti-icon-layers-linked; }
.#{$ti-prefix}-layers-subtract:before { content: $ti-icon-layers-subtract; }
.#{$ti-prefix}-layers-union:before { content: $ti-icon-layers-union; }
.#{$ti-prefix}-layout:before { content: $ti-icon-layout; }
.#{$ti-prefix}-layout-2:before { content: $ti-icon-layout-2; }
.#{$ti-prefix}-layout-align-bottom:before { content: $ti-icon-layout-align-bottom; }
.#{$ti-prefix}-layout-align-center:before { content: $ti-icon-layout-align-center; }
.#{$ti-prefix}-layout-align-left:before { content: $ti-icon-layout-align-left; }
.#{$ti-prefix}-layout-align-middle:before { content: $ti-icon-layout-align-middle; }
.#{$ti-prefix}-layout-align-right:before { content: $ti-icon-layout-align-right; }
.#{$ti-prefix}-layout-align-top:before { content: $ti-icon-layout-align-top; }
.#{$ti-prefix}-layout-board:before { content: $ti-icon-layout-board; }
.#{$ti-prefix}-layout-board-split:before { content: $ti-icon-layout-board-split; }
.#{$ti-prefix}-layout-bottombar:before { content: $ti-icon-layout-bottombar; }
.#{$ti-prefix}-layout-cards:before { content: $ti-icon-layout-cards; }
.#{$ti-prefix}-layout-columns:before { content: $ti-icon-layout-columns; }
.#{$ti-prefix}-layout-distribute-horizontal:before { content: $ti-icon-layout-distribute-horizontal; }
.#{$ti-prefix}-layout-distribute-vertical:before { content: $ti-icon-layout-distribute-vertical; }
.#{$ti-prefix}-layout-grid:before { content: $ti-icon-layout-grid; }
.#{$ti-prefix}-layout-grid-add:before { content: $ti-icon-layout-grid-add; }
.#{$ti-prefix}-layout-kanban:before { content: $ti-icon-layout-kanban; }
.#{$ti-prefix}-layout-list:before { content: $ti-icon-layout-list; }
.#{$ti-prefix}-layout-navbar:before { content: $ti-icon-layout-navbar; }
.#{$ti-prefix}-layout-rows:before { content: $ti-icon-layout-rows; }
.#{$ti-prefix}-layout-sidebar:before { content: $ti-icon-layout-sidebar; }
.#{$ti-prefix}-layout-sidebar-right:before { content: $ti-icon-layout-sidebar-right; }
.#{$ti-prefix}-leaf:before { content: $ti-icon-leaf; }
.#{$ti-prefix}-lego:before { content: $ti-icon-lego; }
.#{$ti-prefix}-lemon:before { content: $ti-icon-lemon; }
.#{$ti-prefix}-lemon-2:before { content: $ti-icon-lemon-2; }
.#{$ti-prefix}-letter-a:before { content: $ti-icon-letter-a; }
.#{$ti-prefix}-letter-b:before { content: $ti-icon-letter-b; }
.#{$ti-prefix}-letter-c:before { content: $ti-icon-letter-c; }
.#{$ti-prefix}-letter-case:before { content: $ti-icon-letter-case; }
.#{$ti-prefix}-letter-case-lower:before { content: $ti-icon-letter-case-lower; }
.#{$ti-prefix}-letter-case-toggle:before { content: $ti-icon-letter-case-toggle; }
.#{$ti-prefix}-letter-case-upper:before { content: $ti-icon-letter-case-upper; }
.#{$ti-prefix}-letter-d:before { content: $ti-icon-letter-d; }
.#{$ti-prefix}-letter-e:before { content: $ti-icon-letter-e; }
.#{$ti-prefix}-letter-f:before { content: $ti-icon-letter-f; }
.#{$ti-prefix}-letter-g:before { content: $ti-icon-letter-g; }
.#{$ti-prefix}-letter-h:before { content: $ti-icon-letter-h; }
.#{$ti-prefix}-letter-i:before { content: $ti-icon-letter-i; }
.#{$ti-prefix}-letter-j:before { content: $ti-icon-letter-j; }
.#{$ti-prefix}-letter-k:before { content: $ti-icon-letter-k; }
.#{$ti-prefix}-letter-l:before { content: $ti-icon-letter-l; }
.#{$ti-prefix}-letter-m:before { content: $ti-icon-letter-m; }
.#{$ti-prefix}-letter-n:before { content: $ti-icon-letter-n; }
.#{$ti-prefix}-letter-o:before { content: $ti-icon-letter-o; }
.#{$ti-prefix}-letter-p:before { content: $ti-icon-letter-p; }
.#{$ti-prefix}-letter-q:before { content: $ti-icon-letter-q; }
.#{$ti-prefix}-letter-r:before { content: $ti-icon-letter-r; }
.#{$ti-prefix}-letter-s:before { content: $ti-icon-letter-s; }
.#{$ti-prefix}-letter-spacing:before { content: $ti-icon-letter-spacing; }
.#{$ti-prefix}-letter-t:before { content: $ti-icon-letter-t; }
.#{$ti-prefix}-letter-u:before { content: $ti-icon-letter-u; }
.#{$ti-prefix}-letter-v:before { content: $ti-icon-letter-v; }
.#{$ti-prefix}-letter-w:before { content: $ti-icon-letter-w; }
.#{$ti-prefix}-letter-x:before { content: $ti-icon-letter-x; }
.#{$ti-prefix}-letter-y:before { content: $ti-icon-letter-y; }
.#{$ti-prefix}-letter-z:before { content: $ti-icon-letter-z; }
.#{$ti-prefix}-letters-case:before { content: $ti-icon-letters-case; }
.#{$ti-prefix}-license:before { content: $ti-icon-license; }
.#{$ti-prefix}-lifebuoy:before { content: $ti-icon-lifebuoy; }
.#{$ti-prefix}-line:before { content: $ti-icon-line; }
.#{$ti-prefix}-line-dashed:before { content: $ti-icon-line-dashed; }
.#{$ti-prefix}-line-dotted:before { content: $ti-icon-line-dotted; }
.#{$ti-prefix}-line-height:before { content: $ti-icon-line-height; }
.#{$ti-prefix}-link:before { content: $ti-icon-link; }
.#{$ti-prefix}-list:before { content: $ti-icon-list; }
.#{$ti-prefix}-list-check:before { content: $ti-icon-list-check; }
.#{$ti-prefix}-list-details:before { content: $ti-icon-list-details; }
.#{$ti-prefix}-list-numbers:before { content: $ti-icon-list-numbers; }
.#{$ti-prefix}-list-search:before { content: $ti-icon-list-search; }
.#{$ti-prefix}-live-photo:before { content: $ti-icon-live-photo; }
.#{$ti-prefix}-live-view:before { content: $ti-icon-live-view; }
.#{$ti-prefix}-loader:before { content: $ti-icon-loader; }
.#{$ti-prefix}-loader-quarter:before { content: $ti-icon-loader-quarter; }
.#{$ti-prefix}-location:before { content: $ti-icon-location; }
.#{$ti-prefix}-lock:before { content: $ti-icon-lock; }
.#{$ti-prefix}-lock-access:before { content: $ti-icon-lock-access; }
.#{$ti-prefix}-lock-off:before { content: $ti-icon-lock-off; }
.#{$ti-prefix}-lock-open:before { content: $ti-icon-lock-open; }
.#{$ti-prefix}-lock-square:before { content: $ti-icon-lock-square; }
.#{$ti-prefix}-login:before { content: $ti-icon-login; }
.#{$ti-prefix}-logout:before { content: $ti-icon-logout; }
.#{$ti-prefix}-luggage:before { content: $ti-icon-luggage; }
.#{$ti-prefix}-lungs:before { content: $ti-icon-lungs; }
.#{$ti-prefix}-macro:before { content: $ti-icon-macro; }
.#{$ti-prefix}-magnet:before { content: $ti-icon-magnet; }
.#{$ti-prefix}-mail:before { content: $ti-icon-mail; }
.#{$ti-prefix}-mail-forward:before { content: $ti-icon-mail-forward; }
.#{$ti-prefix}-mail-opened:before { content: $ti-icon-mail-opened; }
.#{$ti-prefix}-mailbox:before { content: $ti-icon-mailbox; }
.#{$ti-prefix}-man:before { content: $ti-icon-man; }
.#{$ti-prefix}-manual-gearbox:before { content: $ti-icon-manual-gearbox; }
.#{$ti-prefix}-map:before { content: $ti-icon-map; }
.#{$ti-prefix}-map-2:before { content: $ti-icon-map-2; }
.#{$ti-prefix}-map-pin:before { content: $ti-icon-map-pin; }
.#{$ti-prefix}-map-pin-off:before { content: $ti-icon-map-pin-off; }
.#{$ti-prefix}-map-pins:before { content: $ti-icon-map-pins; }
.#{$ti-prefix}-map-search:before { content: $ti-icon-map-search; }
.#{$ti-prefix}-markdown:before { content: $ti-icon-markdown; }
.#{$ti-prefix}-marquee:before { content: $ti-icon-marquee; }
.#{$ti-prefix}-marquee-2:before { content: $ti-icon-marquee-2; }
.#{$ti-prefix}-mars:before { content: $ti-icon-mars; }
.#{$ti-prefix}-mask:before { content: $ti-icon-mask; }
.#{$ti-prefix}-mask-off:before { content: $ti-icon-mask-off; }
.#{$ti-prefix}-massage:before { content: $ti-icon-massage; }
.#{$ti-prefix}-math:before { content: $ti-icon-math; }
.#{$ti-prefix}-math-function:before { content: $ti-icon-math-function; }
.#{$ti-prefix}-math-symbols:before { content: $ti-icon-math-symbols; }
.#{$ti-prefix}-maximize:before { content: $ti-icon-maximize; }
.#{$ti-prefix}-meat:before { content: $ti-icon-meat; }
.#{$ti-prefix}-medal:before { content: $ti-icon-medal; }
.#{$ti-prefix}-medical-cross:before { content: $ti-icon-medical-cross; }
.#{$ti-prefix}-medicine-syrup:before { content: $ti-icon-medicine-syrup; }
.#{$ti-prefix}-menu:before { content: $ti-icon-menu; }
.#{$ti-prefix}-menu-2:before { content: $ti-icon-menu-2; }
.#{$ti-prefix}-message:before { content: $ti-icon-message; }
.#{$ti-prefix}-message-2:before { content: $ti-icon-message-2; }
.#{$ti-prefix}-message-circle:before { content: $ti-icon-message-circle; }
.#{$ti-prefix}-message-circle-2:before { content: $ti-icon-message-circle-2; }
.#{$ti-prefix}-message-circle-off:before { content: $ti-icon-message-circle-off; }
.#{$ti-prefix}-message-dots:before { content: $ti-icon-message-dots; }
.#{$ti-prefix}-message-language:before { content: $ti-icon-message-language; }
.#{$ti-prefix}-message-off:before { content: $ti-icon-message-off; }
.#{$ti-prefix}-message-plus:before { content: $ti-icon-message-plus; }
.#{$ti-prefix}-message-report:before { content: $ti-icon-message-report; }
.#{$ti-prefix}-messages:before { content: $ti-icon-messages; }
.#{$ti-prefix}-messages-off:before { content: $ti-icon-messages-off; }
.#{$ti-prefix}-microphone:before { content: $ti-icon-microphone; }
.#{$ti-prefix}-microphone-2:before { content: $ti-icon-microphone-2; }
.#{$ti-prefix}-microphone-off:before { content: $ti-icon-microphone-off; }
.#{$ti-prefix}-microscope:before { content: $ti-icon-microscope; }
.#{$ti-prefix}-milk:before { content: $ti-icon-milk; }
.#{$ti-prefix}-minimize:before { content: $ti-icon-minimize; }
.#{$ti-prefix}-minus:before { content: $ti-icon-minus; }
.#{$ti-prefix}-minus-vertical:before { content: $ti-icon-minus-vertical; }
.#{$ti-prefix}-mist:before { content: $ti-icon-mist; }
.#{$ti-prefix}-mood-boy:before { content: $ti-icon-mood-boy; }
.#{$ti-prefix}-mood-confuzed:before { content: $ti-icon-mood-confuzed; }
.#{$ti-prefix}-mood-crazy-happy:before { content: $ti-icon-mood-crazy-happy; }
.#{$ti-prefix}-mood-cry:before { content: $ti-icon-mood-cry; }
.#{$ti-prefix}-mood-empty:before { content: $ti-icon-mood-empty; }
.#{$ti-prefix}-mood-happy:before { content: $ti-icon-mood-happy; }
.#{$ti-prefix}-mood-kid:before { content: $ti-icon-mood-kid; }
.#{$ti-prefix}-mood-nervous:before { content: $ti-icon-mood-nervous; }
.#{$ti-prefix}-mood-neutral:before { content: $ti-icon-mood-neutral; }
.#{$ti-prefix}-mood-sad:before { content: $ti-icon-mood-sad; }
.#{$ti-prefix}-mood-smile:before { content: $ti-icon-mood-smile; }
.#{$ti-prefix}-mood-suprised:before { content: $ti-icon-mood-suprised; }
.#{$ti-prefix}-mood-tongue:before { content: $ti-icon-mood-tongue; }
.#{$ti-prefix}-moon:before { content: $ti-icon-moon; }
.#{$ti-prefix}-moon-2:before { content: $ti-icon-moon-2; }
.#{$ti-prefix}-moon-stars:before { content: $ti-icon-moon-stars; }
.#{$ti-prefix}-moped:before { content: $ti-icon-moped; }
.#{$ti-prefix}-motorbike:before { content: $ti-icon-motorbike; }
.#{$ti-prefix}-mountain:before { content: $ti-icon-mountain; }
.#{$ti-prefix}-mouse:before { content: $ti-icon-mouse; }
.#{$ti-prefix}-movie:before { content: $ti-icon-movie; }
.#{$ti-prefix}-mug:before { content: $ti-icon-mug; }
.#{$ti-prefix}-multiplier-0-5x:before { content: $ti-icon-multiplier-0-5x; }
.#{$ti-prefix}-multiplier-1-5x:before { content: $ti-icon-multiplier-1-5x; }
.#{$ti-prefix}-multiplier-1x:before { content: $ti-icon-multiplier-1x; }
.#{$ti-prefix}-multiplier-2x:before { content: $ti-icon-multiplier-2x; }
.#{$ti-prefix}-mushroom:before { content: $ti-icon-mushroom; }
.#{$ti-prefix}-music:before { content: $ti-icon-music; }
.#{$ti-prefix}-new-section:before { content: $ti-icon-new-section; }
.#{$ti-prefix}-news:before { content: $ti-icon-news; }
.#{$ti-prefix}-nfc:before { content: $ti-icon-nfc; }
.#{$ti-prefix}-note:before { content: $ti-icon-note; }
.#{$ti-prefix}-notebook:before { content: $ti-icon-notebook; }
.#{$ti-prefix}-notes:before { content: $ti-icon-notes; }
.#{$ti-prefix}-notification:before { content: $ti-icon-notification; }
.#{$ti-prefix}-number-0:before { content: $ti-icon-number-0; }
.#{$ti-prefix}-number-1:before { content: $ti-icon-number-1; }
.#{$ti-prefix}-number-2:before { content: $ti-icon-number-2; }
.#{$ti-prefix}-number-3:before { content: $ti-icon-number-3; }
.#{$ti-prefix}-number-4:before { content: $ti-icon-number-4; }
.#{$ti-prefix}-number-5:before { content: $ti-icon-number-5; }
.#{$ti-prefix}-number-6:before { content: $ti-icon-number-6; }
.#{$ti-prefix}-number-7:before { content: $ti-icon-number-7; }
.#{$ti-prefix}-number-8:before { content: $ti-icon-number-8; }
.#{$ti-prefix}-number-9:before { content: $ti-icon-number-9; }
.#{$ti-prefix}-nurse:before { content: $ti-icon-nurse; }
.#{$ti-prefix}-octagon:before { content: $ti-icon-octagon; }
.#{$ti-prefix}-octagon-off:before { content: $ti-icon-octagon-off; }
.#{$ti-prefix}-old:before { content: $ti-icon-old; }
.#{$ti-prefix}-olympics:before { content: $ti-icon-olympics; }
.#{$ti-prefix}-omega:before { content: $ti-icon-omega; }
.#{$ti-prefix}-outlet:before { content: $ti-icon-outlet; }
.#{$ti-prefix}-overline:before { content: $ti-icon-overline; }
.#{$ti-prefix}-package:before { content: $ti-icon-package; }
.#{$ti-prefix}-pacman:before { content: $ti-icon-pacman; }
.#{$ti-prefix}-page-break:before { content: $ti-icon-page-break; }
.#{$ti-prefix}-paint:before { content: $ti-icon-paint; }
.#{$ti-prefix}-palette:before { content: $ti-icon-palette; }
.#{$ti-prefix}-panorama-horizontal:before { content: $ti-icon-panorama-horizontal; }
.#{$ti-prefix}-panorama-vertical:before { content: $ti-icon-panorama-vertical; }
.#{$ti-prefix}-paperclip:before { content: $ti-icon-paperclip; }
.#{$ti-prefix}-parachute:before { content: $ti-icon-parachute; }
.#{$ti-prefix}-parentheses:before { content: $ti-icon-parentheses; }
.#{$ti-prefix}-parking:before { content: $ti-icon-parking; }
.#{$ti-prefix}-peace:before { content: $ti-icon-peace; }
.#{$ti-prefix}-pencil:before { content: $ti-icon-pencil; }
.#{$ti-prefix}-pennant:before { content: $ti-icon-pennant; }
.#{$ti-prefix}-pepper:before { content: $ti-icon-pepper; }
.#{$ti-prefix}-percentage:before { content: $ti-icon-percentage; }
.#{$ti-prefix}-perspective:before { content: $ti-icon-perspective; }
.#{$ti-prefix}-phone:before { content: $ti-icon-phone; }
.#{$ti-prefix}-phone-call:before { content: $ti-icon-phone-call; }
.#{$ti-prefix}-phone-calling:before { content: $ti-icon-phone-calling; }
.#{$ti-prefix}-phone-check:before { content: $ti-icon-phone-check; }
.#{$ti-prefix}-phone-incoming:before { content: $ti-icon-phone-incoming; }
.#{$ti-prefix}-phone-off:before { content: $ti-icon-phone-off; }
.#{$ti-prefix}-phone-outgoing:before { content: $ti-icon-phone-outgoing; }
.#{$ti-prefix}-phone-pause:before { content: $ti-icon-phone-pause; }
.#{$ti-prefix}-phone-plus:before { content: $ti-icon-phone-plus; }
.#{$ti-prefix}-phone-x:before { content: $ti-icon-phone-x; }
.#{$ti-prefix}-photo:before { content: $ti-icon-photo; }
.#{$ti-prefix}-photo-off:before { content: $ti-icon-photo-off; }
.#{$ti-prefix}-physotherapist:before { content: $ti-icon-physotherapist; }
.#{$ti-prefix}-picture-in-picture:before { content: $ti-icon-picture-in-picture; }
.#{$ti-prefix}-picture-in-picture-off:before { content: $ti-icon-picture-in-picture-off; }
.#{$ti-prefix}-picture-in-picture-on:before { content: $ti-icon-picture-in-picture-on; }
.#{$ti-prefix}-pig:before { content: $ti-icon-pig; }
.#{$ti-prefix}-pill:before { content: $ti-icon-pill; }
.#{$ti-prefix}-pills:before { content: $ti-icon-pills; }
.#{$ti-prefix}-pin:before { content: $ti-icon-pin; }
.#{$ti-prefix}-pinned:before { content: $ti-icon-pinned; }
.#{$ti-prefix}-pinned-off:before { content: $ti-icon-pinned-off; }
.#{$ti-prefix}-pizza:before { content: $ti-icon-pizza; }
.#{$ti-prefix}-plane:before { content: $ti-icon-plane; }
.#{$ti-prefix}-plane-arrival:before { content: $ti-icon-plane-arrival; }
.#{$ti-prefix}-plane-departure:before { content: $ti-icon-plane-departure; }
.#{$ti-prefix}-plane-inflight:before { content: $ti-icon-plane-inflight; }
.#{$ti-prefix}-planet:before { content: $ti-icon-planet; }
.#{$ti-prefix}-plant:before { content: $ti-icon-plant; }
.#{$ti-prefix}-plant-2:before { content: $ti-icon-plant-2; }
.#{$ti-prefix}-play-card:before { content: $ti-icon-play-card; }
.#{$ti-prefix}-player-pause:before { content: $ti-icon-player-pause; }
.#{$ti-prefix}-player-play:before { content: $ti-icon-player-play; }
.#{$ti-prefix}-player-record:before { content: $ti-icon-player-record; }
.#{$ti-prefix}-player-skip-back:before { content: $ti-icon-player-skip-back; }
.#{$ti-prefix}-player-skip-forward:before { content: $ti-icon-player-skip-forward; }
.#{$ti-prefix}-player-stop:before { content: $ti-icon-player-stop; }
.#{$ti-prefix}-player-track-next:before { content: $ti-icon-player-track-next; }
.#{$ti-prefix}-player-track-prev:before { content: $ti-icon-player-track-prev; }
.#{$ti-prefix}-playlist:before { content: $ti-icon-playlist; }
.#{$ti-prefix}-plug:before { content: $ti-icon-plug; }
.#{$ti-prefix}-plus:before { content: $ti-icon-plus; }
.#{$ti-prefix}-point:before { content: $ti-icon-point; }
.#{$ti-prefix}-pokeball:before { content: $ti-icon-pokeball; }
.#{$ti-prefix}-polaroid:before { content: $ti-icon-polaroid; }
.#{$ti-prefix}-pool:before { content: $ti-icon-pool; }
.#{$ti-prefix}-power:before { content: $ti-icon-power; }
.#{$ti-prefix}-pray:before { content: $ti-icon-pray; }
.#{$ti-prefix}-prescription:before { content: $ti-icon-prescription; }
.#{$ti-prefix}-presentation:before { content: $ti-icon-presentation; }
.#{$ti-prefix}-presentation-analytics:before { content: $ti-icon-presentation-analytics; }
.#{$ti-prefix}-printer:before { content: $ti-icon-printer; }
.#{$ti-prefix}-prison:before { content: $ti-icon-prison; }
.#{$ti-prefix}-prompt:before { content: $ti-icon-prompt; }
.#{$ti-prefix}-propeller:before { content: $ti-icon-propeller; }
.#{$ti-prefix}-puzzle:before { content: $ti-icon-puzzle; }
.#{$ti-prefix}-puzzle-2:before { content: $ti-icon-puzzle-2; }
.#{$ti-prefix}-pyramid:before { content: $ti-icon-pyramid; }
.#{$ti-prefix}-qrcode:before { content: $ti-icon-qrcode; }
.#{$ti-prefix}-question-mark:before { content: $ti-icon-question-mark; }
.#{$ti-prefix}-radio:before { content: $ti-icon-radio; }
.#{$ti-prefix}-radioactive:before { content: $ti-icon-radioactive; }
.#{$ti-prefix}-radius-bottom-left:before { content: $ti-icon-radius-bottom-left; }
.#{$ti-prefix}-radius-bottom-right:before { content: $ti-icon-radius-bottom-right; }
.#{$ti-prefix}-radius-top-left:before { content: $ti-icon-radius-top-left; }
.#{$ti-prefix}-radius-top-right:before { content: $ti-icon-radius-top-right; }
.#{$ti-prefix}-rainbow:before { content: $ti-icon-rainbow; }
.#{$ti-prefix}-receipt:before { content: $ti-icon-receipt; }
.#{$ti-prefix}-receipt-2:before { content: $ti-icon-receipt-2; }
.#{$ti-prefix}-receipt-off:before { content: $ti-icon-receipt-off; }
.#{$ti-prefix}-receipt-refund:before { content: $ti-icon-receipt-refund; }
.#{$ti-prefix}-receipt-tax:before { content: $ti-icon-receipt-tax; }
.#{$ti-prefix}-recharging:before { content: $ti-icon-recharging; }
.#{$ti-prefix}-record-mail:before { content: $ti-icon-record-mail; }
.#{$ti-prefix}-rectangle:before { content: $ti-icon-rectangle; }
.#{$ti-prefix}-rectangle-vertical:before { content: $ti-icon-rectangle-vertical; }
.#{$ti-prefix}-recycle:before { content: $ti-icon-recycle; }
.#{$ti-prefix}-refresh:before { content: $ti-icon-refresh; }
.#{$ti-prefix}-refresh-alert:before { content: $ti-icon-refresh-alert; }
.#{$ti-prefix}-registered:before { content: $ti-icon-registered; }
.#{$ti-prefix}-relation-many-to-many:before { content: $ti-icon-relation-many-to-many; }
.#{$ti-prefix}-relation-one-to-many:before { content: $ti-icon-relation-one-to-many; }
.#{$ti-prefix}-relation-one-to-one:before { content: $ti-icon-relation-one-to-one; }
.#{$ti-prefix}-repeat:before { content: $ti-icon-repeat; }
.#{$ti-prefix}-repeat-once:before { content: $ti-icon-repeat-once; }
.#{$ti-prefix}-replace:before { content: $ti-icon-replace; }
.#{$ti-prefix}-report:before { content: $ti-icon-report; }
.#{$ti-prefix}-report-analytics:before { content: $ti-icon-report-analytics; }
.#{$ti-prefix}-report-medical:before { content: $ti-icon-report-medical; }
.#{$ti-prefix}-report-money:before { content: $ti-icon-report-money; }
.#{$ti-prefix}-report-search:before { content: $ti-icon-report-search; }
.#{$ti-prefix}-resize:before { content: $ti-icon-resize; }
.#{$ti-prefix}-ripple:before { content: $ti-icon-ripple; }
.#{$ti-prefix}-road-sign:before { content: $ti-icon-road-sign; }
.#{$ti-prefix}-rocket:before { content: $ti-icon-rocket; }
.#{$ti-prefix}-rotate:before { content: $ti-icon-rotate; }
.#{$ti-prefix}-rotate-2:before { content: $ti-icon-rotate-2; }
.#{$ti-prefix}-rotate-360:before { content: $ti-icon-rotate-360; }
.#{$ti-prefix}-rotate-clockwise:before { content: $ti-icon-rotate-clockwise; }
.#{$ti-prefix}-rotate-clockwise-2:before { content: $ti-icon-rotate-clockwise-2; }
.#{$ti-prefix}-rotate-rectangle:before { content: $ti-icon-rotate-rectangle; }
.#{$ti-prefix}-route:before { content: $ti-icon-route; }
.#{$ti-prefix}-router:before { content: $ti-icon-router; }
.#{$ti-prefix}-row-insert-bottom:before { content: $ti-icon-row-insert-bottom; }
.#{$ti-prefix}-row-insert-top:before { content: $ti-icon-row-insert-top; }
.#{$ti-prefix}-rss:before { content: $ti-icon-rss; }
.#{$ti-prefix}-ruler:before { content: $ti-icon-ruler; }
.#{$ti-prefix}-ruler-2:before { content: $ti-icon-ruler-2; }
.#{$ti-prefix}-run:before { content: $ti-icon-run; }
.#{$ti-prefix}-sailboat:before { content: $ti-icon-sailboat; }
.#{$ti-prefix}-salt:before { content: $ti-icon-salt; }
.#{$ti-prefix}-satellite:before { content: $ti-icon-satellite; }
.#{$ti-prefix}-sausage:before { content: $ti-icon-sausage; }
.#{$ti-prefix}-scale:before { content: $ti-icon-scale; }
.#{$ti-prefix}-scale-outline:before { content: $ti-icon-scale-outline; }
.#{$ti-prefix}-scan:before { content: $ti-icon-scan; }
.#{$ti-prefix}-school:before { content: $ti-icon-school; }
.#{$ti-prefix}-scissors:before { content: $ti-icon-scissors; }
.#{$ti-prefix}-scooter:before { content: $ti-icon-scooter; }
.#{$ti-prefix}-scooter-electric:before { content: $ti-icon-scooter-electric; }
.#{$ti-prefix}-screen-share:before { content: $ti-icon-screen-share; }
.#{$ti-prefix}-screen-share-off:before { content: $ti-icon-screen-share-off; }
.#{$ti-prefix}-scuba-mask:before { content: $ti-icon-scuba-mask; }
.#{$ti-prefix}-search:before { content: $ti-icon-search; }
.#{$ti-prefix}-section:before { content: $ti-icon-section; }
.#{$ti-prefix}-seeding:before { content: $ti-icon-seeding; }
.#{$ti-prefix}-select:before { content: $ti-icon-select; }
.#{$ti-prefix}-selector:before { content: $ti-icon-selector; }
.#{$ti-prefix}-send:before { content: $ti-icon-send; }
.#{$ti-prefix}-separator:before { content: $ti-icon-separator; }
.#{$ti-prefix}-separator-horizontal:before { content: $ti-icon-separator-horizontal; }
.#{$ti-prefix}-separator-vertical:before { content: $ti-icon-separator-vertical; }
.#{$ti-prefix}-server:before { content: $ti-icon-server; }
.#{$ti-prefix}-servicemark:before { content: $ti-icon-servicemark; }
.#{$ti-prefix}-settings:before { content: $ti-icon-settings; }
.#{$ti-prefix}-settings-automation:before { content: $ti-icon-settings-automation; }
.#{$ti-prefix}-shadow:before { content: $ti-icon-shadow; }
.#{$ti-prefix}-shadow-off:before { content: $ti-icon-shadow-off; }
.#{$ti-prefix}-shape:before { content: $ti-icon-shape; }
.#{$ti-prefix}-shape-2:before { content: $ti-icon-shape-2; }
.#{$ti-prefix}-shape-3:before { content: $ti-icon-shape-3; }
.#{$ti-prefix}-share:before { content: $ti-icon-share; }
.#{$ti-prefix}-shield:before { content: $ti-icon-shield; }
.#{$ti-prefix}-shield-check:before { content: $ti-icon-shield-check; }
.#{$ti-prefix}-shield-checkered:before { content: $ti-icon-shield-checkered; }
.#{$ti-prefix}-shield-chevron:before { content: $ti-icon-shield-chevron; }
.#{$ti-prefix}-shield-lock:before { content: $ti-icon-shield-lock; }
.#{$ti-prefix}-shield-off:before { content: $ti-icon-shield-off; }
.#{$ti-prefix}-shield-x:before { content: $ti-icon-shield-x; }
.#{$ti-prefix}-ship:before { content: $ti-icon-ship; }
.#{$ti-prefix}-shirt:before { content: $ti-icon-shirt; }
.#{$ti-prefix}-shopping-cart:before { content: $ti-icon-shopping-cart; }
.#{$ti-prefix}-shopping-cart-discount:before { content: $ti-icon-shopping-cart-discount; }
.#{$ti-prefix}-shopping-cart-off:before { content: $ti-icon-shopping-cart-off; }
.#{$ti-prefix}-shopping-cart-plus:before { content: $ti-icon-shopping-cart-plus; }
.#{$ti-prefix}-shopping-cart-x:before { content: $ti-icon-shopping-cart-x; }
.#{$ti-prefix}-shredder:before { content: $ti-icon-shredder; }
.#{$ti-prefix}-signature:before { content: $ti-icon-signature; }
.#{$ti-prefix}-sitemap:before { content: $ti-icon-sitemap; }
.#{$ti-prefix}-skateboard:before { content: $ti-icon-skateboard; }
.#{$ti-prefix}-sleigh:before { content: $ti-icon-sleigh; }
.#{$ti-prefix}-slice:before { content: $ti-icon-slice; }
.#{$ti-prefix}-slideshow:before { content: $ti-icon-slideshow; }
.#{$ti-prefix}-smart-home:before { content: $ti-icon-smart-home; }
.#{$ti-prefix}-smoking:before { content: $ti-icon-smoking; }
.#{$ti-prefix}-smoking-no:before { content: $ti-icon-smoking-no; }
.#{$ti-prefix}-snowflake:before { content: $ti-icon-snowflake; }
.#{$ti-prefix}-soccer-field:before { content: $ti-icon-soccer-field; }
.#{$ti-prefix}-social:before { content: $ti-icon-social; }
.#{$ti-prefix}-sock:before { content: $ti-icon-sock; }
.#{$ti-prefix}-sofa:before { content: $ti-icon-sofa; }
.#{$ti-prefix}-sort-ascending:before { content: $ti-icon-sort-ascending; }
.#{$ti-prefix}-sort-ascending-2:before { content: $ti-icon-sort-ascending-2; }
.#{$ti-prefix}-sort-ascending-letters:before { content: $ti-icon-sort-ascending-letters; }
.#{$ti-prefix}-sort-ascending-numbers:before { content: $ti-icon-sort-ascending-numbers; }
.#{$ti-prefix}-sort-descending:before { content: $ti-icon-sort-descending; }
.#{$ti-prefix}-sort-descending-2:before { content: $ti-icon-sort-descending-2; }
.#{$ti-prefix}-sort-descending-letters:before { content: $ti-icon-sort-descending-letters; }
.#{$ti-prefix}-sort-descending-numbers:before { content: $ti-icon-sort-descending-numbers; }
.#{$ti-prefix}-soup:before { content: $ti-icon-soup; }
.#{$ti-prefix}-space:before { content: $ti-icon-space; }
.#{$ti-prefix}-spacing-horizontal:before { content: $ti-icon-spacing-horizontal; }
.#{$ti-prefix}-spacing-vertical:before { content: $ti-icon-spacing-vertical; }
.#{$ti-prefix}-speakerphone:before { content: $ti-icon-speakerphone; }
.#{$ti-prefix}-speedboat:before { content: $ti-icon-speedboat; }
.#{$ti-prefix}-sport-billard:before { content: $ti-icon-sport-billard; }
.#{$ti-prefix}-square:before { content: $ti-icon-square; }
.#{$ti-prefix}-square-0:before { content: $ti-icon-square-0; }
.#{$ti-prefix}-square-1:before { content: $ti-icon-square-1; }
.#{$ti-prefix}-square-2:before { content: $ti-icon-square-2; }
.#{$ti-prefix}-square-3:before { content: $ti-icon-square-3; }
.#{$ti-prefix}-square-4:before { content: $ti-icon-square-4; }
.#{$ti-prefix}-square-5:before { content: $ti-icon-square-5; }
.#{$ti-prefix}-square-6:before { content: $ti-icon-square-6; }
.#{$ti-prefix}-square-7:before { content: $ti-icon-square-7; }
.#{$ti-prefix}-square-8:before { content: $ti-icon-square-8; }
.#{$ti-prefix}-square-9:before { content: $ti-icon-square-9; }
.#{$ti-prefix}-square-check:before { content: $ti-icon-square-check; }
.#{$ti-prefix}-square-dot:before { content: $ti-icon-square-dot; }
.#{$ti-prefix}-square-forbid:before { content: $ti-icon-square-forbid; }
.#{$ti-prefix}-square-forbid-2:before { content: $ti-icon-square-forbid-2; }
.#{$ti-prefix}-square-minus:before { content: $ti-icon-square-minus; }
.#{$ti-prefix}-square-off:before { content: $ti-icon-square-off; }
.#{$ti-prefix}-square-plus:before { content: $ti-icon-square-plus; }
.#{$ti-prefix}-square-root:before { content: $ti-icon-square-root; }
.#{$ti-prefix}-square-root-2:before { content: $ti-icon-square-root-2; }
.#{$ti-prefix}-square-rotated:before { content: $ti-icon-square-rotated; }
.#{$ti-prefix}-square-rotated-off:before { content: $ti-icon-square-rotated-off; }
.#{$ti-prefix}-square-toggle:before { content: $ti-icon-square-toggle; }
.#{$ti-prefix}-square-toggle-horizontal:before { content: $ti-icon-square-toggle-horizontal; }
.#{$ti-prefix}-square-x:before { content: $ti-icon-square-x; }
.#{$ti-prefix}-squares-diagonal:before { content: $ti-icon-squares-diagonal; }
.#{$ti-prefix}-squares-filled:before { content: $ti-icon-squares-filled; }
.#{$ti-prefix}-stack:before { content: $ti-icon-stack; }
.#{$ti-prefix}-stack-2:before { content: $ti-icon-stack-2; }
.#{$ti-prefix}-stack-3:before { content: $ti-icon-stack-3; }
.#{$ti-prefix}-stairs:before { content: $ti-icon-stairs; }
.#{$ti-prefix}-stairs-down:before { content: $ti-icon-stairs-down; }
.#{$ti-prefix}-stairs-up:before { content: $ti-icon-stairs-up; }
.#{$ti-prefix}-star:before { content: $ti-icon-star; }
.#{$ti-prefix}-star-half:before { content: $ti-icon-star-half; }
.#{$ti-prefix}-star-off:before { content: $ti-icon-star-off; }
.#{$ti-prefix}-stars:before { content: $ti-icon-stars; }
.#{$ti-prefix}-steering-wheel:before { content: $ti-icon-steering-wheel; }
.#{$ti-prefix}-step-into:before { content: $ti-icon-step-into; }
.#{$ti-prefix}-step-out:before { content: $ti-icon-step-out; }
.#{$ti-prefix}-stethoscope:before { content: $ti-icon-stethoscope; }
.#{$ti-prefix}-sticker:before { content: $ti-icon-sticker; }
.#{$ti-prefix}-strikethrough:before { content: $ti-icon-strikethrough; }
.#{$ti-prefix}-submarine:before { content: $ti-icon-submarine; }
.#{$ti-prefix}-subscript:before { content: $ti-icon-subscript; }
.#{$ti-prefix}-subtask:before { content: $ti-icon-subtask; }
.#{$ti-prefix}-sum:before { content: $ti-icon-sum; }
.#{$ti-prefix}-sun:before { content: $ti-icon-sun; }
.#{$ti-prefix}-sun-off:before { content: $ti-icon-sun-off; }
.#{$ti-prefix}-sunrise:before { content: $ti-icon-sunrise; }
.#{$ti-prefix}-sunset:before { content: $ti-icon-sunset; }
.#{$ti-prefix}-superscript:before { content: $ti-icon-superscript; }
.#{$ti-prefix}-swimming:before { content: $ti-icon-swimming; }
.#{$ti-prefix}-switch:before { content: $ti-icon-switch; }
.#{$ti-prefix}-switch-2:before { content: $ti-icon-switch-2; }
.#{$ti-prefix}-switch-3:before { content: $ti-icon-switch-3; }
.#{$ti-prefix}-switch-horizontal:before { content: $ti-icon-switch-horizontal; }
.#{$ti-prefix}-switch-vertical:before { content: $ti-icon-switch-vertical; }
.#{$ti-prefix}-table:before { content: $ti-icon-table; }
.#{$ti-prefix}-table-export:before { content: $ti-icon-table-export; }
.#{$ti-prefix}-table-import:before { content: $ti-icon-table-import; }
.#{$ti-prefix}-table-off:before { content: $ti-icon-table-off; }
.#{$ti-prefix}-tag:before { content: $ti-icon-tag; }
.#{$ti-prefix}-tags:before { content: $ti-icon-tags; }
.#{$ti-prefix}-tallymark-1:before { content: $ti-icon-tallymark-1; }
.#{$ti-prefix}-tallymark-2:before { content: $ti-icon-tallymark-2; }
.#{$ti-prefix}-tallymark-3:before { content: $ti-icon-tallymark-3; }
.#{$ti-prefix}-tallymark-4:before { content: $ti-icon-tallymark-4; }
.#{$ti-prefix}-tallymarks:before { content: $ti-icon-tallymarks; }
.#{$ti-prefix}-tank:before { content: $ti-icon-tank; }
.#{$ti-prefix}-target:before { content: $ti-icon-target; }
.#{$ti-prefix}-temperature:before { content: $ti-icon-temperature; }
.#{$ti-prefix}-temperature-celsius:before { content: $ti-icon-temperature-celsius; }
.#{$ti-prefix}-temperature-fahrenheit:before { content: $ti-icon-temperature-fahrenheit; }
.#{$ti-prefix}-temperature-minus:before { content: $ti-icon-temperature-minus; }
.#{$ti-prefix}-temperature-plus:before { content: $ti-icon-temperature-plus; }
.#{$ti-prefix}-template:before { content: $ti-icon-template; }
.#{$ti-prefix}-tent:before { content: $ti-icon-tent; }
.#{$ti-prefix}-terminal:before { content: $ti-icon-terminal; }
.#{$ti-prefix}-terminal-2:before { content: $ti-icon-terminal-2; }
.#{$ti-prefix}-test-pipe:before { content: $ti-icon-test-pipe; }
.#{$ti-prefix}-text-direction-ltr:before { content: $ti-icon-text-direction-ltr; }
.#{$ti-prefix}-text-direction-rtl:before { content: $ti-icon-text-direction-rtl; }
.#{$ti-prefix}-text-resize:before { content: $ti-icon-text-resize; }
.#{$ti-prefix}-text-wrap:before { content: $ti-icon-text-wrap; }
.#{$ti-prefix}-text-wrap-disabled:before { content: $ti-icon-text-wrap-disabled; }
.#{$ti-prefix}-thermometer:before { content: $ti-icon-thermometer; }
.#{$ti-prefix}-thumb-down:before { content: $ti-icon-thumb-down; }
.#{$ti-prefix}-thumb-up:before { content: $ti-icon-thumb-up; }
.#{$ti-prefix}-ticket:before { content: $ti-icon-ticket; }
.#{$ti-prefix}-tilt-shift:before { content: $ti-icon-tilt-shift; }
.#{$ti-prefix}-tir:before { content: $ti-icon-tir; }
.#{$ti-prefix}-toggle-left:before { content: $ti-icon-toggle-left; }
.#{$ti-prefix}-toggle-right:before { content: $ti-icon-toggle-right; }
.#{$ti-prefix}-tool:before { content: $ti-icon-tool; }
.#{$ti-prefix}-tools:before { content: $ti-icon-tools; }
.#{$ti-prefix}-tools-kitchen:before { content: $ti-icon-tools-kitchen; }
.#{$ti-prefix}-tools-kitchen-2:before { content: $ti-icon-tools-kitchen-2; }
.#{$ti-prefix}-tornado:before { content: $ti-icon-tornado; }
.#{$ti-prefix}-tournament:before { content: $ti-icon-tournament; }
.#{$ti-prefix}-track:before { content: $ti-icon-track; }
.#{$ti-prefix}-tractor:before { content: $ti-icon-tractor; }
.#{$ti-prefix}-trademark:before { content: $ti-icon-trademark; }
.#{$ti-prefix}-traffic-cone:before { content: $ti-icon-traffic-cone; }
.#{$ti-prefix}-traffic-lights:before { content: $ti-icon-traffic-lights; }
.#{$ti-prefix}-train:before { content: $ti-icon-train; }
.#{$ti-prefix}-transfer-in:before { content: $ti-icon-transfer-in; }
.#{$ti-prefix}-transfer-out:before { content: $ti-icon-transfer-out; }
.#{$ti-prefix}-trash:before { content: $ti-icon-trash; }
.#{$ti-prefix}-trash-off:before { content: $ti-icon-trash-off; }
.#{$ti-prefix}-trash-x:before { content: $ti-icon-trash-x; }
.#{$ti-prefix}-tree:before { content: $ti-icon-tree; }
.#{$ti-prefix}-trees:before { content: $ti-icon-trees; }
.#{$ti-prefix}-trending-down:before { content: $ti-icon-trending-down; }
.#{$ti-prefix}-trending-down-2:before { content: $ti-icon-trending-down-2; }
.#{$ti-prefix}-trending-down-3:before { content: $ti-icon-trending-down-3; }
.#{$ti-prefix}-trending-up:before { content: $ti-icon-trending-up; }
.#{$ti-prefix}-trending-up-2:before { content: $ti-icon-trending-up-2; }
.#{$ti-prefix}-trending-up-3:before { content: $ti-icon-trending-up-3; }
.#{$ti-prefix}-triangle:before { content: $ti-icon-triangle; }
.#{$ti-prefix}-triangle-off:before { content: $ti-icon-triangle-off; }
.#{$ti-prefix}-triangle-square-circle:before { content: $ti-icon-triangle-square-circle; }
.#{$ti-prefix}-trident:before { content: $ti-icon-trident; }
.#{$ti-prefix}-trophy:before { content: $ti-icon-trophy; }
.#{$ti-prefix}-truck:before { content: $ti-icon-truck; }
.#{$ti-prefix}-truck-delivery:before { content: $ti-icon-truck-delivery; }
.#{$ti-prefix}-truck-off:before { content: $ti-icon-truck-off; }
.#{$ti-prefix}-truck-return:before { content: $ti-icon-truck-return; }
.#{$ti-prefix}-typography:before { content: $ti-icon-typography; }
.#{$ti-prefix}-umbrella:before { content: $ti-icon-umbrella; }
.#{$ti-prefix}-underline:before { content: $ti-icon-underline; }
.#{$ti-prefix}-unlink:before { content: $ti-icon-unlink; }
.#{$ti-prefix}-upload:before { content: $ti-icon-upload; }
.#{$ti-prefix}-urgent:before { content: $ti-icon-urgent; }
.#{$ti-prefix}-user:before { content: $ti-icon-user; }
.#{$ti-prefix}-user-check:before { content: $ti-icon-user-check; }
.#{$ti-prefix}-user-circle:before { content: $ti-icon-user-circle; }
.#{$ti-prefix}-user-exclamation:before { content: $ti-icon-user-exclamation; }
.#{$ti-prefix}-user-minus:before { content: $ti-icon-user-minus; }
.#{$ti-prefix}-user-off:before { content: $ti-icon-user-off; }
.#{$ti-prefix}-user-plus:before { content: $ti-icon-user-plus; }
.#{$ti-prefix}-user-search:before { content: $ti-icon-user-search; }
.#{$ti-prefix}-user-x:before { content: $ti-icon-user-x; }
.#{$ti-prefix}-users:before { content: $ti-icon-users; }
.#{$ti-prefix}-vaccine:before { content: $ti-icon-vaccine; }
.#{$ti-prefix}-vaccine-bottle:before { content: $ti-icon-vaccine-bottle; }
.#{$ti-prefix}-variable:before { content: $ti-icon-variable; }
.#{$ti-prefix}-vector:before { content: $ti-icon-vector; }
.#{$ti-prefix}-vector-beizer-2:before { content: $ti-icon-vector-beizer-2; }
.#{$ti-prefix}-vector-bezier:before { content: $ti-icon-vector-bezier; }
.#{$ti-prefix}-vector-triangle:before { content: $ti-icon-vector-triangle; }
.#{$ti-prefix}-venus:before { content: $ti-icon-venus; }
.#{$ti-prefix}-versions:before { content: $ti-icon-versions; }
.#{$ti-prefix}-video:before { content: $ti-icon-video; }
.#{$ti-prefix}-video-minus:before { content: $ti-icon-video-minus; }
.#{$ti-prefix}-video-off:before { content: $ti-icon-video-off; }
.#{$ti-prefix}-video-plus:before { content: $ti-icon-video-plus; }
.#{$ti-prefix}-view-360:before { content: $ti-icon-view-360; }
.#{$ti-prefix}-viewfinder:before { content: $ti-icon-viewfinder; }
.#{$ti-prefix}-viewport-narrow:before { content: $ti-icon-viewport-narrow; }
.#{$ti-prefix}-viewport-wide:before { content: $ti-icon-viewport-wide; }
.#{$ti-prefix}-virus:before { content: $ti-icon-virus; }
.#{$ti-prefix}-virus-off:before { content: $ti-icon-virus-off; }
.#{$ti-prefix}-virus-search:before { content: $ti-icon-virus-search; }
.#{$ti-prefix}-vocabulary:before { content: $ti-icon-vocabulary; }
.#{$ti-prefix}-volume:before { content: $ti-icon-volume; }
.#{$ti-prefix}-volume-2:before { content: $ti-icon-volume-2; }
.#{$ti-prefix}-volume-3:before { content: $ti-icon-volume-3; }
.#{$ti-prefix}-walk:before { content: $ti-icon-walk; }
.#{$ti-prefix}-wall:before { content: $ti-icon-wall; }
.#{$ti-prefix}-wallet:before { content: $ti-icon-wallet; }
.#{$ti-prefix}-wallpaper:before { content: $ti-icon-wallpaper; }
.#{$ti-prefix}-wand:before { content: $ti-icon-wand; }
.#{$ti-prefix}-wave-saw-tool:before { content: $ti-icon-wave-saw-tool; }
.#{$ti-prefix}-wave-sine:before { content: $ti-icon-wave-sine; }
.#{$ti-prefix}-wave-square:before { content: $ti-icon-wave-square; }
.#{$ti-prefix}-wifi:before { content: $ti-icon-wifi; }
.#{$ti-prefix}-wifi-0:before { content: $ti-icon-wifi-0; }
.#{$ti-prefix}-wifi-1:before { content: $ti-icon-wifi-1; }
.#{$ti-prefix}-wifi-2:before { content: $ti-icon-wifi-2; }
.#{$ti-prefix}-wifi-off:before { content: $ti-icon-wifi-off; }
.#{$ti-prefix}-wind:before { content: $ti-icon-wind; }
.#{$ti-prefix}-windmill:before { content: $ti-icon-windmill; }
.#{$ti-prefix}-window:before { content: $ti-icon-window; }
.#{$ti-prefix}-wiper:before { content: $ti-icon-wiper; }
.#{$ti-prefix}-wiper-wash:before { content: $ti-icon-wiper-wash; }
.#{$ti-prefix}-woman:before { content: $ti-icon-woman; }
.#{$ti-prefix}-world:before { content: $ti-icon-world; }
.#{$ti-prefix}-world-download:before { content: $ti-icon-world-download; }
.#{$ti-prefix}-world-latitude:before { content: $ti-icon-world-latitude; }
.#{$ti-prefix}-world-longitude:before { content: $ti-icon-world-longitude; }
.#{$ti-prefix}-world-upload:before { content: $ti-icon-world-upload; }
.#{$ti-prefix}-wrecking-ball:before { content: $ti-icon-wrecking-ball; }
.#{$ti-prefix}-writing:before { content: $ti-icon-writing; }
.#{$ti-prefix}-writing-sign:before { content: $ti-icon-writing-sign; }
.#{$ti-prefix}-x:before { content: $ti-icon-x; }
.#{$ti-prefix}-yin-yang:before { content: $ti-icon-yin-yang; }
.#{$ti-prefix}-zodiac-aquarius:before { content: $ti-icon-zodiac-aquarius; }
.#{$ti-prefix}-zodiac-aries:before { content: $ti-icon-zodiac-aries; }
.#{$ti-prefix}-zodiac-cancer:before { content: $ti-icon-zodiac-cancer; }
.#{$ti-prefix}-zodiac-capricorn:before { content: $ti-icon-zodiac-capricorn; }
.#{$ti-prefix}-zodiac-gemini:before { content: $ti-icon-zodiac-gemini; }
.#{$ti-prefix}-zodiac-leo:before { content: $ti-icon-zodiac-leo; }
.#{$ti-prefix}-zodiac-libra:before { content: $ti-icon-zodiac-libra; }
.#{$ti-prefix}-zodiac-pisces:before { content: $ti-icon-zodiac-pisces; }
.#{$ti-prefix}-zodiac-sagittarius:before { content: $ti-icon-zodiac-sagittarius; }
.#{$ti-prefix}-zodiac-scorpio:before { content: $ti-icon-zodiac-scorpio; }
.#{$ti-prefix}-zodiac-taurus:before { content: $ti-icon-zodiac-taurus; }
.#{$ti-prefix}-zodiac-virgo:before { content: $ti-icon-zodiac-virgo; }
.#{$ti-prefix}-zoom-cancel:before { content: $ti-icon-zoom-cancel; }
.#{$ti-prefix}-zoom-check:before { content: $ti-icon-zoom-check; }
.#{$ti-prefix}-zoom-in:before { content: $ti-icon-zoom-in; }
.#{$ti-prefix}-zoom-money:before { content: $ti-icon-zoom-money; }
.#{$ti-prefix}-zoom-out:before { content: $ti-icon-zoom-out; }
.#{$ti-prefix}-zoom-question:before { content: $ti-icon-zoom-question; }
