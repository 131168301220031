.activity-logs-card{
    border-radius: 20px;
    .card-header{
        border-bottom: 0;
        background-color: transparent !important;
        margin-top: 1rem;
    }
    .activity-logs-table {

        .material-table__wrap{
            background-color: #242630;
            color: black;
        
            .MuiTableRow-root{
              td {
                color: #e4e6eb !important;
      
              }
              td:first-child{
                border-radius:20px 0 0 20px;
              }
              
            td:last-child{
                border-radius:0 20px 20px 0;
      
              }
              .MuiTableRow-head{
        
                background-color: red !important;
                border: 0 solid;
                border-color: inherit;
                border-bottom: 0;
               
                .MuiTableRow-head:first-child{
                    border-radius:20px 0 0 0;
                  }
              
        
                .MuiTableRow-head:last-child{
                    border-radius:0 20px 0 0;
                  }
              }
            } 
          }
          .MuiTableHead-root{
            tr{
              th{
                background-color: #2E2F39 !important;
                border-bottom: 0 !important;
              }
              th:first-child{
                border-radius:20px 0 0 20px;
              }
              
            th:last-child{
                border-radius:0 20px 20px 0;
              }
            }
          }
    
        .activity-info {
            width: 10px !important;
            height: 40px;
           border-radius: 10px !important;
           display: flex;
           align-items: center;
           justify-content: center;
           color: #46bcaa;
           font-size: 22px;
           border: 0;
           background-color: transparent;
           margin: 0 !important;
       
           &:hover {
             background-color: #E4EBF5;
           }
           
         }
      }
}