.daterangepicker{
    border-radius: 20px !important;
    border: none !important;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    .ranges {
        ul{
            text-align: center;
            li.active{
                background-color: #000000 !important;
                border-radius: 8px !important;
            }
            li{
                &:hover{
                    border-radius: 8px !important;
                }
            }
        }
    }
    .daterangepicker.opensright {
        &::after,&::before,&:active{
            display: none !important;

        }
        display: none !important;
    }
    .calendar-table {
        table{
            width: 85% !important;
        }
        td.in-range{
            background-color:#f2f2f2 ;
        }
        td{
            // width: 100px !important;
           
        }
        td.active{
            background-color: #000000;
        }
        .off.ends.active{
            background-color: #fff !important;
            color: #fff !important;
            cursor: default;
        }
    }
    .applyBtn{
        background-color: #000000 !important;
        border-color: #000000 !important;
        color: #ffff !important;
    }
    .cancelBtn{
        background-color: transparent;
        border-color: #000000;
        color: #000000 !important;

    }
    .monthselect{
        border: none !important;
    }
    .yearselect {
        border: none !important;
    }
}
.react-datepicker-popper{
    z-index: 1000;
}
.react-datepicker__input-container{
    input{
        border-radius: 20px !important;
        border: none !important;
        // box-shadow: 0px 5px 10px #e4e4e4;
        background-color: #e4e4e4;
        // padding: .6rem 2rem .6rem 1rem;
        padding: .6rem 1rem;
    }

}
.react-datepicker{
    border-radius: 20px;
    border: none;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    padding: .7rem 1rem 0rem 1rem;
    .react-datepicker__header {
        background: transparent;
        border-bottom: 2px solid #f3f3f3;
        .react-datepicker__day-name{
            font-size: 12px;
        }
    }
    .react-datepicker__day--selected{
        background-color: #000000 !important;
        color: #fff;
    }
    .react-datepicker-time__input{
        border-radius: 8px;
        padding: .3rem .5rem;
        // border: 2px solid rgba(0, 0, 0, 0.2);
        input{
        border: 2px solid rgba(0, 0, 0, 0.2);
        }
    }
    .react-datepicker__day{
        font-size: 13px;
    }
    .react-datepicker__input-time-container{
        display: flex;
        align-items: center;
        justify-content: center;
    }
  }
  .daterangepicker {
    .monthselect, .yearselect{
        width: auto;
        background-color: #f2f2f2;
        color: #000;
        border: 1px solid #e5e4e4 !important;
        border-radius: 5px;
     }
 }