.activity-logs-card{
  border-radius: 20px;
  .card-header{
      border-bottom: 0;
      background-color: transparent !important;
      margin-top: 1rem;
  }
  .activity-logs-table {

    .material-table__wrap {
      background-color: white;
  
      .MuiTableHead-root {
        .MuiTableCell-head {
          font-size: 15px !important;
        }
  
        tr {
          th {
            background-color: #f2f5f9 !important;
            border: none !important;
          }
  
          th:first-child {
            border-radius: 20px 0 0 20px;
          }
  
          th:last-child {
            border-radius: 0 20px 20px 0;
            padding-right: 0px !important;
            margin-right: 0px !important;
          }
        }
  
      }
      .MuiTableBody-root{
        tr{
          &:hover{
            background-color: #e8edf4 !important;
          } 
        }
  
   }

      .MuiTableRow-root {
        //background-color: "#f5f5f5";
        
        td:first-child{
          border-radius:20px 0 0 20px;
        }
        
      td:last-child{
          border-radius:0 20px 20px 0;

        }
    
    
        &:nth-child(even) {
          background-color: #f2f5f9;
        }
      }
  
      // .MuiTableRow-footer {
      //   padding-top: 1.2rem !important;
      // }
    }

    .activity-info {
        width: 10px !important;
        height: 40px;
       border-radius: 10px !important;
       display: flex;
       align-items: center;
       justify-content: center;
       color: #46bcaa;
       font-size: 22px;
       border: 0;
       background-color: transparent;
       margin: 0 !important;
   
       &:hover {
         background-color: #E4EBF5;
       }
       
     }
  }
}
.activity-filter-container {
  .mt-4 {
    margin-top: 10px !important;

  }
  .filter-value-middle {
 display: flex;
 justify-content: start;
  }
  .form-control {
    border-radius: 50px !important;
    background-clip: padding-box !important;
    background-color: #f8f9fa !important;
    border: 1px solid #f8f9fa !important;
    border-radius: 8px !important;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0,  0.2) !important;
  }
  .form-control.right {
    border-radius: 8px !important;

  }
  .form__form-group {
    width: 100% !important;
  }
}
