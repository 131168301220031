//
// helper.scss
//
.section {
    padding: 70px 0 0px 0;
    position: relative;
    @media (max-width: 768px) {
      padding: 60px 0;
    }
  }
  
  .section-tab {
    padding: 5px 0 0 0 !important;
    position: relative;
    @media (max-width: 768px) {
      padding: 30px 0 60px 0;
    }
  }
  