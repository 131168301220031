.note-change-time-dropdown {
  height: 30px;
  width: 175px;
  position: absolute;
  inset: 0px 0px auto auto;
  margin: 0px;
  transform: translate(0px, 38px);
  border-radius: 20px !important;
  left: -40px !important;

  @media (max-width: 600px) {
    height: 420px;
    width: 350px;
  }
}

.activity-filter-container {

  .form-control,
  .form__form-group-input-wrap>textarea {
    background-clip: padding-box !important;
    background-color: #f8f9fa !important;
    border: 1px solid #f8f9fa !important;
    border-radius: 10px !important;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
    color: #fff !important;
  }

  .form__form-group-input-wrap>textarea {
    padding: 16px !important;
    color: #fff !important;
  }

  textarea::placeholder {
    font-size: 14px;
    font-weight: 400;
    font-size: 14px;
    font-weight: 500px;
    color: #a1a5b4;
  }

  .react-select__control {
    max-height: 48px !important;
    min-height: 48px !important;
  }

  .tags {
    min-height: 120px;
    max-height: 120px;
    overflow-y: scroll;
    background-clip: padding-box !important;
    background-color: #f8f9fa !important;
    border: 1px solid #f8f9fa !important;
    border-radius: 10px !important;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
    color: #000 !important;
    padding: 10px 0px 10px 5px;
  }

  .outline-input {
    background-color: #000 !important;
    border: 1px solid #000 !important;
    padding: 5px 10px !important;
    width: 100% !important;
  }

  .list-item {
    position: relative;
    padding-left: 1rem;

  }

  .edit-icon {
    position: absolute;
    right: 10px;
    visibility: hidden;
  }

  .list-item:hover .edit-icon {
    visibility: visible;
  }

  .selected-tag {
    background-color: #a1aebc !important;
    border-radius: 5px;
  }
}

.swiper-all {
  width: 100% !important;
  display: flex;
  align-items: center;

  .swiper {
    display: flex;
    overflow-x: hidden;
    white-space: nowrap;
    // width: 70% !important;
    max-width: 100%;
    position: relative;

    // min-width:min-content;
    .nav-link {
      flex: 0 0 auto;
    }
  }
}

@media screen and (max-width: "1390px") {
  .swiper-tabs {
    // .tab-all{
    //   width: 100%;
    // }
  }
}

.multi-input {
  border: 1px solid #d8dae0;
  border-radius: 8px !important;

  .form-control {
    background-color: transparent !important;
    height: 100px;
    border-radius: 8px !important;
    border: none !important;
    // border: 1px solid #9ea2b1;
    // border-bottom: 1px solid #4a4a4c !important;
    color: #000;
  }
}

.file-text-input {
  position: relative !important;
}

.show-file-input {
  height: auto !important;
  border: none !important;
  // position: absolute;
  // bottom: 0;
}

.multi-ptions-input {
  border-top: 1px solid #e7e8eb;
  padding: 10px 0px;

  .react-emoji {
    width: 0 !important;
    height: 0 !important;
    margin-top: 0 !important;
  }

  .react-input-emoji--container {
    // display: none !important;
    margin: 0 !important;
  }

  .react-emoji-picker--wrapper {
    left: 0 !important;
    right: auto !important;
    margin-bottom: 15px;
  }

  .react-input-emoji--button {
    svg {
      width: 18px !important;
      height: 18px !important;
    }
  }

  .paperclip-icon {
    transform: rotate(45deg);
    font-size: 18px;
    color: #858585;

    &:hover {
      color: #000;
    }
  }
}

.note-card {
  .card-body {
    padding: 30px;
  }

  .note-list {
    .add-reaction {
      display: none;
    }

    .show-reaction {
      display: inline;
      cursor: pointer;
      width: 22px;
      filter: invert(52%) sepia(12%) saturate(13%) hue-rotate(317deg) brightness(97%) contrast(102%);
    }

    &:hover {
      .add-reaction {
        display: inline;
        cursor: pointer;
        width: 22px;
        filter: invert(52%) sepia(12%) saturate(13%) hue-rotate(317deg) brightness(97%) contrast(102%);

        &:hover {
          filter: invert(11%) sepia(9%) saturate(988%) hue-rotate(169deg) brightness(90%) contrast(86%);
        }
      }
    }

    .reaction {
      em-emoji-picker {
        position: absolute;
        height: 23.5rem;
        z-index: 999;
        box-shadow: 0 0 5px 5px #f2f2f2;
      }
    }

  }


  .reacted_emoji {
    border-radius: 50px;
    padding: 1px 8px;
    background-color: #e5e4e4;
    font-size: 16px;
  }
}

.input-group-emoji {
  em-emoji-picker {
    position: absolute;
    // top: 50px;
    bottom: 4rem;
    height: 23.5rem;
    z-index: 999;
    box-shadow: 0 0 5px 5px #f2f2f2;
  }

  .add-reaction {
    display: inline;
    cursor: pointer;
    width: 22px;
    filter: invert(52%) sepia(12%) saturate(13%) hue-rotate(317deg) brightness(97%) contrast(102%);

    &:hover {
      filter: invert(0%) sepia(1%) saturate(7494%) hue-rotate(359deg) brightness(92%) contrast(96%);
    }
  }

  .show-reaction {
    display: inline;
    cursor: pointer;
    width: 22px;
    filter: invert(52%) sepia(12%) saturate(13%) hue-rotate(317deg) brightness(97%) contrast(102%);
  }
}

.files {
  background-color: #e5e4e4;
  border-radius: 8px;
  padding: 5px 15px;
  position: relative;

  .folder-icon {
    color: #6c757d;
    font-size: 16px;

  }

  .close-file {
    position: absolute;
    right: -5px;
    color: #cdcdcd;
    top: -6px;
    font-size: 12px;
    background-color: #565656;
    padding: 2px 3px;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: #000;
      color: #f35321;
    }
  }
}
.modal-search-input{
  background-color: #dddddd;
  border-radius: 8px;
}
.border-btn{
  border: 1px solid #6c757d;
}