.radialbar-chart {
    border: none !important;
    .apexcharts-canvas {
      .apexcharts-datalabels-group {
        
        text:first-child {
          font-size: 22px !important;
        }
        text:last-child {
          font-size: 16px !important;
        }
      }
      .apexcharts-legend {
        left: 0 !important;
        overflow-y: scroll !important;
        
        .apexcharts-legend-series {
          margin-top: 0 !important;
          display: flex;
          align-items: center !important;
          .apexcharts-legend-text {
            font-size: 14px !important;
            font-weight: 500 !important;
            color: #11142d !important;
            font-family:  "Inter", sans-serif !important;
            //padding-right: 10px !important;
            left: 12px;
            padding-right: 20px !important;
            margin-top: 1rem !important;
          }
          .apexcharts-legend-marker {
            width: 15px !important;
            height: 15px !important;
            border-radius: 20px !important;
            margin-top: 1rem !important;
           }
        }
      }
    }


  }
  
  
   @media only screen and (max-width: 1500px) {
     .radialbar-chart {
       .apexcharts-canvas {
        display: flex !important;
        justify-content: center !important;
         .apexcharts-legend {
          display: flex ;
          flex-direction: row !important;
          margin-left: 20px !important;
           overflow-y: hidden !important;
           .apexcharts-legend-series {
             .apexcharts-legend-text {
               font-size: 14px !important;
               font-weight: 500 !important;
               color: #11142d !important;
               padding-right: 20px !important;
             }
            
           }
         }
       }
     }
   }
   .apexcharts-xaxis-label{
    color: white !important;
    font-size: 14px !important;
     font-weight: 500 !important;
   }
.apexcharts-yaxis-label{
    font-size: 14px !important;
    font-weight: 500 !important;
}

.apexcharts-datalabels{
  font-size: 14px !important;
}
.apexcharts-yaxis  {
 // filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
}
.apexcharts-yaxis-title-text{
  display: none !important;
}
.line-chart{
  .apexcharts-legend {
    left: 0 !important;
    overflow-y: scroll !important;
    
    .apexcharts-legend-series {
      margin-top: 0 !important;
      display: flex;
      align-items: center !important;
      .apexcharts-legend-text {
        font-size: 14px !important;
        font-weight: 500 !important;
        color: #11142d !important;
        font-family:  "Inter", sans-serif !important;
        //padding-right: 10px !important;
        left: 12px;
        padding-right: 20px !important;
        margin-top: 1rem !important;
      }
      .apexcharts-legend-marker {
        width: 15px !important;
        height: 15px !important;
        border-radius: 20px !important;
        margin-top: 1rem !important;
       }
    }
  }
}