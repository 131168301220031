.product-avatar {
    img {
      border-radius: 20px !important;

    }

    .btn {
      backdrop-filter: blur(6px) !important;
      background-color: rgba(0, 0, 0, 0.7);
      //padding: 6px 32.4px;
      //padding-inline: 34px;
      border: none !important;
      border-radius: 20px 0px 0px 20px;
      font-size: 9px !important;
      padding: 0px !important;
    }

    .btn-second {
      border-radius: 0px 20px 20px 0px;

    }

    .analytics-image-button {
      opacity: 0;
      position: relative;
      bottom: 49px;
      width: 120px;
      padding: 0 !important;
      margin: 0 !important;
    }

    &:hover {
      .analytics-image-button {
        opacity: 1;
      }
    }
  }
    .product-store-badge{
    background-color: #2b335d;
    color:#fff; 
    padding: 7px 10px;
  }
  .product-detail-wrapper {
    .product-detail-card {
        border-radius: 20px;
        border: none;
        background-color: #242731;
        padding-bottom: 1rem;

        .card-header {
            border: none !important;
            border-radius: 20px;
            // padding-bottom: 0;
            // margin-bottom: 0;
        }
       .image-carousel {
      
      
       }
        .product-detail-image-cardbody{
            padding: 1rem !important;
            background-size: cover;
            background-position: center top;
            background-repeat: no-repeat;
            img {
                border-radius: 10px;
            }
            .send_comment_btn{
              background-color: #08070f;
              border-color: #08070f;
            }
            .product-img-tags{
              width: 50px;
              height: 50px;
              border-radius: 5px;
              
          }
        }
        .product-detail-badge{
          color: #e4e6eb;
          background-color: #17191e;
        }
    }

    .image-cross-left, .image-cross-right {
        background-color:rgba(173, 181, 189, 0.2);
        font-size: 14px !important;
        height: 25px;
        min-height: 25px;
        max-height: 25px;
        width: 25px;
        min-width: 25px;
        max-width: 25px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0.5rem;
        &:hover {
            background-color:rgba(173, 181, 189, 0.5);
            color: #ffff;
        }
    }
      .product-image {
            border-radius: 20px;
            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */
        }
            .product-description-wrapper {
                .card {
                    min-height: 20rem !important;
            max-height: 20rem !important;
            border-radius: 20px !important;
          
                .card-body{
                  overflow-y: scroll;
                    padding-top: 0 !important;
                    margin-bottom: 2rem !important;
                    @media (min-width: 576px){
                        height: 10rem;
                        overflow: auto;
                    }
                    @media (min-width: 1200px) {
                        padding: 1.5rem;
            
                    }
                }
                }
               
               
            }
            .hearth {
                background-color: #FFE6E7;
                border-radius: 10px;
                margin-right: 5px;
                i {
                    color: #DC3445;
                    font-size: 25px;
                    font-weight: 600;
                }
            }
            .shared {
                background-color: #F0EFFB;
                border-radius: 10px;
                margin-right: 5px;
                i {
                    color: #6A40BB;
                    font-size: 25px;
                    font-weight: 600;
                }
            }
            .sale {
                background-color: #EDF8F7;
                border-radius: 10px;
                margin-right: 5px;
                i {
                    color: #45BBA8;
                    font-size: 25px;
                    font-weight: 600;
                }
            }
            .view {
                background-color: #EDF8F7;
                border-radius: 10px;
                margin-right: 5px;
                i {
                    color: #45BBA8;
                    font-size: 25px;
                    font-weight: 600;
                }
            }
          
        
}
.chart-mini-responsive{
  display: flex;
}
@media only screen and (max-width: 1920px) {
  .post-info-section {
    width: 78% ;
  }
}
@media only screen and (max-width: 1650px) {
  .post-info-section {
    width: 72% ;
  }
}
@media only screen and (max-width: 1440px) {
  .post-info-section {
    width: 69%;
  }
}
@media only screen and (max-width: 1330px) {
  .chart-mini-responsive {
    display: none !important;
  }
}

@media only screen and (max-width: 1300px) {
  .post-info-section {
    width: 58% ;
  }
}
.user-static-card{
  background-color: #242731 !important;
  border: none !important;
  .share-badge {
    background-color: #294849;
    color: white;
    width: 166px;
    height: 36px;
    margin-right: .5rem;

    i {
      color: #42b2a1;
    }
  }

  .like-badge {
    background-color: #2b335d;
    color: white !important;
    width: 166px;
    height: 36px;
    margin-right: .5rem;

    i {
      color: #4c68f6;
    }
  }

  .comment-badge {
    background-color: #564c33;
    color: white !important;
    width: 166px;
    height: 36px;
    margin-right: .5rem;

    i {
      color: #fdce50;
    }
  }

  .save-badge {
    background-color: #2b335d;
    color: white !important;
    width: 166px;
    height: 36px;
    margin-right: .5rem;

    i {
      color: #4c68f7;
    }
  }

  .view-badge {
    background-color: #542e26 !important;
    color: white;
    width: 166px;
    height: 36px;
    margin-right: .5rem;

    i {
      color: #f35321;
    }
  }

  .impression-badge {
    background-color: #323053 !important;
    color: white !important;
    width: 166px;
    height: 36px;
    margin-right: .5rem;

    i {
      color: #6c5dd3;
    }
  }

  .reach-badge {
    background-color: #57414d !important;
    color: white;
    width: 166px;
    height: 36px;
    margin-right: .5rem;
  }

  }
  .line{
    border-left: 1.5px solid #828c94;
    height: 25px;
  }
