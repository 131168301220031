.support {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    margin-left: auto;
    margin-right: auto;
    padding-left: calc(var(--bs-gutter-x)*.5);
    padding-right: calc(var(--bs-gutter-x)*.5);
    width: 100%;
   
      overflow: auto;

    .card {
        height: calc(100% - 1.5rem);
        border-radius: 50px !important;
        border: none !important;
        max-height: 65rem !important;
        padding-bottom: 5rem;
        background-color: #FDFDFD;
        @media (min-width: 1200px) {
            margin-bottom: 1.5rem;

        }
        .card-header {
            border-radius: 2rem 2rem 0 0;
            background-color: #FDFDFD !important;
        }
        .card-body-scrollable{
            @media (min-width: 576px){
                height: 10rem;
                overflow: auto;
            }
            @media (min-width: 1200px) {
                padding: 1.5rem;
    
            }
                
        }
        .card-body{
            color: var(--bs-card-color);
            flex: 1 1 auto;
            max-height: 40rem !important;
            overflow-y: scroll;

            .table {
                padding: 1rem;
               
                --bs-table-accent-bg: transparent;
                    margin-bottom: 1rem;
                    vertical-align: top;
                    width: 100%;
                    border-collapse: collapse;
                    caption-side: bottom;
                   
                      border-collapse: separate; 
                      border-spacing: 0 10px; 
                      margin-top: -10px;
                      td {
                        border-style: solid none;
                        
                        
                    }
                    td:first-child {
                        border-left-style: solid;
                        border-top-left-radius: 20px; 
                        border-bottom-left-radius: 20px;
                        
                    }
                    td:last-child {
                        border-left-style: solid;
                        border-top-right-radius: 20px; 
                        border-bottom-right-radius: 20px;
                        
                    }
                  
            }
        }
        
    }
}