.material-table-toolbar {
  // height: 70px;
  height: auto;
  // margin-top: 10px;

  .toolbar-pagination {
    span {
      font-size: 18px;
      font-weight: 300;
      color: #9ea2b1;
    }
  }

  .toolbar-search {
    @media (max-width: 600px) {
      width: 100% !important;
    }

    .form-search {

      width: 300px !important;
      background: rgb(23, 25, 30);
      backdrop-filter: blur(6px);
      padding: 18px 20px 18px 65px !important;
      border-radius: 10px;
      background-image: url("../../images/icon/search.svg");
      background-position: 24px 15px;
      background-repeat: no-repeat;

      @media (max-width: 600px) {
        width: 100% !important;
      }

      &::-webkit-input-placeholder {
        font-size: 16px;
        font-weight: 400;
        color: #cccc;
      }

      &::-moz-placeholder {
        font-size: 16px;
        font-weight: 400;
        color: #cccc;
      }

      &:-ms-input-placeholder {
        font-size: 16px;
        font-weight: 400;
        color: #cccc;
      }

      &:-moz-placeholder {
        font-size: 16px;
        font-weight: 400;
        color: #cccc;
      }

    }
  }


  .toolbar-filter {
    .form-select {
      font-size: 18px;
      padding: 10px 50px 10px 30px;
      border-radius: 30px !important;
      border: 1px solid $content;
      cursor: pointer;
      background-color: transparent;
      max-width: 340px;
      text-overflow: ellipsis;
    }

    .btn-list-type {
      width: 50px;
      height: 50px;
      background-color: transparent;
      border: 1px solid $content;
      border-radius: 12px;
      color: $content;
      -webkit-user-select: none;
      user-select: none;
      cursor: pointer;

      svg {
        path {
          stroke: $content !important;
        }
      }

      &.active {
        border: 2px solid $primary;
        color: $primary;
        fill: $primary;

        svg {
          path {
            stroke: $primary !important;
          }
        }
      }
    }
  }
}

.material-table__wrap {

  background: #000000;
  border-radius: 17px;
  padding: 40px 32px 40px 32px;

  @media (max-height: 768px) {
    padding: 30px 20px 30px 20px;
  }

  .MuiPaper-root {
    font-family: 'Poppins', sans-serif;
    background: transparent !important;
    box-shadow: none !important;
    color: $content-light !important;
  }

  .MuiTableHead-root {
    th {
      font-family: 'Poppins', sans-serif;
      font-weight: 700;
      font-size: 16px;
      color: white;
      border: none !important;

    }
  }

  .MuiTableRow-root {
    min-height: 72px;
    color: $content-light;
    vertical-align: middle;


    &:nth-child(even) {
      background-color: #2E2F39;
    }

    td {
      font-weight: 400;
      font-size: 16px;
      border: none;
    }
  }

  .MuiTableRow-footer {
    border: none !important;
    background-color: transparent !important;
    padding-top: 1rem;
    display: flex;
    justify-content: end;

    .MuiTablePagination-input {
      background-color: #000 !important;
      border-radius: 8px !important;
      color: white !important;
    }

    svg {
      fill: white !important;
    }

    .MuiToolbar-root {
      div {
        background-color: #17191e !important;
        border-radius: 8px;

        .Mui-disabled.MuiButton-contained {
          background-color: #fff !important;
          border-radius: 10;
          color: #000 !important;
          min-height: 28px !important;
          max-height: 28px !important;

          .MuiButton-label {
            color: #000 !important;
          }
        }

        .Mui-disabled {
          background-color: #4c4d52 !important;
        }

        .MuiIconButton-root {
          padding: 10px !important;
          height: 30px;
        }

        span:first-child {
          .MuiIconButton-root {
            border-radius: 8px 0 0 8px !important;
          }
        }

        span:last-child {
          .MuiIconButton-root {
            border-radius: 0 8px 8px 0 !important;
          }
        }

        span>button {
          border-radius: 0 !important;
        }

        .MuiButtonBase-root {
          border: 1px solid #2a2c33 !important;

          &:hover {
            background-color: #2b3035 !important;
          }
        }

        .MuiSelect-select {
          height: 17px;
          display: flex;
          align-items: center;
        }

        .MuiButton-label {
          font-size: 13px;
          color: #fff;
        }

        svg {
          width: 0.8rem;
          height: 0.8rem;
          fill: white !important;
        }
      }
    }

    .MuiTablePagination-selectRoot {
      background-clip: padding-box !important;
      background-color: #191a21 !important;
      border: 1px solid #191a21 !important;
      border-radius: 1rem !important;
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2) !important;
      color: white !important;

      position: relative;
      overflow: visible;

      .MuiSvgIcon-root {
        width: 1.5rem !important;
        height: 1.5rem !important;
        fill: white !important;
      }
    }


    // .pagination {
    //   .active-page {
    //     background-color: #6C5DD3 !important;
    //     color: $white !important;
    //   }

    //   .page-link {
    //     border: 1px solid $muted !important;
    //     background-color: #000 !important;
    //     border: none !important;

    //     &:hover {
    //       background-color: #6C5DD3 !important;
    //     }
    //   }
    // }
  }
}

.MuiList-root {
  background-color: #000;
  color: white;
}

.Component-horizontalScrollContainer-5 {
  border-radius: 20px !important;
}

.user-table-card {

  // background-color: #000000;
  // margin-top: 2rem;
  // border-radius: 20px;
  .MuiTableBody-root {
    --bs-gutter-x: 1.5rem !important;
    --bs-gutter-y: 0;
    display: flex !important;
    flex-wrap: wrap !important;
    padding-bottom: 10px;

    >* {
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-left: calc(var(--bs-gutter-x) * 0.5);
    }
  }
}


.notification-table {
  .material-table__wrap {
    background: transparent;
    padding: 0;

    .MuiTableRow-footer {
      padding-top: 0;
    }
  }
}

.trending-table {

  .material-table__wrap {
    background-color: #242630;
    color: black;

    .MuiTableBody-root {
      .MuiTableRow-root {
        &:hover {
          background-color: hsla(0, 0%, 100%, .075) !important;
        }
      }
    }

    .MuiTableRow-root {

      td {
        color: #e4e6eb !important;

      }

      td:first-child {
        border-radius: 20px 0 0 20px;
      }

      td:last-child {
        border-radius: 0 20px 20px 0;

      }

      .MuiTableRow-head {
        border: 0 solid;
        border-color: inherit;
        border-bottom: 0;

        .MuiTableRow-head:first-child {
          border-radius: 20px 0 0 20px;
        }


        .MuiTableRow-head:last-child {
          border-radius: 0 20px 20px 0;
        }
      }
    }
  }

  .MuiTableHead-root {
    tr {
      th {
        background-color: #2E2F39 !important;
      }

      th:first-child {
        border-radius: 20px 0 0 20px;
      }

      th:last-child {
        border-radius: 0 20px 20px 0;
      }
    }
  }

  .MuiTableRow-footer {
    padding-top: 1.2rem !important;
  }
}

.all_recent_activities {
  background-color: #23262f !important;

  .card-header {
    margin-top: 1rem;
  }

  .recent_activities_table {

    .material-table__wrap {
      background-color: white;

      .MuiTableHead-root {
        tr {

          th {
            background-color: #2e2f39 !important;
            border-bottom: 0 !important;
          }

          th:first-child {
            border-radius: 20px 0 0 20px;
            width: 100px;
          }

          th:last-child {
            border-radius: 0 20px 20px 0;
            width: 60%;
          }
        }

      }

      .MuiTableRow-root {
        &:nth-child(even) {
          background-color: transparent !important;
        }

        td {
          font-weight: 400;
          font-size: 16px;
          border: none;
          overflow: hidden;
          padding-top: 6px !important;
          color: #e9ecef !important;
          // :first{
          //   padding-top: 1rem !important;
          // }
        }
      }

    }
  }

  .timeline_table {
    padding-right: 20px;

    ul,
    li {
      list-style: none;
      padding: 0;
    }

    li {
      padding-bottom: 0rem;
      position: relative;

      &:last-child {
        border: 0px;
        padding-bottom: 0;
      }
    }

    .timeline-dotted {
      width: 20px;
      height: 20px;
      border-radius: 50px !important;
      position: relative;

      .timeline-dotted-line {
        background-color: #585C66 !important;
        margin-top: 21px !important;
        position: absolute !important;
        height: 32px !important;
        width: 3px !important;
        display: flex !important;
        justify-content: center !important;
        margin-left: 8px !important;
      }
    }
  }
}

.social-media-cards {
  // margin-right: 2rem !important;

  .material-table__wrap {
    padding-bottom: 0;
  }

  .MuiTableBody-root {
    --bs-gutter-x: 1.5rem !important;
    --bs-gutter-y: 0;
    display: flex !important;
    flex-wrap: wrap !important;
    padding-bottom: 10px;

    >* {
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-left: calc(var(--bs-gutter-x) * 0.5);
    }
  }
}

.hashtag_table {
  .material-table__wrap {
    background-color: #232630;

    .MuiTableHead-root {


      tr {
        border: none !important;


        th {
          // background-color: #D1CFEC !important;
          background-color: #7670c6 !important;
          color: white;
          border-bottom: 0 !important;
          border-top: 0 !important;
          border-width: 0 !important;
          min-width: 156px !important;
          max-width: 156px !important;
          padding-left: 30px !important;
          margin-right: 0px !important;
          padding-right: 0px !important;

          // .MuiTableSortLabel-icon{
          //   font-size: 20px !important;
          //   color: red !important;
          //   font-weight: 700 !important;
          //   width: 20px !important;
          // }
        }

        th:first-child {
          border-radius: 20px 0 0 20px;
          min-width: 350px !important;
        }

        th:last-child {
          border-radius: 0 20px 20px 0;
        }
      }
    }

    .MuiTableBody-root {
      font-weight: 500;
      font-size: 16px;

      .MuiTableRow-root {
        border-radius: 20px !important;

        .avatar.avatar-lg {
          border-radius: 10px;
        }
      }

      tr {
        // border: 1px solid gray !important;
        border-radius: 20px !important;
        color: black !important;

        td {
          // border: 2px solid gray !important;
          text-align: center !important;
          color: #fff !important;
        }

        td:first-child {
          border-radius: 20px 0 0 20px !important;
          margin-right: 0px !important;
          padding-right: 0px !important;
        }

        td:last-child {
          border-radius: 0 20px 20px 0;
          // border: 2px solid gray !important;
        }
      }

    }

  }
}

.MuiTableBody-root {
  .MuiTableRow-root {
    &:hover {
      background-color: hsla(0, 0%, 100%, .075) !important;
    }
  }
}

.MuiTableRow-footer {
  select {
    background-color: #17191e;
    border: 1px solid #2a2c33;
    color: #fff;
  }
  .MuiSelect-root{
    background-color: #17191e;
    border: 1px solid #2a2c33;
    color: #fff;
  }
  .MuiSelect-icon{
    color: #fff !important;
  }
  .pagination-btn {
    background-color: #17191e;
    border: 1px solid #2a2c33;
    &:hover {
      background-color: #2b3035 !important;
      border: 1px solid #2a2c33;
    }
  }
  .active{
    background-color: #fff !important;
    color: #000 !important;
    opacity: inherit;
    &:hover {
      background-color: #fff !important;
    }
  }
  .pagination-icon {
    background-color: #17191e;
    border: 1px solid #2a2c33;
    &:hover {
      background-color: #2b3035 ;
      border: 1px solid #2a2c33;
    }
  }

  :disabled {
    background-color: #4c4d52;
    color: #b2b7bf;
  }

}
.MuiInput-underline::before, .MuiInput-underline::after{
  border-bottom: none !important;
}
.MuiTableSortLabel-root{
  color: #fff !important;
  &:hover{
    color: #cccccc !important;
  }
}
.MuiTableSortLabel-active{
  color: #fff !important;
  .MuiTableSortLabel-icon{
  color: #fff !important;

  }
}