.fc-col-header-cell {

    height: 55px;

    .fc-scrollgrid-sync-inner {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

    }
}

.fc-direction-ltr {
    width: -webkit-fill-available;
}

.calendar-accordion-title {
    font-size: 16px !important;
    font-weight: 700;
    padding-left: 0 !important;
    i{
        font-size: 16px !important;
    }
}

.calendar-accordion {
    background-color: transparent !important;
    cursor: pointer;
    padding: 0;
    margin-bottom: 0;
    margin-top: 0;

    .accordion-arrow {
        padding-top: .5rem;
        padding-bottom: .5rem;
    }
}

.calendar-img {
    width: 72px;
    height: 72px;
}

.calendar-img-box {
    width: 50px;
    height: 50px;
    border: 2px solid #9554f1;
    border-radius: 100px;
    position: relative;

    img {
        width: 45px;
        border-radius: 50%;
        padding: 2px;
    }

    span {
        display: flex;
        color: #9554f1;
        border: 2px solid #fff;
        width: 20px;
        height: 20px;
        background: #ffffff;
        align-items: center;
        justify-content: center;
        position: absolute;
        border-radius: 100px;
        box-shadow: 0px 0px 5px 2px #0000005e;
        bottom: 0;
        right: -7px;
    }
}

.calendar-menu-item {
    color: #9554f1;
}

.calendar-menu-item.disable {
    .calendar-img-box {
        background: #f0f0f0 !important;
        border: 2px solid #f0f0f0 !important;
    }

    span {
        color: #cbcbcb !important;
    }
}
.calendar-close-btn{
    position: absolute;
    right: 40px;
    top: 35px;
    padding: 5px 10px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none ;
    width: 30px;
    height: 30px;
    i{
        font-size: 18px;
    }
}
.fc-today-button{
    border-radius: 8px !important;
}
.caption-btn{
    position: absolute;
    right: 0;
    bottom: 2.3rem;
    margin-right: 0.2rem;
}
.fc-event{
    background: transparent;
    border: none;
    width: 40px;
    height: 40px;
    .fc-event-main{
        img{
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 2px;
        }
    }
}
.fc-timegrid-slot-label-cushion{
    font-weight: 500;
    position: absolute;
    margin-top: 0.4rem;
    left: 0;
}
.fc-scrollgrid-section-body>td{
    border: 0 !important;
}
.fc-timegrid-slot{
    height: 2rem;
    // border-top: 0 ;
}
.fc-timegrid-slot-label{
    // border-top: 3px solid #dddddd ;
    border: 0;
}
.fc .fc-button-group > .fc-button{
    text-transform: capitalize;
}
.fc .fc-button-primary:focus {
    box-shadow:none !important;
}
.fc-col-header-cell-cushion, .fc-daygrid-day-number{
    color: #7f7f7f;
}
.fc-scroller{
    max-height: 38rem;
}