.product-detail-wrapper {
    .product-detail-card {
        border-radius: 20px;
        border: none;
        padding-bottom: 1rem;
        .card-header {
            border: none !important;
            border-radius: 20px;
            background-color: white;
            // padding-bottom: 0;
            // margin-bottom: 0;
        }
       .image-carousel {
      
      
       }
        .product-detail-image-cardbody{
            padding: 1rem !important;
            background-size: cover;
            background-position: center top;
            background-repeat: no-repeat;
            img {
                border-radius: 10px;
            }
            .send_comment_btn{
              background-color: #212529;
            }
            .product-img-tags{
              width: 50px;
              height: 50px;
              border-radius: 5px;
              
          }
            
             
        }
        .product-detail-badge{
          color: #000;
          background-color: #e4e4e4;
        }
    }
    .image-cross-left, .image-cross-right {
        background-color:rgba(173, 181, 189, 0.2);
        font-size: 14px !important;
        height: 25px;
        min-height: 25px;
        max-height: 25px;
        width: 25px;
        min-width: 25px;
        max-width: 25px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0.5rem;
        &:hover {
            background-color:rgba(173, 181, 189, 0.5);
            color: #ffff;
        }
    }
      .product-image {
            border-radius: 20px;
            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */
        }
            .product-description-wrapper {
                .card {
                    min-height: 20rem !important;
            max-height: 20rem !important;
            border-radius: 20px !important;
          
                .card-body{
                  overflow-y: scroll;
                    padding-top: 0 !important;
                    margin-bottom: 2rem !important;
                    @media (min-width: 576px){
                        height: 10rem;
                        overflow: auto;
                    }
                    @media (min-width: 1200px) {
                        padding: 1.5rem;
            
                    }
                }
                }
               
               
            }
            .hearth {
                background-color: #FFE6E7;
                border-radius: 10px;
                margin-right: 5px;
                i {
                    color: #DC3445;
                    font-size: 25px;
                    font-weight: 600;
                }
            }
            .shared {
                background-color: #F0EFFB;
                border-radius: 10px;
                margin-right: 5px;
                i {
                    color: #6A40BB;
                    font-size: 25px;
                    font-weight: 600;
                }
            }
            .sale {
                background-color: #EDF8F7;
                border-radius: 10px;
                margin-right: 5px;
                i {
                    color: #45BBA8;
                    font-size: 25px;
                    font-weight: 600;
                }
            }
            .view {
                background-color: #EDF8F7;
                border-radius: 10px;
                margin-right: 5px;
                i {
                    color: #45BBA8;
                    font-size: 25px;
                    font-weight: 600;
                }
            }
          
        
}
.chart-mini-responsive{
    display: flex;
  }
  @media only screen and (max-width: 1920px) {
    .post-info-section {
      width: 78% ;
    }
  }
  @media only screen and (max-width: 1650px) {
    .post-info-section {
      width: 72% ;
    }
  }
  @media only screen and (max-width: 1440px) {
    .post-info-section {
      width: 69%;
    }
  }
  @media only screen and (max-width: 1330px) {
    .chart-mini-responsive {
      display: none !important;
    }
  }
  
  @media only screen and (max-width: 1300px) {
    .post-info-section {
      width: 58% ;
    }
  }
  .product-store-badge{
    background-color: #e3ecf8;
    color:#2b335d; 
    padding: 7px 10px;

  }
  .user-static-card{
    border: none !important;
    .share-badge {
      background-color: #EBF8F6;
      color: #6d7683 !important;
      width: 166px;
      height: 36px;
      margin-right: .5rem;
  
      .badge-number {
        color: #000;
      }
  
      i {
        color: #46BCAB;
      }
    }
  
    .like-badge {
      background-color: #EAF3FF;
      color: #6d7683 !important;
      width: 166px;
      height: 36px;
      margin-right: .5rem;
  
      .badge-number {
        color: #000;
      }
  
      i {
        color: #2D87FF;
      }
    }
  
    .comment-badge {
      background-color: #FFF5DC;
      color: #6d7683 !important;
      width: 166px;
      height: 36px;
      margin-right: .5rem;
  
      .badge-number {
        color: #000;
      }
  
      i {
        color: #F3BF37;
      }
    }
  
    .save-badge {
      background-color: #EAF3FF;
      color: #6d7683 !important;
      width: 166px;
      height: 36px;
      margin-right: .5rem;
  
      .badge-number {
        color: #000;
      }
  
      i {
        color: #4d69fa;
      }
    }
  
    .view-badge {
      background-color: #FFEEE9 !important;
      color: #6d7683;
      width: 166px;
      height: 36px;
      margin-right: .5rem;
  
      .badge-number {
        color: #000 !important;
      }
  
      i {
        color: #F35421;
      }
    }
  
    .impression-badge {
      background-color: #F0EFFB !important;
      color: #6d7683;
      width: 166px;
      height: 36px;
      margin-right: .5rem;
  
      .badge-number {
        color: #000 !important;
      }
  
      i {
        color: #6C5DD3;
      }
    }
  
    .reach-badge {
      background-color: #FFEEF7 !important;
      color: #6d7683;
      width: 166px;
      height: 36px;
      margin-right: .5rem;
  
      .badge-number {
        color: #000 !important;
      }
    }
  }
  .line{
    border-left: 1.5px solid #cdcdcd;
    height: 25px;
  }